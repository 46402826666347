import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, DatePicker } from 'antd'
import styles from './css/datePicker.module.scss'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/en-gb'
import locale from 'antd/es/date-picker/locale/en_GB'

const DatePickerFilter = observer(
  ({ onFilter, onReset, selectedData, forceCleanup }) => {
    const [selectedValue, setSelectedValue] = useState(selectedData)
    const { t } = useTranslation()

    useEffect(() => {
      if (forceCleanup && (!selectedData || !selectedData.length)) {
        setSelectedValue([])
      }
    }, [forceCleanup, selectedData])

    const handleSelectChange = (value) => {
      setSelectedValue(value)
    }

    const handleFilter = () => {
      selectedValue.length ? onFilter(selectedValue) : handleReset()
    }

    const handleReset = () => {
      setSelectedValue([])
      onReset()
    }

    return (
      <div className={styles.filterSelectWrapper}>
        <div className={styles.filterSelectWrapper__buttonsWrapper}>
          <Button
            type="primary"
            onClick={handleFilter}
            className={styles.filterSelectWrapper__buttonsWrapper_submit}
          >
            {t('w_ok')}
          </Button>
          <Button
            onClick={handleReset}
            className={styles.filterSelectWrapper__buttonsWrapper_reset}
          >
            {t('w_clear')}
          </Button>
        </div>
        <DatePicker.RangePicker
          allowClear={false}
          value={selectedValue}
          onChange={handleSelectChange}
          placeholder={[t('w_start_date'), t('w_final_date')]}
          locale={locale}
        />
      </div>
    )
  }
)

export default DatePickerFilter
