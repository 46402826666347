import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import api from '../utils/axiosAuthInterceptor'
import dayjs from 'dayjs'
import PartsAnalyticsStore from './PartsAnalyticsStore'
import templateApi from '../api/parts/templateApi'

const download = require('downloadjs')
const axios = api

const wsAddress = process.env.REACT_APP_WS_SERVER_ADDRESS
const wsType = process.env.REACT_APP_WS_TYPE

class PartsStore {
  analyticsStore = new PartsAnalyticsStore()
  signLoading = false
  signatureLoading = false
  saveLoading = false
  cancelLoading = false
  isLoading = false
  templateOrder = {}

  ordersLoaded = false
  orderLoaded = false
  orderDataLoaded = false
  orders = []
  archiveOrders = []
  users = []
  access = undefined
  writeAccess = false
  templatesStatuses = {}
  isTemplateEdit = false
  finalIssues = false

  comments = []
  commentInput = ''
  commentFiles = []
  irIssuesFiles = []
  isDisabledCommentButton = true
  isCommentButtonLoading = false
  versions = undefined
  page = 1

  isDisabledRequestCommentButton = true
  modalOpened = false
  archiveModalOpened = false
  openedContract = undefined

  instructionFile = undefined
  sellerStamp = undefined
  buyerStamp = undefined
  chineseRows = []

  templateDataRows = []
  order = null
  POLoadingButtons = false
  orderName = ''
  factoryEmails = undefined
  hiddenTableColumns = []

  createModalOpened = false
  factories = undefined
  persons = undefined
  QCpersons = []
  createOrderData = {
    name: '',
    description: '',
    factoryId: undefined,
    person: undefined,
    contractId: '',
  }
  createButtonDisabled = true
  importLoading = false
  searchFactories = []

  PLDataSource = undefined

  inspectionReportData = undefined
  saveInspectionButtonLoading = false
  inspectionReportDataLoaded = false
  issuesData = undefined
  photoPreviewVisible = false
  photoPreviewImage = ''
  photoPreviewTitle = ''
  inspectionReportApproved = false
  inspectionReportRejected = false
  QCsentReview = undefined
  IReportId = undefined
  IRpermissions = undefined

  downloadInspectionLoading = false
  approveLoading = false
  rejectInspectionLoading = false
  PLLoadingButtons = false
  inspectionLoadingButtons = false
  QCsendReviewLoading = false

  sendToFactoryComment = ''
  sendToFactoryVersion = undefined
  sendToFactoryLoading = false

  finalTemplates = []
  templatesArchive = {}

  archiveModalVisible = false
  archiveContractVisible = false
  archiveTemplatesVisible = false
  archiveType = undefined

  importTemplate = null
  canLoadPackingList = false
  loadAccess = {}
  orderAccess = {}

  plTemplates = []
  finalPlTemplates = []
  irTemplates = []
  partPoFinal = []
  template = {}

  currencyLoading = false
  selectLoading = false
  modals = {}
  issuesComments = []
  addIssuePermission = undefined
  inspectionId = undefined
  creatorList = []
  buyersList = []
  newOrderId = ''
  contractExists = false

  filtersParams = {
    responsiblePerson: {
      filtered: '',
      input: '',
      options: [],
    },
    qc: {
      filtered: '',
      input: '',
      options: [],
    },
    status: {
      filtered: [],
      input: [],
      options: [
        { value: 1, label: 'w_open' },
        { value: 2, label: 'w_closed' },
        { value: 4, label: 'w_done' },
      ],
    },
    type: {
      filtered: [],
      input: [],
      options: [
        { value: 'seasonal', label: 'w_seasonal' },
        { value: 'regular', label: 'w_regular' },
      ],
    },
    name: {
      filtered: [],
      input: [],
      outerTableInput: [],
      outerTableFiltered: [],
      options: [],
    },
    archiveName: {
      outerTableInput: [],
      outerTableFiltered: '',
      options: [],
    },
    factories: {
      outerTableInput: [],
      outerTableFiltered: [],
      options: [],
    },
    byParts: {
      opened: false,
      filtered: [],
      input: [],
      options: [],
    },
    deliveryDate: {
      filtered: [],
      input: [],
      checked: false,
    },
    creationDate: {
      filtered: [],
      input: '',
    },
    planningDeliveryDate: {
      filtered: [],
      input: [],
    },
    creator: {
      filtered: '',
      input: '',
      options: [],
    },
    contractApprovedStatus: {
      opened: false,
      filtered: [],
      input: [],
      options: [
        { value: 'po_uploaded', label: 'w_po_uploaded' },
        { value: 'price_rejected', label: 'w_price_rejected' },
        { value: 'awaiting_approval', label: 'w_awaiting_approval' },
        { value: 'price_approved', label: 'w_price_approved' },
        { value: 'prices_signed', label: 'w_prices_signed' },
        { value: 'prices_resigned', label: 'w_prices_resigned' },
      ],
    },
    delayed: {
      opened: false,
      checked: false,
      input: '',
    },
  }
  sorters = {
    column: '',
    order: '',
  }
  filter = ''
  limit = 20
  offset = 0
  totalContracts = undefined
  ordersUploadsQty = undefined

  progress = 0
  progressText = ''
  totalRows = null
  createButtonLoading = false
  viewPermission = false
  confirm = true
  intCode = ''
  productName = ''
  ws = null
  uploadSize = 0
  uploadChunk = 0
  fileToUpload = null
  source = null
  controller = null
  backButtonDisabled = false
  pageError = false
  accessError = false
  archiveCommentModalOpen = false
  rejectFile = null
  rejectText = ''
  approveText = false
  approveRejectButtons = false
  pricesApproval = false
  pricesSigning = false
  errorShown = false
  errorStatus = ''
  errorLink = ''
  errorMessage = ''
  showSignatureModal = false
  hasError = false
  activeTabKey = 'Part 0'
  archiveTab = '1'
  currencyArray = []
  viewCurrencyQuestion = false
  allowOtherCurrency = false
  orderInfoModalOpen = false
  orderInfoEditing = false
  factoryId = ''
  assignQCModalOpen = false
  shippingMethod = ''
  templateVersion = ''
  approveLoadingStates = new Map()
  downloadFileProgres = []

  // выбор колонок
  isColumnsSelectorOpened = false
  selectedColumns = []
  prevSelectedColumns = []
  selectedAllColumns = true
  selectedAllColumnsInterminate = false
  columns = []

  // изменение buyer
  isChangeBuyerSelectVisible = false

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
      description: description,
    })
  }

  setActiveTabKey(key) {
    this.activeTabKey = key
  }
  setArchiveTabKey(key) {
    this.archiveTab = key
  }

  handleError = (error, link) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 405)
    ) {
      const errorMessage = error.response.data.message || 'Access denied.'
      this.errorMessage = errorMessage
      this.errorStatus = '403'
    } else if (error.response) {
      const errorMessage = error.response.data.message || 'An error occurred.'
      this.errorMessage = errorMessage
      this.errorStatus = '500'
    } else {
      this.errorMessage = error.message
      this.errorStatus = '500'
    }
    this.errorLink = link
    this.hasError = true
  }
  clearErrors = () => {
    this.errorStatus = ''
    this.errorLink = ''
    this.errorMessage = ''
    this.hasError = false
  }

  getPartsAccess = async () => {
    try {
      const response = await axios.get('/api/node/v1/parts/accesses')
      if (response.data.success) {
        this.access = response.data.data
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'No access.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  checkAccess = async () => {
    if (!this.access) {
      await this.getPartsAccess()
    }
  }
  extractFilteredValues = () => {
    const {
      name: { filtered: nameFiltered, outerTableFiltered },
      responsiblePerson: { filtered: responsiblePerson },
      type: { filtered: type },
      status: { filtered: status },
      deliveryDate: { filtered: deliveryDate },
      creationDate: { filtered: creationDate },
      planningDeliveryDate: { filtered: planningDeliveryDate },
      contractApprovedStatus: { filtered: contractApprovedStatus },
      creator: { filtered: creator },
      qc: { filtered: qc },
      byParts: { filtered: byParts },
      factories: { outerTableFiltered: factories },
    } = this.filtersParams

    return {
      byParts,
      factories,
      name: outerTableFiltered.length > 0 ? outerTableFiltered : nameFiltered,
      responsiblePerson,
      type,
      status,
      deliveryDate: {
        onlyExpired: this.filtersParams['deliveryDate'].checked,
        between: deliveryDate,
      },
      creationDate,
      planningDeliveryDate,
      contractApprovedStatus,
      creator,
      qc,
    }
  }

  getOrders = async () => {
    this.ordersLoaded = false
    await this.checkAccess()
    const {
      name: { filtered, outerTableFiltered },
      responsiblePerson: { filtered: responsiblePerson },
      type: { filtered: type },
      status: { filtered: status },
      deliveryDate: { filtered: deliveryDate },
      creationDate: { filtered: creationDate },
      planningDeliveryDate: { filtered: planningDeliveryDate },
      contractApprovedStatus: { filtered: contractApprovedStatus },
      creator: { filtered: creator },
      qc: { filtered: qc },
      byParts: { filtered: byParts },
      factories: { outerTableFiltered: factories },
    } = this.filtersParams
    const filters = {
      byParts,
      factories,
      name: outerTableFiltered.length > 0 ? outerTableFiltered : filtered,
      responsiblePerson,
      type,
      status,
      deliveryDate: {
        onlyExpired: this.filtersParams['deliveryDate'].checked,
        between: deliveryDate,
      },
      creationDate,
      planningDeliveryDate,
      contractApprovedStatus,
      creator,
      qc,
    }

    const { column, order } = this.sorters
    try {
      const response = await axios.post('/api/node/v1/parts/orders/active', {
        filters,
        pagination: {
          offset: this.offset,
          limit: this.limit,
        },
        sorters: {
          column,
          order,
        },
      })
      if (response.data.success) {
        this.orders = response.data.data.orders
        this.totalContracts = response.data.data.totalContracts
        this.ordersUploadsQty = response.data.data.ordersUploadsQty
        this.contracts = response.data.data.filterList?.contractsAll
        this.filtersParams['name'].options =
          response.data.data.filterList?.contractNames.map((el) => ({
            id: el.value,
            value: el.label,
            label: el.label,
          }))
        this.filtersParams['creator'].options =
          response.data.data.filterList?.creators
        this.filtersParams['responsiblePerson'].options =
          response.data.data.filterList?.responsiblePersons
        this.filtersParams['qc'].options =
          response.data.data.filterList?.qcEmployee
        this.filtersParams['factories'].options =
          response.data.data.filterList?.factories
        this.importTemplate = null
      }
    } catch (error) {
      this.handleError(error, '/')
    }
    this.ordersLoaded = true
  }

  getSparePartsOptions = async () => {
    const filters = this.extractFilteredValues()
    this.selectLoading = true
    try {
      const response = await axios.post(`/api/node/v1/parts/orders/filters`, {
        filters,
      })
      this.filtersParams['byParts'].options = response.data.data
    } catch (error) {
      console.error('Error getting spare parts options', error)
    } finally {
      this.selectLoading = false
    }
  }

  changePage = async (pagination) => {
    const newPageSize = pagination.pageSize
    const isPageSizeChanged = this.limit !== newPageSize
    this.page = isPageSizeChanged ? 1 : pagination.current
    this.offset = isPageSizeChanged ? 0 : (this.page - 1) * newPageSize
    this.limit = newPageSize
    await this.getOrders()
  }

  getArchiveOrders = async (update = false) => {
    await this.checkAccess()
    const {
      archiveName: { outerTableFiltered: archiveName },
    } = this.filtersParams
    const filters = {
      name: archiveName || [],
    }

    try {
      let response = await axios.post('/api/node/v1/parts/orders/archive', {
        filters,
      })
      if (response.data.success) {
        this.archiveOrders = response.data.data.orders
        this.filtersParams['archiveName'].options = response.data.data.orders
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while opening archive.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  openCreateModal = async () => {
    this.createOrderData = {
      name: '',
      description: '',
      factoryId: undefined,
      type: '',
      abbreviation: '',
      contractId: '',
    }
    if (!this.factories) await this.getFactories()
    if (!this.persons) await this.getResponsiblePersons()
    this.createModalOpened = true
  }

  setOrderName = ({ target }) => {
    this.createOrderData.name = target.value
    this.checkData()
  }

  setOrderDescription = ({ target }) => {
    this.createOrderData.description = target.value
  }

  setOrderContractId = (value) => {
    this.createOrderData.contractId = value
    this.checkData()
  }

  setOrderFactory = (value, name) => {
    this.createOrderData.factoryId = value
    this.createOrderData.name = name
    this.checkData()
  }

  setOrderPerson = (value) => {
    this.createOrderData.person = value
    this.checkData()
  }

  setOrderType = (value) => {
    this.createOrderData.type = value
    this.checkData()
  }

  getFactories = async () => {
    let response = await fetch('/api/node/v1/parts/getFactories')
    let data = await response.json()
    this.factories = data.data
    return
  }

  getResponsiblePersons = async () => {
    let response = await fetch('/api/node/v1/parts/getPersons')
    let data = await response.json()
    this.persons = data.data.responsible
    this.QCpersons = data.data.qc
    return
  }

  closeCreateModal = () => {
    this.createModalOpened = false
    this.createButtonDisabled = true
    this.contractExists = false
  }

  checkData = () => {
    let orderData = this.createOrderData
    if (
      orderData.name &&
      orderData.name !== '' &&
      orderData.factoryId &&
      orderData.person
    )
      this.createButtonDisabled = false
    else this.createButtonDisabled = true
  }

  createEditOrder = async () => {
    try {
      this.createButtonLoading = true
      if (this.createOrderData.type === '')
        this.createOrderData.type = 'unknown'
      let body = { ...this.createOrderData }
      body.name = this.checkPostFix(body.name)

      if (
        !this.filtersParams['name'].options.find((el) => el.label === body.name)
      ) {
        let response = await axios.post('/api/node/v1/parts/order', body)

        if (response.data.success) {
          // this.openNotificationWithIcon("success", "Order saved!");
          this.closeCreateModal()
          this.newOrderId = response.data.data.orderId
        }
      } else {
        this.contractExists = true
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    } finally {
      this.createButtonLoading = false
      this.newOrderId = ''
    }
  }

  openArchiveContract = async (index) => {
    this.order = {
      id: this.archiveOrders[index].id,
    }
    await this.getOrderData(this.archiveOrders[index].id, false, true)
    this.archiveModalOpened = true
  }

  getOrderData = async (orderId, update = false, archive = '') => {
    if (!update) this.orderLoaded = false
    await this.checkAccess()
    this.orderDataLoaded = true
    try {
      const response = await axios.get(`/api/node/v1/parts/order/${orderId}`, {
        params: { archive },
      })
      if (response.data.success) {
        const data = response.data.data
        if (archive === '1') {
          this.templatesArchive = {
            PO: data.PO,
            PL: data.PL,
            PLfinal: data.PLfinal,
            POfinal: data.POfinal,
          }
        }
        if (archive === '') {
          this.versions = data.PO
          this.finalTemplates = data.POfinal
          this.plTemplates = data.PL
          this.finalPlTemplates = data.PLfinal
          this.partPoFinal = data.PartPoFinal
          this.irTemplates = data.IR
        }
        this.order = data.order
        this.comments = data.comments
        this.canLoadPackingList = data.access.canLoadPL
        this.factoryId = data.order.factory_id
        this.orderAccess = data.access
        if (!update) this.orderLoaded = true
        this.importLoading = false
        this.orderDataLoaded = false
        this.creatorList = data.creatorList
        this.buyersList = data.buyers
      }
    } catch (error) {
      this.handleError(error, '/react/parts')
    }
  }

  getTemplatesStatuses = async (orderId) => {
    let response = await axios.get(
      `/api/node/v1/parts/getTemplatesStatuses/${orderId}`
    )
    this.templatesStatuses = response.data.data
    return
  }

  toggleEditOrder = () => {
    this.orderInfoEditing = !this.orderInfoEditing
  }

  checkPostFix = (name) => {
    const reg = /_sp$/
    if (!reg.test(name)) name += '_sp'
    return name
  }

  saveContractInfoModal = async (values) => {
    if (Object.keys(values).length === 0) {
      this.orderInfoEditing = false
    } else {
      const body = {
        orderId: this.order.id,
        data: { ...values },
      }
      if (values.hasOwnProperty('name')) {
        body.data.name = this.checkPostFix(values.name)
      }
      try {
        const response = await axios.patch('/api/node/v1/parts/order', body)
        if (response.data.success) {
          this.openNotificationWithIcon('success', 'Saved')
        }
        await this.getOrderData(this.order.id, true)
      } catch (error) {
        if (error.response) {
          const errorMessage =
            error.response.data.message || 'An error occurred.'
          this.openNotificationWithIcon('error', errorMessage)
        } else {
          this.openNotificationWithIcon('error', error.message)
        }
      }
      this.orderInfoEditing = false
    }
  }

  changeOrderStatus = async (orderId, idx, status) => {
    this.orders[idx].changeStatusLoading = true
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/order/status/${orderId}`,
        {
          status,
        }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Status changed!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while changing status'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.orders[idx].changeStatusLoading = false
    await this.getOrders()
  }

  downloadFile = (fileId, type) => {
    this.downloadFileLoading = true
    axios
      .get(`/api/node/v1/file/get/${type}/${fileId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const filename = decodeURI(response.headers['content-disposition'])
        download(response.data, filename)
        this.downloadFileLoading = false
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message ||
            'An error occurred while downloading the file.'
          this.openNotificationWithIcon('error', errorMessage)
        } else {
          this.openNotificationWithIcon(
            'error',
            'An error occurred while downloading the file.'
          )
        }
      })
  }

  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(`${link}`, {
        responseType: 'blob',


        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          // Находим объект файла в массиве и обновляем его прогресс
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      // Устанавливаем статус файла в "exception"
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }

  closeContract = () => {
    this.modalOpened = false
    this.importTemplate = null
    this.getOrders()
  }

  closeArchiveContract = () => {
    this.archiveModalOpened = false
    this.importTemplate = null
  }

  changeCommentInput = (value) => {
    this.commentInput = value
    this.checkCommentButtonDisabled()
  }

  changeCommentFiles = (value) => {
    this.commentFiles = value.target.files
  }

  changeIrIssuesFiles = (value) => {
    this.irIssuesFiles = value.target.files
  }

  saveNewComment = async () => {
    this.isCommentButtonLoading = true
    this.isDisabledCommentButton = false
    const body = {
      orderId: this.order.id,
      text: this.commentInput,
    }
    try {
      let response = await axios.post(`/api/node/v1/parts/order/comment`, body)
      if (response.data.success) {
        if (this.commentFiles.length > 0) {
          const data = new FormData()
          data.append('orderId', this.order.id)
          this.commentFiles.forEach((file) => {
            data.append('trio', file.originFileObj)
          })
          await axios.post(
            `/api/node/v1/parts/order/comments/upload/${this.order.id}/${response.data.data}`,
            data
          )
        }
        this.openNotificationWithIcon('success', 'Saved!')
        await this.getOrderData(this.order.id, true)
        this.commentInput = '<p><br></p>'
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          'An error occurred while saving the comment.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon(
          'error',
          'An error occurred while saving the comment.'
        )
      }
    }
    this.commentFiles = []
    this.isCommentButtonLoading = false
    this.isDisabledCommentButton = true
  }

  checkCommentButtonDisabled = () => {
    const inputWithoutTags = this.commentInput.replace(/<[^>]*>/g, '')
    const trimmedValue = inputWithoutTags.trim()
    if (trimmedValue.length === 0 || this.commentInput === '<p><br></p>')
      this.isDisabledCommentButton = true
    else this.isDisabledCommentButton = false
  }

  clearFilters = async () => {
    for (let filter in this.filtersParams) {
      this.filtersParams[filter].opened = false
      this.filtersParams[filter].input = []
      this.filtersParams[filter].filtered = []
      this.filtersParams[filter].outerTableInput = []
      this.filtersParams[filter].outerTableFiltered = []
      this.filtersParams[filter].checked = false
    }
    this.searchFactories = []
    this.sorters.column = ''
    this.sorters.order = ''
    this.page = 1
    this.offset = 0
    this.limit = 20
    await this.getOrders()
    await this.getSparePartsOptions()
  }

  changeFilter = (value, type) => {
    this.filtersParams[type].input = value
  }

  changeOuterTableFilter = (value, type) => {
    this.filtersParams[type].outerTableInput = value
    if (type === 'archiveName' && value.length === 0) {
      this.filtersParams[type].outerTableFiltered =
        this.filtersParams[type].outerTableInput
      this.getArchiveOrders()
    }
  }

  correctDateFilter = (dates, type) => {
    const modifiedStartDate = dayjs(dates[0])
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    const modifiedEndDate = dayjs(dates[1])
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss')
    this.filtersParams[type].input = [modifiedStartDate, modifiedEndDate]
    return [modifiedStartDate, modifiedEndDate]
  }

  onClearFilter = (type) => {
    this.filtersParams[type].input = []
    this.filtersParams['deliveryDate'].checked = false
    this.acceptTableFilter(type, [])
  }

  acceptTableFilter = (type, data, isChecked = undefined) => {
    if (isChecked !== undefined) {
      this.filtersParams['deliveryDate'].checked = isChecked
    }
    this.filtersParams[type].filtered = data
    this.offset = 0 // check
    this.page = 1
    this.getOrders()
  }
  // фильтр вне таблицы
  acceptSearchUpperFilter = async (field) => {
    this.filtersParams[field].outerTableFiltered =
      this.filtersParams[field].outerTableInput
    this.filtersParams[field].filtered = []
    if (field === 'archiveName') {
      await this.getArchiveOrders()
    } else {
      this.offset = 0
      this.page = 1
      await this.getOrders()
      await this.getSparePartsOptions()
    }
  }
  //фильтр для запчастей
  acceptSparePartsFilter = async () => {
    const arrayOfValues = []
    this.filtersParams['byParts'].input.forEach((val) => {
      const parsedValue = JSON.parse(val)
      arrayOfValues.push(parsedValue)
    })
    this.filtersParams['byParts'].filtered = arrayOfValues
    this.offset = 0
    this.page = 1
    await this.getOrders()
    await this.getSparePartsOptions()
  }

  setSortOrder = (type) => {
    this.sorters.order = type
  }
  setSortColumn = (type) => {
    this.sorters.column = type
  }

  //get PO & Final Template
  getPOTemplate = async (orderId, id) => {
    this.isLoading = true
    this.isTemplateEdit = false
    await this.checkAccess()
    try {
      const response = await templateApi.getPOTemplate(orderId, id)
      this.templateOrder = response.data.order
      this.templateDataRows = response.data.rows
      this.templateType = response.data.template.type
      this.template = response.data.template
      this.hiddenTableColumns = response.data.template.hiddenColumns
      if (this.templateType !== 'po') {
        this.sellerStamp = response.data.order.seller?.stampFilename
        this.instructionFile = response.data.template.instructionFile
        this.buyerStamp = response.data.order.buyer?.stampFilename
        this.chineseRows = response.data.template.chineseRows
      }
    } catch (error) {
      this.handleError(error, `/react/parts/order?id=${id}`)
    } finally {
      this.isLoading = false
    }
  }

  connectToWS = async () => {
    this.progress = 0
    this.totalRows = 0
    this.intCode = ''
    this.productName = ''
    let userId = localStorage.userId
    return new Promise((resolve, reject) => {
      let server = new WebSocket(
        `${wsType}://${wsAddress}/sockjs-node/${userId}`
      )

      this.uploadSize = 0

      server.addEventListener('message', (event) => {
        let message = JSON.parse(event.data)
        if (message.type === 'total') {
          this.totalRows = message.data
        }
        if (message.type === 'loading') {
          this.uploadSize += message.data.percent
          this.progress = ((this.uploadSize / this.totalRows) * 90).toFixed(1)
          this.progressText = message.data.title
        }
        if (message.type === 'writing') {
          this.progress = (90 + (10 / 6) * (message.data.percent + 1)).toFixed(
            1
          )
          this.progressText = message.data.title
        }
        if (message.type === 'progress') {
          this.progress = (
            (message.data.percent / this.totalRows) *
            100
          ).toFixed(1)
          this.progressText = message.data.title
        }
        if (message.type === 'permission') {
          this.viewPermission = true
          this.intCode = message.data.intCode
          this.productName = message.data.prodName
        }
        if (message.type === 'currencySelection') {
          this.viewCurrencyQuestion = true
          this.currencyArray = message.data.currency
        }
        if (message.type === 'allowOtherCurrency') {
          this.allowOtherCurrency = true
        }
      })

      server.onopen = function () {
        resolve(server)
      }

      server.onerror = function (err) {
        reject(err)
      }
    })
  }

  // import PO & Final Template
  getTemplateType = () => {
    const indexTemplate = this.importTemplate
    const dataType = {}
    if (indexTemplate === 1) {
      dataType.template = 'POTemplate'
      dataType.type = 'po'
    }
    if (indexTemplate === 2) {
      dataType.template = 'POTemplate'
      dataType.type = 'prices'
    }
    if (indexTemplate === 3) {
      dataType.template = 'POTemplate'
      dataType.type = 'part_po_final'
    }
    return dataType
  }
  importFile = (file) => {
    this.fileToUpload = file
    if (!this.fileToUpload) return 0
    this.importLoading = true
    this.backButtonDisabled = true
    this.uploadPO()
  }

  uploadPLTemplate = async (file, isFinal, draftFinalId, partOfFinalId) => {
    this.importLoading = true
    this.backButtonDisabled = true
    const orderId = this.order.id
    const data = new FormData()
    data.append('temp', file)
    data.append('orderId', orderId)
    data.append('type', 'PLTemplate')
    data.append('isFinal', isFinal)
    if (draftFinalId !== undefined) {
      data.append('draftFinalId', draftFinalId)
    }
    if (partOfFinalId !== undefined) {
      data.append('partOfFinalId', partOfFinalId)
    }

    const server = await this.connectToWS()
    this.ws = server

    try {
      const response = await axios.post(
        '/api/node/v1/parts/import/PLTemplate',
        data
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File uploaded!')
        await this.getOrderData(orderId, true)
      } else {
        this.openNotificationWithIcon(
          'error',
          response.data.errors ?? response.data.message
        )
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while uploading file'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    server.close()
    this.backButtonDisabled = false
    this.importLoading = false
  }

  uploadPO = async () => {
    const orderId = this.order.id
    if (!this.fileToUpload) return 0

    const CHUNK_SIZE = 384000
    const totalChunks = Math.floor(this.fileToUpload.size / CHUNK_SIZE)

    let server = await this.connectToWS()
    this.ws = server

    const send = async (piece, start, end, size) => {
      const dataType = this.getTemplateType()
      const formData = new FormData()
      formData.append('file', piece)
      formData.append('orderId', orderId)
      formData.append('type', dataType.type)
      formData.append('start', start)
      formData.append('end', end)
      // 6/03 TODO EDIT for request cancelling
      // this.controller = new AbortController();
      // console.log('controller', this.controller)
      // const CancelToken = axios.CancelToken;
      // this.source = CancelToken.source();

      const fileName = this.fileToUpload.name

      axios
        .post(`/api/node/v1/parts/import/${dataType.template}`, formData, {
          headers: {
            'Content-Range': 'bytes ' + start + '-' + end + '/' + size,
            'Content-Disposition': encodeURIComponent(fileName),
          },
          // signal: this.controller.signal,
          // cancelToken: this.source.token
        })
        .then(async (response) => {
          if (response.data.success) {
            server.close()
            await this.getOrderData(orderId, true)
            this.backButtonDisabled = false
            this.approveRejectButtons = true //check later
            this.openNotificationWithIcon('success', 'File uploaded!')
          }
          if (!response.status === 200) {
            server.close()
            this.importLoading = false
            this.backButtonDisabled = false
          }
          if (response.data.success === false) {
            throw new Error(response.data.message)
          }
        })
        .catch((error) => {
          if (!this.errorShown) {
            if (error.response) {
              const errorMessage =
                error.response.data.message ||
                'An error occurred while uploading the file.'
              this.openNotificationWithIcon('error', errorMessage)
            } else {
              this.openNotificationWithIcon('error', error.message)
            }
          }
          server.close()
          this.importLoading = false
          this.backButtonDisabled = false
          this.errorShown = true
          //   if (this.controller.signal.aborted) {
          //       console.log("Request has been aborted");
          //     }
          //   if(axios.isCancel(error)) {
          //     console.log(error.message);
          //  }
        })
    }
    this.errorShown = false
    axios
      .get('/api/node/v1/parts/import/clear/POTemplate')
      .then((response) => {
        for (let chunk = 0; chunk < totalChunks + 1; chunk++) {
          const CHUNK = this.fileToUpload.slice(
            chunk * CHUNK_SIZE,
            (chunk + 1) * CHUNK_SIZE
          )
          send(CHUNK, chunk, totalChunks + 1, this.fileToUpload.size)
        }
      })
      .catch((err) => {
        this.openNotificationWithIcon('error', 'Error')
      })
  }

  uploadIRFile = async (file, inspectionId) => {
    this.inspectionLoadingButtons = true
    const data = new FormData()
    data.append('trio', file)
    data.append('orderId', this.order.id)
    data.append('id', inspectionId)
    try {
      const response = await axios.post(
        `/api/node/v1/parts/inspection/upload/${this.order.id}/IR/${inspectionId}
    `,
        data
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File uploaded!')
        await this.getOrderData(this.order.id, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while uploading file'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.inspectionLoadingButtons = false
  }

  generateIR = async (id) => {
    const orderId = this.order.id
    const data = {
      orderId,
      id,
    }
    try {
      const response = await axios.post(
        `/api/node/v1/parts/inspection/generate
    `,
        data
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File uploaded!')
        await this.getOrderData(this.order.id, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while uploading file'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  generateFinalPL = async (isFinal, finalPLId, partOfFinalId) => {
    console.log(
      'versionId, isFinal, finalPLId',
      this.order.id,
      isFinal,
      finalPLId,
      partOfFinalId
    )
    const orderId = this.order.id
    const data = {
      orderId,
      isFinal,
      draftFinalId: finalPLId,
      partOfFinalId,
    }
    try {
      const response = await axios.post(
        `/api/node/v1/parts/packing/generate
    `,
        data
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File uploaded!')
        await this.getOrderData(this.order.id, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while uploading file'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  onCountDownFinish = () => {
    this.ws.send(
      JSON.stringify({
        type: 'sp module',
        data: { subtype: 'rejection', payload: null },
      })
    )
    this.viewPermission = false
    this.viewCurrencyQuestion = false
  }
  confirmIntCode = (e) => {
    this.ws.send(
      JSON.stringify({
        type: 'sp module',
        data: { subtype: e.currentTarget.value, payload: null },
      })
    )
    this.viewPermission = false
  }

  chooseCurrency = (e) => {
    this.ws.send(
      JSON.stringify({
        type: 'sp module',
        data: { subtype: 'resolve', payload: e.currentTarget.value },
      })
    )
    this.viewCurrencyQuestion = false
  }

  confirmAnotherCurrency = (value) => {
    this.ws.send(
      JSON.stringify({ type: 'sp module', data: { subtype: value } })
    )
    this.allowOtherCurrency = false
  }

  getPackingList = async (orderId, version) => {
    this.PLDataSource = undefined
    this.isTemplateEdit = false
    await this.checkAccess()
    if (!this.order) this.order = { id: orderId }
    try {
      const response = await axios.get(
        `/api/node/v1/parts/getPackingList/${orderId}/${version}`
      )
      if (response.data.success) {
        const data = response.data.data
        this.orderName = data.orderName
        this.currency = data.currency
        this.PLDataSource = data.data
        this.shippingMethod = data.deliveryTerms
        this.templateVersion = `${data.createdAt} - PL - version ${data.version}`
      }
    } catch (error) {
      this.handleError(error, `/react/parts/order?id=${orderId}`)
    }
  }

  getInspectionReport = async (orderId, version) => {
    this.inspectionReportDataLoaded = false
    this.isTemplateEdit = false
    await this.checkAccess()
    if (!this.order) this.order = { id: orderId }
    try {
      const response = await axios.get(
        `/api/node/v1/parts/getInspectionReport/${orderId}/${version}`
      )
      if (response.data.success) {
        this.inspectionReportData = response.data.data.report
        this.inspectionReportApproved = response.data.data.approved_at
        this.inspectionReportRejected = response.data.data.rejected_at
        this.currency = response.data.data.currency
        this.orderName = response.data.data.orderName
        this.issuesData = response.data.data.issues
        this.users = response.data.data.users
        this.shippingMethod = response.data.data.deliveryTerms
        this.inspectionReportDataLoaded = true
        this.QCsentReview = response.data.data.sentReview
        this.IReportId = response.data.data.id
        this.IRpermissions = response.data.data.permissions
        this.templateVersion = `${response.data.data.createdAt} - Inspection - version ${response.data.data.version}`
      }
    } catch (error) {
      this.handleError(error, `/react/parts/order?id=${orderId}`)
    }
  }

  setApproveLoading(idx, isLoading) {
    this.approveLoadingStates.set(idx, isLoading)
  }
  //inInspection  и idx нужны, потому что одни те же кнопки ввнутри инспекшна и снаружи
  approveInspectionReport = async (
    orderId,
    inspectionId,
    ver,
    idx = 0,
    inInspection = false
  ) => {
    this.setApproveLoading(idx, true)
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/inspection/approve`,
        { orderId, inspectionId }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Approved!')
        inInspection
          ? await this.getInspectionReport(orderId, ver)
          : await this.getOrderData(orderId, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while approving report'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.setApproveLoading(idx, false)
  }

  rejectInspectionReport = async (values) => {
    this.rejectInspectionLoading = true
    const orderId = this.order.id
    try {
      const formData = new FormData()
      formData.append('orderId', orderId)
      formData.append('inspectionId', this.IReportId)
      formData.append('comment', values.comment)
      if (values.file) {
        formData.append('trio', values.file)
      }
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      const response = await axios.patch(
        `/api/node/v1/parts/inspection/reject/${orderId}/${this.IReportId}`,
        formData,
        config
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Rejected!')
        this.inInspection
          ? await this.getInspectionReport(orderId, this.irVersion)
          : await this.getOrderData(orderId, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while rejecting report'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.closeModal('rejectInspection')
    this.rejectInspectionLoading = false
  }

  sendIRForReview = async (orderId, id, version) => {
    this.QCsendReviewLoading = true
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/inspection/review`,
        { orderId, id }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Review was sent')
        await this.getInspectionReport(orderId, version)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while sending review'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.QCsendReviewLoading = false
  }

  changeInspectionValue = (value, index, type) => {
    let temp = [...this.inspectionReportData]
    temp[index][type] = value
    this.inspectionReportData = temp
  }

  saveInspectionReport = async (id, ver) => {
    this.saveInspectionButtonLoading = true
    let newInspectionData = []

    for (let row of this.inspectionReportData) {
      if (row.number === 'Total') break
      let obj = Object.assign({}, row)
      delete obj.photos
      newInspectionData.push(obj)
    }
    try {
      const response = await axios.post(
        '/api/node/v1/parts/saveInspectionReport',
        {
          data: newInspectionData,
          issues: this.issuesData,
          orderId: id,
        }
      )
      if (response.data.success) {
        for (let row of this.inspectionReportData) {
          if (row.number === 'Total') break

          row.changedPhoto = false
          let data = new FormData()
          data.append('rowId', row.id)
          data.append('orderId', id)
          if (row.photos?.length === 0 && row.deletedPhoto) {
            row.changedPhoto = true
          }
          if (row.newPhotos?.length) {
            for (let photo of row.newPhotos) {
              data.append('pictures', photo)
            }
            row.changedPhoto = true
          }
          if (
            (row.newPhotos?.length && row.photos?.length) ||
            (row.photos?.length && row.deletedPhoto)
          ) {
            for (let photo of row.photos) {
              let lastIdx = photo.filename.lastIndexOf('/')
              data.append('oldPics[]', photo.filename.slice(lastIdx + 1))
            }
            row.changedPhoto = true
          }
          if (row.changedPhoto) {
            await axios.post(
              `/api/node/v1/parts/upload/inspectionReports/${row.id}`,
              data
            )
          }
        }
        this.openNotificationWithIcon('success', 'Inspection report saved!')
        this.isTemplateEdit = false
        await this.getInspectionReport(id, ver)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          'An error occurred while saving the template.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon(
          'error',
          'An error occurred while saving the template.'
        )
      }
    }
    this.saveInspectionButtonLoading = false
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  handleCancel = () => {
    this.photoPreviewVisible = false
  }

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj)
    }

    this.photoPreviewImage = file.url || file.preview
    this.photoPreviewVisible = true
    this.photoPreviewTitle =
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
  }

  exportPartTemplates = async (type, version, idx, tabIndex) => {
    let orderName, url, buttonLoading, temp
    if (type === 'PartPL') {
      temp = this.partPoFinal[tabIndex].PL.active
      buttonLoading = 'PLLoadingButtons'
      url = '/api/node/v1/parts/export/PLTemplate'
    } else if (type === 'PartIR') {
      temp = this.partPoFinal[tabIndex].IR?.active
      buttonLoading = 'inspectionLoadingButtons'
      url = '/api/node/v1/parts/export/inspectionReport'
    } else if (type === 'finalPartPl') {
      temp = this.partPoFinal[tabIndex].PLfinal.active
      buttonLoading = 'PLLoadingButtons'
      url = '/api/node/v1/parts/export/PLTemplate'
    } else if (type === 'part') {
      temp = this.partPoFinal[idx].PART
      buttonLoading = 'POLoadingButtons'
      url = '/api/node/v1/parts/export'
    }
    orderName = idx || idx === 0 ? this.order.name : this.orderName

    if ((idx || idx === 0) && type !== 'part') {
      temp[idx].loading = true
    } else {
      this[buttonLoading] = true
    }
    const orderId = this.order.id
    try {
      const response = await axios.post(
        url,
        {
          orderId,
          version,
          type,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )

      if (response.status === 200 || response.status === 201) {
        const blob = new Blob([response.data])
        if (response.status === 200 || response.status === 201) {
          let header = response.headers['content-disposition']
          header = header
            .split('filename=')[1]
            .split(';')[0]
            .replaceAll('%20', ' ')
            .replace(/^"|"$/g, '')
          let uploadDate = header.split('-').slice(-1).toString()
          let templateType = header.split('-')[0].toString()
          download(blob, `${orderName} ${templateType} ${uploadDate}.xlsx`)
        }
      } else {
        const errorMessage = `${response.statusText}`
        throw new Error(errorMessage)
      }
    } catch (error) {
      const errorMessage =
        error.message || 'An error occurred while downloading the file.'
      this.openNotificationWithIcon('error', errorMessage)
    }
    if ((idx || idx === 0) && type !== 'part') {
      temp[idx].loading = false
    } else {
      this[buttonLoading] = false
    }
  }

  exportTemplate = async (type, version, idx) => {
    let temp, orderName
    let url
    let buttonLoading
    if (
      type === 'po' ||
      type === 'prices' ||
      type === 'final' ||
      type === 'unsign' ||
      type === 're-sign'
    ) {
      orderName = type === 'po' ? `${this.order.name}_sp` : this.order.name
      temp = type === 'po' ? 'versions' : 'finalTemplates'
      buttonLoading = 'POLoadingButtons'
      url = '/api/node/v1/parts/export'
    } else if (type === 'PL' || type === 'FinalPL') {
      orderName = idx || idx === 0 ? this.order.name : this.orderName
      temp = type === 'PL' ? 'plTemplates' : 'finalPlTemplates'
      buttonLoading = 'PLLoadingButtons'
      url = '/api/node/v1/parts/export/PLTemplate'
    } else if (type === 'Inspection') {
      orderName = idx || idx === 0 ? this.order.name : this.orderName
      temp = 'irTemplates'
      buttonLoading = 'inspectionLoadingButtons'
      url = '/api/node/v1/parts/export/inspectionReport'
    }
    if (idx || idx === 0) {
      this[temp][idx].loading = true
    } else {
      this[buttonLoading] = true
    }

    const orderId = this.order.id
    try {
      const response = await axios.post(
        url,
        {
          orderId,
          version,
          type,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )
      const blob = new Blob([response.data])
      if (response.status === 200 || response.status === 201) {
        let header = response.headers['content-disposition']
        header = header
          .split('filename=')[1]
          .split(';')[0]
          .replaceAll('%20', ' ')
          .replace(/^"|"$/g, '')
        let uploadDate = header.split('-').slice(-1).toString()
        let templateType = header.split('-')[0].toString()
        download(blob, `${orderName} ${templateType} ${uploadDate}.xlsx`)
      } else {
        const errorMessage = `${response.statusText}`
        throw new Error(errorMessage)
      }
    } catch (error) {
      const errorMessage =
        error.message || 'An error occurred while downloading the file.'
      this.openNotificationWithIcon('error', errorMessage)
    }
    if (idx || idx === 0) {
      this[temp][idx].loading = false
    } else {
      this[buttonLoading] = false
    }
  }

  downloadIRTemplate = async (inspectionId, idx, type, tabIndex) => {
    const template =
      type !== 'PartIR'
        ? this.irTemplates[idx]
        : this.partPoFinal[tabIndex].IR?.active[idx]
    template.loading = true

    axios
      .get(
        `/api/node/v1/parts/inspection/export/${this.order.id}/${inspectionId}`,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const filename = decodeURI(response.headers['content-disposition'])
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        template.loading = false
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage =
            error.response.data.message ||
            'An error occurred while downloading the file.'
          this.openNotificationWithIcon('error', errorMessage)
        } else {
          this.openNotificationWithIcon(
            'error',
            'An error occurred while downloading the file.'
          )
        }
        template.loading = false
      })
  }

  changeInspectionPhotos = async (index, file, type) => {
    let temp = [...this.inspectionReportData]
    if (type === 'add') {
      if (!temp[index].newPhotos) {
        temp[index].newPhotos = []
      }
      temp[index].newPhotos = [...temp[index].newPhotos, file]
    } else {
      temp[index].newPhotos.splice(
        temp[index].newPhotos.findIndex((el) => el.uid === file.uid),
        1
      )
    }
    this.inspectionReportData = [...temp]
  }

  deleteOldPhotos = async (index, photoIdx) => {
    let temp = [...this.inspectionReportData]
    temp[index].photos.splice(photoIdx, 1)
    temp[index].deletedPhoto = true
    this.inspectionReportData = [...temp]
  }

  changeIssueValue = async (e, idx, type) => {
    let temp = [...this.issuesData]
    temp[idx][type] = e
    this.issuesData = temp
  }

  addFinalVersion = async (idx) => {
    let finalsArr = this.versions[idx].finals
    finalsArr.push(+finalsArr[finalsArr.length - 1] + 1)
  }

  addPOVersion = async () => {
    this.versions.push({
      version: +this.versions[this.versions.length - 1].version + 1,
      finals: [1],
    })
  }
  // signing the template
  changeFinalVersion = async (version, idx) => {
    const temp = [...this.finalTemplates]
    temp[idx].signLoading = true
    this.versionId = version
    this.showSignatureModal = true
    temp[idx].signLoading = false
    this.finalTemplates = temp
  }
  // reject template
  approvePrice = async (versionId, idx) => {
    let temp = [...this.finalTemplates]
    temp[idx].signLoading = true
    const orderId = this.order.id
    try {
      const response = await axios.patch(
        '/api/node/v1/parts/template/procedures/approval',
        {
          versionId,
          orderId,
          procedure: 'approval',
        }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File approved!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while approving template'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    temp[idx].signLoading = false
    this.finalTemplates = temp
    await this.getOrderData(orderId, true)
  }

  saveRejectComment = async (values) => {
    const orderId = this.order.id
    const versionId = this.versionId
    const idx = this.rejectIdx
    let temp = [...this.finalTemplates]
    temp[idx].rejectLoading = true
    this.saveLoading = true
    try {
      const response = await axios.patch(
        '/api/node/v1/parts/template/procedures/approval',
        {
          versionId,
          orderId,
          procedure: 'rejection',
          comment: values.comment,
        }
      )

      if (response.data.success) {
        if (values.file) {
          const data = new FormData()
          data.append('trio', values.file)
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
          await axios.post(
            `/api/node/v1/parts/template/upload/${orderId}/${versionId}/rejectionFile`,
            data,
            config
          )
        }
        this.openNotificationWithIcon('success', 'Saved!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while approving template'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    temp[idx].rejectLoading = false
    this.finalTemplates = temp
    this.saveLoading = false
    this.closeModal('rejectModal')
    await this.getOrderData(orderId, true)
  }

  saveStatusComment = async (values) => {
    const orderId = this.orderId
    // TODO 27/07 change later after backend is ready
    try {
      const response = await axios.patch(
        '/api/node/v1/parts/template/procedures/approval',
        {
          orderId,
          procedure: 'statusChange',
          comment: values.comment,
        }
      )

      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Comment saved!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while saving comment'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    await this.getOrders()
  }

  sendFileFromModal = async (file) => {
    this.signatureLoading = true
    const orderId = this.order.id
    const versionId = this.versionId
    const data = new FormData()
    data.append('trio', file)
    data.append('version', versionId)
    data.append('orderId', orderId)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    try {
      const response = await axios.post(
        `/api/node/v1/parts/template/upload/${orderId}/${versionId}/signatureFile`,
        data,
        config
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File is added')
        this.getOrderData(this.order.id, true)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          'An error occurred while uploading the file.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon(
          'error',
          'An error occurred while uploading the file.'
        )
      }
    }
    this.signatureLoading = false
    this.showSignatureModal = false
  }

  editTemplate = () => {
    const temp = { ...this.templateOrder }
    this.isTemplateEdit = true
    this.templateOrder = { ...temp }
  }

  cancelEditPO = async (template, id, version, finalVersion) => {
    this.cancelLoading = true
    let temp = { ...this.templateOrder }
    await this.getPOTemplate(id, version)
    this.isTemplateEdit = false
    this.templateOrder = { ...temp }
    this.cancelLoading = false
  }

  editPOValue = (idx, field, value) => {
    let temp = { ...this.templateOrder }
    this.templateDataRows[idx][field] = value
    this.templateOrder = { ...temp }
  }

  changePOPhotos = async (index, file, type) => {
    let temp = [...this.templateDataRows]
    if (type === 'add') {
      if (!temp[index].newPhotos) {
        temp[index].newPhotos = []
      }
      temp[index].newPhotos = [...temp[index].newPhotos, file]
    } else {
      temp[index].newPhotos.splice(
        temp[index].newPhotos.findIndex((el) => el.uid === file.uid),
        1
      )
    }
    this.templateDataRows = [...temp]
  }

  deleteOldPOPhotos = async (index, photoIdx) => {
    let temp = [...this.templateDataRows]
    temp[index].photos.splice(photoIdx, 1)
    temp[index].deletedPhoto = true
    this.templateDataRows = [...temp]
  }

  changeDeliveryComment = (value) => {
    this.templateOrder.deliveryComment = value
  }

  savePOTemplate = async (template, version, finalVersion) => {
    this.saveLoading = true
    let body = {
      data: this.templateDataRows,
      order: this.templateOrder,
      stamp: this.sellerStamp,
      template,
      version,
    }

    try {
      const response = await axios.post(
        `/api/node/v1/parts/savePOTemplate`,
        body
      )
      if (response.data.success) {
        if (template === 'POTemplate') {
          for (let row of this.templateDataRows) {
            if (row.type !== 'part' && row.type !== 'partsPart') {
              continue
            }
            row.changedPhoto = false
            let data = new FormData()
            data.append('orderId', this.templateOrder.id)
            data.append('partId', row.partId)
            data.append('rowId', row.id)
            data.append('version', version)
            if (row.photos?.length === 0 && row.deletedPhoto) {
              data.append('deletedAllPhotos', row.id)
              row.changedPhoto = true
            }
            if (row.newPhotos?.length) {
              for (let photo of row.newPhotos) {
                data.append('partPhotos', photo)
              }
              row.changedPhoto = true
            }
            if (
              (row.newPhotos?.length && row.photos?.length) ||
              (row.photos?.length && row.deletedPhoto)
            ) {
              for (let photo of row.photos) {
                let lastIdx = photo.filename.lastIndexOf('/')
                data.append('oldPics[]', photo.filename.slice(lastIdx + 1))
              }
              row.changedPhoto = true
            }
            if (row.changedPhoto) {
              await axios.post(`/api/node/v1/parts/upload/poTemplate`, data)
            }
          }
        } else if (
          this.sellerStamp?.hasOwnProperty('uid') ||
          !this.sellerStamp
        ) {
          let data = new FormData()
          data.append('stamp', this.sellerStamp)
          data.append('version', version)

          await axios.post(
            `/api/node/v1/parts/upload/sellersStamp/${this.templateOrder.id}/${version}`,
            data
          )
        }
        this.openNotificationWithIcon('success', 'Saved!')
        await this.getPOTemplate(this.templateOrder.id, version)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          'An error occurred while saving the template.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon(
          'error',
          'An error occurred while saving the template.'
        )
      }
    }
    this.isTemplateEdit = false
    this.saveLoading = false
  }

  changePOPhotos = (index, file, type) => {
    let temp = [...this.templateDataRows]
    if (type === 'add') {
      if (!temp[index].newPhotos) {
        temp[index].newPhotos = []
      }
      temp[index].newPhotos = [...temp[index].newPhotos, file]
    } else {
      temp[index].newPhotos.splice(
        temp[index].newPhotos.findIndex((el) => el.uid === file.uid),
        1
      )
    }
    this.templateDataRows = [...temp]
  }

  cancelEditPL = (id, ver) => {
    this.cancelLoading = true
    let temp = { ...this.order }
    this.PLDataSource = undefined
    this.getPackingList(id, ver)
    this.isTemplateEdit = false
    this.order = { ...temp }
    this.cancelLoading = false
  }

  cancelEditIR = (id, ver) => {
    this.cancelLoading = true
    let temp = { ...this.order }
    this.getInspectionReport(id, ver)
    this.isTemplateEdit = false
    this.order = { ...temp }
    this.cancelLoading = false
  }

  editPLValue = (idx, field, value) => {
    let temp = [...this.PLDataSource]
    temp[idx][field] = value
    const fields = ['ctns', 'ctn', 'dimension', 'gwCtn', 'nwCtn']
    if (fields.includes(field)) {
      for (let i = idx + 1; i < temp.length - 1; i++) {
        if (temp[i].hasOwnProperty('offset')) break
        temp[i][field] = value
      }
    }
    this.PLDataSource = [...temp]
  }

  changeStamp = (file, type) => {
    let temp = [...this.templateDataRows]
    if (type === 'add') {
      this.sellerStamp = file
    } else {
      this.sellerStamp = undefined
    }
    this.templateDataRows = [...temp]
  }

  savePLTemplate = async () => {
    this.saveLoading = true
    let data = this.PLDataSource
    data = data.filter((obj) => obj.number !== 'Total')
    try {
      const response = await axios.post('/api/node/v1/parts/savePackingList', {
        data,
        orderId: this.order.id,
      })
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Saved!')
        this.isTemplateEdit = false
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while editing template'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.saveLoading = false
  }

  deleteRow = async (index) => {
    let temp = [...this.templateDataRows]
    temp[index].type = 'deleted'
    this.templateDataRows = [...temp]
  }

  openSendModal = (version) => {
    this.sendToFactoryVersion = version
    this.sendToFactoryModalOpened = true
  }

  changeSendToFactoryComment = (value) => {
    this.sendToFactoryComment = value
  }

  sendToFactory = async () => {
    this.sendToFactoryLoading = true
    let orderId = this.order.id

    let data = {
      version: this.sendToFactoryVersion,
      comment: this.sendToFactoryComment,
      order: orderId,
    }
    let response = await axios.post(`/api/node/v1/parts/sendToFactory`, data)
    if (!response.data.success) this.openNotificationWithIcon('error', 'ERROR!')
    else {
      this.closeSendModal()
      await this.getOrderData(orderId, true)
      this.openNotificationWithIcon('success', 'Sended!')
    }
    this.sendToFactoryLoading = false
  }

  changeImportTemplate = (value) => {
    this.importTemplate = value
  }

  openTemplatesModal = async (type) => {
    let orderId = this.order.id
    await this.getOrderData(orderId, true, '1')
    this.archiveTemplatesVisible = true
    this.archiveType = type
  }

  closeTemplatesModal = async (type) => {
    let orderId = this.order.id
    await this.getOrderData(orderId, true)
    this.archiveTemplatesVisible = false
  }

  openArchiveOrderModal = async () => {
    await this.getArchiveOrders()
    this.archiveContractVisible = true
  }
  closeArchiveOrderModal = (type) => {
    this.archiveContractVisible = false
    this.filtersParams['archiveName'].outerTableInput = []
    this.filtersParams['archiveName'].outerTableFiltered = []
    this.archiveType = type
  }

  toggleOrderInfoModal = async () => {
    this.orderInfoModalOpen = !this.orderInfoModalOpen
    if (!this.persons) await this.getResponsiblePersons()
  }

  toggleSignatureModal = (id) => {
    this.versionId = id
    this.showSignatureModal = !this.showSignatureModal
  }

  toggleAssignQCModaL = async () => {
    this.assignQCModalOpen = !this.assignQCModalOpen
    if (!this.persons) await this.getResponsiblePersons()
  }

  toggleArchiveCommentModal = (rowId, idx) => {
    this.archiveOrder = {
      orderId: rowId,
      idx,
    }
    this.archiveCommentModalOpen = !this.archiveCommentModalOpen
  }

  openRejectIrModal = (modalId, id, idx = 0, version, inInspection = false) => {
    this.IReportId = id
    this.rejectIdx = idx
    this.irVersion = version
    this.inInspection = inInspection
    this.modals[modalId] = true
  }

  openModal = (modalId, id, idx = 0, version) => {
    if (modalId === 'rejectModal') {
      this.versionId = id
      this.rejectIdx = idx
    }
    this.modals[modalId] = true
  }

  closeModal = (modalId) => {
    this.modals[modalId] = false
  }

  isModalVisible = (modalId) => {
    return this.modals[modalId] === true
  }

  getIRIssues = async (irId) => {
    try {
      const response = await axios.get(
        `/api/node/v1/parts/inspection/issues/${this.order.id}/${irId}`
      )
      if (response.data.success) {
        this.issuesComments = response.data.data
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error getting data'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  handleIssueModalOpening = async (modalId, addIssuePermission, irId) => {
    this.openModal(modalId)
    this.addIssuePermission = addIssuePermission
    this.inspectionId = irId
    await this.getIRIssues(irId)
  }

  saveIRIssue = async (values) => {
    const orderId = this.order.id
    const body = {
      orderId,
      inspectionId: this.inspectionId,
      comment: values.comment,
    }
    try {
      const response = await axios.post(
        '/api/node/v1/parts/inspection/issues',
        body
      )
      if (response.data.success) {
        if (this.irIssuesFiles.length > 0) {
          const commentId = response.data.data.id
          const data = new FormData()
          data.append('orderId', orderId)
          data.append('inspectionId', this.inspectionId)
          this.irIssuesFiles.forEach((file) => {
            data.append('trio', file.originFileObj)
          })
          await axios.post(
            `/api/node/v1/parts/inspection/issuesFile/${orderId}/${commentId}`,
            data
          )
        }
        this.openNotificationWithIcon('success', 'Saved')
        await this.getIRIssues(this.inspectionId)

        const irId = this.irTemplates.findIndex(
          (el) => el.id === body.inspectionId
        )

        if (irId !== -1) {
          this.irTemplates[irId].hasIssues === false &&
            (this.irTemplates[irId].hasIssues = true)
        }
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  assignQC = async (value) => {
    const body = {
      orderId: this.order.id,
      data: { ...value },
    }
    this.saveLoading = true
    try {
      const response = await axios.patch('/api/node/v1/parts/order', body)
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Saved')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.saveLoading = false
    this.toggleAssignQCModaL()
    await this.getOrderData(this.order.id, true)
  }
  changeCurrency = async (e) => {
    this.currencyLoading = true
    try {
      const response = await axios.put('/api/node/v1/parts/changeCurrency', {
        orderId: this.order.id,
        value: e,
      })
      if (response.data.success) this.order.currency = e
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while changing currency'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.currencyLoading = false
  }

  getTemplateInfo = (type) => {
    const templateInfo = {}
    if (type === 'po') {
      templateInfo.url = 'template'
      templateInfo.archiveType = ['templatesArchive', 'PO']
      templateInfo.toArchive = 'versions'
    }
    if (
      type === 'prices' ||
      type === 'final' ||
      type === 're-sign' ||
      type === 'unsign'
    ) {
      templateInfo.url = 'template'
      templateInfo.toArchive = 'finalTemplates'
      templateInfo.archiveType = ['templatesArchive', 'POfinal']
    }
    if (type === 'PLTemplate') {
      templateInfo.url = 'packingGroup'
      templateInfo.toArchive = 'plTemplates'
      templateInfo.archiveType = ['templatesArchive', 'PL']
    }
    if (type === 'finalPl') {
      templateInfo.url = 'packingGroup'
      templateInfo.toArchive = 'finalPlTemplates'
      templateInfo.archiveType = ['templatesArchive', 'PLfinal']
    }
    return templateInfo
  }

  addToArchiveTemplate = async (type, version, idx) => {
    const templateInfo = this.getTemplateInfo(type)
    const [templatesArchive, templateType] = templateInfo.archiveType
    const temp = [...this[templateInfo.toArchive]]
    this[templateInfo.toArchive][idx].archiveLoading = true
    const orderId = this.order.id
    temp.splice(idx, 1)
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/${templateInfo.url}/archive/${version}`,
        {
          orderId,
        }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Added to archive!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this[templateInfo.toArchive][idx].archiveLoading = false
    this[templateInfo.toArchive] = temp
    await this.getOrderData(orderId, true)
  }

  deleteTemplate = async (type, version, idx) => {
    const templateInfo = this.getTemplateInfo(type)
    const [templatesArchive, templateType] = templateInfo.archiveType
    const temp = [...this[templatesArchive][templateType]]
    temp.deleteLoading = true
    const orderId = this.order.id
    temp.splice(idx, 1)
    try {
      const response = await axios.delete(
        `/api/node/v1/parts/${templateInfo.url}/${orderId}/${version}`,
        {
          data: {
            orderId,
            version,
            type,
          },
        }
      )

      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Successfully deleted!')
        await this.getOrderData(orderId, true, '1')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while deleting template'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    temp.deleteLoading = false
    this[templatesArchive][templateType] = temp
  }

  deleteContract = async (idx) => {
    this.archiveOrders[idx].deleteContractLoading = true
    let id = this.archiveOrders[idx].id
    try {
      const response = await axios.delete('/api/node/v1/parts/order', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          id,
        }),
      })
      if (response.data.success) {
        this.updateArchiveOrders(idx, id)
        this.openNotificationWithIcon('success', 'Successfully deleted!')
      }
    } catch (error) {
      this.archiveOrders[idx].deleteContractLoading = false
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  deleteAllContracts = async () => {
    let temp = [...this.archiveOrders]
    try {
      const response = await axios.delete('/api/node/v1/parts/order')
      if (response.data.success) {
        temp.splice(0, temp.length)
        this.openNotificationWithIcon('success', 'Successfully deleted!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred.'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.archiveOrders = temp
  }

  addToArchive = async (orderId, idx, values) => {
    const temp = [...this.orders]
    this.orders[idx].archiveContractLoading = true
    temp.splice(idx, 1)
    const data = {
      orderId,
      reason: values.reason,
      comment: values.comment,
    }
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/order/archive/${orderId}`,
        data
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Added to archive!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while adding to archive'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.toggleArchiveCommentModal()
    this.orders[idx].archiveContractLoading = false
    this.orders = temp
    this.getOrders()
  }

  restoreTemplateArchive = async (version, idx, type) => {
    const templateInfo = this.getTemplateInfo(type)
    const [templatesArchive, templateType] = templateInfo.archiveType
    const temp = [...this[templatesArchive][templateType]]
    this[templatesArchive][templateType][idx].restoreLoading = true
    const orderId = this.order.id
    temp.splice(idx, 1)
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/${templateInfo.url}/archive/${version}`,
        {
          orderId,
        }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'File restored!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while restoring template'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this[templatesArchive][templateType][idx].restoreLoading = false
    this[templatesArchive][templateType] = temp
  }

  updateArchiveOrders = (idx, id) => {
    if (this.filtersParams['archiveName'].outerTableInput.length === 1) {
      this.changeOuterTableFilter([], 'archiveName')
    } else {
      this.filtersParams['archiveName'].outerTableInput = [
        ...this.filtersParams['archiveName'].outerTableInput.filter(
          (el) => el !== this.archiveOrders[idx].name
        ),
      ]
    }

    this.archiveOrders = [...this.archiveOrders.filter((el) => el.id !== id)]

    this.filtersParams['archiveName'].options = [
      ...this.filtersParams['archiveName'].options.filter((el) => el.id !== id),
    ]
  }

  restoreContract = async (orderId, idx) => {
    this.archiveOrders[idx].restoreContractLoading = true
    let id = this.archiveOrders[idx].id
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/order/archive/${orderId}`,
        {
          body: JSON.stringify({
            orderId,
          }),
        }
      )
      if (response.data.success) {
        this.updateArchiveOrders(idx, id)
        this.openNotificationWithIcon('success', 'Order restored!')
        this.getOrders()
      }
    } catch (error) {
      this.archiveOrders[idx].restoreContractLoading = false
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while restoring contract'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  getFactoryContacts = async () => {
    let response = await axios.get(
      `/api/node/v1/factory_contacts/${this.factoryId}`
    )
    this.factoryEmails = response.data.data?.contacts?.filter(
      (email) => email !== '' && email !== null
    )
  }

  sendEmailToFactory = async (version, idx, emails) => {
    const temp = [...this.versions]
    temp[idx].emailLoading = true
    let orderId = this.order.id
    let body = {
      orderId,
      version,
      email: emails,
    }
    try {
      const response = await axios.post(
        `/api/node/v1/parts/messages/versions/factory/send`,
        body
      )
      if (response.data.success) {
        //1/11 TODO исправить позже, чтобы с бэка дата приходила в ответе.
        let date = new Date()
        let formattedDate = date.toISOString().split('T')[0]
        temp[idx].send_to_factory = formattedDate
        await this.getOrderData(orderId)
        this.openNotificationWithIcon('success', 'Email sent!')
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message || 'Error while sending email'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    temp[idx].emailLoading = false
    this.versions = temp
  }

  agreeWithTheContract = async (agree) => {
    const orderId = this.order.id
    try {
      const response = await axios.patch(
        `/api/node/v1/parts/order/agree/${orderId}`,
        {
          agreed: agree,
        }
      )
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Saved!')
        await this.getOrderData(orderId)
      }
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          'Error while agreeing with the contract'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
  }

  calculateSum = (dataIndex, offsetType) => {
    const total = this.inspectionReportData.reduce((sum, val) => {
      if (val.hasOwnProperty(offsetType)) {
        let qty = val[dataIndex]
        if (typeof qty === 'number' && !isNaN(qty)) {
          return sum + qty
        }
      }
      return sum
    }, 0)
    return total
  }

  get irTotalRealCtns() {
    return this.inspectionReportData.reduce((sum, val) => {
      if (val.hasOwnProperty('offset')) {
        let qty = parseFloat(val.realCtns)
        return isNaN(qty) ? sum : sum + qty
      }
      return sum
    }, 0)
  }

  get totalRealTotQty() {
    return this.inspectionReportData.reduce((sum, val) => {
      if (val.hasOwnProperty('partOffset')) {
        let qty = parseFloat(val.realTotQty)
        return isNaN(qty) ? sum : sum + qty
      }
      return sum
    }, 0)
  }

  // выбор колонок
  toggleColumnsSelector = (flag) => {
    this.isColumnsSelectorOpened = flag
  }

  changeSelectedColumns = (dataIndex) => {
    if (dataIndex === 'all') {
      if (this.selectedAllColumns) {
        this.selectedAllColumns = false
        const allColumns = this.columns.map((el) => el.dataIndex)
        this.selectedColumns = allColumns
      } else {
        this.selectedAllColumns = true
        this.selectedAllColumnsInterminate = false
        this.selectedColumns = []
      }

      return
    }

    const idx = this.selectedColumns.findIndex((el) => el === dataIndex)
    if (idx !== -1) this.selectedColumns.splice(idx, 1)
    else this.selectedColumns.push(dataIndex)

    if (this.selectedColumns.length !== 0) {
      if (this.selectedColumns.length === this.columns.length) {
        this.selectedAllColumns = false
        this.selectedAllColumnsInterminate = false
      } else {
        this.selectedAllColumnsInterminate = true
        this.selectedAllColumns = false
      }
    } else {
      this.selectedAllColumnsInterminate = false
      this.selectedAllColumns = true
    }
  }

  fetchColumns = async (tableName) => {
    try {
      const response = await axios.get(
        `/api/node/v1/parts/table-settings/${tableName}`
      )
      if (response.data.success && response.data.data) {
        response.data.data.hiddenColumns.length !== 0 &&
          (this.selectedAllColumns = false)

        this.selectedColumns = response.data.data.hiddenColumns
        this.prevSelectedColumns = [...this.selectedColumns]
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error)
      }
    }
  }

  saveColumns = async (tableName, columnsName) => {
    let sendRequest = true

    if (columnsName.length === this.prevSelectedColumns.length) {
      const sortedPrevArr = this.prevSelectedColumns.sort()
      sendRequest = !columnsName
        .sort()
        .every((el, index) => el === sortedPrevArr[index])
    }

    if (sendRequest) {
      try {
        const response = await axios.put(
          `/api/node/v1/parts/table-settings/${tableName}`,
          {
            hiddenColumns: columnsName,
          }
        )

        if (response.data.success) {
          this.selectedColumns = columnsName
          this.prevSelectedColumns = [...columnsName]
        }
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data.message
          this.openNotificationWithIcon('error', errorMessage)
        } else {
          this.openNotificationWithIcon('error', error)
        }
      }
    }
  }

  // изменение buyer
  changeBuyerSelectVisibility = (visibility) => {
    this.isChangeBuyerSelectVisible = visibility
  }

  changeBuyer = async (orderId, buyerId) => {
    try {
      const response = await axios.patch('/api/node/v1/parts/order', {
        orderId,
        data: { buyer_id: buyerId },
      })

      if (response.data.success) {
        this.order.buyer = this.buyersList.find((el) => el.id === buyerId)
        this.order.buyer_id = buyerId
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error)
      }
    } finally {
      this.changeBuyerSelectVisibility(false)
    }
  }
}

export default PartsStore
