import { makeAutoObservable } from 'mobx';
import React, { Fragment } from 'react';
import {
  notification, Select, Input, Button, Checkbox
} from 'antd';
import api from '../utils/axiosAuthInterceptor';

const download = require('downloadjs')
const axios = api;
const { Option } = Select;


class CategoryAccessStore {
    search = undefined;
    userId = undefined;
    userTypeId = undefined;

    isModalCategoryCreateOpen = false;
    intCodeValuesArr = [];
    translateCategoryArr = [];
    categoriesList = [];

    categoryName = '';
    parentCategory = ''
    intcodeValues = new Map();

    errors = [];
    isLoaded = false;

    page = 1;

    taskModel = null;

    saveButtonDisabled = true;
    saveButtonLoading = false;

    isModalRequestOpen = false;
    loadingChangeRequestNotify = false;

    overdueRequests = 0;
    averageDelayTime = 0;

    requests = [];
    categories = [];

    filterName = "";
    filterType = null;
    filterStatus = null;
    filterDate = [];
    requestTypes = [];
    requestType = null;
    requestStatuses = [];

    loadingSignContract = false;
    isDisabledRequestCommentButton = true;
    isDisabledAddRequestFileButton = true;
    isDisabledAddPriceFileButton = true;

    productGroups = [];
    productGroupsForAdd = [];
    selectedProductGroup = [];
    selectedProductGroup_tree = null;

    factories = [];
    contractFactory = undefined;
    contractNumber = '';
    contractPart = '';

    contracts = [];
    selectedContract = null;
    isDisabledAddContract = true;

    users = [];
    isDisabledAddUser = true;

    models = [];

    isRequestLoading = false;
    isPriceLoading = false;
    isContractLoading = false;
    requestModerator = false;

    editName = undefined;
    editType = undefined;
    editGroups = undefined;
    isEditModalOpen = false;
    editSaveButtonDisabled = false;
    editSaveButtonLoading = false;

    managers = undefined;
    brandManagers = undefined;
    engineers = undefined;
    names = [];
    numbers = [];

    splitFile = undefined;
    splitUploading = false;

    columns = [];

    isModalCategoryUpdateOpen = false;
    currentRow = {};

    selectedRowKeys = undefined;

    isColumnsSelectorOpened = false;
    selectedColumns = [];
    selectedAllColumns = true;
    selectedAllColumnsInterminate = false;

    constructor() {
        makeAutoObservable(this);
    }

    addIntCodeValuesArr = () => {
        this.intCodeValuesArr.push({
        })
    };

    getUser = async () => {
        let resp = await fetch('/api/node/v1/users/get/current');
        let data = await resp.json();
        this.userId = data.userId
        this.userTypeId = data.userTypeId
    }

    getCategory = async () => { // vld
        console.time('getCategory');
        const response = await axios.get(`/api/node/v1/category/access`)
        if (response.data.success)
            this.setStateResponseData(response.data.data);
    };

    setStateResponseData = (response, update = false) => { // vld
        this.isLoaded = true;
        this.categories = response.category;
        this.categoriesList = response.categoriesList;
        this.translateCategoryArr = response.translateCategoryArr;
        this.users = response.users;
        this.columns = this.generateColumns(response.columns);
    };

    renderTranslateCategoryArr = ({ id, ru, en, cn }) => {
        return (
            <Option key={"selectTranslate" + id}
                value={id}>{ru + " " + en + " " + cn}
            </Option>
        )
    };

    renderParentCategoryList = (e) => {
        return (
            <Option key={"ParentCategory" + e.id}
                value={e.id}>{e['lang.ru'] + " " + e['lang.en'] + " " + e['lang.cn']}
            </Option>
        )
    };

    deleteRow = async (row) => {
      let temp = [...this.categories];
      let idx = temp.findIndex(e => e.currentId === row.currentId);

      this.categories = temp;


      let response = await axios.get(`/api/node/v1/category/delete/${temp[idx].currentId}`);
      if (response.data.success) {
          temp.splice(idx, 1);
          this.openNotificationWithIcon('success', 'Row deleted!');
      } else {
          this.openNotificationWithIcon('error', 'Error!');
      }

      this.categories = temp;
    }

    openNotificationWithIcon = (type, message) => {
      notification[type]({
          message: message,
          placement: 'bottomRight'
      });
    };

    generateColumns = columns => {
      for (const column of columns) {
        if (column.edit) {
              column.dataIndex = column.key;
              column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm }) =>
                  this.getFiltersInColumn(
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  column.key,
                  'true'
                  );
              column.onFilter = (value, record) => {
                if(value === column.key){
                  return !record[column.key]
                } else {
                  return record[column.key] && record[column.key].indexOf(value) !== -1 ;
                }
              }

              column.filterSearch = true;
              column.render = (text, record) => {

                //Даем доступ на изменения селектов только руководителям и админу, userTypeId это id в таблице posts
                if(this.userTypeId === 8 && column.key === "Engineers") {
                  this.isSelectDisabled = false
                } else if(this.userTypeId === 5 && column.key === "Product managers") {
                  this.isSelectDisabled = false
                } else if(this.userTypeId === 7 && column.key === "Brand managers") {
                  this.isSelectDisabled = false
                } else if(this.userTypeId === 21 && column.key === "Spare parts") {
                  this.isSelectDisabled = false
                } else if(this.userTypeId === 6 && column.key === "Marketing department") {
                  this.isSelectDisabled = false
                } else if(this.userTypeId === 1 || this.userId === 55) {
                  this.isSelectDisabled = false
                } else {
                  this.isSelectDisabled = true
                }

                  return (
                      <Select
                        mode="multiple"
                        size="small"
                        style={{minWidth: '90px', maxWidth: '150px', width: '100%',}}
                        showSearch
                        autoClearSearchValue
                        placeholder={this.t('w_select')}
                        value={text}
                        optionFilterProp="children"
                        disabled={this.isSelectDisabled}
                        onDeselect={(value) => {
                          this.handleMenu(value, record, column.key, true)
                        }}
                        onSelect={(value) => {
                          this.handleMenu(value, record, column.key, false);
                        }}
                      >
                        {this.users.map((item, idx) => {
                            if (item['department.name_en'] === column.key)
                            return <Option value={item.id} key={column.key+'_'+ item.id + idx}>{item.surname} {item.name}</Option>;
                        })}
                      </Select>
                  );
              };
              continue;
          } else {
          column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm }) =>
            this.getFiltersInColumn(setSelectedKeys, selectedKeys, confirm, column.key);
          column.onFilter = (value, record) => {
            if (value === 'нет данных' && !record.number) {
              return !record[column.key];
            } else {
              return record[column.key] === value;
            }
          };

        }

          column.dataIndex = column.key;
      }

      columns.push({
        title: '',
        dataIndex: 'action',
        width: '10%',
        render: (_, row) => (
          <Fragment>
            <Button onClick={_ => this.deleteRow(row)} style={{width: '35px', marginBottom: '3px', padding: 0}}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Button>

              <Button onClick={_ => this.openModalCategoryUpdate(row)} style={{width: '35px', padding: 0}}>
              <i className="fa fa-pencil-square-o" aria-hidden="true"/>
              </Button>

          </Fragment>
        )
      })
      return columns;
    }

    //редактирование категории
  openModalCategoryUpdate = (row) => {
    this.currentRow = row;
    this.isModalCategoryUpdateOpen = true;
  }

  updateRow = async (values) => {
    //Если значение в инпуте не меняется пользователем, форма собирает данные с этого инпута как undefined.
    //Идет проверка: если приходит undefined, то значение присваивается из currentRow.
    if(values.int1 === undefined) values.int1 = this.currentRow.int1
    values.int1Id = this.currentRow.int1Id
    if(values.int2 === undefined) values.int2 = this.currentRow.int2
    values.int2Id = this.currentRow.int2Id
    values.categoryIntId = this.currentRow.id

    if(values.sub2Int1 === undefined) values.sub2Int1 = this.currentRow.sub2Int1
    values.sub2Int1Id = this.currentRow.sub2Int1Id
    if(values.sub2Int2 === undefined) values.sub2Int2 = this.currentRow.sub2Int2
    values.sub2Int2Id = this.currentRow.sub2Int2Id
    values.sub2Id = this.currentRow.currentId

    let temp = [...this.categories];
    let idx = temp.findIndex(e => e.currentId === values.sub2Id);
    temp[idx].int1 = values.int1
    temp[idx].int2 = values.int2
    temp[idx].sub2Int1 = values.sub2Int1
    temp[idx].sub2Int2 = values.sub2Int2

    let response = await axios.post('/api/node/v1/category/updateIntcode', values)
    if(response.data.success){
      this.categories = temp
      this.openNotificationWithIcon('success', 'Row saved!')
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

    handleMenu =  (userId, row, key, isDeleting = false) => {
      try {
        const categoryId = row.currentId;

        //ряд чекбоксов собирает массив ключей (это id строк)
        let arrId = this.selectedRowKeys;

        if (arrId === undefined || arrId.length === 0) {
          arrId = [categoryId];
        }

        let temp = [...this.categories];

        arrId.forEach((item) => {

          let accessCategoriesNewId = null;
          for (const category of this.categories) {
            if(item === category.key) {
              accessCategoriesNewId = category['accessCategoriesNewId' + key];
              break;
            }
          }
          let data = {
            categoryId: item,
            accessCategoriesNewId: accessCategoriesNewId,
            userId: userId
          };

          let idx = temp.findIndex(e => e.currentId === item);

          if(isDeleting){
            axios.post('/api/node/v1/category/deleteUser', data)
               .then(response => {
                if (response.data.success) {

                  let ind = temp[idx][key].findIndex(el => el === userId)
                  temp[idx][key].splice(ind, 1)
                  // temp[idx][key] = temp[idx][key].filter(e => e !== userId)

                }
              })
              .catch(error => { console.log(error) });
          } else {
            if(temp[idx][key]){
              if(!temp[idx][key].includes(userId)){
                axios.post('/api/node/v1/category/updateUser', data)
                  .then(response => {
                    if (response.data.success) {


                        temp[idx][key].push(userId)


                      return
                    }
                    console.log(response.data.message)
                  })
                  .catch(error => { console.log(error) });
              }

            } else {
              axios.post('/api/node/v1/category/updateUser', data)
                .then(response => {
                  if (response.data.success) {

                      temp[idx][key] = [userId]

                    return
                  }
                  console.log(response.data.message)
                })
                .catch(error => { console.log(error) });
            }
          }
        });


        this.categories = temp


      } catch (error) {
        console.log('error-update >>>', error);
      }

    };

  t = (value) => {
    return value
  }

  setTranslate = (t) => {
    this.t = t;
  }

    getFiltersInColumn = (
        setSelectedKeys,
        selectedKeys,
        confirm,
        nameColumn,
        nameInStore,
      ) => {
        let rowValues = [];

        if (nameInStore) {
        let categoryUsers
          if(nameColumn === 'Product managers') categoryUsers = this.users.filter(el => el.post_id === 3 || el.post_id === 5);
          if(nameColumn === 'Engineers') categoryUsers = this.users.filter(el => el.post_id === 11 || el.post_id === 24);
          if(nameColumn === 'Brand managers') categoryUsers = this.users.filter(el => el.post_id === 7 || el.post_id === 10);
          if(nameColumn === 'Spare parts') categoryUsers = this.users.filter(el => el.post_id === 21 || el.post_id === 22);
          if(nameColumn === 'Marketing department') categoryUsers = this.users.filter(el => el.post_id === 6 || el.post_id === 9);

            rowValues = categoryUsers?.map((item) => {
              return { label: item.surname + ' ' + item.name, value: item.id };
            });
            rowValues.push({label: '', value: nameColumn}) //фильтруем по пустому полю

          } else {
            rowValues = [
              ...new Set(
                this.categories?.map((item) => {
                  if (item[nameColumn]) {
                    return item[nameColumn];
                  } else {
                    return 'нет данных';
                  }
                })
              ),
            ].map((item) => {
              return { label: this.t(item), value: this.t(item) };
            });
          }

        const onChangeFilter = (checked) => {
          setSelectedKeys(checked);
        };

        const onCheckAll = (e) => {
          if (e.target.checked) {
            const allValues = rowValues.map((item) => item.value);
            setSelectedKeys(allValues);
          } else {
            setSelectedKeys();
          }
        };

        const searchFilter = (e) => {
          const searchValues = rowValues.map((item) => {
            if (
              e.target.value &&
              item.label
                .toString()
                .toLowerCase()
                .includes(e.target.value.toString().toLowerCase())
            ) {
              return item.value;
            }
          });
          setSelectedKeys(searchValues);
        };

        const getFilter = () => {
          confirm();
        };

        const resetFilter = () => {
          setSelectedKeys();
        };

        return (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Input
              placeholder="Поиск в фильтре"
              onChange={searchFilter}
            />
            <Checkbox style={{padding: '5px', fontWeight: 'bold'}} onChange={onCheckAll}>
              {this.t('w_select_all')}
            </Checkbox>
            <Checkbox.Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '200px',
                padding: '5px',
                maxHeight: '300px',
                overflowY: 'scroll',
              }}
              options={rowValues}
              value={selectedKeys}
              onChange={onChangeFilter}
            />
            <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px 15px 10px 5px'}}>
              <Button
                style={{marginLeft: '10px'}}
                type="link"
                danger
                size="small"
                onClick={resetFilter}
              >
                {this.t('w_reset')}
              </Button>
              <Button
                style={{marginLeft: '10px'}}
                type="primary"
                size="small"
                onClick={getFilter}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      };

    openModalCategoryCreate = () => {//vld
        this.isModalCategoryCreateOpen = true;
    }

    closeModalCategoryCreate = () => {//vld
        this.isModalCategoryCreateOpen = false;
    }

    createNewCategory = _ => { //vld
            let saveData = {
                lang_id: +this.categoryName,
                parent_id: +this.parentCategory,
                category: 1,
                view: 1,
                intCodeArr: this.intcodeValues,
            };

            axios.post('/api/node/v1/category/create', saveData)
                .then(async response => {
                    if (response.data.success) {
                        this.openNotificationWithIcon('success', 'Category saved successfully!');
                        this.closeModalCategoryCreate();
                        this.getCategory();
                    } else {
                        this.openNotificationWithIcon('error', 'Access denied');
                    }
                    this.saveButtonLoading = false;
                })
                .catch(error => {
                    console.log(error)
                });

    };

    changeCategoryName = (e) => { //vld
        this.categoryName = e;

    };

    changeParentCategory = (e) => { //vld
        this.parentCategory = e;

    };

    changeIntcode = (e) => { //vld
      this.intcodeValues.set(e.target.getAttribute('data-index'), e.target.value);
    };

    exportExcel = async () => {
      this.PLLoadingButtons = true;
      const response = await fetch('/api/node/v1/category/export', {
          method: 'GET',
      });
      if (response.status === 500) {
          this.openNotificationWithIcon('error', 'Error!')
      } else {
        const filename = `Category access ${new Date().toJSON().slice(0,10).replace(/-/g,'.')}.xlsx`;
        const blob = await response.blob();
        download(blob, filename);
      }
      this.PLLoadingButtons = false;
    }
      //выбор колонок
    toggleColumnsSelector = (flag) => {
      this.isColumnsSelectorOpened = flag;
    }

    changeSelectedColumns = (dataIndex) => {

      if (dataIndex === 'all') {
          if (this.selectedAllColumns) {
              this.selectedAllColumns = false;
              let allColumns = this.columns.map(el => el.dataIndex);
              this.selectedColumns = allColumns;
          } else {
              this.selectedAllColumns = true;
              this.selectedAllColumnsInterminate = false;
              this.selectedColumns = [];
          }

          this.saveUnselectedColumns();
          return;
      }

      let idx = this.selectedColumns.findIndex(el => el === dataIndex);
      if (idx !== -1)
          this.selectedColumns.splice(idx, 1);
      else
          this.selectedColumns.push(dataIndex);


      if (this.selectedColumns.length !== 0) {
          if (this.selectedColumns.length === this.columns.length) {
              this.selectedAllColumns = false;
              this.selectedAllColumnsInterminate = false;
          }
          else {
              this.selectedAllColumnsInterminate = true
              this.selectedAllColumns = false;
          }
      } else {
          this.selectedAllColumnsInterminate = false;
          this.selectedAllColumns = true;
      }

      this.saveUnselectedColumns();

      //чтобы не плыла шапка таблицы при выборе отображаемых колонок
      let table = document.querySelector('.ant-table-body');
      if (table.scrollLeft > 1300)
          table.scrollLeft = 1300;
  }

  saveUnselectedColumns = () => {
    axios.put('/api/node/v1/category/updateColumns', { columns: JSON.stringify(this.selectedColumns) });
}

initColumnsFilter = async () => {
  const response = await axios.get('/api/node/v1/category/columns');
  let columns = JSON.parse(response.data.data);
  if (!columns || columns.length === 0)
      return;
  this.selectedColumns = columns;

  if (this.selectedColumns.length !== 0) {
      this.selectedAllColumns = false;
      if (this.selectedColumns.length !== this.columns.length)
          this.selectedAllColumnsInterminate = true;
  }
}


}

export default CategoryAccessStore;
