import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import download from 'downloadjs'
import i18next from 'i18next'

import tabConf from '../components/sampleTest/services/info/tab.conf'
import isTableLineFilled from '../components/sampleTest/services/isTableLineFilled'
import access from '../components/sampleTest/services/access'
import api from '../utils/axiosAuthInterceptor'
import dayjs from 'dayjs'

const axios = api
const nodePath = require('path')

class SampleTestStore {
  statusTooltipTitles = {
    w_approve: 'w_test_result_assigned_status_approved',
    w_reject: 'w_test_result_assigned_status_rejected',
    w_other: 'w_test_result_assigned_status_other',
    null: 'w_awaiting_approval',
  }
  allowedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/zip',
    'application/x-rar',
    'application/x-zip-compressed',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'application/vnd.rar',
    'application/msword',
  ]
  columnKeys = {
    factory_id: 'w_Supplier_code',
    quantity: 'w_quantity',
    sku_id: 'w_supplier_model',
    location: 'w_location',
    brand_managers: 'w_brand_manager',
    product_managers: 'w_product_manager',
    engineers: 'w_engineer',
  }
  draggerFileList = []
  skuDraggerFileList = []
  isResponsible = true
  rowUpdating = {}
  modalCreateOpen = false
  modalEmailOpen = false
  modalCommentOpen = false
  modalSkuOpen = false
  modalGenerateSeveralSku = false
  modalLocationOpen = false
  modalResultOpen = false
  modalActionsOpen = false
  modalUploadReportTests = false
  modalSupplierLocationOpen = false
  currentLocationData = null
  currentSampleTestId = null
  currentLabelRow = {}
  currentUserId = null
  currentUserPostId = null
  access = null
  activeTab = 2
  factoryList = []
  competitorsList = []
  testTypesList = []
  testQuantityList = []
  saveTestQuantityList = []
  factoryContacts = []
  supplierModel = []
  mainInquiryInfo = {}
  newInfo = {}
  optionsTableMainInquiry = {}
  productGroup = []
  product_managers = []
  brand_managers = []
  engineers = []
  qc = []
  engineerList = []
  commonData = []
  testResult = []
  currentTabFilesIds = []
  reportTestFiles = []
  commentInput = ''
  qcList = []
  currentFactory = {}
  currentTableRow = {}
  loading = false
  lastSkuId = null
  infoModalOpen = false
  indicationNumberModal = false
  skuEditing = false
  inquiryEditRow = {
    qc: false,
    engineer: false,
    brand_manager: false,
    product_manager: false,
    deadline: false,
    location: false,
    other_location: false,
  }
  isDisabledCommentButton = true
  errorStatus = ''
  errorLink = ''
  errorMessage = ''
  hasError = false
  mainTable = []
  mainTableFilters = {}
  currentTableFilters = {
    id: [],
    factory: [],
    product: [],
    managers: [],
    brand_managers: [],
    engineers: [],
    qc: [],
    deadline: [],
    priority: [],
    status: [],
    created_at: [],
    close_date: [],
    competitor: [],
    limit: 10,
    page: 1,
    total: null,
    sortOrder: null,
    sortColumn: null,
    assigned: 'all',
    needAssigned: false,
  }
  emptyResponsible = {}
  inquiryFilter = {
    priority: [],
    location: [],
    sortOrder: null,
    sortColumn: null,
    supplierCode: [],
    supplierModel: [],
    competitor: [],
    competitorModel: [],
  }
  preventWriteHistory = false
  deadlineNotesModalOpen = false
  currentDeadlineNote = null
  currentDeadline = null
  deadlineHistoryModalOpen = false
  currentDeadlineHistory = null
  clearDate = false
  historyKeys = {
    samples_readiness_time: 'delivery_history',
    samples_deadline_for_testing: 'deadline_history',
  }
  autoAssignText = {
    priorities: 'w_automatically_changed_by_status',
    samples_readiness_time: 'w_automatically_changed_by_delivery_time',
    other: 'w_automatically_changed_by_other',
  }
  uploadModalOpen = false
  uploadModalConfig = {}
  intcodesModalOpen = false
  intcodesModalConfig = {}
  intCodesList = []
  intcodesLoading = false
  comparisonModalOpen = false
  downloadAllDisabled = false
  downloadBtnDisabled = {}
  inquiryType = 'validation'
  clearInquiryTableFilters = () => {
    this.inquiryFilter = {
      priority: [],
      location: [],
      sortOrder: null,
      sortColumn: null,
      supplierCode: [],
      supplierModel: [],
      competitor: [],
      competitorModel: [],
    }
  }
  testStatusMapping = {
    1: 'recommend',
    2: 'loaded',
    3: 'reject',
  }
  skuStatusMapping = {
    recommend: {
      status_id: 1,
      lang: 'w_recommended',
    },
    other: {
      status_id: 2,
      lang: 'w_other',
    },
    reject: {
      status_id: 3,
      lang: 'w_rejected',
    },
    finished: {
      status_id: 4,
      lang: 'w_finished',
    },
  }
  currentPurposeMapping = {
    validation: 'V-',
    competitor: 'C-',
    're-test': 'RE-',
  }
  fileUploadProgress = {}
  fileMultiUploadProgress = null

  fileDownloadProgress = {}
  fileMultiDownloadProgress = null

  acceptUpload =
    'image/*,.pdf,.docx,.doc,.xls,.xlsx,.xlsm,.xlsb,.xlam,.rar,.zip,.7z,application/msword,.rtf,.txt,.wps,.et,.ods,.odc'
  additionalExtensions = [
    'zip',
    'rar',
    '7z',
    'xls',
    'xlsx',
    'doc',
    'docx',
    'rtf',
    'txt',
    'wps',
    'et',
    'ods',
    'odc',
    'xlsm',
    'xlsb',
    'xlam',
  ]
  intCodeConfig = {
    recommendIntCode: null,
    updateComparisonTable: null,
    IdRecommendIntCode: null,
  }
  commentsLang = null

  laboratoryAddress = '上海市闵行区虹梅南路4916弄18号 广为6楼实验室。'
  laboratoryRecipient = 'TERRY 15868314634'
  downloadFileProgres = []
  //CHAT
  commentsLanguage = {
    chat_techSpecification: undefined,
    chat_techInfo: undefined,
    chat_testResult: undefined,
  }
  commentsCounts = {}
  commentsData = {
    techSpecification: {},
    techInfo: {},
    testResult: {},
  }
  commentsUsers = {
    techSpecification: {},
    techInfo: {},
    testResult: {},
  }
  currentUser = null
  userId = null
  userTypeId = null
  constructor() {
    makeAutoObservable(this)
  }

  changeInquiryType = (type) => {
    this.clearFilters()
    this.inquiryType = type
  }
  // получить данные юзера
  getUser = async () => {
    const response = await axios.get('/api/node/v1/users/get/current')

    this.currentUser = response.data
    this.userId = response.data.userId
    this.userTypeId = response.data.userTypeId
  }
  setDefaultInquiryType = () => {
    this.inquiryType = 'validation'
  }

  getCurrentTabName = (tabId, type) => {
    let tabKeys = {
      2: 'w_inquiry_info',
      3: 'w_internal_tech_specification',
      4:
        type === 'validation' || type === 're-test'
          ? 'w_supplier_technical_info'
          : 'w_competitor_tech_info',
      5: 'w_test_result',
    }
    return tabKeys[tabId]
  }

  clearFilters = () => {
    this.currentTableFilters = {
      id: [],
      factory: [],
      product: [],
      managers: [],
      brand_managers: [],
      engineers: [],
      qc: [],
      deadline: [],
      priority: [],
      status: [],
      created_at: [],
      close_date: [],
      competitor: [],
      limit: 10,
      page: 1,
      total: null,
      sortOrder: null,
      sortColumn: null,
      assigned: 'all',
      needAssigned: false,
    }
  }

  modalOpen = () => {
    this.modalCreateOpen = true
    this.clearResponsible()
  }

  clearCommentRow = () => {
    this.commentInput = ''
    this.isDisabledCommentButton = true
  }

  openQcModal = () => {
    this.modalCommentOpen = true
  }

  emailModalOpen = (row) => {
    this.currentLabelRow = row
    this.modalEmailOpen = true
  }

  emailModalClose = () => {
    this.currentLabelRow = {}
    this.modalEmailOpen = false
  }

  commentModalClose = () => {
    this.modalCommentOpen = false
  }

  skuModalClose = () => {
    this.modalSkuOpen = false
  }

  toObjectByKey(arr, key = 'id') {
    return arr.reduce((o, e) => {
      o[e[key]] = e
      return o
    }, {})
  }

  uploadModalClose = () => {
    this.uploadModalConfig = {}
    this.uploadModalOpen = false
  }

  uploadModal = (
    model,
    supplierCode,
    tests,
    row_index,
    group_index,
    group,
    sku_id,
    modelTitle,
    setActiveKey
  ) => {
    this.uploadModalConfig = {
      model,
      supplierCode,
      row_index,
      group_index,
      group,
      sku_id,
      modelTitle,
      ...this.prepareOtherTests(
        tests,
        this.testTypesList,
        Array.isArray(sku_id)
      ),
      setActiveKey,
    }
    this.uploadModalOpen = true
  }

  intcodesModalClose = () => {
    this.intcodesModalConfig = {}
    this.intcodesModalOpen = false
  }

  intcodesModal = ({ model, handler }) => {
    model = Array.isArray(model) ? model : [model]
    this.intcodesModalConfig = {
      model: model.join(', '),
      handler: (val) => handler(val),
    }
    this.intcodesModalOpen = true
  }

  openComparisonModal = (codes, model, handler) => {
    this.intcodesModalConfig.intCodes = codes
    if (model) {
      this.intcodesModalConfig.model = model
      this.intcodesModalConfig.handler = (val) => handler(val)
    }
    this.comparisonModalOpen = true
  }

  closeComparisonModal = () => {
    this.comparisonModalOpen = false
  }

  prepareOtherTests = (tests, allTests, withoutChosen) => {
    const testsObj = tests?.length ? this.toObjectByKey(tests, 'test_id') : {}
    const chosen_by_manager_tests = !withoutChosen
      ? tests.filter((item) => item.chosen_by_manager)
      : []
    const testIds = !withoutChosen
      ? chosen_by_manager_tests.map((item) => item.test_id)
      : []
    const other_tests = allTests
      .filter((item) => !testIds.includes(item.id))
      .map((item) => ({
        name: item.name,
        test_id: item.id,
        status_id: testsObj[item.id]?.status_id || null,
        stage: null,
        chosen_by_manager: false,
      }))

    return { chosen_by_manager_tests, other_tests }
  }

  locationModalClose = () => {
    this.modalLocationOpen = false
  }

  supplierLocationModalClose = () => {
    this.currentLocationData = null
    this.modalSupplierLocationOpen = false
  }

  generateAllEnabled = (severalSku) => {
    let allData = this.mainInquiryInfo.table || []
    return allData.filter((el) =>
      severalSku ? isTableLineFilled(el) : !el.label_id && isTableLineFilled(el)
    ).length
  }

  prepareAllLabelData = (rows) => {
    const mainInquiry = this.mainInquiryInfo
    const engineer = mainInquiry?.engineers?.reduce(
      (acc, engineer) => {
        acc.ids.push(engineer.user_id)
        acc.names.push(engineer.fullName)
        return acc
      },
      { ids: [], names: [] }
    )
    const product_manager = mainInquiry?.product_managers?.reduce(
      (acc, pm) => {
        acc.ids.push(pm.user_id)
        acc.names.push(pm.fullName)
        return acc
      },
      { ids: [], names: [] }
    )
    const product = i18next.t(this.mainInquiryInfo?.product?.lang?.product_key)

    return rows.map((row) => {
      return {
        id: row?.label_id,
        sample_test_table_id: row.id,
        factory_model: row?.supplier_model,
        product_category: product,
        engineer: engineer.names,
        product_manager: product_manager.names,
        supplier_code: row.factory?.abbreviation,
        location: row?.location,
        additional: {
          factory_id: row.factory_id,
          sku_id: row.sku_id,
          product_managers: product_manager.ids,
          engineers: engineer.ids,
        },
      }
    })
  }

  isIrrelevantLabel = (record) => {
    const labelValues = record.label[0]
    if (!labelValues) return []
    const keysToCheck = [
      'factory_id',
      'sku_id',
      'product_managers',
      'engineers',
    ]
    const mismatchedValues = []
    keysToCheck.forEach((property) => {
      if (['product_managers', 'engineers'].includes(property)) {
        if (!this.compareResponsible(labelValues[property], property))
          mismatchedValues.push(this.columnKeys[property])
      } else if (record[property] !== labelValues[property]) {
        mismatchedValues.push(this.columnKeys[property])
      }
    })
    return mismatchedValues
  }

  compareResponsible = (resp, key) => {
    const userIDs = this.mainInquiryInfo[key]
      ? this.mainInquiryInfo[key].map((obj) => obj.user_id)
      : []
    return (
      resp.length === userIDs.length && resp.every((el) => userIDs.includes(el))
    )
  }

  generateAllLabel = async () => {
    this.loading = true
    let allData = this.mainInquiryInfo.table || []
    allData = allData.filter((el) => !el.label_id && isTableLineFilled(el))
    if (!allData.length) return
    try {
      await axios.post(
        '/api/node/v1/sampleTest/label/generateAll',
        this.prepareAllLabelData(allData)
      )
      await this.getSkuTable(this.currentSampleTestId)
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  saveImage = async (file, id) => {
    try {
      const body = {
        sample_test_id: this.currentSampleTestId,
      }
      const formData = new FormData()
      formData.append(`sampleTest`, file)
      const response = await axios.post(
        `/api/node/v1/sampleTest/table/saveImage?id=${id}`,
        formData,
        {
          params: body,
        }
      )

      return response.data.data
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  exportTable = async (id) => {
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/table/exportTable?id=${id}`,
        { responseType: 'blob' }
      )
      download(response.data, `inquiry-${id}.xlsx`)
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  supplierLocationModalOpen = (row) => {
    this.currentLocationData = {
      factory_id: row.factory_id,
      factory: row.factory_abbreviation,
      address: row.current_supplier_location,
      id: row.id,
    }
    this.modalSupplierLocationOpen = true
  }

  deadlineChangeModalOpen = (row, dataIndex) => {
    this.currentDeadlineNote = {
      title:
        dataIndex === 'samples_deadline_for_testing'
          ? 'w_changing_samples_deadline_for_testing'
          : 'w_changing_samples_delivery_time',
      text:
        dataIndex === 'samples_deadline_for_testing'
          ? 'w_reason_for_changing_samples_deadline_for_testing'
          : 'w_comment',
      date_value: this.currentDeadline,
      dataIndex: dataIndex,
      row,
    }
    this.deadlineNotesModalOpen = true
  }

  openDeadlineHistoryModal = (row, dataIndex) => {
    this.deadlineHistoryModalOpen = true
    this.currentDeadlineHistory = {
      supplier_model: `${
        this.mainInquiryInfo.purpose === 'validation' ||
        this.mainInquiryInfo.purpose === 're-test'
          ? row.supplier_model
          : row.competitor_model
      }`,
      supplier_code:
        this.mainInquiryInfo.purpose === 'validation' ||
        this.mainInquiryInfo.purpose === 're-test'
          ? row.factory_abbreviation
          : row.competitor_name,
      history: row[this.historyKeys[dataIndex]],
      date_title:
        dataIndex === 'samples_deadline_for_testing'
          ? 'w_deadline'
          : 'w_delivery_time',
      title:
        dataIndex === 'samples_deadline_for_testing'
          ? 'w_samples_deadline_for_testing'
          : 'w_samples_delivery_time',
    }
  }

  checkOverdueDeadline = (row) => {
    const deadline = dayjs(row.samples_deadline_for_testing)
    const endDate = [null, this.skuStatusMapping.other.status_id].includes(
      row.status_id
    )
      ? dayjs()
      : dayjs(row.decision_date)
    return this.checkTagColor(
      deadline.startOf('day').diff(endDate.startOf('day'), 'days')
    )
  }

  checkTagColor = (diff) => {
    if (diff < -7) {
      return 'deadline_error'
    } else if (diff < 0 && diff >= -7) {
      return 'deadline_warning'
    } else {
      return 'default'
    }
  }

  resultModalClose = () => {
    this.modalResultOpen = false
  }

  deleteFileIsDisabled = (file, status) => {
    if (this.mainInquiryInfo.close_date) return true
    if (!this.isResponsible) return true
    if (this.activeTab === 5 && status) return true
    if (access(this.access, this.activeTab, 'delete_all')) return false
    return (
      !access(this.access, this.activeTab, 'delete_file') ||
      file.creator_id !== this.currentUserId
    )
  }

  deleteRowIsDisabled = (status, creator_id, main) => {
    if (!this.isResponsible) return true
    if (status) return true
    if (access(this.access, main || this.activeTab, 'delete_all')) return false
    return creator_id !== this.currentUserId
  }

  uploadFileIsDisabled = () => {
    if (this.mainInquiryInfo.close_date && this.activeTab === 5) return true
    if (!this.isResponsible) return true
    return !access(this.access, this.activeTab, 'upload_file')
  }

  revertLocationChange = () => {
    const index = this.mainInquiryInfo.table.findIndex(
      (row) => row.id === this.currentTableRow.id
    )
    this.mainInquiryInfo.table[index].location = this.currentTableRow.location
    this.mainInquiryInfo.table[index].other_location =
      this.currentTableRow.other_location
    this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
    this.currentTableRow = {}
  }

  modalClose = () => {
    this.modalCreateOpen = false
    this.emptyResponsible = {}
  }

  clearResponsible = () => {
    this.product_managers = []
    this.brand_managers = []
    this.engineers = []
    this.qc = []
  }

  skuWithoutFiles = () => {
    return (
      this.commonData.filter(
        (el) =>
          el.supplier_code && el.supplier_model && !el?.groups[0]?.files.length
      ).length > 1
    )
  }

  setInfoModalOpen = (id) => {
    this.lastSkuId = id
    this.infoModalOpen = true
  }

  infoModalClose = () => {
    this.lastSkuId = null
    this.infoModalOpen = false
  }

  prepareCheckBoxes = () => {
    return this.commonData.filter(
      (el) =>
        !el?.groups[0]?.files.length && el.supplier_code && el.supplier_model
    )
  }

  getAccess = async () => {
    try {
      if (this.access !== null) return
      let response = await axios.get(
        '/api/node/v1/sampleTest/access/access',
        {}
      )
      if (response.data.success) this.access = response.data.data.accesses
      this.currentUserId = response.data.data.currentUserId
      this.currentUserPostId = response.data.data.currentUserPostId
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'No access.'
        this.openNotificationWithIcon('error', errorMessage)
        this.handleError(error, '/')
      } else {
        this.openNotificationWithIcon('error', error.message)
        this.handleError(error, '/')
      }
    }
  }

  updateFilters = (key, data, assigned, needAssigned) => {
    if (key !== 'page') {
      this.currentTableFilters.page = 1
    }
    this.currentTableFilters = {
      ...this.currentTableFilters,
      [key]: data,
      ...(assigned !== undefined && { assigned: assigned }),
      ...(needAssigned !== undefined && { needAssigned: needAssigned }),
    }
  }
  qcFiltered = () => {
    if (
      this.currentTableFilters.assigned === 'assigned' ||
      this.currentTableFilters.assigned === 'no_assigned'
    ) {
      return true
    }
    return false
  }
  getMainTable = async () => {
    try {
      this.loading = true
      let response = await axios.get(
        '/api/node/v1/sampleTest/common/commonTable',
        {
          params: {
            ...this.currentTableFilters,
            purpose: this.inquiryType,
          },
        }
      )
      this.mainTable = response.data.data.table
      this.mainTableFilters = response.data.data.filters
      this.mainTableFilters.product = this.mainTableFilters.product.sort(
        this.sortOptions('text')
      )
      this.currentTableFilters.total = response.data.data.filters.total
      this.loading = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  deleteTest = async (id) => {
    try {
      await axios.delete(`/api/node/v1/sampleTest/common/commonTable/${id}`)
      this.mainTable = this.mainTable.filter((row) => row.id !== +id)
      await this.getMainTable()
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  clearErrors = () => {
    this.errorStatus = ''
    this.errorLink = ''
    this.errorMessage = ''
    this.hasError = false
  }

  closeAllModal = () => {
    this.modalClose()
    this.emailModalClose()
    this.commentModalClose()
    this.infoModalClose()
    this.intcodesModalClose()
    this.locationModalClose()
    this.resultModalClose()
    this.skuModalClose()
    this.uploadModalClose()
    this.supplierLocationModalClose()
  }

  handleError = (error, link) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 405)
    ) {
      const errorMessage = error.response.data.message || 'Access denied.'
      this.errorMessage = errorMessage
      this.errorStatus = '403'
    } else if (error.response) {
      const errorMessage = error.response.data.message || 'An error occurred.'
      this.errorMessage = errorMessage
      this.errorStatus = '500'
    } else {
      this.errorMessage = error.message
      this.errorStatus = '500'
    }
    this.errorLink = link
    this.hasError = true
  }

  openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
      description: description,
    })
  }

  getFactory = async (supplierModel) => {
    const response = await axios.get(
      '/api/node/v1/sampleTest/factories/factories',

      {
        params: {
          supplierModel,
        },
      }
    )

    this.factoryList = response.data.data
  }

  getCompetitors = async () => {
    const response = await axios.get(
      '/api/node/v1/sampleTest/competitors/competitors'
    )
    this.competitorsList = response.data.data
  }

  getTestTypes = async () => {
    const response = await axios.get(
      '/api/node/v1/sampleTest/testTypes/getTests'
    )
    this.testTypesList = response.data.data
  }
  getTestQuantity = async (id) => {
    const response = await axios.get(
      `/api/node/v1/sampleTest/testTypes/getQuantity/${id}`
    )
    this.testQuantityList = response.data.data
    this.saveTestQuantityList = response.data.data
  }

  getFactoryContacts = async (id) => {
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/factories/factoryContacts/${id}`
      )
      this.factoryContacts = response.data.data
    } catch (e) {
      this.openNotificationWithIcon('error', `Get contacts error`)
    }
  }

  openSendingEmail = async (row, setSendingEmail) => {
    this.currentLabelRow = row
    await this.getFactoryContacts(row.factory_id)
    setSendingEmail(row.id)
  }

  closeSendingEmail = () => {
    this.currentLabelRow = {}
    this.factoryContacts = []
  }

  getEngineer = async () => {
    const response = await axios.get('/api/node/v1/sampleTest/post/engineer')
    this.engineerList = response.data.data
  }

  getQc = async () => {
    try {
      this.rowUpdating.qc = true
      const response = await axios.get('/api/node/v1/sampleTest/post/qc', {
        params: {
          inquiry_id: this.mainInquiryInfo.id,
        },
      })
      this.qc = response.data.data
      this.rowUpdating.qc = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  sortOptions = (key) => (a, b) => {
    return i18next.t(a[key]).localeCompare(i18next.t(b[key]))
  }

  getProductGroup = async () => {
    const response = await axios.get(
      '/api/node/v1/sampleTest/product/productGroup'
    )
    this.productGroup = response.data.data.sort(this.sortOptions('value'))
  }

  getEmptyResponsibleAlert = (data) => {
    this.emptyResponsible.brand_managers = !data.brand_managers.length
  }

  getProductResponsible = async (id) => {
    try {
      this.emptyResponsible = {}
      const response = await axios.get(
        `/api/node/v1/sampleTest/product/responsible/${id}`
      )
      this.getEmptyResponsibleAlert(response.data.data)
      this.product_managers = response.data.data.managers
      this.brand_managers = response.data.data.brand_managers
      this.engineers = response.data.data.engineers
      this.qc = response.data.data.qc
    } catch (e) {
      this.openNotificationWithIcon(
        'error',
        `Ошибка загрузки ответственных лиц`
      )
    }
  }

  getProductManagers = async () => {
    try {
      this.rowUpdating.product_managers = true
      const response = await axios.get(
        `/api/node/v1/sampleTest/post/product_manager`,
        {
          params: {
            inquiry_id: this.mainInquiryInfo.id,
          },
        }
      )
      this.product_managers = response.data.data
      this.rowUpdating.product_managers = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Get product managers error')
    }
  }

  getBrandManagers = async () => {
    try {
      this.rowUpdating.brand_managers = true
      const response = await axios.get(
        `/api/node/v1/sampleTest/post/brand_manager`,
        {
          params: {
            inquiry_id: this.mainInquiryInfo.id,
          },
        }
      )
      this.brand_managers = response.data.data
      this.rowUpdating.brand_managers = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Get brand managers error')
    }
  }

  getProductEngineers = async () => {
    try {
      this.rowUpdating.engineers = true
      const response = await axios.get(
        `/api/node/v1/sampleTest/post/engineer`,
        {
          params: {
            inquiry_id: this.mainInquiryInfo.id,
          },
        }
      )
      this.engineers = response.data.data
      this.rowUpdating.engineers = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Get engineers error')
    }
  }

  getResponsible = async (key) => {
    return {
      engineer: () => this.getProductEngineers(),
      brand_manager: () => this.getBrandManagers(),
      product_manager: () => this.getProductManagers(),
    }[key]()
  }

  getSupplierModel = async (withoutIntCode, supplierCode) => {
    const response = await axios.get(
      `/api/node/v1/sampleTest/supplierModel/${this.mainInquiryInfo.product.product_id}`,
      {
        params: {
          withoutIntCode,
          supplierCode,
        },
      }
    )
    this.supplierModel = response.data.data
  }

  sendMailLabel = async (emails, sku_id) => {
    let response
    try {
      const body = {
        id: this.currentLabelRow.label_id,
        supplier_code: this.currentLabelRow.factory.name,
        factory_model: this.currentLabelRow.supplier_model,
        value: emails,
        sku_id: sku_id,
      }
      response = await axios.post('/api/node/v1/sampleTest/label/send', body)
      const index = this.mainInquiryInfo.table.findIndex(
        (label) => label.label_id === this.currentLabelRow.label_id
      )
      this.mainInquiryInfo.table[index] = {
        ...this.currentLabelRow,
        label: [{ ...this.currentLabelRow.label[0], was_send: true }],
      }
      this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
      if (response.data.data.some((obj) => obj.send === false)) {
        const sendError = response.data.data.filter((el) => el.error)
        this.openNotificationWithIcon(
          'warning',
          'Not sent',
          sendError.map((obj) => obj.email).join('\n\n')
        )
      } else {
        this.openNotificationWithIcon('success', i18next.t('w_label_sent'))
      }
    } catch (error) {
      this.openNotificationWithIcon('error', 'Send error')
    }
  }

  saveInquiryInfo = async (
    row,
    value,
    setChangeValue,
    responsible,
    idComment
  ) => {
    this.changeInquiryEdit(row)
    if (!value) return
    this.rowUpdating[responsible] = true

    const body = {
      id: this.currentSampleTestId,
      type: row,
      mainInquiry_id: this.mainInquiryInfo.id,
      idComment: idComment,
    }

    if (['engineer', 'brand_manager', 'product_manager', 'qc'].includes(row)) {
      body.responsible = value
    } else if (row === 'deadline') {
      body.deadline_for_testing = value
    } else {
      body.qc_id = value
    }
    try {
      const response = await axios.put(
        '/api/node/v1/sampleTest/inquiryInfo',
        body
      )
      this.mainInquiryInfo[responsible] = response.data.data
      this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
      row === 'deadline'
        ? (this.mainInquiryInfo.deadline_for_testing = value)
        : this.changeInfoAfterSave(row)(response.data.data)
      this.rowUpdating[responsible] = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Change info error')
    }
  }

  changeInfoAfterSave = (key) => {
    return {
      engineer: (data) => (this.mainInquiryInfo.engineers = data),
      brand_manager: (data) => (this.mainInquiryInfo.brand_managers = data),
      product_manager: (data) => (this.mainInquiryInfo.product_managers = data),
      qc: (data) => (this.mainInquiryInfo.qc = data),
    }[key]
  }

  prepareLocationForLabel = (key) => {
    return {
      w_other: (row) => row.other_location,
      w_laboratory: (row) => i18next.t(row.location),
      w_supplier: (row) =>
        row.close_date ? row.supplier_location : row.current_supplier_location,
    }[key]
  }

  missingData = (obj) => {
    let keysMapping = {
      priorities: 'w_priority',
      quantity: 'w_quantity',
      samples_readiness_time: 'w_samples_delivery_time',
      samples_deadline_for_testing: 'w_samples_deadline_for_testing',
      sku_id: 'w_supplier_model',
      factory_id: 'w_Supplier_code',
      location: 'w_location',
    }
    const missingKeys = []
    Object.keys(keysMapping).forEach((key) => {
      if (!(key in obj) || !obj[key]) {
        missingKeys.push(i18next.t(keysMapping[key]))
      }
    })
    return missingKeys
  }

  labelGenerateSeveralSku = async (rows, product, i, t, reGenerate) => {
    const body = this.prepareAllLabelData(rows)
    const response = await axios.post(
      '/api/node/v1/sampleTest/label/generateSeveralSku',
      body
    )
    await this.getSkuTable(this.currentSampleTestId)
    if (response.data.success) {
      return reGenerate
        ? this.openNotificationWithIcon('success', t('w_label_regenerated'))
        : undefined
    }
  }

  labelGenerate = async (row, product, i, t, reGenerate) => {
    if (!isTableLineFilled(row)) {
      return
    }
    const mainInquiry = this.mainInquiryInfo
    const body = this.prepareAllLabelData([row])[0]
    const response = await axios.post(
      '/api/node/v1/sampleTest/label/labelInfo',
      body
    )
    if (response.data.success) {
      const index = mainInquiry.table.findIndex((el) => el.id === row.id)
      mainInquiry.table[index].label = [response.data.data]
      mainInquiry.table[index].label_id = response.data.data.id
      mainInquiry.table[index].factory_label = response.data.data.updated_at
      mainInquiry.table[index].label_creator =
        response.data.data.user.creator_name
      this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
      return reGenerate
        ? this.openNotificationWithIcon('success', t('w_label_regenerated'))
        : undefined
    }
  }

  downloadLabel = async (row) => {
    const response = await axios.get('/api/node/v1/sampleTest/label/download', {
      responseType: 'blob',
      params: {
        id: row.label_id,
      },
    })

    download(response.data, row.label[0].fileName)
  }

  correctDateFormat = (date) => {
    return dayjs(date).format('YYYY-MM-DD HH:mm')
  }

  formatOnlyDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD')
  }

  correctDateFilter = (dates) => {
    return [
      dayjs(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      dayjs(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    ]
  }

  correctTooltipDateFormat = (date) => {
    return dayjs(date).format('DD/MM/YYYY')
  }

  downloadCommonFiles = async (file, index) => {
    this.downloadBtnDisabled[`${file.path}-${index}`] = true
    let updateNeeded = this.updateNeeded
    const fileName = file.fileName
    // `${
    //   this.mainInquiryInfo.purpose === 'validation' ? 'V-' : 'C-'
    // }${this.currentSampleTestId} ${file.fileName} №${index}${nodePath.extname(
    //   file.path
    // )}`
    const response = await axios.get(
      `/api/node/v1/sampleTest/common/download/${file.id}`,
      {
        responseType: 'blob',
        params: {
          id: file.id,
          tab_id: file.tab_id,
        },
        onDownloadProgress: (downloadProgress) => {
          const percentComplete = Math.round(
            (downloadProgress.loaded * 100) / downloadProgress.total
          )
          this.fileDownloadProgress[this.activeTab] = {
            ...this.fileDownloadProgress[this.activeTab],
            [file.id]: percentComplete,
          }
          this.fileMultiDownloadProgress = percentComplete
        },
      }
    )
    if (updateNeeded) {
      this.fileDownloadProgress[this.activeTab][file.id] = null
      this.fileMultiDownloadProgress = null
      await this.getCommonData()
    } else {
      this.fileDownloadProgress[this.activeTab][file.id] = null
      this.fileMultiDownloadProgress = null
      this.currentTabFilesIds = [
        ...this.currentTabFilesIds,
        response.data.data?.last_id,
      ]
    }

    download(response.data, fileName)
    this.downloadBtnDisabled[`${file.path}-${index}`] = false
  }
  downloadReportTestFiles = async (file, index) => {
    this.downloadBtnDisabled[`${file.path}-${index}`] = true
    const fileName = file.fileName
    // `${
    //   this.mainInquiryInfo.purpose === 'validation' ? 'V-' : 'C-'
    // }${this.currentSampleTestId} ${file.fileName} №${index}${nodePath.extname(
    //   file.path
    // )}`
    const response = await axios.get(
      `/api/node/v1/sampleTest/reportTest/download/${file.id}`,
      {
        responseType: 'blob',
        params: {
          id: file.id,
        },
      }
    )
    download(response.data, fileName)
    this.downloadBtnDisabled[`${file.path}-${index}`] = false
  }

  downloadAllFiles = async (ids) => {
    this.downloadAllDisabled = true
    const id = this.currentSampleTestId
    const tab_name = i18next.t(
      this.getCurrentTabName(this.activeTab, this.mainInquiryInfo.purpose)
    )
    const product_group = i18next.t(
      this.mainInquiryInfo.product.lang.product_key
    )
    const fileName = `${product_group}_${id}_${tab_name}`
    const response = await axios.get(
      '/api/node/v1/sampleTest/common/downloadAll',
      {
        responseType: 'blob',
        params: {
          ids: JSON.stringify(this.prepareFileIndexes()),
          test_id: id,
          purpose: this.mainInquiryInfo.purpose,
        },
      }
    )
    download(response.data, fileName)
    this.downloadAllDisabled = false
  }

  prepareFileIndexes = () => {
    return this.commonData.reduce((fileIndexes, item) => {
      item.groups.forEach((group) => {
        group.files.forEach((file, index) => {
          fileIndexes[file.id] = index + 1
        })
      })
      return fileIndexes
    }, {})
  }

  updateLocation = (data) => {
    const index = this.mainInquiryInfo.table.findIndex(
      (row) => row.id === data.id
    )
    this.mainInquiryInfo.table[index].other_location = data.other_location
    this.mainInquiryInfo.table[index].location = data.location
    this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
  }

  saveTests = async (data) => {
    let body = {
      id: data.sku_id,
      tests: data.tests,
      total: data.total,
      sample_test_id: this.currentSampleTestId,
    }
    try {
      const response = await axios.patch(
        '/api/node/v1/sampleTest/table/tableCell/tests',
        body
      )
      const index = this.mainInquiryInfo.table.findIndex(
        (row) => row.id === response.data.data.id
      )
      const images = this.mainInquiryInfo.table[index].images
      this.mainInquiryInfo.table[index] = response.data.data
      this.mainInquiryInfo.table[index].images = images
      this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
    } catch (e) {
      this.openNotificationWithIcon('error', 'Update row error')
    }
  }
  saveInquiryTable = async (row, dataIndex, timeMarker, additional) => {
    let body = {
      id: row.id,
      [dataIndex]: !row[dataIndex] ? null : row[dataIndex],
      additional,
      lang: this.commentsLang,
      sample_test_id: this.currentSampleTestId,
    }

    if (dataIndex === 'factory_id') {
      if (!row[dataIndex] || this.factoryList?.length > 1) {
        body.sku_id = null
      }
    }
    if (dataIndex === 'sku_id') {
      await this.getFactory(row?.sku_id)
      if (this.factoryList?.length === 1) {
        body.factory_id = this.factoryList[0]?.id
      }
    }
    if (timeMarker)
      body.samples_deadline_for_testing = row.samples_deadline_for_testing
    if (dataIndex === 'location')
      body = this.prepareLocationToSave(row, dataIndex)
    if (['samples_readiness_time', 'priorities'].includes(dataIndex))
      body.initiator = dataIndex
    this.rowUpdating[this.checkCurrentRowUpdating(dataIndex)] = {
      [row.id]: true,
    }
    try {
      const response = await axios.patch(
        '/api/node/v1/sampleTest/table/tableCell',
        body
      )
      const index = this.mainInquiryInfo.table.findIndex(
        (row) => row.id === response.data.data.id
      )
      const images = this.mainInquiryInfo.table[index].images
      this.mainInquiryInfo.table[index] = response.data.data 
      this.mainInquiryInfo.table[index].images = images
      this.mainInquiryInfo.table = [...this.mainInquiryInfo.table]
      this.preventWriteHistory = false
      this.rowUpdating[this.checkCurrentRowUpdating(dataIndex)] = {
        [row.id]: false,
      }
    } catch (e) {
      this.openNotificationWithIcon('error', 'Update row error')
    }
  }

  prepareLocationToSave = (row, index) => {
    let body = {
      id: row.id,
      location: null,
      other_location: null,
      sample_test_id: this.currentSampleTestId,
    }
    if (!row[index]) return body
    body.location =
      row[index] === 'w_other' && !row.other_location ? null : row[index]
    body.other_location =
      row[index] === 'w_other' && !!row.other_location
        ? row.other_location
        : null
    return body
  }

  responsibleExist = () => {
    return this.brand_managers.length
  }

  createInquiry = async (values) => {
    try {
      values.responsible = [
        ...this.product_managers,
        ...this.brand_managers,
        ...this.engineers,
      ]
      this.clearResponsible()
      const response = await axios.post('/api/node/v1/sampleTest', values)
      window.location.href = `/react/sampleTest/${response.data.data.id}`
    } catch (e) {
      this.openNotificationWithIcon('error', `Ошибка создания`)
    }
  }

  getData = async () => {
    if (this.activeTab === 2) {
      this.mainInquiryInfo = []
      this.getInquiryData(this.currentSampleTestId)
    }
  }

  isLastSkuWithoutDecision = () => {
    const hasDecision = this.mainInquiryInfo.table.filter((el) =>
      [
        this.skuStatusMapping.recommend.status_id,
        this.skuStatusMapping.reject.status_id,
      ].includes(el.status_id)
    ).length
    const withoutDecision = this.mainInquiryInfo.table.filter(
      (el) =>
        ![
          this.skuStatusMapping.recommend.status_id,
          this.skuStatusMapping.reject.status_id,
        ].includes(el.status_id)
    ).length
    return hasDecision && withoutDecision === 1
  }

  prepareTestExt = (tests) => {
    if (!this.testTypesList.length || !tests.length) return ''
    const allTests = this.toObjectByKey(this.testTypesList)
    const result = tests
      .map((id) => {
        return `--${allTests[id]?.name}`
      })
      .join('')
    return `${result}--`
  }

  saveFile = async (
    files,
    product,
    supplier_code,
    supplier_model,
    id,
    row_index,
    updateNeeded,
    group,
    group_index,
    tests = []
  ) => {
    const filteredFiles = files.filter((file) => {
      const fileName = file.name
      const fileExtension = fileName?.split('.')?.pop()

      if (
        !this.allowedFileTypes.includes(file.type) &&
        !file.type.startsWith('image/') &&
        !this.additionalExtensions.includes(fileExtension)
      ) {
        this.openNotificationWithIcon(
          'error',
          fileName,
          i18next.t('w_wrong_file_format')
        )
        return false
      }

      return true
    })
    try {
      const body = {
        sample_test_id: this.mainInquiryInfo.id,
        sample_test_sku_id: id,
        product,
        supplier_code,
        tab_id: this.activeTab,
        supplier_model,
        group_id: group.id,
        stage: group.stage,
        tests: tests,
        testsExt: this.prepareTestExt(tests),
        lang: this.commentsLang,
      }

      const formData = new FormData()
      for (let i = 0; i < filteredFiles.length; i++) {
        const actualFile = filteredFiles[i].originFileObj
        formData.append(`sampleTest`, actualFile)
      }
      if (!!filteredFiles?.length) {
        const response = await axios.post(
          `/api/node/v1/sampleTest/upload/common/${id}`, //2
          formData,
          {
            params: body,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )

              this.fileUploadProgress[this.activeTab] = {
                ...this.fileUploadProgress[this.activeTab],
                [id]: percentCompleted,
              }
              if (updateNeeded) this.fileMultiUploadProgress = percentCompleted
            },
          }
        )
        if (updateNeeded) {
          this.fileUploadProgress[this.activeTab][id] = null
          this.fileMultiUploadProgress = null
          await this.getCommonData()
        } else {
          this.fileUploadProgress[this.activeTab][id] = null
          this.fileMultiUploadProgress = null
          this.commonData[row_index] = response.data.data?.files[0]
          const idsFiles = response.data.data?.files[0]?.groups[0]?.files.map(
            (file) => file.id
          )
          this.currentTabFilesIds = idsFiles
        }
      }
    } catch (e) {
      this.openNotificationWithIcon(
        'error',
        e?.response?.data?.message || 'Server error'
      )
    }
  }

  saveReportTestFile = async (file, id, updateNeeded, tests = []) => {
    const fileName = file.name
    const fileExtension = fileName?.split('.')?.pop()

    if (
      !this.allowedFileTypes.includes(file.type) &&
      !file.type.startsWith('image/') &&
      !this.additionalExtensions.includes(fileExtension)
    ) {
      this.openNotificationWithIcon('error', i18next.t('w_wrong_file_format'))
      return
    }
    try {
      const body = {
        sample_test_id: this.mainInquiryInfo.id,
        tab_id: this.activeTab,
        tests: tests,
        testsExt: this.prepareTestExt(tests),
      }
      const formData = new FormData()
      formData.append(`sampleTest`, file)

      const response = await axios.post(
        `/api/node/v1/sampleTest/reportTest/${id}`,
        formData,
        {
          params: body,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )

            this.fileUploadProgress[this.activeTab] = {
              ...this.fileUploadProgress[this.activeTab],
              [id]: percentCompleted,
            }
            if (updateNeeded) this.fileMultiUploadProgress = percentCompleted
          },
        }
      )
      if (updateNeeded) {
        this.fileUploadProgress[this.activeTab][id] = null
        this.fileMultiUploadProgress = null
        this.reportTestFiles = response.data.data
      }
    } catch (e) {
      this.openNotificationWithIcon(
        'error',
        e?.response?.data?.message || 'Server error'
      )
    }
  }

  saveTestResultFile = async (file, product, user_id, i) => {
    const id = this.mainInquiryInfo.mainInquiry[i || 0].id
    const supplier_code = this.currentFactory?.factoryFullName
    const body = {
      inquiry_main_id: id,
      product,
      supplier_code,
      tab_id: this.activeTab,
      user_id,
    }
    const formData = new FormData()
    formData.append(`sampleTest`, file)

    const response = await axios.post(
      `/api/node/v1/sampleTest/upload/testResult/${id}`,
      formData,
      {
        params: body,
      }
    )
    if (response.data.success) {
      this.testResult[i || 0].sample_test_test_results.push(response.data.data)
    } else {
    }
  }

  createComment = async (value) => {
    this.commentInput = value
    this.checkCommentButtonDisabled()
  }

  containsNonHtml(str) {
    const regex = /<[^>]+>/g
    const nonHtmlTags = str.replace(regex, '')
    return nonHtmlTags.trim().length > 0
  }

  checkCommentButtonDisabled = () => {
    if (
      this.commentInput.length === 0 ||
      this.commentInput === '<p><br></p>' ||
      !this.containsNonHtml(this.commentInput)
    )
      this.isDisabledCommentButton = true
    else this.isDisabledCommentButton = false
  }

  addComment = async () => {
    try {
      const inquiry_main_id = +this.mainInquiryInfo.id
      let body = {
        inquiry_main_id,
        tab_id: +this.activeTab,
        comment: this.commentInput,
        lang: this.commentsLang,
      }
      let response = await axios.post(
        '/api/node/v1/sampleTest/common/comment',
        body
      )
      this.commonData[0].comments = this.commonData[0].comments.concat(
        response.data.data
      )
      this.commentInput = ''
    } catch (e) {
      this.openNotificationWithIcon('error', `Server error`)
    }
  }
  findSmallestDate = (rows) => {
    if (this.mainInquiryInfo.close_date && this.mainInquiryInfo.deadline)
      return dayjs(this.mainInquiryInfo.deadline).format('YYYY-MM-DD')
    let smallestDate = null
    if (rows.length) {
      for (const row of rows) {
        if (
          [null, this.skuStatusMapping.other.status_id].includes(
            row.status_id
          ) &&
          row.samples_deadline_for_testing
        ) {
          const currentDate = new Date(row.samples_deadline_for_testing)
          if (smallestDate === null || currentDate < smallestDate) {
            smallestDate = currentDate
          }
        }
      }
    }
    return smallestDate ? dayjs(smallestDate).format('YYYY-MM-DD') : ''
  }

  getInquiryData = async (id) => {
    this.loading = true
    try {
      const response = await axios.get(`/api/node/v1/sampleTest/${id}`, {
        params: {
          lang: this.commentsLang,
        },
      })
      this.mainInquiryInfo = response.data.data.inquiry
      this.newInfo = response.data.data.visit
      this.isResponsible = response.data.data.isResponsible
      this.optionsTableMainInquiry = response.data.data.optionsFilterTable
      this.loading = false
    } catch (e) {
      this.handleError(e, `/react/sampleTest`)
    }
  }

  getIntCodes = async () => {
    const id = this.mainInquiryInfo?.product.product_id
    this.intcodesLoading = true
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/product/intcode/${id}`
      )
      this.intCodesList = response.data.data
      this.intcodesLoading = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  getSkuTable = async (id) => {
    this.loading = true
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/skuTable/${id}`,
        {
          params: {
            ...this.inquiryFilter,
          },
        }
      )
      this.mainInquiryInfo.table = response.data.data.table
      this.loading = false
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  saveApproveActions = async (
    status_id,
    comment,
    id,
    sku_id,
    priority,
    test_id,
    row_index,
    recommend_int_code,
    comparisonTable,
    recommend_int_code_id
  ) => {
    let body = {
      id,
      comment,
      status_id,
      sku_id,
      priority,
      test_id,
      recommend_int_code,
      comparisonTable,
      lang: this.commentsLang,
      recommend_int_code_id,
      sample_test_id: this.currentSampleTestId,
    }
    try {
      let response = await axios.post(
        '/api/node/v1/sampleTest/testResult/fileStatus',
        body
      )
      this.commonData[row_index] = response.data.data[0]
    } catch (e) {
      this.openNotificationWithIcon(
        'error',
        e.response?.data?.message || 'Server error'
      )
    }
    this.modalResultOpen = false
  }

  getCommonData = async () => {
    this.loading = true
    this.commonData = []
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/commonPage/${this.currentSampleTestId}/${this.activeTab}`,
        {
          params: {
            lang: this.commentsLang,
          },
        }
      )
      this.currentUserId = response.data.data.currentUserId
      this.isResponsible = response.data.data.isResponsible
      this.commonData = [...response.data.data.files]
      this.newInfo = response.data.data.visit
      this.currentTabFilesIds = response.data.data.all_files_ids
      this.reportTestFiles = response.data.data.reportTestFiles
      this.loading = false
      this.getComments(
        this.activeTab === 2
          ? this.getCurrentTabNameChat(4)
          : this.getCurrentTabNameChat(this.activeTab)
      )
    } catch (e) {
      this.handleError(e, '/react/sampleTest')
    }
  }

  getCommonDataById = async (sampleTestId) => {
    this.loading = true
    this.commonData = []
    try {
      const response = await axios.get(
        `/api/node/v1/sampleTest/commonPage/${sampleTestId}/5`,
        {
          params: {
            lang: this.commentsLang,
          },
        }
      )
      this.currentUserId = response.data.data.currentUserId
      this.isResponsible = response.data.data.isResponsible
      this.commonData = [...response.data.data.files]
      this.currentTabFilesIds = response.data.data.all_files_ids
      this.reportTestFiles = response.data.data.reportTestFiles
      this.loading = false
    } catch (e) {
      this.handleError(e, '/react/sampleTest')
    }
  }

  getResultData = async () => {
    const response = await axios.get(
      `/api/node/v1/sampleTest/testResult/${this.currentSampleTestId}/${this.activeTab}`
    )
    this.testResult = response.data.data
  }

  getCurrentFactoryInf = async (id) => {
    const response = await axios.get(
      `/api/node/v1/sampleTest/factories/currentFactory/${id}`
    )
    this.currentFactory = response.data.data
  }

  setCurrentSampleTestId = (id) => {
    this.currentSampleTestId = +id
  }

  changeActiveTab = async (id) => {
    this.clearInquiryTableFilters()
    this.activeTab = +id
    ;[3, 4, 5].includes(+id) ? await this.getCommonData() : await this.getData()
  }

  assignToQc = async (value) => {
    if (!value.comment.trim()) return
    const sample_test_id = this.mainInquiryInfo.id
    const body = {
      sample_test_id,
      qc_comment: value.comment,
    }
    try {
      const response = await axios.post(
        `/api/node/v1/sampleTest/testResult/comment`,
        body
      )

      if (response.data.success) {
        this.mainInquiryInfo.qc_comments.unshift(response.data.data)
        this.mainInquiryInfo.assigned_to_qc = true
      }
    } catch (e) {
      this.openNotificationWithIcon('error', 'Assign to qc error')
    }
  }

  createTableRow = async (sample_test_id) => {
    const body = {
      sample_test_id,
    }
    const response = await axios.post(
      `/api/node/v1/sampleTest/table/tableRow`,
      body
    )
    this.mainInquiryInfo.table = this.mainInquiryInfo.table.concat(
      response.data.data
    )
  }

  deleteTableRow = async (id, test_id) => {
    this.loading = true
    try {
      console.log('this.currentSampleTestId', this.currentSampleTestId)
      await axios.delete(`/api/node/v1/sampleTest/table/tableRow/${id}`, {
        params: {
          test_id: test_id,
          sample_test_id: this.currentSampleTestId,
        },
      })
      this.mainInquiryInfo.table = this.mainInquiryInfo.table.filter(
        (row) => row.id !== +id
      )
      this.loading = false
      if (test_id) await this.getData()
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  deleteCommonFile = async (
    file,
    sku_id,
    group_index,
    sample_test_id,
    row_index
  ) => {
    try {
      const id = file.id
      const tab_id = +file.tab_id
      const index = this.commonData.findIndex((el) => el.id === sku_id)
      const deleteGroup =
        this.commonData[index].groups[group_index]?.files.length === 1 ? 1 : 0
      const lastGrouped =
        Array.isArray(sku_id) && group_index === 0 && deleteGroup ? 1 : 0
      let response = await axios.delete(
        `/api/node/v1/sampleTest/file/${tab_id}/${id}`,
        {
          params: {
            sample_test_id,
            lastGrouped,
            sku_id,
            deleteGroup,
          },
        }
      )
      if (lastGrouped) {
        await this.getCommonData()
      } else {
        this.commonData[row_index] = response.data.data[0]
        this.currentTabFilesIds = this.currentTabFilesIds.filter(
          (el) => el !== file.id
        )
      }
    } catch (e) {
      this.openNotificationWithIcon(
        'error',
        e.response?.data?.message || 'Server error'
      )
    }
  }
  deleteReportTestFile = async (file) => {
    const id = file.id

    const response = await axios.delete(
      `/api/node/v1/sampleTest/reportTest/${id}`
    )
    if (response.data.success) {
      this.reportTestFiles = this.reportTestFiles.filter(
        (el) => el.id !== file.id
      )
    }
  }
  deleteResultFile = async (file, i) => {
    const id = file.id
    const tab_id = +file.tab_id
    const response = await axios.delete(
      `/api/node/v1/sampleTest/file/${tab_id}/${id}`
    )
    if (response.data.success) {
      this.testResult[i || 0][tabConf.tabConf[tab_id]] = this.testResult[
        i || 0
      ][tabConf.tabConf[tab_id]].filter((file) => file.id !== id)
    }
  }

  addTableRow = async (data, copy) => {
    try {
      const body = {
        row: {
          ...data,
          status_id: null,
          user_comment: null,
          status: null,
          decision_date: null,
          decision_user_id: null,
          decision_user_name: null,
          copyId: data.id,
        },
        copy: true,
      }
      if (copy) {
        body.row.fileGroup = null
        body.row.sku_id = null
        body.row.factory_id = null
        body.row.competitor_id = null
        body.row.competitor_model = null
      }
      const response = await axios.post(
        `/api/node/v1/sampleTest/table/tableRow`,
        body
      )
      this.mainInquiryInfo.table = this.mainInquiryInfo.table.concat(
        response.data.data
      )
      return response.data.data
    } catch (e) {
      this.openNotificationWithIcon('error', 'Server error')
    }
  }

  changeInquiryEdit = (key) => {
    return {
      engineer: () => {
        this.inquiryEditRow.engineer = !this.inquiryEditRow.engineer
        return this.inquiryEditRow.engineer ? this.getProductEngineers() : null
      },
      brand_manager: () => {
        this.inquiryEditRow.brand_manager = !this.inquiryEditRow.brand_manager
        return this.inquiryEditRow.brand_manager
          ? this.getBrandManagers()
          : null
      },
      product_manager: () => {
        this.inquiryEditRow.product_manager =
          !this.inquiryEditRow.product_manager
        return this.inquiryEditRow.product_manager
          ? this.getProductManagers()
          : null
      },
      deadline: () =>
        (this.inquiryEditRow.deadline = !this.inquiryEditRow.deadline),
      qc: () => {
        this.inquiryEditRow.qc = !this.inquiryEditRow.qc
        return this.inquiryEditRow.qc ? this.getQc() : null
      },
    }[key]()
  }

  checkCurrentRowUpdating = (dataIndex) => {
    if (['location', 'other_location'].includes(dataIndex)) return 'location'
    if (dataIndex === 'sku_id') return 'supplier_model'
    if (dataIndex === 'factory_id') return 'factory_abbreviation'
    return dataIndex
  }

  hideActionColumn = () => {
    return (
      !access(this.access, this.activeTab, 'edit_table') ||
      this.mainInquiryInfo.close_date ||
      !this.isResponsible
    )
  }
  isEditableRow = (row) => {
    return (
      access(this.access, this.activeTab, 'edit_table') &&
      this.isResponsible &&
      !row.status_id &&
      !this.mainInquiryInfo.close_date
    )
  }

  isEditableDeadline = (row) => {
    return (
      (access(this.access, this.activeTab, 'edit_table') ||
        access(this.access, this.activeTab, 'edit_samples_deadline')) &&
      this.isResponsible &&
      !row.status_id &&
      !this.mainInquiryInfo.close_date
    )
  }
  quantityFormatter = (val) => {
    // if (val === '0') return '1'
    if (val.length > 7) return val.slice(0, 7)
    return val
  }
  quantityParser = (val) => {
    // if (val === '0') return '1'
    if (val === '') return ''
    return val
  }
  openindicationNumberModal = () => {
    this.indicationNumberModal = true
  }
  quantityBeforeSave = (value, save) => {
    const numString = String(value)
    if (value === null) {
      save(null)
      return
    }
    // else if (value < 1) {
    // value = 1
    // }
    else if (String(numString).length > 7) {
      this.openNotificationWithIcon('error', 'w_invalid_characters_number')
      value = Number(numString.slice(0, 7))
    }
    save(value)
  }
  changingTestsQuantity = (obj1, obj2) => {
    if (obj1 === obj2) return true

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return false
    }

    let keys1 = Object.keys(obj1)
    let keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) return false

    for (let key of keys1) {
      if (!keys2.includes(key)) return false

      if (key === 'tests') {
        if (!Array.isArray(obj1[key]) || !Array.isArray(obj2[key])) return false
        if (obj1[key].length !== obj2[key].length) return false

        let names1 = obj1[key].map((test) => test.name).sort()
        let names2 = obj2[key].map((test) => test.name).sort()

        for (let i = 0; i < names1.length; i++) {
          if (names1[i] !== names2[i]) return false
        }
      } else {
        if (!this.changingTestsQuantity(obj1[key], obj2[key])) return false
      }
    }

    return true
  }
  changeCommentsLang = async (lang) => {
    this.commentsLang = lang
    if (this.activeTab === 2) {
      await this.getInquiryData(this.currentSampleTestId)
    } else {
      this.getCommonData()
    }
  }
  // предпросмотр файлов
  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(`${link}${file.id}`, {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }
  shouldDisableButtonNewSampleModal = (
    form,
    intCode,
    selectedFactory,
    selectedModel
  ) => {
    const isCompetitorModelInvalid =
      this.mainInquiryInfo.purpose === 'competitor' &&
      !form.getFieldValue('competitor_model')?.trim()?.length

    const hasFormErrors = !!form
      .getFieldsError()
      .filter(({ errors }) => errors.length).length

    const isPurposeValidationOrRetest =
      this.mainInquiryInfo.purpose === 'validation' ||
      this.mainInquiryInfo.purpose === 're-test'

    const isIntCodeConditionMet =
      (intCode !== 're-test' &&
        this.mainInquiryInfo.purpose === 'validation') ||
      (intCode === 're-test' && this.mainInquiryInfo.purpose === 're-test')

    const isFactoryOrModelNotSelected =
      (isPurposeValidationOrRetest && !selectedFactory) ||
      (isPurposeValidationOrRetest && !selectedModel)

    return (
      !form.isFieldsTouched(true) ||
      isCompetitorModelInvalid ||
      hasFormErrors ||
      isIntCodeConditionMet ||
      isFactoryOrModelNotSelected
    )
  }
  //CHAT
  getCurrentTabNameChat = (tabId, chat) => {
    let tabKeys = {
      3: chat ? 'chat_techSpecification' : 'techSpecification',
      4: chat ? 'chat_techInfo' : 'techInfo',
      5: chat ? 'chat_testResult' : 'testResult',
    }
    return tabKeys[tabId]
  }
  getComments = async (tab) => {
    try {
      if (!this.currentSampleTestId) return
      let tab_id = 3
      if (tab === 'chat_techSpecification' || tab === 'techSpecification')
        tab_id = 3
      else if (tab === 'chat_techInfo') tab_id = 4
      else if (tab === 'chat_testResult') tab_id = 5

      const response = await axios.get(
        `/api/node/v1/sampleTest/chat/${tab}/${this.currentSampleTestId}`,
        { params: { lang: this.commentsLanguage[tab] } }
      )

      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[this.getCurrentTabNameChat(+tab_id)] =
          response.data.data.messages
        this.commentsUsers[this.getCurrentTabNameChat(+tab_id, 'chat')] =
          response.data.data.users.map((el) => {
            return {
              value: el.fullName,
              label: el.fullName,
              id: el.user_id,
            }
          })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
  postComment = async (text, tab, files, users) => {
    try {
      let tab_id
      if (tab === 'chat_techSpecification' || tab === 'techSpecification')
        tab_id = 3
      else if (tab === 'chat_techInfo') tab_id = 4
      else if (tab === 'chat_testResult') tab_id = 5
      const formData = new FormData()
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append('chat_files', files[i])
        }
      }
      formData.append(`sample_test_id`, this.currentSampleTestId)
      formData.append(`user_id`, this.currentUserId)
      formData.append(`text`, text)
      formData.append(`users`, users)
      const response = await axios.post(
        `/api/node/v1/sampleTest/chat/${tab}`,
        formData
      )
      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[this.getCurrentTabNameChat(+tab_id)] =
          response.data.data.messages
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
  deleteComment = async (commentId, tab) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/sampleTest/chat/${tab}/${commentId}`
      )
      if (response.data.success) {
        await this.getComments(tab)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
  downloadChatFile = async (file) => {
    const response = await axios.get(
      `/api/node/v1/sampleTest/download/chatFiles/${file.id}`,
      {
        responseType: 'blob',
      }
    )
    if (response.status === 200) {
      download(response.data, file?.original_name)
    }
  }
  renderBadge = (tabId) => {
    try {
      let badgeVisible = false
      if (tabId === 2) {
        badgeVisible =
          this.newInfo?.inquity ||
          this.newInfo?.qc?.assign ||
          this.newInfo?.qc?.qc?.includes(this.currentUserId)
      } else if (tabId === 5) {
        badgeVisible =
          this.newInfo?.solution?.some((item) => item?.isOlder) ||
          this.newInfo?.finalSolution?.some(
            (sulution) => sulution?.tab_id === tabId && sulution?.isOlder
          )
      }
      if ([3, 4, 5].includes(tabId)) {
        badgeVisible =
          badgeVisible ||
          this.newInfo?.files?.some(
            (file) => file?.tab_id === tabId && file?.isOlder
          ) ||
          this.newInfo?.filesReport?.some(
            (file) => file?.tab_id === tabId && file?.isOlder
          )
      }
      return badgeVisible
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
      return false
    }
  }
  updateLastVisitTest = async (chat) => {
    let body = {
      user_id: this.currentUserId,
      sample_test_id: this.currentSampleTestId,
      chat: chat,
    }
    try {
      const response = await axios.patch(
        '/api/node/v1/sampleTest/updateLastVIsit',
        body
      )
    } catch (e) {
      this.openNotificationWithIcon('error', 'Update Last Visit Test error')
    }
  }
}
export default SampleTestStore
