import { makeAutoObservable, toJS } from 'mobx'
import download from 'downloadjs'
import { notification } from 'antd'
import api from '../utils/axiosAuthInterceptor'
import i18next from 'i18next'
import dayjs from 'dayjs'

const axios = api
const moment = require('moment')

class FactoriesStore {
  tableLoading = true
  isBtnsDisabled = false
  factories = []
  userId = undefined
  page = 1
  provinces = []
  cities = []
  factoryId = undefined
  details = []
  detailFiles = undefined
  factoryContacts = undefined
  provinceOrCityModalTypeOpen = null
  user = null
  downloadFileLoad = false

  factoriesState = {
    newOpen: false,
    idxPopover: null
  }

  contactsModalState = {
    isModalOpen: false,
    isEdit: false,
    contactId: null,
    idx: null,
    isStateLoading: false,
    idxPopover: null,
    newOpen: false,
  }

  detailsModalState = {
    isModalOpen: false,
    state: 'common',
    prevStateOfCard: null,
    isStateLoading: false,
    activekey: null,
    idxPopover: null,
    newOpen: false,
    isEmailLoading: false,
  }

  filter = ''
  showFilter = true
  uploadLoading = undefined
  pageSize = 20
  totalFactoriesQty = undefined
  searchText = undefined
  editButton = false
  fileName = undefined
  abbreviation = null
  emailModalOpen = false
  emailModalMainTable = false

  filtersParams = {
    province: {
      filtered: [],
      options: [],
    },
    city: {
      filtered: [],
      options: [],
    },
    abbreviations: {
      filtered: [],
      options: [],
    },
    sort: {
      sortColumn: null,
      sortOrder: null,
    },
    productGroups: {
      filtered: [],
      options: [],
    },
  }
  sort = {
    name: true,
  }

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  // All get data function
  getData = async () => {
    this.tableLoading = true
    const params = {
      provinces: this.filtersParams.province.filtered,
      cities: this.filtersParams.city.filtered,
      rowsPerPage: this.pageSize,
      page: this.page,
      order: this.filtersParams.sort.sortOrder,
      orderBy: this.filtersParams.sort.sortColumn,
      find: this.searchText,
      abbreviations: this.filtersParams.abbreviations.filtered,
      productGroups: this.filtersParams.productGroups.filtered,
    }

    let response = await axios.get('/api/node/v1/factories', {
      params,
    })
    this.factories = response.data.data.rows.map((factory) => ({
      ...factory,
      product_groups: factory.product_groups
        ? factory.product_groups.split(',').map((group) => ({
            label: group,
            value: group,
          }))
        : [],
    }))
    this.userId = response.data.data.user.user_id
    this.user = response.data.data.user
    this.totalFactoriesQty = response.data.data.count
    this.tableLoading = false
    this.isBtnsDisabled = false
    this.prevStateOfRow = null
  }

  getAllAbbreviations = async () => {
    try {
      let response = await axios.get('/api/node/v1/factories/abbreviations')
      this.filtersParams.abbreviations.options = response.data.map((el) => ({
        value: el.id,
        label: el.abbreviation,
      }))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', `Get all abbreviations error`)
    }
  }

  getAllproductGroups = async () => {
    try {
      let response = await axios.get('/api/node/v1/factories/productgGroups')
      this.filtersParams.productGroups.options = response.data
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', `Get all product groups error`)
    }
  }

  getProvinces = async () => {
    try{
      let response = await axios.get('/api/node/v1/provinces')
      let beforeMap = response.data.data
      const temp = JSON.parse(JSON.stringify(this.filtersParams))
      temp.province.options = beforeMap.map((el) => ({
        value: el.id,
        label: el.name,
      }))
      this.filtersParams = temp
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  getCities = async () => {
    try{
      let response = await axios.get('/api/node/v1/cities')
      let beforeMap = response.data.data
      const temp = JSON.parse(JSON.stringify(this.filtersParams))
      temp.city.options = beforeMap.map((el) => ({
        value: el.id,
        label: el.name,
      }))
      this.filtersParams = temp
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  getAll = async () => {
    await this.getData()
    await this.getAllAbbreviations()
    await this.getAllproductGroups()
    await this.getProvinces()
    await this.getCities()
  }

  // Factories main table
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  changePage = (e) => {
    this.page = e.current
    this.getData()
  }

  changeSize = (current, size) => {
    this.pageSize = size
  } 

  editRow = async (index) => {
    this.isBtnsDisabled = true
    this.prevStateOfRow = JSON.parse(JSON.stringify(this.factories[index]))
    this.factories[index].isEdit = true
    this.factories[index].detailsButton = true
  }


  onFinishMainForm = async (values) => {
    if (this.prevStateOfRow) {
      values.id = this.prevStateOfRow.id
      if (values.email) {
        await this.getContacts(values.id)
        if (this.checkExactEmailExist(values.email, 'SaveRow')) return
      }
      await this.saveFactoryRow(values)
    } else {
      values.isNew = true
      await this.saveFactoryRow(values)
    } 
  }
  
  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }


  saveFactoryRow = async (row) => {
    if(typeof row.product_groups[0] === 'object'){
      row.product_groups = row.product_groups.map(prod=>prod.value)
    }
   
    const body = {
      id: row.id,
      name: row.full_company_name,
      abbreviation: row.name,
      address: row.address,
      postal_code: row.postal_code,
      province_id: row.province_id,
      city_id: row.city_id,
      contact_person: row.contact_person,
      email: row.email,
      phone: row.phone,
      web: row.web,
      cooperate_date: dayjs(row.cooperate_date).hour(11).minute(0).second(0),
      comments: row.comments,
      product_groups: row.product_groups,
    }

    try {
      this.tableLoading = true
      this.isBtnsDisabled = false
      const response = await axios.put('/api/node/v1/factories', body)

      if (response.data.success) {
        this.openNotificationWithIcon('success', 'Row saved!')
        this.getAll()
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.message || 'Unknown error'
      if (errorMessage.startsWith('w_')) {
        let fullCompanyName =
          error.response.data.errors.fullCompanyName.length > 0
            ? error.response.data.errors.fullCompanyName
            : 'Factory without full company name'
        errorMessage = i18next.t(errorMessage)
        errorMessage = errorMessage.split('#').join(fullCompanyName)
      }
      this.openNotificationWithIcon('error', `Error: ${errorMessage}`)
    } finally {
      this.tableLoading = false
    }
  }

  deleteRow = async (id) => {
    try {
      this.tableLoading = true
      await axios.delete(`/api/node/v1/factories/${id}`)
      this.openNotificationWithIcon('success', i18next.t('w_suсcess'))
      const abr = this.filtersParams.abbreviations
      if (abr.filtered.length > 0) {
        abr.filtered = abr.filtered.filter((id) => id !== id)
      }
      await this.getAll()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.tableLoading = false
    }
  }

  cancelRow = (row, index) => {
    let temp = [...this.factories]
    if (row.isNew) {
      temp.splice(index, 1)
    } else {
      temp[index] = this.prevStateOfRow
    }
    temp[index].isEdit = false
    this.isBtnsDisabled = false
    this.factories = temp
  }

  addRow = () => {
    this.isBtnsDisabled = true
    this.page = 1
    this.filter = ''
    let temp = [...this.factories]
    temp.unshift({
      name: '',
      full_company_name: '',
      address: '',
      province_id: undefined,
      city_id: undefined,
      contact_person: '',
      email: '',
      phone: '',
      web: '',
      cooperate_date: undefined,
      comments: '',
      postal_code: undefined,
      isNew: true,
      isEdit: true,
      detailsButton: true,
      product_groups: [],
    })
    this.factories = temp
  }

  setFilter = (e) => {
    this.filter = e.target.value
  }

  onSearch = (value) => {
    this.searchText = this.filter.trim()
    if (value == '') {
      this.searchText = undefined
    }
    this.getData()
  }

  //фильтрация для бэка

  acceptFilter = (type, data) => {
    this.filtersParams[type].filtered = data
    this.page = 1
    this.getData()
  }

  setSortOrder = (type) => {
    this.filtersParams.sort.sortOrder = type
  }

  setSortColumn = (type) => {
    this.filtersParams.sort.sortColumn = type
  }

  clearFilters = (isGetDataNeeded = true) => {
    for (let filter in this.filtersParams) {
      if (this.filtersParams[filter].filtered) {
        this.filtersParams[filter].filtered = []
      } else {
        this.filtersParams[filter].sortColumn = undefined
        this.filtersParams[filter].sortOrder = undefined
      }
    }
    this.filter = ''
    this.page = 1
    this.searchText = undefined
    this.isBtnsDisabled = false
    if (isGetDataNeeded) {
      this.getData()
    }
  }

  onClearFilter = (type) => {
    this.filtersParams[type].filtered = []
    this.getData()
  }

  filteredByUrl = async (factory) => {
    this.filtersParams.abbreviations.filtered = factory
    await this.getAll()
    if (this.factories.length) {
      this.filtersParams.abbreviations.filtered = {
        label: this.factories[0].name,
        value: factory,
      }
    } else {
      this.openNotificationWithIcon('error', 'No such factory with this ID')
      this.filtersParams.abbreviations.filtered = []
    }
  }

  exportFactories = async () => {
    this.downloadFileLoad = true
    const params = {
      provinces: this.filtersParams.province.filtered,
      cities: this.filtersParams.city.filtered,
      productGroups: this.filtersParams.productGroups.filtered,
      order: this.filtersParams.sort.sortOrder,
      orderBy: this.filtersParams.sort.sortColumn,
      find: this.searchText,
      abbreviations: this.filtersParams.abbreviations.filtered,
    }
    try{
      const response = await axios({
        url: '/api/node/v1/factories/export',
        responseType: 'blob',
        method: 'get',
        params,
      })
      const blob = await response.data
      download(blob, `Factories ${moment().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.downloadFileLoad = false
    }
  }

  sendExcelEmail = async (values) => {
    const emails = values.emails.map((email) => email.email)

    const body = {
      provinces: this.filtersParams.province.filtered,
      cities: this.filtersParams.city.filtered,
      productGroups: this.filtersParams.productGroups.filtered,
      order: this.filtersParams.sort.sortOrder,
      orderBy: this.filtersParams.sort.sortColumn,
      find: this.searchText,
      abbreviations: this.filtersParams.abbreviations.filtered,
      emails,
      comment: values.comment,
    }

    try {
      this.detailsModalState.isEmailLoading = true
      await axios.post('/api/node/v1/factories/export/mail', body)
      this.openNotificationWithIcon('success', 'Email sent!')
      this.emailModalMainTable = false
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.detailsModalState.isEmailLoading = false
    }
  }

  changeModalType = (type) => {
    this.provinceOrCityModalTypeOpen = type
  }

  postProvinceOrCity = async (values) => {
    try{
      let reqType = this.provinceOrCityModalTypeOpen === 'province' ? 'provinces' : 'cities'
      let body = {
        name: values.name,
        province_id: values.province_id ? values.province_id : null,
      }
      await axios.post(`/api/node/v1/${reqType}/update`, body)
      this.changeModalType(null)
      await this.getCities()
      await this.getProvinces()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  handleCancel = () => {
    this.previewVisible = false
  }

  // Details modal

  openDetailsModal = async (id) => {
    this.factoryId = id

    await this.getDetails()
    this.detailsModalState.isModalOpen = true
    this.cancelBtnDisabled = true
    this.editBtnDisabled = false
  }

  cancelEditDetailsModal = () => {
    if (this.detailsModalState.prevStateOfCard) {
      let idx = this.details.findIndex((el) => el.isEdit === true)
      this.details[idx] = { ...this.detailsModalState.prevStateOfCard }
    } else {
      this.details.pop()
    }
    this.clearDetailsState()
  }

  toggleEmailModal = (event) => {
    event.stopPropagation()
    this.emailModalOpen = !this.emailModalOpen
  }

  toggleEmailModalMainTable = () => {
    this.emailModalMainTable = !this.emailModalMainTable
  }

  getFile = (e) => {
    if (Array.isArray(e)) {
      return e
    }
    if (e.fileList.length > 1) {
      e.fileList.shift()
    }
    return e && e.fileList[0]
  }

  getFileDetails = async (id) => {
    let response = await fetch(`/api/node/v1/instructions/file/${id}`)
    let data = await response.json()
    this.fileName = data.data.files.file_link
    this.fileId = data.data.files.id
  }

  getDetails = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/factories/details/${this.factoryId}`
      )
      if (response.data.success) {
        this.details = response.data.data.details
        this.abbreviation = response.data.data.abbreviation
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error'
        this.openNotificationWithIcon('error', errorMessage)
      } else {
        this.openNotificationWithIcon('error', error.message)
      }
    }
    this.clearDetailsState()
    if (this.details?.length === 0) {
      this.handleAddDetailsModalBtn()
    }
  }

  clearDetailsState = () => {
    this.detailsModalState = {
      ...this.detailsModalState,
      state: 'common',
      prevStateOfCard: null,
      isStateLoading: false,
      newOpen: false,
      idxPopover: null,
    }
  }

  deleteFile = async () => {
    this.detailsModalState.isStateLoading = true
    let factoryId = this.factoryId
    let body = {
      factory_id: factoryId,
    }

    const response = await axios.post(
      `/api/node/v1/factories/details/signature/update`,
      body
    )
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'File deleted!')
      this.details.map((el) => (el.original_filename = null))
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
    this.detailsModalState.isStateLoading = false
  }

  handlePanelChange = (key) => {
    this.detailsModalState.activekey = key
  }

  editDetail = (idx, event) => {
    event.stopPropagation()
    this.detailsModalState.prevStateOfCard = { ...this.details[idx] }
    this.details[idx].isEdit = true
    this.detailsModalState.state = 'editing'
    this.detailsModalState.activekey = this.details[idx].id
  }

  editDetailValues = (detailId, type, value) => {
    let temp = [...this.details]
    let el = temp.find((elem) => elem.id === detailId)
    el[type] = value
    this.details = temp
  }

  onFinishFailedDetailsForm = () => {
    this.openNotificationWithIcon('error', 'You must fill all required fields!')
  }

  onFinishDetailsForm = async (values) => {
    let file
    values.factory_id = this.factoryId
    let temp = [...this.details]
    let idx = temp.findIndex((el) => el.isEdit === true)
    values.details_name = temp[idx].details_name
    let id = temp[idx].id !== 'New' ? temp[idx].id : null
    if (id) {
      values.id = id
    }
    if (values.beneficiary_currency === 'USD') {
      values.beneficiary_account_no_usd = values.beneficiary_account_no
      values.beneficiary_account_no_cny = null
    }
    if (values.beneficiary_currency === 'CNY') {
      values.beneficiary_account_no_cny = values.beneficiary_account_no
      values.beneficiary_account_no_usd = null
    }
    if (
      id &&
      values.is_transfer_to_russia !==
        this.detailsModalState.prevStateOfCard.is_transfer_to_russia
    ) {
      values.checkbox_update_date = Date.now()
      values.user_id = this.userId
    }
    if (!id && values.is_transfer_to_russia) {
      values.checkbox_update_date = Date.now()
      values.user_id = this.userId
    }

    const {
      beneficiary_account_no,
      beneficiary_currency,
      ...data
    } = values

    this.detailsModalState.isStateLoading = true
    let response = await axios.put(`/api/node/v1/factories/details`, {
      data,
    })
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Updated!')
      if (file !== undefined) {
        let data = new FormData()
        data.append('factory_id', this.factoryId)
        data.append('companySealSignature', file)
        await axios.post(
          `/api/node/v1/factories/details/signature/update`,
          data
        )
      }
      await this.getDetails()
      this.detailsModalState.activekey = response.data.data
    } else {
      this.openNotificationWithIcon('error', 'Error!')
      this.detailsModalState.isStateLoading = false
    }
  }

  sendDetailsEmail = async (values) => {
    const emails = values.emails.map((email) => email.email)

    const body = {
      factoryId: this.factoryId,
      emails,
      comment: values.comment,
    }

    try {
      this.detailsModalState.isEmailLoading = true
      await axios.post('/api/node/v1/factories/messages/details/send', body)
      this.openNotificationWithIcon('success', 'Email sent!')
      this.emailModalOpen = false
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.detailsModalState.isEmailLoading = false
    }
  }

  closeDetailsModal = () => {
    this.detailsModalState.isModalOpen = false
    this.clearDetailsState()
  }

  toDeleteDetail = async (detailId, details_name) => {
    this.detailsModalState.isStateLoading = true
    const response = await axios.delete(
      `/api/node/v1/factories/details/toDelete/${detailId}`
    )
    if (response.data.success) {
      this.openNotificationWithIcon(
        'success',
        `${details_name ? details_name : 'Bank details'} was deleted!`
      )
      this.getDetails()
    }
  }

  handleBeneficiaryCurrency = (value, detail) => {
    let idx = this.details.findIndex((el) => detail.id === el.id)
    this.details[idx].beneficiary_currency = value
  }

  handleAddDetailsModalBtn = () => {
    const newDetail = {
      id: 'New',
      beneficiary_currency: 'USD',
      beneficiary_account_no_cny: null,
      beneficiary_account_no_usd: null,
      beneficiary_address: null,
      beneficiary_bank_address: null,
      beneficiary_bank_name: null,
      beneficiary_name: null,
      cnaps: null,
      company_registration_date: null,
      director_name: null,
      original_filename: null,
      purpose_of_payment: null,
      swift: null,
      isEdit: true,
      details_name: null,
      beneficiary_account_no: null,
      is_transfer_to_russia: false,
      checkbox_update_date: null
    }
    this.detailsModalState.activekey = 'New'
    this.detailsModalState.state = 'editing'
    this.details.push(newDetail)
  }

  handleDeletePopover = (state, idx) => {
    this[state].idxPopover = idx
  }
  onOpenChangePopover = (state, newOpen) => {
    this[state].newOpen = newOpen
  }
  cancelPopover = (state) => {
    this[state].newOpen = false
    this[state].idxPopover = null
  }

  //Contacts Modal

  getContacts = async (factoryId) => {
    let response = await axios.get(`/api/node/v1/factory_contacts/${factoryId}`)
    this.factoryContacts = response.data.data.contacts
    this.abbreviation = response.data.data.abbreviation
    this.contactsModalClearState()

    if (this.factoryContacts.length === 0) {
      this.handleAddModalBtn(factoryId)
    }
    return
  }

  openContactsModal = async (id) => {
    this.factoryId = id
    await this.getContacts(id)
    this.contactsModalState.isModalOpen = true
  }

  closeContactsModal = () => {
    this.contactsModalState.isModalOpen = false
  }

  editContact = async (contactId, idx) => {
    this.contactsModalState.contactId = contactId
    this.contactsModalState.idx = idx
    this.factoryContacts[idx].isEdit = true
    this.contactsModalState.isEdit = true
  }

  onFinishFailedContactsForm = () => {
    this.openNotificationWithIcon(
      'error',
      'Wrong fields data, check the error messages'
    )
  }

  onFinishContactsForm = async (values) => {
    this.contactsModalState.isStateLoading = true
    const body = {
      contact_person: values.contact_person,
      email: values.email,
      phone: values.phone,
      is_main: values.is_main,
      factory_id: this.factoryId,
    }

    let response = await axios.put(
      `/api/node/v1/factory_contacts/${this.contactsModalState.contactId}`,
      body
    )
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Updated!')
      this.factoryContacts = response.data.data
      this.contactsModalClearState()
      this.getData()
    } else {
      this.openNotificationWithIcon('error', response.data.message)
      this.contactsModalState.isStateLoading = false
    }
  }

  handleDeletePopoverContact = (el) => {
    let changedEl, idx
    if (el?.isDelPopOverVisible) {
      changedEl = { ...el, isDelPopOverVisible: false }
    } else {
      changedEl = { ...el, isDelPopOverVisible: true }
    }
    idx = this.factoryContacts.findIndex((elem) => elem.id === el.id)
    this.factoryContacts[idx] = changedEl
  }

  deleteContact = async (contactId) => {
    this.contactsModalState.isEdit = false
    let temp = [...this.factoryContacts]
    let idx = this.factoryContacts.findIndex((el) => el.id === contactId)
    let { ...el } = temp.find((e) => e.id === contactId)
    this.handleDeletePopoverContact(el)
    el.isDeleting = true
    this.factoryContacts[idx] = el
    let response = await axios.delete(
      `/api/node/v1/factory_contacts/${contactId}`
    )
    if (response.data.success) {
      this.factoryContacts.splice(idx, 1)
      this.openNotificationWithIcon('success', 'Contact deleted!')
    } else {
      el.isDeleting = false
      this.factoryContacts[idx] = el
      this.openNotificationWithIcon('error', 'Error!')
    }
    this.contactsModalClearState()
  }

  contactsModalOnchangeCheckbox = (idx) => {
    this.factoryContacts[idx].is_main = !this.factoryContacts[idx].is_main
  }

  handleAddModalBtn = () => {
    this.contactsModalState.contactId = 'New'
    this.contactsModalState.isEdit = true
    this.factoryContacts.push({
      id: 'New',
      factory_id: this.factoryId,
      contact_person: '',
      email: '',
      phone: '',
      is_main: false,
      isEdit: true,
    })
    this.contactsModalState.idx = this.factoryContacts.findIndex(
      (el) => el.id === 'New'
    )
  }

  handleOnCancelModalContactsBtn = () => {
    if (this.contactsModalState.contactId === 'New') {
      this.factoryContacts.pop()
    } else {
      this.factoryContacts[this.contactsModalState.idx].isEdit = false
    }
    this.contactsModalClearState(true)
  }

  contactsModalClearState = () => {
    this.contactsModalState = {
      ...this.contactsModalState,
      isEdit: false,
      contactId: null,
      idx: null,
      isStateLoading: false,
      idxPopover: null,
      newOpen: false,
    }
  }

  checkExactEmailExist = (email, id) => {
    let temp = [...this.factoryContacts]
    let findExactEmail = temp.some(
      (el) =>
        el.email?.trim() == email?.trim() &&
        (id === 'SaveRow' ? el.is_main === 0 : el.id !== id)
    )
    if (findExactEmail) {
      this.openNotificationWithIcon('error', 'This email is already exist')
      return !!findExactEmail
    }
  }
}

export default FactoriesStore
