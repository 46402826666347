const form_field = [
    {
        task_type_id: '1',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            }
        ]
    },
    {
        task_type_id: '2',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'testType',
                required: true,
            },
            {
                name: 'productGroup',
                required: true,
            },
            {
                name: 'model',
                required: true,
            }
        ]
    },
    {
        task_type_id: '3',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'contract',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '4',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'contract',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '5',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'contract',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '6',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'contract',
                required: true,
            },
            {
                name: 'placeType',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '7',
        field: [
            {
                name: 'productGroup',
                required: true,
            },
            {
                name: 'placeType',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '8',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'productGroup',
                required: true,
            },
            {
                name: 'placeType',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '9',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'productGroup',
                required: true,
            },
            {
                name: 'videoType',
                required: true,
            },
            
        ]
    },
    {
        task_type_id: '10',
        field: [ 
            {
                name: 'placeType',
                required: true,
            },   
        ]
    },
    {
        task_type_id: '11',
        field: []
    },
    {
        task_type_id: '12',
        field: []
    },
    {
        task_type_id: '13',
        field: [
            {
                name: 'workType',
                required: true,
            },
        ]
    },
    {
        task_type_id: '14',
        field: [
            {
                name: 'province',
                required: true,
            },
            {
                name: 'city',
                required: true,
            },
            {
                name: 'factory',
                required: true,
            },
            {
                name: 'placeType',
                required: true,
            },
        ]
    },
    {
        task_type_id: '15',
        field: [
            {
                name: 'request',
                required: true,
            },
        ]
    },
    {
        task_type_id: '16',
        field: []
    },
    
];
  
  
  export default form_field;