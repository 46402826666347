import { observer } from 'mobx-react-lite'
import React from 'react'
import styles from './currentTime.module.scss'
import Moment from 'react-moment'
import "moment-timezone";
const CurrentTime = observer(() => {
  return (
	<div className={styles.time_wrapper}>
		<span className={styles.header}> Время в Китае: </span>
		<div className={styles.time}>
			<Moment format="HH:mm:ss" interval={1} tz='Asia/Shanghai' />
		</div>
	</div>
  )
})

export default CurrentTime
