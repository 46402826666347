import axios from 'axios'
import setAuthToken from '../utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, CLEAR_ERRORS } from './types'

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post('/api/users/login', userData)
    .then((res) => {
      // Save to localStorage
      const {
        token,
        accessToken,
        accessTokenExpire,
        userId,
        userName,
        user_dep_id,
        user_role,
        user_firstName,
        user_lastName,
        user_post_id,
      } = res.data.data

      localStorage.setItem('jwtToken', token)
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('accessTokenExpire', accessTokenExpire)
      localStorage.setItem('userId', userId)
      localStorage.setItem('userName', userName)
      localStorage.setItem('userDepId', user_dep_id)
      localStorage.setItem('userRole', user_role)
      localStorage.setItem('userFirstName', user_firstName)
      localStorage.setItem('userLastName', user_lastName)
      localStorage.setItem('userPostId', user_post_id)

      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      dispatch(setCurrentUser(decoded))
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
}

export const resetPassword = (data, setReset) => (dispatch) => {
  axios
    .post('/api/users/resetPassword', data)
    .then(() => {
      setReset(true);
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
}

export const recoveryPassword = (data, setRecovered) => (dispatch) => {
  axios
    .post('/api/users/recoveryPassword', data)
    .then(() => {
      setRecovered(true);
    })
    .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        })
      }
    )
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

export const clearErrors = () => ({
  type: CLEAR_ERRORS
});

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  }
}

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken')
  localStorage.removeItem('accessToken')
  // Remove auth header for future requests
  setAuthToken(false)
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
}
