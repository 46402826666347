import {makeAutoObservable} from "mobx";
import api from '../utils/axiosAuthInterceptor';
import {notification} from "antd";
import i18next from "i18next";
import download from "downloadjs";
const axios = api;
const moment = require('moment')



class ConsigneesStore {
  isMainLoading = false
  isEditting = false
  btnLoading = {
    id: null,
    type: null,
  }
  isExcelBtnLoading = false
  consigneesData = [];
  user = {}
  rowBeforeEdit = null;
  paginations = {
    page : 1,
    pageSize: 10,
    totalConsignees: null
  }
  filtersParams = {
    code: {
      filtered: [],
      options: [],
    }
  }
  
  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

 //Main model CRUD
 getConsignees = async () => {
  try{
    this.isMainLoading = true
    const params = {
      page: this.paginations.page,
      pageSize: this.paginations.pageSize,
      code: this.filtersParams.code.filtered,
    }
    let response = await axios.get(`/api/node/v1/consignees`, {params})
    this.consigneesData = response.data.data.rows
    this.paginations.totalConsignees = response.data.data.count
    this.user = response.data.data.user
    this.filtersParams.code.options = response.data.data.consigneesCodesOpt
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

createConsignee = async (body) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: 'New',
      type: 'save'
    }
    await axios.post(`/api/node/v1/consignees`, body)
    await this.getConsignees()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

updateConsignee = async (body) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: body.id,
      type: 'save'
    }
    await axios.put(`/api/node/v1/consignees`, body)
    await this.getConsignees()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

deleteConsignee = async (id) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: id,
      type: 'delete'
    }
    await axios.delete(`/api/node/v1/consignees/${id}`)
    await this.getConsignees()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

//Row operations

editRow = async (index) => {
  this.rowBeforeEdit = {...this.consigneesData[index]}
  const temp = [...this.consigneesData]
  temp[index].isEdit = true;
  this.consigneesData = temp
  this.isEditting = true
}

onFinishMainForm = async (values) => {
  if (this.rowBeforeEdit) {
    values.id = this.rowBeforeEdit.id
    await this.updateConsignee(values)
  } else {
    await this.createConsignee(values)
  }
}

onFinisFailedMainForm = () => {
  this.openNotificationWithIcon(
    'error',
    i18next.t('w_form_finish_failed')
  )
}

addRow = () => {
  let temp = [...this.consigneesData];
  this.isEditting = true
  temp.unshift({
    id: 'New',
    name: '',
    isEdit: true
  })
  this.consigneesData = temp
}

cancelEdit = (index) => {
  let temp = [...this.consigneesData]
  if(temp[index].id === 'New'){
    temp.splice(index, 1)
  } else {
    temp[index] = this.rowBeforeEdit
  }
  temp[index].isEdit = false
  this.consigneesData = temp
  this.clearMainState()
}

//Filtration

  acceptFilter = (type, data) => {
    this.filtersParams[type].filtered = data
    this.page = 1
    this.getConsignees()
  }

  onClearFilter = (type) => {
    this.filtersParams[type].filtered = []
    this.getConsignees()
  }

  clearFilters = (isGetConsigneesNeeded = true) => {
    for (let filter in this.filtersParams) {
      if (this.filtersParams[filter].filtered) {
        this.filtersParams[filter].filtered = []
      } else {
        this.filtersParams[filter].sortColumn = null
        this.filtersParams[filter].sortOrder = null
      }
    }
    if (isGetConsigneesNeeded) {
      this.getConsignees()
    }
  }


//Other

changePage = (e) => {
  this.paginations.page = e.current
  this.getConsignees()
}

changeSize = (current, size) => {
  this.paginations.pageSize = size
} 

openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    placement: 'bottomRight'
  });
};

clearMainState = () => {
  this.isEditting = false
  this.isMainLoading = false
  this.btnLoading = {
    id: null,
    type: null
  }
  this.isExcelBtnLoading = false
  this.rowBeforeEdit = null
}

getExcel = async () => {
  try{
    this.isExcelBtnLoading = true
    const params = {
      page: null,
      pageSize: null,
      code: this.filtersParams.code.filtered,
    }
    
    const response = await axios({
      url: '/api/node/v1/consignees/excel',
      responseType: 'blob',
      method: 'get',
      params
      
    })
    const blob = await response.data
    download(blob, `Consignees ${moment().format('DD.MM.YYYY')}.xlsx`)
  } catch (error) {
    console.error(error)
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.isExcelBtnLoading = false
  }
}

  copyRowData = (row) => {
    const rowData = [];

    if (row.code) {
      rowData.push(row.code);
    }

    if (row.full_company_name) {
      rowData.push(row.full_company_name);
    }

    if (row.address) {
      rowData.push(row.address);
    }

    if (row.phone) {
      rowData.push(row.phone);
    }

    if (rowData.length > 0) {
      const textToCopy = rowData.join(' ');

      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.openNotificationWithIcon('success', 'Row data copied to clipboard!');
    }
    else{
      this.openNotificationWithIcon('error', 'No data to copy');
    }
  }

}

export default ConsigneesStore
