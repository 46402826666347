import {makeAutoObservable} from "mobx";
import api from '../utils/axiosAuthInterceptor';
import {notification} from "antd";
import i18next from "i18next";
import download from 'downloadjs'
const axios = api
const moment = require('moment')

class CargoCompaniesStore {
  isMainLoading = false
  isEditting = false
  isExcelBtnLoading = false
  btnLoading = {
    id: null,
    type: null,
  }
  paginations = {
    page : 1,
    pageSize: 10,
    totalCargoCompanies: null
  }
  cargoCompaniesData = [];
  user = {}
  rowBeforeEdit = null;
  filtersParams = {
    code: {
      filtered: [],
      options: [],
    }
  }

  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  //Main model CRUD
  getCargoCompanies = async () => {
    try{
      this.isMainLoading = true
      const params = {
        page: this.paginations.page,
        pageSize: this.paginations.pageSize,
        code: this.filtersParams.code.filtered
      }
      let response = await axios.get(`/api/node/v1/cargoCompanies`, {params})
      this.cargoCompaniesData = response.data.data.rows
      this.paginations.totalCargoCompanies = response.data.data.count
      this.user = response.data.data.user
      this.filtersParams.code.options = response.data.data.cargoCompaniesCodesOpt
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isMainLoading = false
    }
  }

  createCargoCompany = async (body) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: 'New',
        type: 'save'
      }
      await axios.post(`/api/node/v1/cargoCompanies`, body)
      await this.getCargoCompanies()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  updateCargoCompany = async (body) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: body.id,
        type: 'save'
      }
      await axios.put(`/api/node/v1/cargoCompanies`, body)
      await this.getCargoCompanies()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  deleteCargoCompany = async (id) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: id,
        type: 'delete'
      }
      await axios.delete(`/api/node/v1/cargoCompanies/${id}`)
      await this.getCargoCompanies()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  //Row operations
  

  editRow = async (index) => {
    this.rowBeforeEdit = {...this.cargoCompaniesData[index]}
    this.cargoCompaniesData[index].isEdit = true;
    this.isEditting = true
  }

  onFinishMainForm = async (values) => {
    if (this.rowBeforeEdit) {
      values.id = this.rowBeforeEdit.id
      await this.updateCargoCompany(values)
    } else {
      await this.createCargoCompany(values)
    }
  }

  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }

  addRow = () => {
    let temp = [...this.cargoCompaniesData];
    this.isEditting = true
    temp.unshift({
      id: 'New',
      code: '',
      full_company_name: '',
      contact_person: '',
      email: '',
      phone: '',
      web: '',
      isEdit: true
    })
    this.cargoCompaniesData = temp
  }

  cancelEdit = (index) => {
    let temp = [...this.cargoCompaniesData]
    if(temp[index].id === 'New'){
      temp.splice(index, 1)
    } else {
      temp[index] = this.rowBeforeEdit
    }
    temp[index].isEdit = false
    this.cargoCompaniesData = temp
    this.clearMainState()
  }

  //Filtration

  acceptFilter = (type, data) => {
    this.filtersParams[type].filtered = data
    this.page = 1
    this.getCargoCompanies()
  }

  onClearFilter = (type) => {
    this.filtersParams[type].filtered = []
    this.getCargoCompanies()
  }

  clearFilters = (isGetCargoCompaniesNeeded = true) => {
    for (let filter in this.filtersParams) {
      if (this.filtersParams[filter].filtered) {
        this.filtersParams[filter].filtered = []
      } else {
        this.filtersParams[filter].sortColumn = null
        this.filtersParams[filter].sortOrder = null
      }
    }
    if (isGetCargoCompaniesNeeded) {
      this.getCargoCompanies()
    }
  }



  //Other
  changePage = (e) => {
    this.paginations.page = e.current
    this.getCargoCompanies()
  }

  changeSize = (current, size) => {
    this.paginations.pageSize = size
  } 

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  clearMainState = () => {
    this.isEditting = false
    this.isMainLoading = false
    this.btnLoading = {
      id: null,
      type: null
    }
    this.rowBeforeEdit = null
  }

  getCargoCompaniesExcel = async () => {
    try{
      this.isExcelBtnLoading = true
      const params = {
        page: null,
        pageSize: null,
        code:this.filtersParams.code.filtered
      }
  
      const response = await axios({
        url: '/api/node/v1/cargoCompanies/excel',
        responseType: 'blob',
        method: 'get',
        params
      })
      const blob = await response.data
      download(blob, `Cargo companies ${moment().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }

}

export default CargoCompaniesStore
