// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".byPersonBar_bar_wrapper__2N7Nb {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n.byPersonBar_bar_wrapper__2N7Nb .byPersonBar_bar__3avNT {\n  width: 100%;\n  height: 100%;\n  padding: 1%;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n.byPersonBar_bar_wrapper__2N7Nb .byPersonBar_header__1UEuL {\n  width: 100%;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  margin-bottom: 1%;\n  display: flex;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.85);\n}", ""]);
// Exports
exports.locals = {
	"bar_wrapper": "byPersonBar_bar_wrapper__2N7Nb",
	"bar": "byPersonBar_bar__3avNT",
	"header": "byPersonBar_header__1UEuL"
};
module.exports = exports;
