import { makeAutoObservable } from 'mobx';
import { notification } from 'antd';
import api from '../utils/axiosAuthInterceptor';
const download = require('downloadjs')
const axios = api;

class AccessoriesStore {

    datasetPackingWholeContract = undefined;
    packingWholeContractUpdated = new Date(1970);
    datasetFinalPackingList = undefined;

    //#1 SPECIFICATION
    datasetSpecification = [];
    specificationLoading = true;
    datasetSpecificationQty = undefined
    factoryModels = undefined
    specificationLoading = true;
    contract = undefined;
    specificationUpdated = undefined;
    copyContract = undefined;
    factoryContracts = [];
    buyers = undefined;

    //#2 CERTIFICATION

    datasetSamplesForCertification = [];
    specificationProducts = undefined;
    specificationProductsLoading = true;
    samplesForCertificationQty = 0;
    specificationFactoryModels = undefined;
    saveCertificationModalOpen = false;
    certificationUpdated = new Date(1970);

    //#3 TESTS
    datasetTestedSamples = undefined;
    testedSamplesQty = undefined;
    testsUpdated = new Date(1970);

    //#5 SPLIT
    datasetSplit = undefined;
    splitUpdated = new Date(1970);
    splitFile = undefined;

    //#6 DELIVERY DATE AND CONSIGNEES
    consigneeDate = undefined;
    consignees = [];
    consigneesUpdated = new Date(1970);

    //#7 ADOPTIONS
    datasetAdoption = undefined;
    adoptionsUpdated = new Date(1970);

    datasetSerialNumbers = undefined;
    serialNumbersUpdated = new Date(1970);

    exportModalOpened = false;
    exportOptions = [];
    exportLoading = false;

    isContractEdit = false;
    activeTab = 0;
    isEditList = [
        0, 0, 0, 0, 0, 0, 0, 0
    ]

    filters = {
        managers: [],
        engineers: [],
        vendors: [],
        contract: undefined
    }

    pagination = {
        current: 1,
        pageSize: 10,
    }

    deleteLoading = false;
    loading = true;
    total = undefined;
    vendors = [];
    vendorsGroups = [];
    managers = [];
    brandManagers = [];
    engineers = [];
    data = [];
    dataset = [];
    userId = undefined;
    userTypeId = undefined;


    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }

    changePage = (page) => {
        this.pagination = page;
        this.getData();
    }

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    getData = async () => {
        this.loading = true;
        let response = await fetch('/api/node/v1/accessories/categories', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();
        this.data = data.data;

        this.loading = false;
    }

    getUser = async () => {
      let resp = await fetch('/api/node/v1/users/get/current');
      let data = await resp.json();
      this.userId = data.userId
      this.userTypeId = data.userTypeId
    }

    onLoadTreeData = async (e) => {
        let key = e.id
        let categoryIntcodeId = e['category_intcodes.id'];
        let response = await fetch(`/api/node/v1/accessories/${categoryIntcodeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let data = await response.json();

        this.data = await this.updateTreeData(this.data, key, data.data.children);

      this.data.map(el => {
        if(!el.hasOwnProperty('children')){
          el.children = []
          el.children.push({data: [{category_intcode_id: el["category_intcodes.id"]}], isLeaf: true})
        }
        return el;
      })
        return;
    }

    updateTreeData = (list, key, children) => {
        return list.map((node) => {
            if (node.id === key) {
                return { ...node, children }
            }
            return node;
        });
    }

  addRow = async (values) => {
    return await axios.post(`/api/node/v1/accessories/addRow`, values)
  }

  deleteRow = async (_, row) => {
    let temp = this.data.find(el => {
      if(el['category_intcodes.id'] === row.category_intcode_id){
        return el
      }
      return null
    })

    let data = {
      rowId: row.id
    }

    await axios.post(`/api/node/v1/accessories/deleteRow`, data)
      .then(response => {
        if (response.data.success) {
          this.onLoadTreeData(temp)
          return
        }
      })
      .catch(error => { console.log(error) });
  }

  updateRow = async (values, row) => {

    let temp = this.data.find(el => {
      if(el['category_intcodes.id'] === values.category_intcode_id){
        return el
      }
      return null
    })

    await axios.post(`/api/node/v1/accessories/updateRow`, values)
      .then(response => {
        if (response.data.success) {
          this.onLoadTreeData(temp)
          return null
        }
      })
      .catch(error => { console.log(error) });

  }

  exportAccessories = async () => {
    this.downloadFileLoad = true;
    const response = await fetch('/api/node/v1/accessories/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    const filename = `Accessories list ${new Date().toJSON().slice(0,10).replace(/-/g,'.').split('.').reverse().join('.')}.xlsx`;
    const blob = await response.blob();
    download(blob, filename);

    this.downloadFileLoad = false;
  }
}

export default AccessoriesStore;
