import { makeAutoObservable } from 'mobx';
import React from 'react';
import { Button, Select, notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
const { Option } = Select;
const axios = api;


class HomepageStore {
  isViewModalOpen = false;
  fileName = undefined;
  fileId = undefined;
  isLoaded = false;
  addVidjetCheckboxOpen = false
  clockCheckboxOpen = false

  createInstruction = {
    module: undefined,
    language: undefined,
    file: undefined,
  };

  isModalViewInstructionList = false;
  isModalCreateInstructionOpen = false;
  isModalOpenCreateAnnouncement = false;
  isModalOpenViewAnnouncement = false;
  disabledBtnSaveNewInstruction = true;

  announcementTypes = ['Newsletter', 'Posts_new'];
  announcements = [];
  instructions = [];
  instructionFiles = [];
  departments = [];
  statistics = [];
  title = '';
  department_id = -1;
  isAdmin = false;

  createAnnouncement = {
    type: 'Posts_new',
    department: [],
  };

  viewAnnouncement = {
    created_at: undefined,
    departmentText: undefined,
    title: undefined,
    description: undefined,
    type: undefined,
    department: [],
    files: [],
    slider: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setClockCheckbox= () =>{
    this.clockCheckboxOpen = !this.clockCheckboxOpen
  }

  setAddVidjetCheckbox = () =>{
    this.addVidjetCheckboxOpen = !this.addVidjetCheckboxOpen
  }

  closeAllCheckbox = () =>{
    this.clockCheckboxOpen = false
    this.addVidjetCheckboxOpen = false
  }

  openPreviewModal = async (id) => {
    this.isViewModalOpen = true;
    let response = await fetch(`/api/node/v1/instructions/file/${id}`);
    let data = await response.json();
    this.fileName = data.data.files.file_link;
    this.fileId = data.data.files.id;
  }

  closePreviewModal = _ => {
    this.isViewModalOpen = false;
    this.fileName = undefined;
  }

  openModalViewInstructionList = (id) => {
    if (id <= 0) return 0;
    fetch(`/api/node/v1/instructions/files/${id}`)
      .then(response => response.json())
      .then(response => {
        this.instructionFiles = response.data.files;
      })
      .catch(err => console.error(err))
    this.isModalViewInstructionList = true;
  };

  closeModalViewInstructionList = () => {
    this.isModalViewInstructionList = false;
  };

  openModalCreateInstruction = () => {
    let data = this.createInstruction;
    data.module = undefined;
    data.language = undefined;
    data.file = undefined;
    this.isModalCreateInstructionOpen = true;
    this.disabledBtnSaveNewInstruction = true;
    this.createInstruction = data;
  };

  closeModalCreateInstruction = () => {
    const data = this.createInstruction;
    data.module = undefined;
    data.language = undefined;
    data.file = undefined;
    this.isModalCreateInstructionOpen = false;
    this.disabledBtnSaveNewInstruction = true;
    this.createInstruction = data;
  };

  openModalViewAnnouncement = (announcementId) => {
    for (const item of this.announcements) {
      if (+item.id === +announcementId) {
        const viewAnnouncement = this.viewAnnouncement;
        viewAnnouncement.title = `${item.theme}`;
        viewAnnouncement.departmentText = item.department.length <= 0 ? `Company news` : `Department: ${item.department.join(', ')}`;
        viewAnnouncement.description = item.description;
        viewAnnouncement.type = item.type;
        viewAnnouncement.department = item.department;
        viewAnnouncement.files = item.files;
        viewAnnouncement.slider = item.slider;
        viewAnnouncement.creater = item.userName + ' ' + item.userSurname;
        viewAnnouncement.created_at = item.created_at;

        this.isModalOpenViewAnnouncement = true;
        this.viewAnnouncement = viewAnnouncement;
        break;
      }
    }
  };

  closeModalViewAnnouncement = () => {
    this.isModalOpenViewAnnouncement = false;
  }

  checkDisabledBtnSaveNewInstruction = _ => {
    let { module, language, file } = this.createInstruction;
    const disabled = module !== undefined && module !== '' &&
      language !== undefined && language !== '' &&
      file !== undefined && file !== '';
    this.disabledBtnSaveNewInstruction = !disabled;
  }

  changeCreateInstructionModule = (e) => {
    let data = this.createInstruction;
    data.module = e;
    this.editTask = data;
    this.checkDisabledBtnSaveNewInstruction();
  };

  changeCreateInstructionLanguage = (e) => {
    let data = this.createInstruction;
    data.language = e;
    this.editTask = data;
    this.checkDisabledBtnSaveNewInstruction()
  };

  changeCreateInstructionFile = (e) => {
    let data = this.createInstruction;
    data.file = e.fileList.length > 0 ? e.fileList[0] : undefined;
    this.editTask = data;
    this.checkDisabledBtnSaveNewInstruction();
  };

  deleteFile = async (fileId, type) => {
    fetch(`/api/node/v1/file/delete/${type}/${fileId}`)
      .then(response => response.json())
      .then(async response => {
        console.log(response);
      })
      .catch(err => console.error(err))
  };

  downloadFile = (fileId, filename, type) => {
    axios.get(`/api/node/v1/file/get/${type}/${fileId}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click()
      })
  };

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  saveNewInstruction = (values) => {
    this.disabledBtnSaveNewInstruction = true;
    const module = +values.selectModule;
    const language = values.selectLang;
    const file = values.upload.file;
    const check = module !== undefined && module !== '' &&
      language !== undefined && language !== '' &&
      values.upload.fileList.length > 0;

    if (check) {
      let data = new FormData();
      data.append('file', file);
      data.append('language', language);
      data.append('module', module);
      axios.post('/api/node/v1/file/upload/instruction/' + module, data)
        .then((response) => {
          if (+response.status === 200) {
            this.openNotificationWithIcon('success', 'Instruction saved successfully!')
            this.closeModalCreateInstruction();
            this.openModalViewInstructionList(module);
          } else {
            this.openNotificationWithIcon('error', 'Error!')
          }
        })
    }
  }

  renderAnnouncementFiles = ({ id, original_name }) => {
    return (
      <div key={"AnnouncementFilesActive_" + id}>
        <Button type="link" onClick={() => this.downloadFile(id, original_name, 'announcement')}
          style={{ marginLeft: '10px', color: '#20a8d8', cursor: 'pointer' }}>
          <i style={{ marginLeft: '10px' }} className="cui-cloud-download" />&nbsp;{original_name}
        </Button>
      </div>
    )
  };

  renderAnnouncementFilesForSlider = ({ id, file_link }) =>
    <div key={"AnnouncementFileInSlider_" + id}><img style={{ width: '300px', height: '300px' }} src={file_link} alt={"AnnouncementFileInSlider_" + id} className="slider-img" /></div>;

  renderModuleForSelect = ({ id, moduleName }) =>
    <Option key={"optionModule" + id} value={id}>{moduleName}</Option>

  getData = _ => {
    axios.get('/api/node/v1/announcements/home',
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        this.setStateResponseData(response.data.data);
      })

    axios.get('/api/node/v1/instructions',
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        this.instructions = response.data.data.instructions;
      })

  };

  setStateResponseData = (data) => {
    this.isLoaded = true;
    this.announcements = data.announcements;
    this.title = data.title;
    this.departments = data.departments;
    this.statistics = data.statistics;
    this.isAdmin = +data.user.userTypeId === 1 ? true : false
  };

  getTasks = async() => {
    try{
      let response = await axios.get(`/api/node/v1/tasks/get/myselfTasks`)
      return (await response)
    }
    catch(e){
      console.log(e)
    }
    
  }

  getTime = async() => {
    try{
    let response = await axios.get(`/api/node/v1/tasks/get/getTime`)
    return (await response)
    }
    catch(e){
      console.log(e)
    }
  }
}

export default HomepageStore;
