// Функция проверки доступа

const access = (arrayOfAccess = [], tab_id, element) => {
  if (
    arrayOfAccess.find((el) => +el.tab_id === +tab_id && el.target === element)
  )
    return true
  return false
}
export default access
