import { refreshToken, getRefreshTokenMutex, setRefreshTokenMutex, refreshTokenPromise } from './refreshToken';
import store from "../store";
import {logoutUser} from "../actions/authActions";
const originalFetch = fetch;

const api = {
  fetch: async (url, options) => {
    if (url.startsWith('/api/')) {
      if (url === '/api/node/v1/users/get/current') {
        if (!options) options = {};
        if (!options.headers) options.headers = {};
        options.headers['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      const response = await originalFetch(url, options);
      const contentType = response.headers.get('Content-Type');
      let res = {};
      if (contentType && contentType.includes('application/json')){
        res = await response.clone().json();
      }
      if (response?.message === 'missingTokenError') store.dispatch(logoutUser());
      if (res?.success === false &&
        res?.message === 'TokenExpiredError') {
        if (!getRefreshTokenMutex()) {
          setRefreshTokenMutex(true);
          try {
            await refreshToken();
            return originalFetch(url, options);
          } catch (refreshError) {
            store.dispatch(logoutUser())
            throw refreshError;
          } finally {
            setRefreshTokenMutex(false);
          }
        } else {
          await refreshTokenPromise;
          return api.fetch(url, options);
        }
      }

      return response;
    } else {
      return originalFetch(url, options);
    }
  },
};

window.fetch = api.fetch;
