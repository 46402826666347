import { makeAutoObservable } from 'mobx'
import download from 'downloadjs'
import { notification } from 'antd'
import api from '../utils/axiosAuthInterceptor'

import dayjs from 'dayjs'
const axios = api
const moment = require('moment')

class QcFeedBackStore {
  //стейты общие
  feedbackList = []
  page = 1
  pageSize = 10
  categories = []
  engineerId = null
  headEngineerId = null
  typeOfEngineer = null
  userOnline = {}
  lengthCounter
  typeOfInterface = null
  filesList = []
  engineerUsersArray = []
  disableButton = false
  factories = []
  toEditQcDescriptions = false
  newInfoFromQC = {}
  engineerData = {}
  defaultDeadlineCount = null
  engineerDataSent = false
  toEditEngineersComment = false
  isAccess = null
  accessByDep = [3, 2, 7, 5]
  isManager = null
  isGeneralAdmin = null
  isEngineer = null
  isHeadOfEngineer = null
  isHeadOfChina = null
  toEditReward = false
  currentProposal = {}
  statuses = { CREATE: 1, WITHDRAWN: 5 }
  amountStatus = { CREATE: 1, UPDATE: 2, ACCEPTED: 3 }
  deadlineStatus = { DEF_DD_LOSED: 1, EN_DD_LOSED: 2, PR_WAS_VERIFIED: 3 }
  proposalLoaded = false
  uploadingFiles = false
  addedFiles = []
  fileRemoved = false
  feedbackListCopy = []
  feedbackListCopyWithoutFilter = {}
  qc = []
  engineers = []
  firstDeadlineEdit = false
  //фильтры
  popovers = {
    ids: false,
    factories: false,
    categories: false,
    relatedOptions: false,
    dates: false,
    qc: false,
    engineers: false,
    result: false,
    verify: false,
    reward: false,
    status: false,
  }
  filterSelectedById = false
  filterSelectedBySupplier = false
  filterSelectedByCategory = false
  filterSelectedByRelatedOptions = false
  filterSelectedByDates = false
  filterSelectedByQc = false
  filterSelectedByEngineers = false
  filterSelectedByResult = false
  filterSelectedByVerify = false
  filterSelectedByReward = false
  filterSelectedByStatus = false
  selectedSuppliers = []
  selectedProductCategories = []
  selectedQc = []
  selectedEngineers = []
  selectedReuslt = []
  selectedVarify = []
  selectedReward = []
  selectedStatus = []
  selectedDates = []
  selectedIds = ''
  selectedRelated = []
  loading = false
  loadingMain = false
  // Стейты для сортировки таблицы
  currentSort = undefined
  currentSortDirection = undefined

  //Стейты для статистки
  statData = []
  statDataCopy = []
  chartList = []
  qcTableStatistics = []
  engineerTableStatistics = []

  //Стейты для статистики по инженерам
  engineerStatData = []
  engineerStatDataCopy = []

  downloadFileProgres = []

  // Стейты для TextUserInterface

  translatedText = { desc: '', engineer: '', adv: '' }

  constructor() {
    makeAutoObservable(this)
  }

  correctTime = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD HH:mm')
  }

  timeWithoutHours = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD')
  }

  openNotificationWithIcon = (type, message, showUp) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }
  notificationWithoutPlacement = (type, message) => {
    notification[type]({
      message: message,
    })
  }
  //функции фильтрации главной таблицы
  filterProposalById = (value, ids) => {
    if (value.length > 0) {
      this.filterSelectedById = true
      this.closeFilter(ids)
    } else {
      this.selectedIds = ''
      this.filterSelectedById = false
    }
    this.popovers.ids = false
    this.getFeedback()
  }
  selectSupplier = (value) => {
    this.selectedSuppliers = value
  }
  selectProductCategory = (value) => {
    this.selectedProductCategories = value
  }
  updateSort = (key, val) => {
    this[key] = val
  }
  popoversOpen = (columnFilter) => {
    const columnPopovers = {
      ids: false,
      factories: false,
      categories: false,
      relatedOptions: false,
      dates: false,
      qc: false,
      engineers: false,
      result: false,
      verify: false,
      reward: false,
      status: false,
    }
    columnPopovers[columnFilter] = true
    this.popovers = columnPopovers
  }
  closeFilter = (columnFilter) => {
    this.popovers[columnFilter] = false
  }
  filterBySupplier = () => {
    if (this.selectedSuppliers.length > 0) {
      this.filterSelectedBySupplier = true
    } else {
      this.filterSelectedBySupplier = false
    }
  }

  filterByProductCategory = () => {
    if (this.selectedProductCategories.length > 0) {
      this.filterSelectedByCategory = true
    } else {
      this.filterSelectedByCategory = false
    }
  }
  selectRelate = (value) => {
    this.selectedRelated = value
  }

  filterByRelatedOption = () => {
    const filters = {
      1: 'product_proposal',
      2: 'process_proposal',
      3: 'method_proposal',
      4: 'other_proposal',
    }

    const selectedFilters = this.selectedRelated
      .map((el) => filters[el])
      .filter(Boolean)

    if (selectedFilters.length > 0) {
      this.filterSelectedByRelatedOptions = true
    } else {
      this.filterSelectedByRelatedOptions = false
    }
  }

  filterByDates = (dates) => {
    this.selectedDates = dates

    if (!dates.includes('')) {
      this.filterSelectedByDates = true
    } else {
      this.filterSelectedByDates = false
      this.selectedDates = []

      this.feedbackList = this.feedbackListCopy
    }
  }

  selectQc = (value) => {
    this.selectedQc = value
  }

  filterByQc = () => {
    if (this.selectedQc.length > 0) {
      this.filterSelectedByQc = true
    } else {
      this.filterSelectedByQc = false
    }
  }

  selectEngineers = (value) => {
    this.selectedEngineers = value
  }
  filterByEngineers = () => {
    if (this.selectedEngineers.length > 0) {
      this.filterSelectedByEngineers = true
    } else {
      this.filterSelectedByEngineers = false
    }
  }

  filterByResult = (value) => {
    if (value !== undefined) {
      this.selectedReuslt = value.toString()
    } else {
      this.selectedReuslt = value
    }

    this.filterSelectedByResult = true
    // }
    if (value === undefined) {
      this.filterSelectedByResult = false
    }
  }

  filterByVerify = (value) => {
    this.selectedVarify = value

    this.filterSelectedByVerify = true

    if (value === undefined) {
      this.filterSelectedByVerify = false
    }
  }

  filterByReward = (value) => {
    this.selectedReward = value
    if (value) {
      this.filterSelectedByReward = true
    } else {
      this.filterSelectedByReward = false
    }
  }
  filterByStatus = (value) => {
    this.selectedStatus = value
    if (value) {
      if (this.feedbackList.length === this.feedbackListCopy.length) {
        this.feedbackList = this.feedbackListCopy.filter(
          (proposal) => proposal.status_id === +value
        )
      } else {
        this.feedbackListCopyWithoutFilter.status = this.feedbackList

        this.feedbackList = this.feedbackList.filter(
          (proposal) => proposal.status_id === +value
        )
      }

      this.filterSelectedByStatus = true
    } else {
      if (this.feedbackListCopyWithoutFilter.length) {
        this.feedbackList = this.feedbackListCopyWithoutFilter
      } else {
        this.feedbackList = this.feedbackListCopy
      }
      this.filterSelectedByStatus = false
    }
  }
  changePage = (e) => {
    this.page = e.current
    this.pageSize = e.pageSize
  }
  getFeedback = async (isInitial = true) => {
    this.clearTranslate()

    const { data } = await axios.get('/api/node/v1/qcFeedback/getQcFeedback', {
      params: {
        id: this.selectedIds,
        supplier: this.selectedSuppliers,
        product: this.selectedProductCategories,
        relate: this.selectedRelated,
        date: this.selectedDates,
        qc: this.selectedQc,
        engineers: this.selectedEngineers,
        result: this.selectedReuslt,
        verify: this.selectedVarify,
        reward: this.selectedReward,
        status: this.selectedStatus,
      },
    })
    this.toEditEngineersComment = false
    this.toEditQcDescriptions = false
    this.loading = true
    if (data.data.engineerData) {
      this.loading = false
      this.isAccess = data.data.isAccess
      this.feedbackList = data.data.engineerData.sort((a, b) => b.id - a.id)
      this.engineerId = data?.data.engineerPostId
      this.headEngineerId = data?.data.engineerHeadPostId
      this.typeOfEngineer = data.data.typeOfEngineer
      this.userOnline = data.data.user
      this.typeOfInterface = true
      this.isManager = data.data.isManager
      this.isGeneralAdmin = data.data.isGeneralAdmin
      this.factories = data.data.factories
      this.categories = data.data.categories
      this.qc = data.data.qc
      this.engineers = data.data.engineers
      this.isEngineer = data.data.isEngineer
      this.isHeadOfEngineer = data.data.isHeadOfEngineer
      this.defaultDeadlineCount = data.data.defaultDeadlineCount?.week_count
      this.isHeadOfChina = data.data.isHeadOfChina
      if (isInitial) {
        this.lengthCounter = data.data.engineerData.length
      }
    }

    if (data.data.headEngineerData) {
      this.loading = false

      this.isAccess = data.data.isAccess
      this.feedbackList = data.data.headEngineerData.sort((a, b) => b.id - a.id)
      this.engineerId = data.data.engineerPostId
      this.headEngineerId = data.data.engineerHeadPostId
      this.typeOfEngineer = data.data.typeOfEngineer
      this.userOnline = data.data.user
      this.typeOfInterface = true
      this.isManager = data.data.isManager
      this.isGeneralAdmin = data.data.isGeneralAdmin
      this.factories = data.data.factories
      this.categories = data.data.categories
      this.qc = data.data.qc
      this.engineers = data.data.engineers
      this.isEngineer = data.data.isEngineer
      this.isHeadOfEngineer = data.data.isHeadOfEngineer
      this.defaultDeadlineCount = data.data.defaultDeadlineCount?.week_count
      this.isHeadOfChina = data.data.isHeadOfChina
      if (isInitial) {
        this.lengthCounter = data.data.headEngineerData.length
      }
    }
    if (data.data.userData) {
      this.loading = false
      this.isAccess = data.data.isAccess
      this.feedbackList = data.data.userData.sort((a, b) => b.id - a.id)
      this.engineerId = 1
      this.headEngineerId = 1
      this.userOnline = data.data.user
      this.isManager = data.data.isManager
      this.isGeneralAdmin = data.data.isGeneralAdmin
      this.factories = data.data.factories
      this.categories = data.data.categories
      this.qc = data.data.qc
      this.engineers = data.data.engineers
      this.isEngineer = data.data.isEngineer
      this.isHeadOfEngineer = data.data.isHeadOfEngineer
      this.isHeadOfChina = data.data.isHeadOfChina
      if (this.isGeneralAdmin) {
        this.typeOfInterface = true
      } else {
        this.typeOfInterface = false
      }
    }
    if (data.data.managerData) {
      this.loading = false
      this.isAccess = data.data.isAccess
      this.feedbackList = data.data.managerData.sort((a, b) => b.id - a.id)
      this.engineerId = 1
      this.headEngineerId = 1
      this.userOnline = data.data.user
      this.typeOfInterface = false
      this.isManager = data.data.isManager
      this.isGeneralAdmin = data.data.isGeneralAdmin
      this.factories = data.data.factories
      this.categories = data.data.categories
      this.qc = data.data.qc
      this.engineers = data.data.engineers
      this.isEngineer = data.data.isEngineer
      this.isHeadOfEngineer = data.data.isHeadOfEngineer
      this.isHeadOfChina = data.data.isHeadOfChina
    }
    this.feedbackListCopy = this.feedbackList
  }
  getProposalData = async (id) => {
    const data = await axios.get(`/api/node/v1/qcFeedback/proposal/${id}`)
    if (data) {
      this.currentProposal = data.data.data.proposalData
      this.userOnline = data.data.data.user
      this.typeOfInterface = data.data.data.typeOfInterface
      this.engineerId = data.data.data.engineerId
      this.headEngineerId = data.data.data.headEngineerId
      this.typeOfEngineer = data.data.data.typeOfEngineer
      this.isAccess = data.data.data.isAccess
      this.isManager = data.data.data.isManager
      this.isGeneralAdmin = data.data.data.isGeneralAdmin
      this.isEngineer = data.data.data.isEngineer
      this.isHeadOfEngineer = data.data.data.isHeadOfEngineer
      this.isHeadOfChina = data.data.data.isHeadOfChina
      this.proposalLoaded = true
      this.toEditEngineersComment = false
      this.loading = false
      if (!this.addedFiles.length && this.addedFiles.length !== undefined) {
        this.uploadingFiles = false
      }
    }
  }
  getDataForCreateFeedbackPage = async () => {
    const data = await axios.get(
      '/api/node/v1/qcFeedback/getDataForCreateFeedbackPage'
    )
    if (data) {
      this.categories = data?.data.data.categories
      this.factories = data.data.data.factories
    }
  }

  saveFeedback = async (feedbackBody, feedbackFiles) => {
    this.loadingMain = true

    const { data } = await axios.post(
      '/api/node/v1/qcFeedback/saveNewFeedback',
      feedbackBody
    )
    if (data) {
      this.loadingMain = true
      const { data: isSuccesed } = await axios.post(
        `/api/node/v1/qcFeedback/upload/qcFeedbackFiles/${data.data.data}`,
        feedbackFiles
      )
      if (isSuccesed) {
        this.loadingMain = false

        this.getFeedback()
        return true
      }
    }
  }

  downloadFile = async (file) => {
    const data = await axios.get(
      `/api/node/v1/qcFeedback/qcFeedbackDownloadFile/${file.id}`,
      {
        responseType: 'blob',
      }
    )

    download(data.data, file.original_name)
  }
  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))

      const data = await axios.get(`${link}${file.id}`, {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          // Находим объект файла в массиве и обновляем его прогресс
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      // Устанавливаем статус файла в "exception"
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }

  exportDataToExcel = async () => {
    const data = await axios({
      url: '/api/node/v1/qcFeedback/exportDataToExcel',
      responseType: 'blob',
      method: 'post',
      data: this.statData,
    })
    if (data) {
      const filename = `Rewards report ${moment().format('DD.MM.YYYY')}.xlsx`
      download(data.data, filename)
    }
  }

  saveNewInfoFromQC = async (values, id) => {
    try {
      const data = await axios.patch(
        `/api/node/v1/qcFeedback/saveNewInfoFromQC/${id}`,
        values
      )
      if (data) {
        this.uploadingFiles = true
        const uploadFiles = await axios.post(
          `/api/node/v1/qcFeedback/upload/qcFeedbackFiles/${id}`,
          this.addedFiles
        )
        if (uploadFiles.data) {
          this.addedFiles = []
          this.getProposalData(id)
        }
      }
    } catch (error) {
      console.error('Error while saving new info:', error)
    }
  }

  removeProposalFile = async (id, fileUrl, fileId) => {
    const data = await axios.delete(
      `/api/node/v1/qcFeedback/removeProposalFile/${id}`,
      { data: { url: fileUrl, fileId } }
    )
    if (data) {
      this.fileRemoved = true
      this.getProposalData(id)
    }
  }

  saveNewInfoFromEngineer = async (values, id) => {
    const data = await axios.patch(
      `/api/node/v1/qcFeedback/saveNewInfoFromEngineer/${id}`,
      values
    )
    if (data) {
      this.getProposalData(id)
    }
    this.engineerDataSent = false
  }

  toEditEngineerData = async (values, emailQC, idCreatorQC, id) => {
    values.emailQC = emailQC
    values.idQC = idCreatorQC

    const data = await axios.patch(
      `/api/node/v1/qcFeedback/toEditEngineerData/${id}`,
      values
    )
    if (data) {
      this.engineerDataSent = true
      this.toEditEngineersComment = false
      this.getProposalData(id)
    }
  }
  createAmount = async (value, id, status) => {
    const data = await axios.patch(
      `/api/node/v1/qcFeedback/createAmount/${id}`,
      { amount: value, status }
    )
    if (data) {
      this.getProposalData(id)
    }
  }
  changeStatusOnRecall = async (id, feedbackBody) => {
    const data = await axios.patch(
      `/api/node/v1/qcFeedback/toRecallStatus/${id}`,
      { params: feedbackBody }
    )
    if (data) {
      this.getProposalData(id)
    }
  }

  getInitialStat = async () => {
    const data = await axios.get(`/api/node/v1/qcFeedback/getStatistics`)
    if (data) {
      this.statData = data.data.data?.statData
    }
  }

  getNewStat = async (dates) => {
    const data = await axios.get(`/api/node/v1/qcFeedback/getNewStatistics`, {
      params: {
        dates,
      },
    })
    if (data) {
      this.statData = data.data.data?.statData
    }
  }

  updateFirstDeadline = async (newDeadline) => {
    await axios.patch(`/api/node/v1/qcFeedback/FirstDeadline/`, { newDeadline })
  }
  acceptReward = async (id, value, status, userQC) => {
    this.loading = true
    const data = await axios.patch(
      `/api/node/v1/qcFeedback/acceptReward/${id}`,
      { amount: value, status, userQC }
    )
    if (data) {
      this.getProposalData(id)
    }
  }
  toTranslate = async (language, text, type) => {
    const response = await axios.post(`/api/node/v1/qcFeedback/toTranslate/`, {
      language,
      text,
    })
    if (response) {
      if (type === 'desc') {
        this.translatedText.desc = response.data.data.translations[0]?.text
      }
      if (type === 'adv') {
        this.translatedText.adv = response.data.data.translations[0]?.text
      }
      if (type === 'engineer') {
        this.translatedText.engineer = response.data.data.translations[0]?.text
      }
    }
  }
  clearTranslate = () => {
    this.translatedText = { desc: '', engineer: '', adv: '' }
  }
  getEngineerStatistic = async (dates) => {
    const response = await axios.get(
      '/api/node/v1/qcFeedback/engineerStatistic',
      {
        params: {
          dates,
        },
      }
    )
    if (response.data.success) {
      this.engineerStatData = response.data.data
    }
  }

  exportEngineerStatDataToExcel = async () => {
    const data = await axios({
      url: '/api/node/v1/qcFeedback/exportEngineerStatDataToExcel',
      responseType: 'blob',
      method: 'post',
      data: this.engineerStatData,
    })
    if (data) {
      const filename = `Engineer statistics ${moment().format(
        'DD.MM.YYYY'
      )}.xlsx`
      download(data.data, filename)
    }
  }
  confirmPayment = async (id, userQC, userQCid, amount, engineer) => {
    try {
      const data = await axios.patch(
        `/api/node/v1/qcFeedback/paymentComplete/${id}`,
        { userQC, userQCid, amount, engineer }
      )
      if (data) {
        this.getProposalData(id)
      }
    } catch (error) {
      console.log(error, 'err')
    }
  }
  selectQcStatistics = (e) => {
    this.qcTableStatistics = e
  }
  selectEngineerStatistics = (e) => {
    this.engineerTableStatistics = e
  }
}

export default QcFeedBackStore
