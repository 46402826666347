// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".currentTime_time_wrapper__2Dyu2 {\n  width: 100%;\n  height: 60%;\n  border: 5px solid #f2f3f3;\n  background-color: white;\n  display: block;\n  margin: 5px;\n}\n.currentTime_time_wrapper__2Dyu2 .currentTime_header__3C8Ot {\n  width: 100%;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  color: rgba(0, 0, 0, 0.85);\n}\n.currentTime_time_wrapper__2Dyu2 .currentTime_time__3XnyN {\n  flex-direction: column;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 100px;\n  color: #000000;\n}", ""]);
// Exports
exports.locals = {
	"time_wrapper": "currentTime_time_wrapper__2Dyu2",
	"header": "currentTime_header__3C8Ot",
	"time": "currentTime_time__3XnyN"
};
module.exports = exports;
