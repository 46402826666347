import store from "../store";
import {logoutUser} from "../actions/authActions";

let refreshTokenPromise = null;
let refreshTokenMutex = false;

export const refreshToken = async () => {
  if (!refreshTokenPromise) {
    refreshTokenPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('/api/users/refreshToken', {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Refresh failed ' + response.status);
        }
        const res = await response.json();
        const {
          token,
          accessToken,
          accessTokenExpire,
          userId,
          userName,
          user_dep_id,
          user_role,
          user_firstName,
          user_lastName,
          user_post_id,
        } = res.data;

        localStorage.setItem('jwtToken', token)
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('accessTokenExpire', accessTokenExpire)
        localStorage.setItem('userId', userId)
        localStorage.setItem('userName', userName)
        localStorage.setItem('userDepId', user_dep_id)
        localStorage.setItem('userRole', user_role)
        localStorage.setItem('userFirstName', user_firstName)
        localStorage.setItem('userLastName', user_lastName)
        localStorage.setItem('userPostId', user_post_id)

        resolve(token);
      } catch (error) {
        store.dispatch(logoutUser())
        reject(error);
      } finally {
        refreshTokenPromise = null;
      }
    });
  }
  return refreshTokenPromise;
};

export const getRefreshTokenMutex = () => refreshTokenMutex;
export const setRefreshTokenMutex = (value) => {
  refreshTokenMutex = value;
};
export { refreshTokenPromise };
