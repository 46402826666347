// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".issuesStat_main__kEvY_ {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n  padding: 15px;\n  background-color: white;\n}\n.issuesStat_main__kEvY_ .issuesStat_top_block__2iGpZ {\n  width: 100%;\n  display: flex;\n  height: 40%;\n}\n.issuesStat_main__kEvY_ .issuesStat_top_block__2iGpZ .issuesStat_left_block__7Kssf {\n  width: 60%;\n  height: 100%;\n  border: 5px solid #f2f3f3;\n  background-color: white;\n}\n.issuesStat_main__kEvY_ .issuesStat_top_block__2iGpZ .issuesStat_right_block__2micn {\n  width: 40%;\n  height: 100%;\n}\n.issuesStat_main__kEvY_ .issuesStat_top_block__2iGpZ .issuesStat_right_block__2micn .issuesStat_time_wrapper__YIYeR {\n  width: 100%;\n  height: 60%;\n  border: 5px solid #f2f3f3;\n  background-color: white;\n}\n.issuesStat_main__kEvY_ .issuesStat_top_block__2iGpZ .issuesStat_right_block__2micn .issuesStat_issues_wrapper__3-pHR {\n  width: 100%;\n  height: 40%;\n}\n.issuesStat_main__kEvY_ .issuesStat_middle_block__2fBSK {\n  width: 100%;\n  display: flex;\n  height: 28%;\n  border: 5px solid #f2f3f3;\n  background-color: white;\n  margin-top: 1vh;\n}\n.issuesStat_main__kEvY_ .issuesStat_bottom_block__36yYB {\n  width: 100%;\n  display: flex;\n  height: 30%;\n  background-color: white;\n  margin-top: 1vh;\n}", ""]);
// Exports
exports.locals = {
	"main": "issuesStat_main__kEvY_",
	"top_block": "issuesStat_top_block__2iGpZ",
	"left_block": "issuesStat_left_block__7Kssf",
	"right_block": "issuesStat_right_block__2micn",
	"time_wrapper": "issuesStat_time_wrapper__YIYeR",
	"issues_wrapper": "issuesStat_issues_wrapper__3-pHR",
	"middle_block": "issuesStat_middle_block__2fBSK",
	"bottom_block": "issuesStat_bottom_block__36yYB"
};
module.exports = exports;
