import { makeAutoObservable, observable } from 'mobx'
import { notification } from 'antd'
import axios from 'axios'
import i18next from 'i18next';
import download from 'downloadjs';

class InstructionsStore {
  constructor() {makeAutoObservable(this, {newModule: observable}, { deep: true })}
  
  state = null
  instructions = []
  user = null
  uploadModal = {
    open: false,
    modulesOpt:[],
    newModule: '',
    selectOpen: false,
    loading: false,
    type: 'link'
  }
  


  getInstructions = async () => {
    try{
      this.state = 'mainLoad'
      let response = await axios.get('/api/node/v1/instructions')
      this.instructions = response.data.data.rows
      this.uploadModal.modulesOpt = response.data.data.allModules.map(
        obj => ({
          value: obj.id,
          label: i18next.t(obj.lang ? obj.lang.key : 'Smart'),
        })
      )
      this.user = response.data.data.user
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error', 
      'Error while getting instructions!'
        )
    } finally {
      this.state = null
    }
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  downloadDocument = async (file) => {
    try{
      let response = await axios.get(`/api/node/v1/instructions/download`, {
        params: {
            file_link: file.file_link,
            file_name: file.file_name
        },
        responseType: 'blob'
    })
      download(response.data, file.file_name)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon( 'error', i18next.t('w_download_error'))
    }
  }

  deleteInstructionFile = async (id) => {
    try{
      await axios.delete(`/api/node/v1/instructions/${id}`)
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon(
      'error', i18next.t('w_error'))
    } 
  }

  // UploadModal

  onFinishUpload = async (values) => {
    if(this.uploadModal.type === 'link'){
      await this.saveInstructionsLink(values)
    }
    else {
      await this.saveInstructionsFile(values)
    }
  }

  saveInstructionsLink = async (values) => {
    values.file_name = values.file_name.trim()
    const body = values
    try{
      this.uploadModal.loading = true
      await axios.post(`/api/node/v1/instructions/link`, body)
      this.uploadModal.loading = false
      this.uploadModal.open = false
      this.openNotificationWithIcon('success', i18next.t('w_save_succeess'))
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error_while_saving'))
      this.uploadModal.loading = false
    } 
  }

  saveInstructionsFile = async (values) => {
    try{
      let file_name = values.file_name ? values.file_name?.trim() :
      values.instruction.file.name.split('.').slice(0, -1).join('.');
      if(file_name.length === 0) {
        this.openNotificationWithIcon('error', i18next.t('w_file_without_name'))
        return
      }
      this.uploadModal.loading = true
      let module_id = values.module_id
      let data = new FormData();
      data.append('instruction', values.instruction.file);
      await axios.post(`/api/node/v1/instructions/save/${module_id}/${file_name}`, data)
      this.uploadModal.loading = false
      this.uploadModal.open = false
      this.openNotificationWithIcon('success', i18next.t('w_save_succeess'))
      await this.getInstructions()
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error_while_saving'))
      this.uploadModal.loading = false
    } 
  }


  changeUlpoadModalState = (type, value) => {
    let temp = { ...this.uploadModal}
    temp[type] = value
    this.uploadModal = temp
  }

}

export default InstructionsStore