import axios from 'axios';
import { refreshToken, getRefreshTokenMutex, setRefreshTokenMutex, refreshTokenPromise } from './refreshToken';
import store from "../store";
import {logoutUser} from "../actions/authActions";
const api = axios.create();

api.interceptors.request.use((config) => {
  if (config.url === '/api/node/v1/users/get/current') {
    config.headers['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  let responseData;

  const contentType = response.headers['content-type'];

  if (response.data instanceof Blob
  && contentType.includes("application/json")) {
    responseData = response.data;
    responseData = await readBlobAsText(responseData);
    responseData = JSON.parse(responseData);
  } else {
    responseData = response?.data ? {...response.data} : {};
  }

  if (responseData?.message === 'missingTokenError') {
    store.dispatch(logoutUser());
  }

  if (responseData?.success === false && responseData?.message === 'TokenExpiredError') {
    if (!getRefreshTokenMutex()) {
      setRefreshTokenMutex(true);
      try {
        const newToken = await refreshToken();
        response.config.headers.Authorization = `Bearer ${newToken}`;
        return api(response.config);
      } catch (refreshError) {
        store.dispatch(logoutUser());
        throw refreshError;
      } finally {
        setRefreshTokenMutex(false);
      }
    } else {
      await refreshTokenPromise;
      return api(response.config);
    }
  }

  return response;
}, (error) => {
  return Promise.reject(error);
});


const readBlobAsText = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error('Failed to read Blob as text'));
    };

    reader.readAsText(blob);
  });
}

export default api;
