import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStores } from '../../../../../useStores'
import Legend from './Legend/Legend'
import styles from './newIssuesBlock.module.scss'
import UpdatedTime from './UpdatedTime/UpdatedTime'

const NewIssuesBlock = observer(() => {
	const store = useStores().issuesStore
	useEffect(() => {}, [store.newTaskArr])


	function renderTasks()
	{
		let newTasks = [];

		for(let el in store.newTaskArr)
		{
			newTasks.push(<div key={store.newTaskArr[el].id} className={styles.task}>
				<div className={styles.info}> 
					<div className={store.newTaskArr[el].priority_id === 1 ? styles.standartPriority : styles.urgentPriority}></div>
					<span className={styles.id}>&nbsp;&nbsp;&nbsp;№{store.newTaskArr[el].id}: &nbsp; </span>
					{
						store.newTaskArr[el].theme.length > 40
						? <span className={styles.theme}>{store.newTaskArr[el].theme.substring(0, 40)}{'...'}</span>
						: <span className={styles.theme}>{store.newTaskArr[el].theme}</span>
					}
					<span className={styles.name}> &nbsp;/&nbsp;</span>
					<span className={styles.name}>{store.newTaskArr[el].user?.name} {store.newTaskArr[el].user?.surname}</span>
				</div>
				<div className={styles.time}>
					{store.correctTime(el.created_at)}
				</div>
			</div>)
		}

		return newTasks;
	}

	return (
		<div className={styles.issues_wrapper}>
			<div className={styles.tasks_box}>
				{renderTasks()}
			</div>
			<div className={styles.footer}>
				<UpdatedTime />
				<Legend />
			</div>
		</div>
  )
})

export default NewIssuesBlock
