import { makeAutoObservable, toJS } from 'mobx'
import { notification } from 'antd'
import QueryString from 'querystring'
import dayjs from 'dayjs'
import api from '../utils/axiosAuthInterceptor'

const download = require('downloadjs')

const axios = api
const moment = require('moment')

class ContractsStore {
  contractLoading = undefined
  currentUser = null
  newContractId = null
  isContractExist = null

  // пагинация
  limit = 10
  page = 1
  totalPages = null

  // выбор колонок
  isColumnsSelectorOpened = false
  selectedColumns = []
  selectedAllColumns = true
  selectedAllColumnsInterminate = false
  columns = []

  // CONTRACT
  contract = {}
  contractAccess = null
  productGroups = []
  defaultUsdRmb = 'FOB'
  defaultSendPort = 'Ningbo'
  defaultCurrency = 'CNY'
  currencyContract = null
  supplyList = ['FOB', 'EXW']
  portList = []
  currencyList = ['USD', 'CNY']
  currency = null
  currentContract = undefined
  tabs = []
  contractTabsUpdated = {}
  filledTabs = {
    include: [],
    disable: [],
  }
  responsibleManagers = {}
  modalType = ''
  recipients = []
  downloadFileProgres = []

  //CHAT
  commentsLanguage = {
    chat_samples_for_tests: undefined,
    chat_inspections: undefined,
    chat_transport_fees: undefined,
    chat_signed_files: undefined,
    chat_memo: undefined,
  }
  commentsCounts = {}
  commentsData = {
    chat_samples_for_tests: {},
    chat_inspections: {},
    chat_transport_fees: {},
    chat_signed_files: {},
    chat_memo: {},
  }
  commentsUsers = {
    chat_samples_for_tests: [],
    chat_inspections: [],
    chat_transport_fees: [],
    chat_signed_files: [],
    chat_memo: [],
  }

  //LOGS
  contractLogs = {}

  // #0 SPECIFICATION
  specificationLoading = true
  datasetSpecification = []
  specSelectOptions = {
    buyers: [],
    managers: [],
    engineers: [],
    brand_managers: [],
  }
  isSpecDropdownOpen = false
  articles = []
  factoryModels = []
  buyers = undefined
  paymentConditionOptions = ['w_after_shipment', 'w_after_inspection']
  specSelectedColumns = []
  specNewSelectedColumns = []
  specFilters = {
    sort: undefined,
    article: undefined,
    customerModel: undefined,
    supplierModel: undefined,
  }
  editRows = [
    {
      key: 'add',
    },
  ]
  pricesRows = [
    {
      key: 'total',
    },
    {
      key: 'sForCertificates',
    },
    {
      key: 'otherFees',
    },
    {
      key: 'totalAmount',
    },
    {
      key: 'deposit',
    },
    {
      key: 'payment',
    },
  ]
  noEditNoPricesRows = [
    {
      key: 'total',
    },
    {
      key: 'payment',
    },
  ]

  // PARSING PO
  specParsingLoading = false
  isParsingModalOpen = false
  specParsingData = []
  specParsingModels = []
  notFoundArticles = []
  showSpecParsingWarning = undefined
  chosenModels = []

  // #1 CERTIFICATION
  datasetCertification = []
  certArticles = []
  certFilters = {
    sort: undefined,
    article: undefined,
    customerModel: undefined,
    supplierModel: undefined,
  }

  // #2 TESTS
  datasetTestedSamples = undefined
  testedSamplesQty = undefined
  testsArticles = []
  testsUpdated = new Date(1970)
  testedSamples = {}
  testedSamplesBatches = null
  testedSamplesColumnsDataset = []
  testedSamplesColumnsChecked = []
  testedSamplesSelectedColumns = []
  testsFilters = {
    sort: undefined,
    article: undefined,
    customerModel: undefined,
    supplierModel: undefined,
  }

  // #4 SPLIT
  datasetSplit = undefined
  bankDetails = null
  splitFilters = {
    sort: undefined,
  }
  splitErrorMessage = null

  // #5 BATCHES SPLIT
  datasetBatchesSplitTotal = []
  datasetBatchesSplit = []
  batchesSplitMessage = ''
  batchesSplitTotalFilters = {
    sort: undefined,
    article: undefined,
  }
  batchesSplitFilters = {}

  // #3 PACKING DATA
  datasetPacking = []

  // INSPECTIONS (TAB№6)
  inspectionsData = []
  batchUploadFile = null
  batchUploadFileInsp = null
  batchFilesInspData = []
  inspectionsOtherData = []
  // AddInspectionModalOpen
  isAddInspectionModalOpen = false
  inspectionArticles = []
  newInspectionSamples = [
    {
      key: 'first',
      article: null,
      supplierModel: null,
      qty_accepted: null,
    },
    {
      key: 'add',
    },
  ]

  // #9 SIGNED FILES
  signedFiles = []
  loadingId = null
  searchedFilename = null
  signedFilesType = null
  // SIGNED FILES MODAL
  isSignedFilesModalOpen = false
  emailContacts = {}
  dataToSend = {
    sendTo: [],
  }
  checkedEmails = {
    spec_users: [],
    request_department: [],
    product_managers: [],
    engineers: [],
    logistic_shipment: [],
    accountant: [],
    qc: [],
    spare_parts: [],
  }
  emailUsers = []
  allEmailUsersContacts = []
  // SEND NOTIFICATION MODAL
  isSendNotificationModalOpen = false
  checkedNotificationEmails = []

  // #10 LEFTOVERS INSPECTIONS
  leftoversInspectionsData = []
  leftoversInspections = []
  isAllCancelled = false
  totalLeftHistory = []
  leftoversFilters = {
    sort: undefined,
    article: undefined,
    customerModel: undefined,
  }

  // #7 Reconciliation
  reconciliationData = []
  transportFeesRec = null
  currencyRec = null

  activeTab = 'specification'

  filters = {
    managers: [],
    engineers: [],
    brandManagers: [],
    vendors: [],
    types: [],
    contract: null,
    sign_date: [],
    order_Yulia: [],
    factory_locations: [],
    marketings: [],
    onlyUpdated: false,
  }
  sort = {
    sign_date: null,
  }

  pagination = {
    current: 1,
    pageSize: 10,
  }
  userPageSize = 10

  deleteLoading = false
  loading = true
  total = undefined
  vendors = []
  vendorsGroups = []
  managers = []
  brandManagers = []
  engineers = []
  types = []
  data = []
  dataset = []

  // Modal
  factories = []
  modalCreateOpen = false
  saveButtonDisabled = true
  extensionVisible = false
  contractFactory = []
  contractNumber = undefined
  batchNumber = undefined
  saveButtonLoading = false

  // EXPORT TO XL (MODAL)
  exportModalOpened = false
  exportOptions = []
  exportPDFOptions = []
  exportLoading = false
  factoryDetailsId = []
  invoiceWithSft = true

  // AddBuyerModal
  AddBuyerModalOpen = false

  //SendEmailModal
  isSendEmailModalOpen = false
  sendEmailOptions = []
  factoryContacts = []

  // AddFactoryContactModal
  isAddFactoryContactModalOpen = false

  // ModalParseFileModal
  isParseFileModalOpen = false
  parseFileOption = null
  parseFile = null

  // User
  userId = null
  userTypeId = null

  //#8 TransportFees
  trFeesData = []
  trFeesFiles = []
  transportFeesDifference = null
  transportFeesNewPhotos = []

  get totalOrderAmount() {
    const data = this.datasetSpecification.reduce((sum, current) => {
      if (!isNaN(current.key)) return +sum + +current.tot_qty
      return +sum + 0
    }, 0)
    return +data
  }

  get totalOrderTestsAmount() {
    const data = this.datasetSpecification.reduce((sum, current) => {
      if (!isNaN(current.key)) return +sum + +current.tests_tot_qty
      return +sum + 0
    }, 0)
    return +data
  }

  get totalSamplesPlanned() {
    const data = this.datasetSpecification.reduce((sum, current) => {
      if (!isNaN(current.key)) return +sum + +current.qty_planned
      return +sum + 0
    }, 0)
    return +data
  }

  get totalOrderPrice() {
    const data = this.datasetSpecification.reduce((sum, current) => {
      if (!isNaN(current.key) && current.amount) return +sum + +current.amount
      return sum + 0
    }, 0)
    return +data.toFixed(2)
  }

  get totalContractAmount() {
    const data =
      this.totalOrderPrice +
      this.contract.other_fees +
      this.contract.certification_amount
    return +data.toFixed(2)
  }

  get deposit() {
    const data = this.contract.deposit
      ? (this.totalContractAmount / 100) * this.contract.deposit
      : 0
    return +data.toFixed(2)
  }

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  // ОБЩИЕ СЕРВИСЫ
  // нотификация
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message,
      placement: 'bottomRight',
    })
  }

  // получить данные юзера
  getUser = async () => {
    const response = await axios.get('/api/node/v1/users/get/current')

    this.currentUser = response.data
    this.userId = response.data.userId
    this.userTypeId = response.data.userTypeId // post_id
  }

  // доступы
  getAccess = async () => {
    try {
      const response = await axios.get('/api/node/v1/contracts/access')
      if (response.data.success) {
        this.contractAccess = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // получить ответственных менеджеров
  getResponsibleManagers = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/responsible/${this.contract.id}`
      )
      if (response.data.success) {
        this.responsibleManagers = response.data.data.contracts_managers
          .map((el) => el.name + ' ' + el.surname)
          .join(', ')
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //поолучить юр лиц
  getRecepients = async () => {
    try {
      if (this.recipients.length !== 0) return

      const response = await axios.get(`/api/node/v1/contracts/recipients`)
      if (response.data.success) {
        this.recipients = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // форматирование даты
  correctTime = (dateString, noTime = false) => {
    const momentDate = moment(dateString)
    if (noTime) {
      return momentDate.format('YYYY-MM-DD')
    }
    return momentDate.format('YYYY-MM-DD HH:mm')
  }

  correctDateFilter = (dates) => [
    dayjs(dates[0]).startOf('day').format('YYYY-MM-DD'),
    dayjs(dates[1]).endOf('day').format('YYYY-MM-DD'),
  ]

  // валидация числовых инпутов
  validateNumber = (value) => {
    if (value === '') {
      value = null
      return value
    }
    // проверка на '', '89', '89b', 'bcd'
    if (isNaN(value)) value = +value.replace(/[^0-9]+/g, '')
    // проверка на отрицательное число и null
    if (value < 0) value = 0
    return value
  }

  changeCommaToDot = (value) => value && value.toString().replace(',', '.')

  // проверка на пустоту комментариев, содержащих html разметку
  checkCommentButtonDisabled = (commentStr) => {
    const newStr = commentStr
      .replace(/<\/?p[^>]*>/g, '') // delete <p></p>
      .replace(/<\/?br[^>]*>/g, '') // delete <br>
      .split(' ')

    if (commentStr.length === 0 || newStr.every((el) => el === '')) {
      return true
    }
    return false
  }

  // проверка строковых значений на пустоту
  checkEmptyString = (str) => {
    if (str === null || str.split(' ').every((el) => el === '')) {
      return true
    }
    return false
  }

  // предпросмотр файлов
  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))
      const data = await axios.get(`${link}${file.id}`, {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }

  //обработка строки, скопированной из xl колонкой
  xlStringToArr = (str) => {
    let value = str.clipboardData.getData('text')
    let arr = value.split('\n')
    if (arr[arr.length - 1] === '') {
      arr.splice(-1, 1)
    }
    return arr.map((el) => this.changeCommaToDot(el))
  }

  // ГЛАВНАЯ СТРАНИЦА КОНТРАКТОВ
  // пагинация
  getData = async ({ page = 1, size = this.limit, postIdFilter = true }) => {
    try {
      this.loading = true
      this.page = page
      this.limit = size

      if (postIdFilter) {
        if (!this.userTypeId) await this.getUser()
        if (this.userTypeId === 3 || this.userTypeId === 5) {
          this.filters.managers.push(this.userId)
        } else if (
          this.userTypeId === 8 ||
          this.userTypeId === 11 ||
          this.userTypeId === 24
        ) {
          this.filters.engineers.push(this.userId)
        } else if (this.userTypeId === 7 || this.userTypeId === 10) {
          this.filters.brandManagers.push(this.userId)
        }
      }

      const response = await axios.post('/api/node/v1/contracts/all', {
        filters: this.filters,
        limit: this.limit,
        page: this.page,
        sort: this.sort,
      })

      if (response.data.success) {
        this.data = response.data.data.result
        this.totalPages = response.data.data.totalRows
        this.loading = false
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  changePageSize = async (size) => {
    this.limit = size
  }

  // фильтры
  getFilterValues = async () => {
    const response = await fetch('/api/node/v1/contracts/filters')
    const data = await response.json()
    this.types = data.data.types
    this.vendorsGroups = data.data.vendorsGroups
    this.managers = data.data.managers
    this.brandManagers = data.data.brandManagers
    this.engineers = data.data.engineers
    this.factoryLocations = data.data.factoryLocations
    this.marketings = data.data.marketings
  }

  changeFiltersValue = (value, key) => {
    this.filters[key] = value
    if (key === 'contract' && value === '') {
      this.filters.onlyUpdated = false
    }
    this.getData({ postIdFilter: false })
  }

  clearFilters = () => {
    this.filters = {
      managers: [],
      vendors: [],
      engineers: [],
      brandManagers: [],
      contract: null,
      types: [],
      sign_date: [],
      order_Yulia: [],
      factory_locations: [],
      marketings: [],
      onlyUpdated: false,
    }
    this.getData({ postIdFilter: false })
  }

  // удаление контракта
  deleteContract = async (id) => {
    this.deleteLoading = true

    const payload = {
      contractId: id,
    }
    try {
      const response = await axios.delete('/api/node/v1/contracts', {
        data: payload,
      })

      if (response.data.success) {
        this.openNotificationWithIcon('success', response.data.message)
        this.deleteLoading = false
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // выбор колонок
  toggleColumnsSelector = (flag) => {
    this.isColumnsSelectorOpened = flag
  }

  changeSelectedColumns = (dataIndex) => {
    if (dataIndex === 'all') {
      if (this.selectedAllColumns) {
        this.selectedAllColumns = false
        const allColumns = this.columns.map((el) => el.dataIndex)
        this.selectedColumns = allColumns
      } else {
        this.selectedAllColumns = true
        this.selectedAllColumnsInterminate = false
        this.selectedColumns = []
      }

      this.saveUnselectedColumns()
      return
    }

    const idx = this.selectedColumns.findIndex((el) => el === dataIndex)
    if (idx !== -1) this.selectedColumns.splice(idx, 1)
    else this.selectedColumns.push(dataIndex)

    if (this.selectedColumns.length !== 0) {
      if (this.selectedColumns.length === this.columns.length) {
        this.selectedAllColumns = false
        this.selectedAllColumnsInterminate = false
      } else {
        this.selectedAllColumnsInterminate = true
        this.selectedAllColumns = false
      }
    } else {
      this.selectedAllColumnsInterminate = false
      this.selectedAllColumns = true
    }

    this.saveUnselectedColumns()
  }

  saveUnselectedColumns = () => {
    axios.put('/api/node/v1/contracts/columns', {
      columns: JSON.stringify(this.selectedColumns),
    })
  }

  initColumnsFilter = async () => {
    const response = await axios.get('/api/node/v1/contracts/columns')

    if (response.data.data) {
      const columns = JSON.parse(response.data.data)
      if (!columns || columns.length === 0) return
      this.selectedColumns = columns

      if (this.selectedColumns.length !== 0) {
        this.selectedAllColumns = false
        if (this.selectedColumns.length !== this.columns.length)
          this.selectedAllColumnsInterminate = true
      }
    }
  }

  // Сохранение настроек пагинации
  getPageSize = async () => {
    const response = await axios.get('/api/node/v1/contracts/limit')
    if (response.data.data) {
      this.userPageSize = response.data.data
    } else {
      this.userPageSize = 10
    }
  }

  savePageSize = async (size) => {
    const response = await axios.put('/api/node/v1/contracts/limit', {
      limit: size,
    })
    if (!response.data.success) return
  }

  // МОДАЛЬНОЕ ОКНО: СОЗДАНИЕ НОВОГО КОНТРАКТА
  openCreateModal = async () => {
    if (this.factories.length === 0) {
      const response = await fetch('/api/node/v1/factories/all')
      const data = await response.json()
      if (!data.data) {
        this.factories = []
      } else {
        this.factories = data.data
      }
    }
    this.modalCreateOpen = true
  }

  closeCreateModal = () => {
    this.modalCreateOpen = false
    this.contractFactory = []
    this.contractNumber = undefined
    this.saveButtonDisabled = true
    this.batchNumber = undefined
    this.contractDescription = undefined
    this.saveButtonLoading = false
    this.extensionVisible = false
  }

  selectFactory = async (e) => {
    this.contractFactory = e
    if (e) {
      this.extensionVisible = true
      this.getMaxContractNumber(e)
    } else {
      this.extensionVisible = false
      this.contractNumber = undefined
      this.batchNumber = undefined
      this.checkSaveButton()
    }
  }

  changeContractNumber = (e) => {
    this.contractNumber = e.target.value
    this.isContractExist = null
    if (e.target.value) {
      this.getMaxContractPart(this.contractFactory, e.target.value)
    }
    this.checkSaveButton()
  }

  getMaxContractNumber = (factoryId) => {
    fetch(`/api/node/v1/statuses/get_max_contract_number/${factoryId}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.hasOwnProperty('contractNumber')) {
          this.contractNumber = response.contractNumber
          this.batchNumber = 1
          this.checkSaveButton()
        } else {
          console.log('Ошибка при получении')
        }
      })
      .catch((err) => console.error(err))
  }

  getMaxContractPart = (factoryId, contractNumber) => {
    if (factoryId > 0 && contractNumber > 0) {
      const data = {
        factoryId,
        contractNumber,
      }
      axios
        .post('/api/node/v1/statuses/get_max_contract_part', data)
        .then(async (response) => {
          if (response.data.hasOwnProperty('contractConsignment')) {
            this.batchNumber = response.data.contractConsignment
            this.checkSaveButton()
          } else {
            console.log('Ошибка при получении')
          }
        })
        .catch((err) => console.error(err))
    }
  }

  checkSaveButton = async () => {
    try {
      if (this.contractFactory && this.contractNumber.length >= 3) {
        const response = await axios.post('/api/node/v1/contracts/check', {
          factory_id: this.contractFactory,
          number: this.contractNumber,
        })
        this.isContractExist = response.data?.data?.exist
        this.saveButtonDisabled = this.isContractExist ? true : false
      } else {
        this.saveButtonDisabled = true
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  createNewContract = async () => {
    try {
      this.saveButtonLoading = true
      let saveData
      if (+this.contractFactory > 0 && +this.contractNumber > 0) {
        saveData = {
          contractFactory: +this.contractFactory,
          contractNumber: +this.contractNumber,
        }
      }

      const response = await axios.post('/api/node/v1/contracts', saveData)

      if (response.data.success) {
        this.openNotificationWithIcon('success', response.data.message)
        this.newContractId = response.data.data.contract_id
        this.closeCreateModal()
      } else {
        throw new Error(response.data.message)
      }
      this.saveButtonLoading = false
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // CONTRACT
  changeActiveTab = (key) => {
    this.activeTab = key
    if (key !== 'specification') this.getContractTabsUpdated()
    switch (this.activeTab) {
      case 'specification':
        this.getContract()
        this.getSpecification()
        break
      case 'certification':
        this.getCertificationData()
        break
      case 'samples_for_tests':
        this.getTestedSamples()
        break
      case 'split':
        this.getSplitData()
        break
      case 'batches_split':
        this.getBatchesSplitTotal()
        this.getBatchesSplit()
        break
      case 'packing_data':
        this.getPackingData()
        break
      case 'inspections':
        this.getInspections()
        this.getBatchFilesInsp()
        this.getInspectionsOther()
        break
      case 'leftovers_inspections':
        this.getLeftoversInspections()
        break
      case 'reconciliation':
        this.getReconciliation()
        break
      case 'transport_fees':
        this.getTransportFeesData()
        break
      case 'signed_files':
        this.getSignedFiles()
        break
      default:
    }
  }

  toAllContracts = () => {
    this.activeTab = 'specification'
  }

  getBankDetails = async () => {
    const response = await axios.get(
      `/api/node/v1/factories/details/${this.contract.factory_id}`
    )
    if (response.data?.success) {
      this.bankDetails = response.data?.data.details
    }
  }

  getTabsInfo = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/tabsInfo/${this.modalType}/${this.contract.id}`
      )

      if (response.data.success) {
        this.tabs = response.data.data.tabs
        this.filledTabs = response.data.data.filledTabs
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  getContractTabsUpdated = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/contractTabs/${this.contract.id}`
      )

      if (response.data.success) {
        this.contractTabsUpdated = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //CHAT
  getComments = async (tab) => {
    try {
      if (!this.contract.id) return

      if (tab === 'samples_for_tests' || tab === '2')
        tab = 'chat_samples_for_tests'
      else if (tab === 'inspections' || tab === '6') tab = 'chat_inspections'
      else if (tab === 'transport_fees' || tab === '8')
        tab = 'chat_transport_fees'
      else if (tab === 'signed_files' || tab === '9') tab = 'chat_signed_files'
      else if (tab === 'memo' || tab === '11') tab = 'chat_memo'
      else if (
        [
          'specification',
          'certification',
          'split',
          'batches_split',
          'packing_data',
          'leftovers_inspections',
          'reconciliation',
        ].includes(tab.toString())
      )
        return

      const response = await axios.get(
        `/api/node/v1/contracts/chat/${tab}/${this.contract.id}`,
        { params: { lang: this.commentsLanguage[tab] } }
      )

      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[tab] = response.data.data.messages
        this.commentsUsers[tab] = response.data.data.users.map((el) => {
          return {
            value: el.name + ' ' + el.surname,
            label: el.name + ' ' + el.surname,
            id: el.id,
          }
        })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  postComment = async (text, tab, files, users) => {
    try {
      const formData = new FormData()
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          formData.append('chat_files', files[i])
        }
      }
      formData.append(`contract_id`, this.contract.id)
      formData.append(`text`, text)
      formData.append(`users`, users)

      const response = await axios.post(
        `/api/node/v1/contracts/chat/${tab}`,
        formData
      )

      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[tab] = response.data.data.messages
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  deleteComment = async (commentId, tab) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/contracts/chat/${tab}/${commentId}`,
        {
          data: { contract_id: this.contract.id },
        }
      )

      if (response.data.success) {
        this.commentsCounts = response.data.data.counts
        this.commentsData[tab] = response.data.data.messages
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  downloadChatFile = async (file) => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/download/chatFiles/${file.id}`,
        {
          responseType: 'blob',
        }
      )

      if (response.status === 200) {
        download(response.data, file?.original_name)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //LOGS
  getContractLogs = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/actions/${this.contract.id}`
      )
      if (response.data.success) {
        this.contractLogs = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // SPECIFICATION (KEY = 0)
  getContract = async (search, tabKey, contractId) => {
    try {
      if (this.contractAccess === null) {
        await this.getAccess()
      }

      // обнуляем дефолтные значения для экпорта в xl и отправки мэйла
      this.exportOptions = []
      this.sendEmailOption = []

      this.contract = undefined
      this.articles = []
      this.specificationLoading = true

      this.newContractId = null

      this.showSpecParsingWarning = null
      this.notFoundArticles = []

      // при переходе по ссылке
      const parsed = QueryString.parse(search)
      if (parsed.hasOwnProperty('id')) {
        this.currentContract = +parsed.id
      }

      if (contractId) {
        this.currentContract = contractId
      }

      const response = await axios.get(
        `/api/node/v1/contracts/contract/${this.currentContract}`
      )

      if (response.data.success) {
        if (response.data.data?.ready_dates?.length === 0) {
          response.data.data.ready_dates.push({
            date: null,
            id: 'new',
          })
        }
        this.contract = response.data.data
        this.specSelectOptions.managers = [...this.contract.contracts_managers]
        this.specSelectOptions.engineers = [
          ...this.contract.contracts_engineers,
        ]
        this.specSelectOptions.brand_managers = [
          ...this.contract.contracts_brand_managers,
        ]
        this.contract.currency = this.contract.currency ?? this.defaultCurrency
        if (this.contractAccess?.specification.edit) {
          this.contract.ready_dates.push({ key: 'add' })
        }

        if (tabKey !== 'specification') this.changeActiveTab(tabKey)
        await this.getContractTabsUpdated()

        if (tabKey) {
          this.activeTab = tabKey
        } else {
          this.activeTab = 'specification'
        }

        this.specificationLoading = false
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  getSpecification = async () => {
    try {
      if (this.contractAccess === null) {
        await this.getAccess()
      }

      const response = await axios.get(
        `/api/node/v1/contracts/specification/${this.currentContract}`,
        { params: this.specFilters }
      )
      if (response.data.success) {
        this.productGroups = response.data.data.productGroups
        this.specSelectedColumns = response.data.data.selected_columns
        this.specNewSelectedColumns = response.data.data.selected_columns
        this.datasetSpecification = response.data.data.specification

        if (this.contractAccess?.specification?.edit) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.editRows,
          ]
        }
        if (this.contractAccess?.specification?.prices) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.pricesRows,
          ]
        }
        if (
          !this.contractAccess?.specification?.edit &&
          !this.contractAccess?.specification?.prices
        ) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.noEditNoPricesRows,
          ]
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Приходят варианты для селектов buyers / brand_managers/ managers / engineers
  getSpecSelectOptions = async (key) => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/select/search?key=${key}`
      )
      if (response.data.success) {
        this.specSelectOptions[key] = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Приходят варианты для селекта send_port
  getSendPorts = async () => {
    try {
      const response = await axios.get('/api/node/v1/destinationPorts')
      if (response.data.success) {
        this.portList = response.data.data.rows
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Редактируем данные текущего контракта:
  // 'buyer_id' || 'sign_date' || 'deposit' || 'other_fees' || 'usd_rmb' || 'send_port' || 'currency'
  updateContractValues = async (value, param) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/specification/contractValues',
        {
          contractId: this.contract.id,
          param,
          value,
        }
      )
      if (response.data.success) {
        if (response.data.data?.ready_dates.length === 0) {
          response.data.data.ready_dates.push({
            date: null,
            id: 'new',
          })
        }
        if (this.contractAccess?.specification.edit) {
          response.data.data.ready_dates.push({ key: 'add' })
        }
        this.contract = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Редактируем данные текущего контракта:
  // 'managers' || 'brand_managers' || 'engineers'
  updateContractPersonnel = async (values, param) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/specification/personnel',
        {
          contractId: this.contract.id,
          param,
          values,
        }
      )
      if (response.data.success) {
        const newKey = `contracts_${param}`
        this.contract[newKey] = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Редактируем данные спецификации:
  // 'price' || 'tot_qty'
  updateSpecCellValue = async (value, param, sample_id, index) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/specification/cell',
        {
          contractId: this.contract.id,
          param,
          value,
          sample_id,
        }
      )
      if (response.data.success) {
        this.datasetSpecification[index] = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Удаление строки (сэмпла) из спецификации
  deleteSpecRow = async (rowId, rowKey) => {
    try {
      if (rowKey === 'new') {
        this.datasetSpecification = this.datasetSpecification.filter(
          (el) => el.key !== 'new'
        )
        return
      }

      const response = await axios.delete(
        '/api/node/v1/contracts/specification/row',
        {
          data: { id: rowId, contractId: this.contract.id },
          params: this.specFilters,
        }
      )

      if (response.data.success) {
        const temp = [...this.datasetSpecification]
        const rows = temp.splice(-7)
        this.datasetSpecification = [
          ...response.data.data.specification,
          ...rows,
        ]

        if (this.productGroups.length !== response.data.data.productGroups) {
          await this.getContract()
        }
        this.productGroups = response.data.data.productGroups
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  checkSpecIsFiltered = () => {
    for (let key in this.specFilters) {
      if (Boolean(this.specFilters[key]) === true) return true
    }
    return false
  }

  // Добавление пустой строки на фронте
  addSpecRow = (index = -7) => {
    const isNewRowAdded = this.datasetSpecification.find(
      (el) => el.key === 'new'
    )
    if (isNewRowAdded) return

    for (let key in this.specFilters) {
      if (Boolean(this.specFilters[key]) === true) return
    }

    const temp = [...this.datasetSpecification]
    const newRow = {
      key: 'new',
      position: index,
    }
    temp.splice(index, 0, newRow)
    this.datasetSpecification = [...temp]
  }

  // Поиск артикулов в селекте article
  findArticles = async (value) => {
    try {
      if (value.length < 3) {
        return
      }

      const response = await axios.get(
        `/api/node/v1/contracts/articles?article=${value}`
      )

      if (response.data.success) {
        this.articles = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Выбор article
  changeProduct = async (customerModelId, index) => {
    const temp = [...this.datasetSpecification]

    const product = this.articles.find(
      (el) => el.customerModelId === customerModelId
    )
    temp[index].name = product.name
    temp[index].article = product.article
    temp[index].customerModelId = product.customerModelId

    this.datasetSpecification = temp
  }

  // Поиск factoryModels для селекта supplierModel
  findFactoryModels = async (customerModelId, isFullList = false) => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/models?customerModelId=${customerModelId}&factoryId=${this.contract.factory_id}&contractId=${this.contract.id}&isFullList=${isFullList}`
      )

      if (response.data.success) {
        if (isFullList) {
          this.specParsingModels = response.data.data.filter(
            (el) => !this.chosenModels.some((elem) => el.skuId === elem.skuId)
          )
        } else {
          this.factoryModels = response.data.data
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Выбор supplierModel и сохранение строки на бэке
  changeFactoryModel = async (skuId, row) => {
    try {
      const response = await axios.post(
        '/api/node/v1/contracts/specification/row',
        {
          contractId: this.contract.id,
          customerModelId: row.customerModelId,
          skuId,
          position: row?.position || null,
        },
        { params: this.specFilters }
      )

      if (response.data.success) {
        const temp = [...this.datasetSpecification]
        const rows = temp.splice(-7)
        this.datasetSpecification = [
          ...response.data.data.specification,
          ...rows,
        ]
        if (this.productGroups.length !== response.data.data.productGroups) {
          await this.getContract()
        }
        this.productGroups = response.data.data.productGroups
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  applySpecColumns = async () => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/specification/columns',
        {
          columns: this.specNewSelectedColumns,
        }
      )
      if (response.data.success) {
        this.getSpecification()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //Скачать файл sku ()
  downloadEdBomFile = async (file) => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/download/skuFiles/${file.id}`,
        {
          responseType: 'blob',
        }
      )

      if (response.status === 200) {
        download(response.data, file?.original_name)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Изменение фильтров
  changeSpecFiltersValue = (value, key) => {
    this.specFilters[key] = value
    this.getSpecification()
  }

  // Редактирование даты поставки
  changeReadyDate = async (value, id, action) => {
    try {
      const date = value ? moment(value).format('YYYY-MM-DD') : value

      const response = await axios.patch(
        '/api/node/v1/contracts/specification/readyDate',
        {
          contractId: this.contract.id,
          date,
          id,
        }
      )

      if (response.data.success) {
        if (response.data.data?.length === 0) {
          response.data.data.ready_dates.push({
            date: null,
            id: 'new',
          })
        }
        if (this.contractAccess?.specification.edit) {
          response.data.data.push({ key: 'add' })
        }
        this.contract.ready_dates = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Добавление пустой строки c датой поставки на фронте
  addReadyDateRow = () => {
    const isNewRowAdded = this.contract.ready_dates.find(
      (el) => el.id === 'new'
    )
    if (isNewRowAdded) return

    const newRow = {
      id: 'new',
      date: null,
    }
    this.contract.ready_dates.splice(-1, 0, newRow)
  }

  // Добавление даты поставки
  addReadyDate = async (value) => {
    try {
      const date = value ? moment(value).format('YYYY-MM-DD') : value

      const response = await axios.post(
        '/api/node/v1/contracts/specification/readyDate',
        {
          contractId: this.contract.id,
          date,
        }
      )

      if (response.data.success) {
        if (response.data.data?.length === 0) {
          response.data.data.push({
            date: null,
            id: 'new',
          })
        }
        if (this.contractAccess?.specification.edit) {
          response.data.data.push({ key: 'add' })
        }
        this.contract.ready_dates = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Удаление даты поставки (на данный момент удалить можно только последнюю)
  deleteReadyDate = async (id) => {
    try {
      if (id === 'new') {
        this.contract.ready_dates = this.contract.ready_dates.filter(
          (el) => el.id !== id
        )
        return
      }

      const response = await axios.delete(
        '/api/node/v1/contracts/specification/readyDate',
        {
          data: {
            contractId: this.contract.id,
            id,
          },
        }
      )

      if (response.data.success) {
        if (response.data.data?.length === 0) {
          response.data.data.push({
            date: null,
            id: 'new',
          })
        }
        if (this.contractAccess?.specification.edit) {
          response.data.data.push({ key: 'add' })
        }
        this.contract.ready_dates = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // экспорт товаров в спецификацию из файла, загруженного в заявках
  exportPO = async () => {
    try {
      this.specParsingLoading = true
      this.chosenModels = []

      const response = await axios.get(
        `/api/node/v1/contracts/specification/parse/po/${this.contract.id}`
      )

      if (['no request', 'no file'].includes(response.data.message)) {
        this.showSpecParsingWarning = response.data.message
        this.po_n = response.data.data?.po_n
        this.specParsingLoading = false
        return
      }

      if (response.data?.success) {
        this.notFoundArticles = response.data.data.notFoundArticles
        if (this.notFoundArticles.length > 0) {
          this.specParsingLoading = false
          return
        }

        this.specParsingData = response.data.data.specification

        this.specParsingLoading = false
        this.isParsingModalOpen = true
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  saveExportPO = async () => {
    try {
      const response = await axios.post(
        '/api/node/v1/contracts/specification/parse',
        {
          contractId: this.contract.id,
          samples: this.specParsingData,
        }
      )

      if (response.data?.success) {
        this.getContract()
        this.getSpecification()
        this.isParsingModalOpen = false
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl ('tot_qty' || 'price')
  pasteFromXlSpec = async (str, index, param) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetSpecification[index + i].id) {
          requestArr.push({
            value: +values[i],
            contract_sku_id: this.datasetSpecification[index + i].id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/specification/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          param,
        },
        { params: this.specFilters }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        this.datasetSpecification = response.data.data.specification

        if (this.contractAccess?.specification?.edit) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.editRows,
          ]
        }
        if (this.contractAccess?.specification?.prices) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.pricesRows,
          ]
        }
        if (
          !this.contractAccess?.specification?.edit &&
          !this.contractAccess?.specification?.prices
        ) {
          this.datasetSpecification = [
            ...this.datasetSpecification,
            ...this.noEditNoPricesRows,
          ]
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // CERTIFICATION (KEY = 2)
  getCertificationData = async (dropLoading = true) => {
    try {
      this.specificationLoading = true
      const response = await axios.get(
        `/api/node/v1/contracts/certification/${this.contract.id}`,
        { params: this.certFilters }
      )

      if (response.data?.success) {
        response.data.data.total.key = 'total'
        this.datasetCertification = [
          ...response.data.data.samples,
          response.data.data.total,
        ]

        // доступ к редактированию (добавлению строки)
        if (this.contractAccess.certification.edit)
          this.datasetCertification.splice(-1, 0, { key: 'add' })

        if (dropLoading) this.specificationLoading = false
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  getCertModels = async () => {
    try {
      const response = await axios.get(
        ` /api/node/v1/contracts/certification/models?contract_id=${this.contract.id}`
      )
      if (response.data?.success) {
        this.certArticles = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // изменение фильтров
  changeCertFiltersValue = (value, key) => {
    this.certFilters[key] = value
    this.getCertificationData()
  }

  // добавление строки на фронте
  addCertificationRow = () => {
    const temp = [...this.datasetCertification]
    const obj = {
      key: 'new',
    }
    temp.splice(-2, 0, obj)
    this.datasetCertification = [...temp]
  }

  // сохранение добавленной строки при выборе инткода
  changeCertProduct = async (id) => {
    try {
      const response = await axios.put(
        '/api/node/v1/contracts/certification/row',
        {
          contractSkuId: id,
          contractId: this.contract.id,
        }
      )

      if (response.data.success) {
        this.getCertificationData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // удаление строки
  deleteCertificationRow = async (rowId, rowKey) => {
    try {
      const temp = [...this.datasetCertification]
      if (rowKey === 'new') {
        temp.splice(-3, 1)
        this.datasetCertification = [...temp]
        return
      }

      const response = await axios.delete(
        '/api/node/v1/contracts/certification/row',
        {
          data: { id: rowId, contractId: this.contract.id },
        }
      )

      if (response.data.success) {
        this.getCertificationData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // сохранение значений delivery_date, price и tot_qty
  changeValueCertification = async (value, row, param, index) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/certification',
        {
          id: row.id,
          contractId: this.contract.id,
          param,
          value,
        }
      )

      if (response.data.success) {
        this.datasetCertification[index] = response.data.data.sample
        this.datasetCertification[this.datasetCertification.length - 1] = {
          ...response.data.data.total,
          key: 'total',
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // сохраняем дату во всех позициях в списке
  updateDeliveryDates = async (index) => {
    try {
      const response = await axios.put(
        '/api/node/v1/contracts/certification/deliveryDate',
        {
          contractId: this.contract.id,
          value: this.datasetCertification[index].delivery_date,
        }
      )

      if (response.data.success) {
        this.getCertificationData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl ('tot_qty' || 'price')
  pasteFromXlCertification = async (str, index, param) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetCertification[index + i].id) {
          requestArr.push({
            value: +values[i],
            contract_sku_id: this.datasetCertification[index + i].id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/certification/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          param,
        },
        { params: this.certFilters }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        response.data.data.total.key = 'total'
        this.datasetCertification = [
          ...response.data.data.samples,
          response.data.data.total,
        ]

        // доступ к редактированию (добавлению строки)
        if (this.contractAccess.certification.edit)
          this.datasetCertification.splice(-1, 0, { key: 'add' })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // TESTED SAMPLES (KEY = 2)
  // основной гет запрос
  getTestedSamples = async (dropLoading = true) => {
    try {
      this.specificationLoading = true
      const response = await axios.get(
        `/api/node/v1/contracts/tests/${this.contract.id}`,
        { params: this.testsFilters }
      )

      if (response.data?.success) {
        this.datasetTestedSamples = response.data.data?.samples
        this.testedSamplesBatches = response.data.data?.batches
        this.testedSamplesQty = response.data.data.samples?.length
        this.testedSamplesData = response.data.data?.contract
        this.testedSamplesColumnsDataset = response.data.data?.columns // все колонки для чекбоксов
        this.testedSamplesSelectedColumns = response.data.data?.selectedColumns // выбранные колонки

        // доступ к редактированию (добавлению строки)
        if (this.contractAccess.samples_for_tests.edit)
          this.datasetTestedSamples.splice(-1, 0, { key: 'add' })

        if (dropLoading) this.specificationLoading = false
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  getTestsModels = async () => {
    try {
      const response = await axios.get(
        ` /api/node/v1/contracts/tests/models?contract_id=${this.contract.id}`
      )
      if (response.data?.success) {
        this.testsArticles = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Изменение фильтров
  changeTestsFiltersValue = (value, key) => {
    this.testsFilters[key] = value
    this.getTestedSamples()
  }

  // удаление строки
  deleteTestedSamplesRow = async (index, rowId, rowKey) => {
    try {
      const temp = [...this.datasetTestedSamples]
      if (rowKey === 'new') {
        temp.splice(-3, 1)
        this.datasetTestedSamples = [...temp]
        return
      }
      const response = await axios.delete('/api/node/v1/contracts/tests/row', {
        data: { id: rowId, contractId: this.contract.id },
      })

      if (response.data.success) {
        this.testedSamplesQty--
        temp.splice(index, 1)
        temp[temp.length - 1] = response.data.data
        this.datasetTestedSamples = temp
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // добавление строки на фронте
  addTestedSamplesRow = () => {
    const temp = [...this.datasetTestedSamples]
    const obj = {
      key: 'new',
    }
    temp.splice(-2, 0, obj)
    this.datasetTestedSamples = [...temp]
  }

  // сохранение добавленной строки при выборе инткода
  changeTestedSamples = async (id) => {
    try {
      const response = await axios.put(
        '/api/node/v1/contracts/tests/row',
        {
          id,
          contractId: this.contract.id,
        },
        { params: this.testsFilters }
      )

      if (response.data.success) {
        this.datasetTestedSamples = response.data.data
        this.testedSamplesQty = response.data.data.length
        this.datasetTestedSamples.splice(-1, 0, { key: 'add' })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // редактирование price и used_qty
  changeValuesTestedSamples = async (value, row, param, index) => {
    try {
      const response = await axios.put(
        '/api/node/v1/contracts/tests/rowValue',
        {
          param,
          value,
          rowId: row.id,
          contractId: this.contract.id,
        }
      )

      if (response.data.success) {
        this.datasetTestedSamples[index] = response.data.data.sample
        this.datasetTestedSamples[this.datasetTestedSamples.length - 1] =
          response.data.data.total
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // редактирование значений в партиях
  changeBatchesValuesTestedSamples = async (
    value,
    id,
    typeId,
    stageId,
    batchId,
    sampleId
  ) => {
    try {
      value = value === null ? null : +this.validateNumber(value)

      const response = await axios.put('/api/node/v1/contracts/tests/cell', {
        value,
        id,
        typeId,
        stageId,
        batchId,
        sampleId,
        contractId: this.contract.id,
      })

      if (response.data.success) {
        const idxS = this.datasetTestedSamples.findIndex(
          (el) => el.id === +sampleId
        )
        this.datasetTestedSamples[idxS] = response.data.data.sample
        this.datasetTestedSamples[this.datasetTestedSamples.length - 1] =
          response.data.data.total
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // по кнопке apply сохраняем выбранные чекбоксы, чтобы добавить новые колонки
  addTestedSamplesColumns = async () => {
    try {
      const response = await axios.put('/api/node/v1/contracts/tests/columns', {
        contractId: this.contract.id,
        columns: this.testedSamplesColumnsChecked,
      })

      if (response.data.success) {
        this.testedSamplesColumnsChecked = []
        this.getTestedSamples()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  pasteFromXlTestsBatches = async (str, index, typeId, stageId, batchId) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetTestedSamples[index + i].id) {
          requestArr.push({
            value: +values[i],
            typeId,
            stageId,
            batchId,
            sampleId: this.datasetTestedSamples[index + i].id,
            contractId: this.contract.id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/tests/pasteBatchColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
        },
        { params: this.testsFilters }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        this.datasetTestedSamples = response.data.data
        this.datasetTestedSamples.splice(-1, 0, { key: 'add' })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl ('used_qty' || 'tot_qty' || 'price')
  pasteFromXlTests = async (str, index, param) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetTestedSamples[index + i].id) {
          requestArr.push({
            value: +values[i],
            sampleId: this.datasetTestedSamples[index + i].id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/tests/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          param,
        },
        { params: this.testsFilters }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        this.datasetTestedSamples = response.data.data
        this.datasetTestedSamples.splice(-1, 0, { key: 'add' })
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // #4 SPLIT
  getSplitData = async () => {
    try {
      this.specificationLoading = true
      const response = await axios.get(
        `/api/node/v1/contracts/split/${this.contract.id}`,
        { params: this.splitFilters }
      )

      if (response.data?.success) {
        if (
          !response.data?.data ||
          Object.keys(response.data.data[0]).length < 10
        ) {
          this.datasetSplit = []
          return this.openNotificationWithIcon(
            'error',
            'Нет данных в спецификации'
          )
        }
        this.datasetSplit = response.data?.data

        this.datasetSplit[this.datasetSplit.length - 3].key = 'total'
        this.datasetSplit[this.datasetSplit.length - 2].key = 'total_volume'
        this.datasetSplit[this.datasetSplit.length - 1].key = 'total_gw'
      } else {
        this.datasetSplit = []
        throw new Error(response.data.message)
      }
      this.specificationLoading = false
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  exportSplitFromPo = async () => {
    try {
      this.splitErrorMessage = null

      const response = await axios.post(
        `/api/node/v1/contracts/split/importFromPo`,
        { contract_id: this.contract.id },
        { params: this.splitFilters }
      )

      if (response.data?.data?.message === 'w_qnt_of_goods_doesnt_match') {
        this.splitErrorMessage = 'w_qnt_of_goods_doesnt_match'
        return
      }

      if (response.data?.success) {
        if (
          !response.data?.data ||
          Object.keys(response.data.data[0]).length < 10
        ) {
          this.datasetSplit = []
          return this.openNotificationWithIcon(
            'error',
            'Нет данных в спецификации'
          )
        }

        this.datasetSplit = response.data?.data

        this.datasetSplit[this.datasetSplit.length - 3].key = 'total'
        this.datasetSplit[this.datasetSplit.length - 2].key = 'total_volume'
        this.datasetSplit[this.datasetSplit.length - 1].key = 'total_gw'
      } else {
        this.datasetSplit = []
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  changeSplit = async (val, row, param) => {
    try {
      const response = await axios.put('/api/node/v1/contracts/split/cell', {
        value: val,
        recipientId: row[param].recipientId,
        skuId: row[param].skuId,
        qtyInTransportBox: row[param].qtyInTransportBox,
        type: 'qty',
        contract_id: this.contract.id,
      })

      if (response.data.success) {
        await this.getSplitData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  pasteRowFromXlSplit = async (values, idx, recipientId) => {
    try {
      values[values.length - 1] = values[values.length - 1].replace(/\n/g, '')

      const requestArr = []
      for (let i = 0; i < values.length; i++) {
        if (recipientId + i <= this.recipients.length) {
          requestArr.push({
            qty: +values[i],
            recipientId: recipientId + i,
            contract_sku_id: this.datasetSplit[idx].id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/split/pasteRow',
        {
          values: requestArr,
          contractId: this.contract.id,
        },
        { params: this.splitFilters }
      )
      if (response.data.success) {
        await this.getSplitData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  pasteColumnFromXlSplit = async (values, index, recipientId) => {
    try {
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetSplit[index + i].id) {
          requestArr.push({
            qty: Number(values[i]),
            sample_id: this.datasetSplit[index + i].id,
            recipient_id: recipientId,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/split/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
        },
        { params: this.splitFilters }
      )

      if (response.data.success) {
        this.datasetSplit = response.data?.data

        this.datasetSplit[this.datasetSplit.length - 3].key = 'total'
        this.datasetSplit[this.datasetSplit.length - 2].key = 'total_volume'
        this.datasetSplit[this.datasetSplit.length - 1].key = 'total_gw'
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // #5 BATCHES SPLIT
  // получение тоталов (верхняя таблица)
  getBatchesSplitTotal = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/batchesSplitGoods/total/${this.contract.id}`,
        {
          params: this.batchesSplitTotalFilters,
        }
      )

      if (response.data.success) {
        this.datasetBatchesSplitTotal = response.data.data
        this.batchesSplitMessage = response.data.message
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // получение партий
  getBatchesSplit = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/batchesSplitGoods/${this.contract.id}`,
        {
          params: this.batchesSplitFilters,
        }
      )

      if (response.data.success) {
        this.datasetBatchesSplit = response.data.data
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  changeBatchesSplitFiltersValue = (value, key, flag) => {
    if (flag === 'total') {
      this.batchesSplitTotalFilters[key] = value
      this.getBatchesSplitTotal()
    } else {
      this.batchesSplitFilters[key] = value
      this.getBatchesSplit()
    }
  }

  // сохранение значения Qnt в столбцах партий
  saveBatchesSplitCell = async (value, id) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/batchesSplitGoods',
        { value, id, contractId: this.contract.id }
      )

      if (response.data.success) {
        this.getBatchesSplitTotal()
        this.getBatchesSplit()
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl
  pasteFromXlBatchesSplit = async (str, index, recipient_id, batch) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetBatchesSplit[batch - 1][index + i]?.contractSkuId) {
          requestArr.push({
            qty: +values[i],
            sample_id:
              this.datasetBatchesSplit[batch - 1][index + i]?.contractSkuId,
            recipient_id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/batchesSplit/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          batch,
        },
        { params: this.batchesSplitFilters }
      )

      if (response.data.success) {
        this.datasetBatchesSplit = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // #3 PACKING DATA
  // основной гет запрос
  getPackingData = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/packingData/${this.contract.id}`
      )
      if (response.data.success) {
        this.datasetPacking = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // изменение значений в ячейке
  changePackingData = async (id, value, param) => {
    try {
      const newData = {
        contractId: this.contract.id,
        id,
        value: {
          [param]: value,
        },
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/packingData',
        newData
      )
      if (response.data.success) {
        this.getPackingData()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // перезаписать значение в sku
  rewriteInSKU = async (id, value, param, flag = null) => {
    try {
      const newData = {
        contractId: this.contract.id,
        id,
        value: {
          [param]: value,
        },
      }

      const response = await axios.put(
        '/api/node/v1/contracts/packingData/rewriteSKU',
        newData
      )
      if (response.data.success) {
        if (flag === 'spec') {
          const idx = this.specParsingData.findIndex((el) => el.skuId === id)
          this.specParsingData[idx].skuQtyInTransportBox = value
        } else {
          this.getPackingData()
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl ('real_NW' || 'real_intermediate_GW' || 'real_GW' || 'real_width' || 'real_height'
  // || 'real_depth' || 'real_qty_in_transport_box')
  pasteFromXlPacking = async (str, index, param) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.datasetPacking[index + i]?.contracts_sku_id) {
          requestArr.push({
            value: +values[i],
            contract_sku_id: this.datasetPacking[index + i].contracts_sku_id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/packingData/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          param,
        }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        this.datasetPacking = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // MODAL EXPORT xl
  openCloseExportModal = async () => {
    this.modalType = 'export'

    if (!this.exportModalOpened) {
      await this.getTabsInfo()
      await this.getBankDetails()
    }

    this.exportModalOpened = !this.exportModalOpened
    this.exportOptions = [...this.filledTabs.include]
    this.factoryDetailsId = []
  }

  changeBankDetails = (e, id) => {
    if (e.target.checked) {
      this.factoryDetailsId.push(id)
    } else {
      this.factoryDetailsId = this.factoryDetailsId.filter((el) => el !== id)
    }
  }

  //Сохранение параметров 'splitCartons', 'batchesSplitCartons'
  includeCartons = async (e, tabKey) => {
    if (tabKey === 4) {
      this.filledTabs.splitCartons = e.target.value
      await this.saveExportSettings(e.target.value, 'splitCartons')
    }
    if (tabKey === 5) {
      this.filledTabs.batchesSplitCartons = e.target.value
      await this.saveExportSettings(e.target.value, 'batchesSplitCartons')
    }
  }

  //Сохранение параметров чекбоксов вкладок и ключей 'isOneFile',
  //'isPriceIncluded', 'isExcel', 'splitCartons', 'batchesSplitCartons'
  saveExportSettings = async (checked, key) => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/tabsInfo/${this.modalType}`,
        {
          key,
          checked,
          contract_id: this.contract.id,
        }
      )

      if (response.data.success) {
        this.filledTabs = response.data.data.filledTabs
        this.tabs = response.data.data.tabs
        if (this.modalType === 'export') {
          this.exportOptions = [...this.filledTabs.include]
        } else {
          this.sendEmailOptions = [...this.filledTabs.include]
        }
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  exportTabsXl = async () => {
    this.exportLoading = true

    if (this.exportOptions.length > 0 || this.factoryDetailsId.length > 0) {
      const responseXL = await axios({
        url: '/api/node/v1/contracts/exportXL',
        responseType: 'blob',
        method: 'post',
        data: {
          exportOptions: this.exportOptions,
          contractId: this.contract.id,
          factoryDetailsId: this.factoryDetailsId,
          batchesSplitCartons: this.filledTabs.batchesSplitCartons,
          splitCartons: this.filledTabs.splitCartons,
          isOneFile: this.filledTabs.isOneFile,
          isPriceIncluded: this.filledTabs.isPriceIncluded,
          invoiceWithSft: this.invoiceWithSft,
        },
      })

      if (responseXL.status === 200) {
        const filename = `${
          responseXL.headers['content-disposition'].split('filename=')[1]
        }`
        const blob = await responseXL.data
        download(blob, filename)
      } else {
        this.openNotificationWithIcon('error', 'Error (xl)!')
      }
    }

    this.exportLoading = false
    this.openCloseExportModal()
  }

  exportTabsPdf = async () => {
    this.exportLoading = true

    if (this.exportOptions.length > 0 || this.factoryDetailsId.length > 0) {
      const responsePDF = await axios({
        url: '/api/node/v1/contracts/exportPDF',
        responseType: 'blob',
        method: 'post',
        data: {
          exportOptions: this.exportOptions,
          contractId: this.contract.id,
          contractName: this.contract.name,
          factoryDetailsId: this.factoryDetailsId,
          batchesSplitCartons: this.filledTabs.batchesSplitCartons,
          splitCartons: this.filledTabs.splitCartons,
          isOneFile: this.filledTabs.isOneFile,
          isPriceIncluded: this.filledTabs.isPriceIncluded,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          lang: this.memoCommentsLang,
          invoiceWithSft: this.invoiceWithSft,
        },
      })

      if (responsePDF.status === 200) {
        const filenamePDF = `${
          responsePDF.headers['content-disposition'].split('filename=')[1]
        }`

        const blobPDF = await responsePDF.data
        download(blobPDF, filenamePDF)
      } else {
        this.openNotificationWithIcon('error', 'Error (pdf)!')
      }
    }

    this.exportLoading = false
    this.openCloseExportModal()
  }

  // добавление компании в Buyers через модалку
  addBuyerModalToggle = () => {
    this.AddBuyerModalOpen = true
  }

  saveBuyer = async (values) => {
    if (values.code === undefined) values.code = ''
    if (values.address === undefined) values.address = ''
    if (values.web === undefined) values.web = ''

    const response = await axios.post(
      '/api/node/v1/contracts/saveBuyer',
      values
    )
    if (response.data.success) {
      this.openNotificationWithIcon('success', 'Saved!')
      const resp = await axios.get('/api/node/v1/buyers/getData')
      this.buyers = resp.data.data
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

  // MODAL SEND MAIL
  openSendEmailModal = async () => {
    this.modalType = 'mail'
    await this.getTabsInfo()
    await this.getBankDetails()
    await this.getEmailContacts()
    await this.getResponsibleManagers()

    this.sendEmailOptions = [...this.filledTabs?.include]
    this.isSendEmailModalOpen = true
    this.factoryDetailsId = []
  }

  changeEmail = (e) => {
    if (!e) e = null
    this.filledTabs.emails = e.target.value
  }

  //Cохранение почт
  saveMails = async () => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/tabsInfo/saveEmails`,
        {
          emails: this.filledTabs.emails,
        }
      )

      if (response.data.success) {
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  exportAndSendModal = async (fileFormat, text) => {
    try {
      const body = {
        contractId: this.contract.id,
        exportOptions: this.sendEmailOptions,
        email: this.filledTabs.emails,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        factoryDetailsId: this.factoryDetailsId,
        batchesSplitCartons: this.filledTabs.batchesSplitCartons,
        splitCartons: this.filledTabs.splitCartons,
        fileFormat,
        isOneFile: this.filledTabs.isOneFile,
        isPriceIncluded: this.filledTabs.isPriceIncluded,
        text,
        invoiceWithSft: this.invoiceWithSft,
      }

      const response = await axios.post('/api/node/v1/contracts/mail', body)
      if (response.data.success) {
        this.openNotificationWithIcon('success', 'E-mail sent!')
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // SIGNED FILES (KEY = 9)
  getEmailContacts = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/signedFiles/sendMailUsers/${this.contract.id}`
      )
      if (response.data?.success) {
        this.emailContacts = response.data.data
        for (let key in this.emailContacts) {
          this.allEmailUsersContacts = [
            ...this.allEmailUsersContacts,
            ...this.emailContacts[key],
          ]
        }
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  sendEmailFromSignedFiles = async (flag) => {
    try {
      if (flag === 'notification') {
        this.dataToSend.sendTo = [...this.checkedNotificationEmails]
      }

      this.dataToSend.contractName = this.contract.name
      this.dataToSend.type = this.signedFilesType
      this.dataToSend.contractId = this.contract.id

      const response = await axios.post(
        '/api/node/v1/contracts/signedFiles/sendMailUsers',
        this.dataToSend
      )
      if (response.data.success) {
        this.openNotificationWithIcon(
          'success',
          'E-mail notification has been sent!'
        )
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // основной гет запрос
  getSignedFiles = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/contractSignedFiles/${this.contract.id}`,
        { params: { filename: this.searchedFilename } }
      )
      if (response.data.success) {
        this.signedFiles = response.data.data
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // сохранение загруженного файла, в ответ приходит обновленный массив файлов сегментов
  saveSignedFiles = async (files, segmentId, segment, t) => {
    try {
      const response = await axios.post(
        `/api/node/v1/contracts/upload/contractSignedFiles/${this.contract.id}`,
        files,
        {
          params: {
            segment_id: segmentId,
          },
        }
      )

      if (response.data.success) {
        this.signedFiles[segment] = response.data.data[segment]
      } else {
        throw new Error('error')
      }
    } catch (error) {
      error.response.data.message
        ? this.openNotificationWithIcon(
            'error',
            t(error.response.data.message)
          )
        : this.openNotificationWithIcon('error', error.message)
    }
  }

  // скачивание одного файла
  downloadFile = async (file) => {
    this.loadingId = file.id
    const data = await axios.get(
      `/api/node/v1/contracts/download/contractSignedFiles/${file.id}`,
      {
        responseType: 'blob',
      }
    )

    download(data.data, file.original_name)

    if (data) {
      this.loadingId = null
      return true
    }
  }

  // скачивание всех файлов
  downloadAllFiles = async (segmentId) => {
    if (this.filledTabs?.signedFilesDisable?.includes(segmentId)) return
    if (!segmentId && this.filledTabs?.signedFilesDisable?.length === 5) return

    this.loadingId = segmentId || 'all'
    const response = await axios.get(
      '/api/node/v1/contracts/download/contractSignedFilesArchive',
      {
        responseType: 'blob',
        params: { typeId: segmentId, contractId: this.contract.id },
      }
    )

    download(
      response.data,
      `${
        response.headers['content-disposition']
          .split('filename=')[1]
          .split(';')[0]
      }`
    )
    this.loadingId = null
  }

  // удаление файла
  deleteFile = async (fileId) => {
    const response = await axios.delete(
      '/api/node/v1/contracts/contractSignedFiles',
      {
        params: { id: fileId },
      }
    )

    if (response.data.success) {
      this.getSignedFiles()
      this.openNotificationWithIcon(
        'success',
        'The file is successfully deleted!'
      )
    } else {
      this.getSignedFiles()
      this.openNotificationWithIcon(
        'error',
        "You can't delete this file! 30 minutes have passed!"
      )
    }
  }

  openSignedFilesModal = async (segmentKey) => {
    await this.getEmailContacts()
    await this.getResponsibleManagers()
    this.isSignedFilesModalOpen = true
    this.signedFilesType = segmentKey
  }

  openSendNotificationModal = async () => {
    await this.getEmailContacts()
    await this.getResponsibleManagers()
    this.signedFilesType = 'Send_link'
    this.isSendNotificationModalOpen = true
  }

  // INSPECTIONS (KEY = 6)
  // 1 - qtyAcceptedBlock
  // Основной гет запрос
  getInspections = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/inspections/${this.contract.id}`
      )
      if (response.data.success) {
        this.inspectionsData = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Получение сэмплов из спецификации для селекта в модальном окне
  getInspectionArticles = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/inspections/models?contract_id=${this.contract.id}`
      )
      if (response.data.success) {
        this.inspectionArticles = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Сохранение новой инспекции
  createNewInspection = async () => {
    try {
      this.newInspectionSamples.splice(-1, 1)

      const response = await axios.post('/api/node/v1/contracts/inspections', {
        contract_id: this.contract.id,
        rows: this.newInspectionSamples,
      })

      if (response.data.success) {
        this.getInspections()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Удаление инспекции
  deleteInspection = async (id) => {
    try {
      const response = await axios.delete(
        '/api/node/v1/contracts/inspections',
        {
          data: { inspection_id: id, contract_id: this.contract.id },
        }
      )

      if (response.data.success) {
        this.getInspections()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Скачивание инспекции
  downloadInspection = async (id) => {
    this.loadingId = id
    const response = await axios.get(
      `/api/node/v1/contracts/inspections/export/${id}`,
      {
        responseType: 'blob',
        headers: {
          timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        },
      }
    )

    download(
      response.data,
      `${response.headers['content-disposition'].split('filename=')[1]}`
    )

    if (response) {
      this.loadingId = null
      return true
    }
  }

  // Изменение статуса принятого товара
  changeInspStatusRow = async (inspection_row_id, status) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/inspections/status',
        {
          inspection_row_id,
          status,
          contract_id: this.contract.id,
        }
      )

      if (response.data.success) {
        this.inspectionsData = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // 2 - inspectionsBlock
  getBatchFilesInsp = async () => {
    const response = await axios.get(
      `/api/node/v1/contracts/inspectionFiles/${this.contract.id}`
    )
    if (response.data.success) {
      this.batchFilesInspData = response.data.data
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

  uploadBatchFileInsp = async (data) => {
    const response = await axios.post(
      `/api/node/v1/contracts/upload/inspectionFiles/${this.contract.id}`,
      data
    )
    if (response.data.success) {
      this.getBatchFilesInsp()
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

  deleteBatchFileInsp = async (fileId) => {
    const response = await axios.delete(
      `/api/node/v1/contracts/inspectionFiles/${fileId}`
    )

    if (response.data.success) {
      this.getBatchFilesInsp()
      this.openNotificationWithIcon(
        'success',
        'The file is successfully deleted!'
      )
    } else {
      this.openNotificationWithIcon(
        'error',
        "You can't delete this file! 24 hours have passed!"
      )
    }
  }

  downloadBatchFileInsp = async (file) => {
    const response = await axios.get(
      `/api/node/v1/contracts/download/inspectionFiles/${file.id}`,
      {
        responseType: 'blob',
      }
    )
    download(response.data, file.original_name)
  }

  acceptOrRejectBatch = async (row, type) => {
    const response = await axios.put(
      `/api/node/v1/contracts/contractBatchFiles/parsed/${type}/${row.id}`
    )
    if (response.data.success) {
      this.renewParsedTable(row.file_id)
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

  // 3 - other block
  getInspectionsOther = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/inspections/others/${this.contract.id}`
      )

      if (response.data.success) {
        this.inspectionsOtherData = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  uploadFileInspectionsOther = async (data) => {
    try {
      const response = await axios.post(
        `/api/node/v1/contracts/upload/inspectionOtherFiles/${this.contract.id}`,
        data
      )

      if (response.data.success) {
        this.inspectionsOtherData = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  deleteFileInspectionsOther = async (fileId) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/contracts/inspections/others/${fileId}`,
        {
          data: {
            contract_id: this.contract.id,
          },
        }
      )

      if (response.data.success) {
        this.inspectionsOtherData = response.data.data
        this.openNotificationWithIcon(
          'success',
          'The file is successfully deleted!'
        )
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  downloadFileInspectionsOther = async (file) => {
    const response = await axios.get(
      `/api/node/v1/contracts/download/inspectionOtherFiles/${file.id}`,
      {
        responseType: 'blob',
      }
    )
    download(response.data, file.original_name)
  }

  // RECONCILIATION (KEY = 7)
  // основной гет запрос
  getReconciliation = async () => {
    try {
      this.specificationLoading = true
      const response = await axios.get(
        `/api/node/v1/contracts/reconciliation/${this.contract.id}`
      )
      if (response.data.success) {
        this.currencyContract = response.data.data.currency
        this.currencyRec = response.data.data.currency_reconciliation
        this.reconciliationData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess.reconciliation.edit)
          this.reconciliationData.splice(
            -response.data.data.reconciliationData.length - 1,
            0,
            { idx: 'add' }
          )
        this.specificationLoading = false
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  updateContractValuesFromReconciliation = async (value, param) => {
    try {
      const response = await axios.patch(
        '/api/node/v1/contracts/reconciliation/contractValues',
        {
          contractId: this.contract.id,
          param,
          value,
        }
      )
      if (response.data.success) {
        this.currencyContract = response.data.data.currency
        this.currencyRec = response.data.data.currency_reconciliation
        this.reconciliationData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess.reconciliation)
          this.reconciliationData.splice(
            -response.data.data.reconciliationData.length - 1,
            0,
            { idx: 'add' }
          )
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //deposit, payments
  changeRecPaymentValues = async (value, param, payment_id = null) => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/reconciliation/payments`,
        {
          contract_id: this.contract.id,
          param,
          value,
          payment_id,
        }
      )
      if (response.data.success) {
        const newData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess.reconciliation)
          newData.splice(-response.data.data.reconciliationData.length - 1, 0, {
            idx: 'add',
          })
        this.reconciliationData = [...newData]
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //recipients
  changeRecRecipientValues = async (value, param, recipient_id) => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/reconciliation/recipients`,
        {
          contract_id: this.contract.id,
          param,
          value,
          recipient_id,
        }
      )
      if (response.data.success) {
        const newData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess.reconciliation)
          newData.splice(-response.data.data.reconciliationData.length - 1, 0, {
            idx: 'add',
          })
        this.reconciliationData = [...newData]
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //save new payment
  addNewRecPayment = async (amount, rate, date) => {
    try {
      const response = await axios.post(
        `/api/node/v1/contracts/reconciliation/payments`,
        {
          contract_id: this.contract.id,
          amount,
          rate,
          date,
        }
      )

      if (response.data.success) {
        const newData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess?.reconciliation.edit)
          newData.splice(-response.data.data.reconciliationData.length - 1, 0, {
            idx: 'add',
          })

        this.reconciliationData = [...newData]
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  deleteRecPayment = async (id) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/contracts/reconciliation/payments`,
        {
          data: {
            contract_id: this.contract.id,
            payment_id: id,
          },
        }
      )

      if (response.data.success) {
        const newData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]

        if (this.contractAccess?.reconciliation.edit)
          newData.splice(-response.data.data.reconciliationData.length - 1, 0, {
            idx: 'add',
          })

        this.reconciliationData = [...newData]
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  //вставка колонки из xl ('minus' || 'plus')
  pasteFromXlReconciliation = async (str, index, param) => {
    try {
      const values = this.xlStringToArr(str)
      const requestArr = []

      for (let i = 0; i < values.length; i++) {
        if (this.reconciliationData[index + i].recipient_id) {
          requestArr.push({
            value: +values[i],
            recipient_id: this.reconciliationData[index + i].recipient_id,
          })
        }
      }

      const response = await axios.patch(
        '/api/node/v1/contracts/reconciliation/pasteColumn',
        {
          values: requestArr,
          contractId: this.contract.id,
          param,
        }
      )

      if (response.data.success) {
        // получить новый массив сэмплов
        this.reconciliationData = [
          response.data.data.contracts_deposit,
          ...response.data.data.contracts_payments,
          { idx: 'info' },
          ...response.data.data.reconciliationData,
        ]
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // Transport Fees
  getTransportFeesData = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/transportFees/${this.contract.id}`
      )

      if (response.data.success) {
        this.trFeesData = response.data.data.transport_fees_data
        this.trFeesFiles = response.data.data.files
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  changeTrFeesValues = async (value, param) => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/transportFees/${this.contract.id}`,
        {
          param,
          value,
        }
      )

      if (response.data.success) {
        this.trFeesData = response.data.data.transport_fees_data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  downloadTrFeesFile = async (file) => {
    try {
      this.loadingId = file.id
      const response = await axios.get(
        `/api/node/v1/contracts/download/transportFeesFile/${file.id}`,
        {
          responseType: 'blob',
        }
      )

      if (response.status === 200) {
        download(response.data, file?.original_name || file.filename)
        this.loadingId = null
      } else {
        throw new Error('error')
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  deleteTrFeesFile = async (fileId) => {
    try {
      const response = await axios.delete(
        `/api/node/v1/contracts/transportFeesFile/${fileId}`,
        {
          data: { contract_id: this.contract.id },
        }
      )

      if (response.data.success) {
        this.trFeesFiles = this.trFeesFiles.filter((el) => el.id !== fileId)
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  saveTrFeesFiles = async (files) => {
    try {
      const response = await axios.post(
        `/api/node/v1/contracts/upload/transportFeesFiles/${this.contract.id} `,
        files
      )
      if (response.data.success) {
        this.trFeesFiles = response.data.data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  acceptOrRejectTransportFees = async (type) => {
    try {
      const response = await axios.patch(
        `/api/node/v1/contracts/transportFees/status/${type}/${this.contract.id}`
      )

      if (response.data.success) {
        this.trFeesData = response.data.data.transport_fees_data
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  // LEFTOVERS INSPECTIONS (TAB №10)
  getLeftoversInspections = async () => {
    try {
      const response = await axios.get(
        `/api/node/v1/contracts/leftoversInspections/${this.contract.id}`,
        { params: this.leftoversFilters }
      )

      if (response.data.success) {
        this.leftoversInspectionsData = response.data.data.data
        this.leftoversInspections = response.data.data.inspections
        this.isAllCancelled = response.data.data.allCancelled
        this.totalLeftHistory = response.data.data.historyRows.reverse()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  changeLeftoversFiltersValue = (value, key) => {
    this.leftoversFilters[key] = value
    this.getLeftoversInspections()
  }

  cancelOrRestoreTotalLeftOne = async (rowId, flag, comment) => {
    try {
      const response = await axios.put(
        `/api/node/v1/contracts/leftoversInspections/${flag}/${rowId}`,
        {
          comment,
          contract_id: this.contract.id,
        }
      )
      if (response.data.success) {
        this.getLeftoversInspections()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }

  cancelOrRestoreTotalLeftAll = async (flag, comment) => {
    try {
      const response = await axios.put(
        `/api/node/v1/contracts/leftoversInspections/${flag}/${this.contract.id}`,
        {
          comment,
          contract_id: this.contract.id,
        }
      )
      if (response.data.success) {
        this.getLeftoversInspections()
      } else {
        throw new Error(response.data.message)
      }
    } catch (error) {
      this.openNotificationWithIcon('error', error.message)
    }
  }
}

export default ContractsStore
