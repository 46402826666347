// Функция проверки значений строки таблицы возвращающая true/false

const isTableLineFilled = (row) => {
  if (
    !row.priorities ||
    !row.quantity ||
    !row.samples_readiness_time ||
    !row.samples_deadline_for_testing ||
    !row.sku_id ||
    !row.factory_id ||
    !row.location
  )
    return false
  return true
}
export default isTableLineFilled
