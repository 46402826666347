import { makeAutoObservable, toJS } from 'mobx';
import { notification } from 'antd';
import React from 'react';
import {
    Select, Button
} from 'antd';
import api from '../utils/axiosAuthInterceptor';
import i18next from 'i18next';
const axios = api;
const dayjs = require('dayjs');

const { Option } = Select;


class AnnouncementsStore {
    loadingFilter  = false
    isModalUpdateOpened = false;
    criterionList = [
        {
            criterion: undefined,
            fileList: []
        },
        {
            criterion: undefined,
            fileList: []
        },
        {
            criterion: undefined,
            fileList: []
        }
    ];
    listTitle = undefined;
    saveButtonLoading = false;
    isSiteUpdatesOpened = false;
    siteUpdateDepSelected = []
    isAdmin = false;
    rateList = {
        theme: undefined,
        rates: [
        ]
    };
    avgRates = undefined;
    isLoaded = false;
    announcements = [];
    title = 'Company news';
    departments=[]
    departmentsOpt=[]
    departmentsOnlyValues = [];
    statistics = [];
    department_id = 0;
    announcementsQty = undefined;
    users = [];
    userId = null
    filterUsers = [];
    searchText = '';
    startDate = null;
    endDate = null;
    isModalOpenCreateAnnouncement = false;
    isModalOpenUpdateAnnouncement = false;
    isModalOpenViewAnnouncement = false;
    announcementTypes = [
    {key:'Newsletter',value:'Newsletter'},
    {key:'Posts new',value:'Posts_new'},
    {
    key:'Notifications on the sections',
    value:'Notifications_on_the_sections'
    }
    ];
    moduleTypes=[]
    moduleTypesOnlyValues=[]
    handbooks =
        {
          key: 'handbooks',
          value: 'handbooks',
          title: 'Handbooks',
          children: [
            { key: 'suppliers', value: 'suppliers', title: 'Suppliers' },
            { key: 'positions', value: 'positions', title: 'Positions' },
            { key: 'departments', value: 'departments', title: 'Departments' },
            { key: 'users', value: 'users', title: 'Users' },
            { key: 'base_characteristics', value: 'base_characteristics', title: 'Base characteristics' },
            { key: 'common_characteristics', value: 'common_characteristics', title: 'Common characteristics' },
            { key: 'units_of_measurement', value: 'units_of_measurement', title: 'Units of measurement' },
            { key: 'ports_of_destination', value: 'ports_of_destination', title: 'Ports of destination' },
            { key: 'cargo_companies', value: 'cargo_companies', title: 'Cargo companies' },
            { key: 'consignees', value: 'consignees', title: 'Consignees' },
            { key: 'buyers', value: 'buyers', title: 'Buyers' },
            { key: 'shippers', value: 'shippers', title: 'Shippers' },
            { key: 'competitors', value: 'competitors', title: 'Competitors' },
            { key: 'translations', value: 'translations', title: 'Translations' },
            { key: 'accessories', value: 'accessories', title: 'Accessories' },
            { key: 'notifications_settings', value: 'notifications_settings', title: 'Notifications settings' },
          ],
        }
    createAnnouncement = {
        id: null,
        type: 'Newsletter',
        department: [],
        theme: '',
        description: undefined,
        modules:[],
        timePeriod:{startDate:null,endDate:null},
        rangePickerValue:[],
        isShowAfterClosing:false,
        is_show_in_company_news: false
    };


    viewAnnouncement = {
        created_at: undefined,
        departmentText: undefined,
        theme: undefined,
        title: undefined,
        description: undefined,
        type: undefined,
        department: [],
        files: [],
        slider: [],
    };

    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }

    deepClone=(object)=>{
       return JSON.parse(JSON.stringify(object))
    }

    openModalCreateAnnouncement = () => {
        this.isModalOpenCreateAnnouncement = true;
    }

    openModalViewAnnouncement = async (announcementId) => {
        const findedAnnouncement = this.announcements.find(announc=>announc.id === announcementId)
            if (findedAnnouncement) {
                const departmentNames = findedAnnouncement.departmentsId.map(departmentId => {
                    const department = this.departmentsOpt.find(opt => opt.id === departmentId);
                    return department ? department.name_en : ''; 
                }); 
                
                const viewAnnouncement = this.viewAnnouncement;
                viewAnnouncement.title = `${findedAnnouncement.theme}`;
                viewAnnouncement.departmentText = findedAnnouncement.departmentsId?.length <= 0 ? `Company news` : `Department: ${departmentNames.join(', ')}`;
                viewAnnouncement.description = findedAnnouncement.description;
                viewAnnouncement.type = findedAnnouncement.type;
                viewAnnouncement.department = findedAnnouncement.department;
                viewAnnouncement.files = findedAnnouncement.files;
                viewAnnouncement.slider = findedAnnouncement.slider;
                viewAnnouncement.creater = findedAnnouncement.userName + ' ' + findedAnnouncement.userSurname;
                viewAnnouncement.created_at = findedAnnouncement.created_at;

                if (!findedAnnouncement.isRead) {
                    await this.markAsRead(announcementId);
                }
                this.isModalOpenViewAnnouncement = true;
                this.viewAnnouncement = viewAnnouncement;
            }
    }

    closeModalViewAnnouncement = async () => {
        this.department_id || this.department_id === 0 ?
            this.getAnnouncementsWithFilter(this.department_id) :
            this.getAnnouncementsData();
        this.isModalOpenViewAnnouncement = false;
    }

    closeModalCreateAnnouncement = () => {
        this.clearForm()
        this.isModalOpenUpdateAnnouncement = false
        this.isModalOpenCreateAnnouncement = false
    }

    checkPresenceRusLetter = (str = '') => {
        let regexp = /[А-яЁё]/gm;
        return regexp.test(str);
    }

    downloadFile = (fileId, filename) => {
        axios.get('/api/node/v1/file/get/announcement/' + fileId,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click()
            })
    };

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    deleteAnnouncement = (id) => {
        if (id <= 0) return 0;

        fetch('/api/node/v1/announcement/delete/' + id)
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.getAnnouncementsData();
                    this.openNotificationWithIcon('success', 'Announcement delete successfully!')
                } else {
                    this.openNotificationWithIcon('error', response.message);
                }
            })
            .catch(err => console.error(err));
    }

    renderStatisticItem = ({ title, count }) => {
        return (
            <div key={"statisticItem_" + title} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <p style={{ marginBottom: '0' }}>{title}</p>
                <span style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', width: '40px', textAlign: 'center' }}>{count}</span>
            </div>
        );
    }

    renderAnnouncementFilesForSlider = ({ id, file_link }) =>
        <div key={"AnnouncementFileInSlider_" + id}><img style={{ width: '300px', height: '300px' }} src={file_link} alt="img" className="slider-img" /></div>;

    renderAnnouncementFiles = ({ id, original_name }) => {
        return (
            <div key={"AnnouncementFilesActive_" + id}>
                <Button type="link" onClick={() => this.downloadFile(id, original_name)} style={{ marginLeft: '10px', color: '#20a8d8', cursor: 'pointer' }}>
                    <i style={{ marginLeft: '10px' }} className="cui-cloud-download" />&nbsp;{original_name}
                </Button>
            </div>
        )
    };

    renderUsers = (user, id) => (
        <Option key={"selectUser" + id} value={id}>{user.name}&nbsp;{user.surname} </Option>
    )

    getOneAnnouncement = async (id) => {
        try{
            let response = await axios.get (`/api/node/v1/announcements/getOne/${id}`)
            let res = response.data.data
            let department = res.announcement_departments ?
            res.announcement_departments.map(obj => obj.department_id) :
            null
            department = department?.filter(depId => depId !== -1)
            let modules = res.announcement_selected_modules ?
            res.announcement_selected_modules.map(
                obj => obj.module
            ) : null
            let rangePickerValue = res.type === 'Notifications_on_the_sections' ? [
                dayjs(res.start_date), dayjs(res.end_date),
            ] : null
            this.createAnnouncement = {
                id,
                type: res.type,
                department,
                theme: res.theme,
                description: res.description,
                modules,
                timePeriod: {
                    startDate:res.start_date,
                    endDate: res.end_date
                },
                rangePickerValue,
                isShowAfterClosing: res.show_after_closing,
                is_show_in_company_news: res.is_show_in_company_news ?? 0
            }
        } catch (error) {
            this.openNotificationWithIcon('error', 'Error while getting announcement!')
        } finally {
            this.isModalOpenUpdateAnnouncement = true
        }
    }

    //end render


    changeCreateAnnouncement = (e,type) => {
        const createAnnouncement = this.createAnnouncement;
        createAnnouncement[type] = e
        this.createAnnouncement = createAnnouncement;
        if (e === 'Newsletter') createAnnouncement.department = [];
        if(type==='department' && e.length == 0) this.onSelectCreateAnnouncementDepartaments(-1)
    };


    saveNewAnnouncement = async (files) => {
        const theme = this.createAnnouncement.theme;
        const description = this.createAnnouncement.description;
        const type = this.createAnnouncement.type;
        const departments = this.createAnnouncement.department;
        const modules=this.createAnnouncement.modules
        const timePeriod=this.createAnnouncement.timePeriod
        const isShowAfterClosing=this.createAnnouncement.isShowAfterClosing
        const is_show_in_company_news= type === 'Notifications_on_the_sections' ? this.createAnnouncement.is_show_in_company_news : null

        if (this.checkPresenceRusLetter(theme) || this.checkPresenceRusLetter(description)) {
            this.openNotificationWithIcon('error', 'The text must not contain Russian characters!');
            return
        }
        let data = {
                    theme: theme,
                    description: description,
                    type: type,
                    departments: departments,
                    modules:modules,
                    timePeriod:timePeriod,
                    isShowAfterClosing:isShowAfterClosing,
                    is_show_in_company_news
                };
        if(this.isModalOpenUpdateAnnouncement){
            await this.updateAnnouncement(data)
            return
        }

                axios.post('/api/node/v1/announcement/save_new', data)
                    .then(response => {
                        if (response.data.success) {
                            this.clearForm()
                            //Загрузка файлов
                            if (+response.data.data.announcementId > 0) {
                                for (let i = 0; i < files.length; i++) {
                                    let data = new FormData();
                                    data.append('file', files[i]);
                                    axios.post('/api/node/v1/file/upload/announcement/' + response.data.data.announcementId, data)
                                }
                            }
                            this.getAnnouncementsWithFilter();
                            this.openNotificationWithIcon('success', 'Announcement saved successfully!')
                            this.closeModalCreateAnnouncement();
                        } else {
                            this.openNotificationWithIcon('error', response.data.message)
                        }
                    })
                    .catch(error => { console.log(error) });
    };

    setAnnouncement = (data) => {
        this.announcements = data;
    }

    setResponseData = async (data) => {
        await this.calcAnnouncementsQty(data.announcements);
        const filteredDepartments = data.departments.filter(depart => depart.id !== 5);
        this.departmentsOpt = [
            { id: 0, name_en: 'Select all' },
            ...filteredDepartments
            ];
        this.departments=filteredDepartments
        this.departmentsOnlyValues = filteredDepartments.map(depart => depart.id);
        const filteredModules = data.modules.filter(
            (module) => module.id !== 15 && module.id !== 9
          );
          this.moduleTypes = filteredModules.map((module) => ({
            key: module.name.toLowerCase().replace(/ /g, '_'),
            value: module.name.toLowerCase().replace(/ /g, '_'),
            title:module.name
          }));
          this.moduleTypes.unshift(this.handbooks)
          this.moduleTypes.unshift({ key: "select all", value: "select all",title:"Select all"});
        const newModuleTypes=this.deepClone(this.moduleTypes)
        let newModuleTypesfilter = newModuleTypes.filter(module => module.value !== 'select all' && module.value !== 'handbooks');
        let newModuleTypesMap=newModuleTypesfilter.map(module => module.value)
        this.moduleTypesOnlyValues = newModuleTypesMap.concat(this.handbooks.children.map(module => module.value));
        this.statistics = data.statistics;
        this.announcements = data.announcements;
        this.userId = data.userId
        this.isLoaded = true;
    }

    updateAnnouncement = async (data) => {
        data.id = this.createAnnouncement.id
        try{
            await axios.put('/api/node/v1/announcements/update', data)
            this.closeModalCreateAnnouncement()
            this.getAnnouncementsData()
            this.openNotificationWithIcon('success', 'Updated!')
        } catch (error) {
            console.error(error)
            this.openNotificationWithIcon('error', 'Error while updating announcement')
        }
    }

    //create update announcement modal
    openCloseModalUpdate = async () => {
        this.isModalUpdateOpened = !this.isModalUpdateOpened;
        this.siteUpdateDepSelected = []
        if (!this.isModalUpdateOpened) {
            this.criterionList = [
                {
                    criterion: undefined,
                    fileList: []
                },
                {
                    criterion: undefined,
                    fileList: []
                },
                {
                    criterion: undefined,
                    fileList: []
                }
            ];
            this.listTitle = undefined;
            this.saveButtonLoading = false;
        }
    }

    addCriterion = () => {
        this.criterionList.push({
            criterion: undefined,
            fileList: []
        });
    }

    changeValue = (e, index) => {
        this.criterionList[index].criterion = e.target.value;
    }

    addFile = (file, index) => {
        file.id = this.criterionList[index].fileList.length;
        this.criterionList[index].fileList = [...this.criterionList[index].fileList, file];
        return false;
    }

    removeFile = (file, index) => {
        let list = [...this.criterionList];
        const fileIndex = list[index].fileList.find(el => el.id === file.id).id;
        list[index].fileList.splice(fileIndex, 1);
        this.criterionList = list;
    }

    changeTitle = (e) => {
        this.listTitle = e.target.value;
    }

    createSiteUpdate = async () => {

        this.saveButtonLoading = true;
        let newList = this.criterionList.filter(element => {
            element.criterion = element.criterion ? element.criterion.trim() : '';
            return element.criterion.length > 0;
        })
        let data = {
            theme: this.listTitle,
            type: 'Update',
            text: newList,
            departments: this.siteUpdateDepSelected
        };

        const response = await axios.post('/api/node/v1/announcement/save_new', data);
        if (response.data.success) {
            for (let [index, update] of response.data.announcementsUpdates.entries()) {
                for (let file of newList[index].fileList) {
                    let data = new FormData();
                    data.append('file', file)
                    axios.post('/api/node/v1/file/upload/announcementsUpdates/' + update.id, data)
                }
            }
            await this.getAnnouncementsData();
            this.openNotificationWithIcon('success', 'Announcement saved successfully!')
            this.openCloseModalUpdate();
        }
        else {
            this.openNotificationWithIcon('error', response.data.message);
            this.saveButtonLoading = false;
        }
    }

    onSelectDepSiteUpdate = () => {

    }

    //rate modal
    closeSiteUpdates = async () => {
        this.department_id ? await this.getAnnouncementsWithFilter(this.department_id) : await this.getAnnouncementsData();
        this.isSiteUpdatesOpened = false;
        this.rateList.rates = [];
        this.saveButtonLoading = false;
    }

    openSiteUpdates = async (id) => {
        const response = await fetch(`/api/node/v1/announcements/get/${id}`);
        let data = await response.json();
        this.rateList.theme = data.data.theme;

        this.rateList.rates = await data.data.announcements_site_updates.map((element) => {
            let ratesExist = element.announcements_site_updates_rates.length > 0;
            return ({
                files: element.announcements_site_updates_files,
                text: element.text,
                functional: ratesExist ? element.announcements_site_updates_rates[0].rate : 0,
                usability: ratesExist ? element.announcements_site_updates_rates[1].rate : 0,
                stability: ratesExist ? element.announcements_site_updates_rates[2].rate : 0,
                functionalId: ratesExist ? element.announcements_site_updates_rates[0].id : 0,
                usabilityId: ratesExist ? element.announcements_site_updates_rates[1].id : 0,
                stabilityId: ratesExist ? element.announcements_site_updates_rates[2].id : 0,
                comment: element.announcements_site_updates_comments[0] ? element.announcements_site_updates_comments[0].comment : '',
                commentId: element.announcements_site_updates_comments[0] ? element.announcements_site_updates_comments[0].id : '',
                siteUpdateId: element.id,
            })
        });
        if (!data.data.isRead) {
            await this.markAsRead(id)
        }

        this.isSiteUpdatesOpened = true;
    }

    markAsRead = async (announcementId) => {
        const body = {
            announcement: announcementId
        };
        await axios.post(`/api/node/v1/announcements/read`, body);
    };

    changeRateFunc = (e, index) => {
        this.rateList.rates[index].functional = e;
    }

    changeRateUsability = (e, index) => {
        this.rateList.rates[index].usability = e;
    }

    changeRateStability = (e, index) => {
        this.rateList.rates[index].stability = e;
    }

    saveRate = async () => {
        this.saveButtonLoading = true;
        const response = await axios.post('/api/node/v1/announcements/save/rate', this.rateList.rates);
        if (response.data.success) {
            this.openNotificationWithIcon('success', 'Rates saved successfully!');
            await this.getAverages();
            this.closeSiteUpdates();
        } else {
            this.saveButtonLoading = false;
            this.openNotificationWithIcon('error', 'Error!');
        }
    }

    changeComment = (e, index) => {
        this.rateList.rates[index].comment = e.target.value;
    }

    //other

    calcAnnouncementsQty = async (data) => {
        let map = new Map();

        for (let item of data) {
            if (!item.isRead) {
                switch (item.type) {
                    case "Newsletter":
                        map.has(0) ? map.set(0, map.get(0) + 1) : map.set(0, 1);
                        break;
                    case "Update":
                        map.has(-1) ? map.set(-1, map.get(-1) + 1) : map.set(-1, 1);
                        break;
                    case "Posts_new":
                        for (let element of item.departmentsId) {
                            map.has(element) ? map.set(element, map.get(element) + 1) : map.set(element, 1);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        this.announcementsQty = await map;
    }

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    getUser = async () => {
        let resp = await fetch('/api/node/v1/users/get/current');
        let data = await resp.json();
        if (+data.userTypeId === 1) {
            this.isAdmin = true;
        }
    };

    getAverages = async () => {
        let resp = await fetch('/api/node/v1/announcements/allRate');
        let data = await resp.json();
        let map = new Map();

        for (const element of data.data) {
            map.set(element.announcement_id, element.rateAvg)
        }
        this.avgRates = map;
    };

    getAllUsers = async () => {
        const response = await fetch('/api/node/v1/users/getAll');
        const data = await response.json();
        this.users = data.data.users;
    }

    getAnnouncementsData = _ => {
        fetch(`/api/node/v1/announcements`)
            .then(response => response.json())
            .then(response => {
                this.setResponseData(response.data);
            })
            .catch(err => console.error(err))

    };

    getAnnouncementsWithFilter = async () => {
        let data = {
            department_id: this.department_id,
            text: this.searchText,
            users: this.filterUsers,
            startDate: this.startDate,
            endDate: this.endDate
        };
        try{
          this.loadingFilter = true
          let response = await axios.post(`/api/node/v1/announcements/filter`, data);
          this.announcements = response.data.data.announcements;
          this.title = response.data.data.title;
        } catch (error) {
          console.error(error)
          this.openNotificationWithIcon('error', i18next.t('w_erorr'))
        } finally {
          this.loadingFilter = false
        }
    }

    search = text => {
        this.searchText = text;
        this.getAnnouncementsWithFilter();
    }

    sortByDepartment = async (department_id) => {
        this.department_id = department_id;
        await this.getAnnouncementsWithFilter();
    }


    handleChangeFilterUsers = async (value) => {
        this.filterUsers = [];

        for (let user of value) {
            this.filterUsers.push(this.users[user].id)
        }
        this.getAnnouncementsWithFilter();
    }

    handleChangeDate = (value) => {
        if (value) {
            this.startDate = value[0].format('YYYY-MM-DD HH:mm:ss');
            this.endDate = value[1].format('YYYY-MM-DD HH:mm:ss');
        } else {
            this.startDate = null;
            this.endDate = null;
        }

        this.getAnnouncementsWithFilter();
    }

    //CreateAnouncment
    clearForm=()=>{
        this.createAnnouncement = {
            id: null,
            type: 'Newsletter',
            department: [],
            theme: '',
            description: undefined,
            modules:[],
            timePeriod:{startDate:null,endDate:null},
            rangePickerValue:[],
            isShowAfterClosing:false
        };
        this.departmentsOpt[0] = { id: 0, name_en: 'Select all' }
        const tempModuleTypes=this.deepClone(this.moduleTypes)
        tempModuleTypes[0]= { key: 'select all', value:'select all',title:'Select all' }
        this.moduleTypes=tempModuleTypes
    }

    onSelectCreateAnnouncementDepartaments=(value)=>{

        if(value === 0||this.createAnnouncement.department.length===
            this.departmentsOnlyValues.length){
        this.departmentsOpt[0] = { id: -1, name_en: 'Unselect all' };
        this.createAnnouncement.department = this.departmentsOnlyValues
        }
        if(value === -1){
        this.departmentsOpt[0] = { id: 0, name_en: 'Select all' };
        this.createAnnouncement.department=[]
        }
    }
    onChangeCreateAnnouncementModules=(value)=>{
        let modules = [...this.createAnnouncement.modules]
        let moduleTypes = this.deepClone(this.moduleTypes);

        if (value.includes('select all')||value.length === this.moduleTypesOnlyValues.length) {
            moduleTypes[0] = { key: 'unselect all', value: 'unselect all', title: 'Unselect all' };
            value=this.moduleTypesOnlyValues
          }
        else if(value.includes('unselect all')||value.length === 0){
            moduleTypes[0]= { key: 'select all', value:'select all',title:'Select all' }
            value=[]
        }

        modules = value
        this.createAnnouncement.modules = modules;
        this.moduleTypes=moduleTypes
    }

    handleDateRangeChangeCreate=(dates,dateStrings)=>{
        if (dateStrings[0] && dateStrings[1]) {
            this.createAnnouncement.rangePickerValue  = dates
            this.createAnnouncement.timePeriod.startDate=dateStrings[0]
            this.createAnnouncement.timePeriod.endDate=dateStrings[1]
          }
    }

    handleCheckboxChange=(e, type)=>{
        this.createAnnouncement[type] = e.target.checked;
    }

    //NotificationOnTheSections

    checkNotification = async (module) => {
        try {
          const response = await axios.get(`/api/node/v1/pushAnnouncements/check/${module}`);
          const data = response.data.data;
          for (let idx = 0; idx < data.length; idx++) {
            const args = {
              message: data[idx].theme,
              description:
              data[idx].description?
              data[idx].description:
              'No description',
              duration: 0,
              top:70,
              key: data[idx].id,
              onClose: async () => {
                if (!data[idx].show_after_closing) {
                  try {
                    await axios.put(`/api/node/v1/pushAnnouncements/change/${data[idx].id}`);
                  } catch (error) {
                    console.error('Error in PUT request:', error);
                  }
                }
              },
            };
            notification.info(args);
          }
        } catch (error) {
          console.error('Error in checkNotification:', error);
        }
      };

//Site update

onChangeSiteUpdate = (e) => {
    this.siteUpdateDepSelected = e;
    if(e.length == 0) this.onSelectSiteUpdateDepartaments(-1)
};

onSelectSiteUpdateDepartaments=(value)=>{

    if(value === 0||this.siteUpdateDepSelected.length===
        this.departmentsOnlyValues.length){
    this.departmentsOpt[0] = { id: -1, name_en: 'Unselect all' };
    this.siteUpdateDepSelected = this.departmentsOnlyValues
    }
    if(value === -1){
    this.departmentsOpt[0] = { id: 0, name_en: 'Select all' };
    this.siteUpdateDepSelected=[]
    }
}

}
export default AnnouncementsStore;

