import { makeAutoObservable, toJS } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";

const axios = api;


class MapStore {
    location = undefined;
    coordinates = undefined;
    bounds = undefined;
    ymaps = undefined;
    factories = undefined;
    currentFactory = undefined;
    saveLoader = false;
    factoriesUpdate = false;
    edit = false;

    constructor() {
        makeAutoObservable(this);
    }

    saveYmaps = (ymaps) => {
        this.ymaps = ymaps;
    }

    geocode = (address, updateLocation = true) => {
        this.ymaps.geocode(address, {
            results: 1
        })
            .then(result => {
                this.coordinates = result.geoObjects.get(0).geometry.getCoordinates();
                this.bounds = result.geoObjects.get(0).properties.get('boundedBy');
                if (updateLocation)
                    this.location = result.geoObjects.get(0).geometry.getCoordinates();
            })
    }

    updateCoordinates = location => {
        this.coordinates = location;
    }

    setEdit = () => {
        this.edit = true;
    }

    cancelEdit = () => {
        this.edit = false;
        this.coordinates = this.location;
    }

    getFactories = async () => {
        let response = await fetch('/api/node/v1/factories/all');
        let factories = await response.json();
        factories.data.sort((a, b) => {
            let nameA = a.abbreviation;
            let nameB = b.abbreviation;
            if (nameA < nameB)
                return -1
            if (nameA > nameB)
                return 1
            return 0
        })
        this.factories = await factories.data.map((element, index) => ({
            name: element.abbreviation,
            key: index,
            coordinates: element.coordinates,
            id: element.id
        }));
    }

    openFactory = (el) => {
        let coord = this.factories[el].coordinates;
        if (coord) {
            this.geocode(coord.split(','));
        }
        else {
            this.geocode('China');
        }
        this.currentFactory = el;
    }

    saveCoordinates = async () => {
        this.saveLoader = true;
        let data = await toJS(this.factories[this.currentFactory]);
        data.coordinates = this.coordinates.join();

        const response = await axios.post('/api/node/v1/factories/saveAddress/', data);
        if (response.data.success) {
            this.openNotificationWithIcon('success', 'Location saved successfully!');
            this.showAll();
            this.edit = false;
            this.factoriesUpdate = true;
            await this.getFactories();
            this.factoriesUpdate = false;
        } else {
            this.openNotificationWithIcon('error', 'Error!');
        }
        this.saveLoader = false;
    };

    showAll = () => {
        this.coordinates = undefined;
        this.location = undefined;
        this.currentFactory = undefined;
    }

    openNotificationWithIcon = (type, message) => {
        notification[type ? "success" : "error"]({
            message: message,
            placement: 'bottomRight'
        });
    };

}

export default MapStore;
