import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import setAuthToken from './utils/setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authActions'
import { Provider } from 'react-redux'
import store from './store'
import PrivateRoute from './components/private-route/PrivateRoute'
import i18next from 'i18next'
import "./utils/fetchAuthInterceptor";

import './App.scss'
import IssuesStat from './views/Issues/components/IssuesStat/IssuesStat'
// import IssuesStat from "./views/Issues/components/IssuesStat/IssuesStat";
//import { renderRoutes } from 'react-router-config';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)
const laravelAddress = process.env.REACT_APP_APP_LARAVEL_URL
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Recovery = React.lazy(() => import('./views/Pages/Recovery'))
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

//Set local storage for test
/*
localStorage.setItem('accessToken', '117c805ef7028a65934305de0fe46a34')
localStorage.setItem('accessTokenExpire', '2021-03-01 18:54:44')
localStorage.setItem('userId', "5")
localStorage.setItem('userName', "Vladimir Garmash")
*/

if (localStorage.accessToken && localStorage.accessToken !== 'undefined') {
  // Set auth token header auth
  const token = localStorage.accessToken
  let expire = localStorage.accessTokenExpire
  let userId = localStorage.userId
  let userName = localStorage.userName
  let userDepId = localStorage.userDepId
  let userRole = localStorage.userRole
  let userFirstName = localStorage.userFirstName
  let userLastName = localStorage.userLastName
  let userPostId = localStorage.userPostId
  // let currentTime = Date.now() / 1000;
  setAuthToken(token)

  let checkData =
    token !== 'undefined' &&
    token !== undefined &&
    token !== '' &&
    userId &&
    userId !== 'undefined' &&
    Number.isInteger(+userId) &&
    userName &&
    userName !== 'undefined'

  const decoded = {
    id: +userId,
    name: userName,
    //iat: 1614529988,
    exp: new Date(expire).getTime() / 1000,
    dep_id: +userDepId,
    role: userRole,
    firstName: userFirstName,
    lastName: userLastName,
    post_id: +userPostId,
  }
  store.dispatch(setCurrentUser(decoded)) // Set user and isAuthenticated

  // if (decoded.exp < currentTime || !checkData) {
  if (!checkData) {
    store.dispatch(logoutUser())

    if (window.location.href.indexOf('/login') < 0) {
      window.location.href = `/login`
    }
  }
}

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/recovery"
                name="Recovery Page"
                render={(props) => <Recovery {...props} />}
              />
              <Route path="/react/issuesStat" component={IssuesStat} />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </React.Suspense>
        </Router>
        <Router></Router>
      </Provider>
    )
  }
}
export default App
