import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend'

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        fallbackLng: false,
        backend: {
            loadPath: '/api/node/v1/translates/getLang/{{lng}}',
        },
        allowMultiLoading: true,
        react: {
            useSuspense: true
        }
    })
export default i18n;