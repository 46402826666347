// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomPopConfirm_CustomPopConfirm__G_dAD .ant-popconfirm-buttons {\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n}\n.CustomPopConfirm_CustomPopConfirm__G_dAD .ant-popconfirm-buttons .ant-btn-primary {\n  margin-left: unset;\n  margin-right: 10px;\n}", ""]);
// Exports
exports.locals = {
	"CustomPopConfirm": "CustomPopConfirm_CustomPopConfirm__G_dAD"
};
module.exports = exports;
