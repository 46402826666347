import api from '../../utils/axiosAuthInterceptor'
const axios = api

const API_BASE_PATH = '/api/node/v1/parts'

const templateApi = {
  getPOTemplate: async (orderId, id) => {
    try {
      const response = await axios.get(
        `${API_BASE_PATH}/template/${orderId}/${id}`
      )
      if (response.data.success) {
        return response.data.data
      }
    } catch (error) {
      throw error
    }
  },
}

export default templateApi
