import { makeAutoObservable, runInAction, configure } from 'mobx';
import { notification } from 'antd';
import api from '../utils/axiosAuthInterceptor';
const axios = api;
const moment = require('moment');

configure({
    enforceActions: "never",
})

class CalendarStore {
    vacations = [];
    isModalVacationOpen = false;
    startVacation = undefined;
    endVacation = undefined
    descriptionVacation = undefined;
    typeVacation = undefined;
    loadingSaveButton = false;
    updateVacation = false;
    idVacation = undefined;
    weekendsQty = undefined;
    week = undefined;
    year = undefined;

    columns = [
        {
            title: 'Start',
            dataIndex: 'startDateTable',
            fixed: true,
            width: 70
        },
        {
            title: 'End',
            dataIndex: 'endDateTable',
            fixed: true,
            width: 70
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Type',
            dataIndex: 'type'
        }
    ];

    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }

    closeModalVacation = () => {
        this.isModalVacationOpen = false;
        this.startVacation = undefined;
        this.endVacation = undefined;
        this.descriptionVacation = undefined;
        this.typeVacation = undefined;
        this.loadingSaveButton = false;
        this.updateVacation = false;
        this.idVacation = undefined;
    }

    openModalVacation = () => {
        this.isModalVacationOpen = true
    }

    changeVacationType = (e) => {
        this.typeVacation = e;
    };

    changeVacationDate = (_, dateString) => {
        this.startVacation = dateString[0];
        this.endVacation = dateString[1];
    };

    changeVacationDesc = (e) => {
        this.descriptionVacation = e.target.value;
    };

    getVacations = (year) => {
        runInAction(() => {
            this.year = year;
            this.weekendsQty = undefined;
        })
        fetch('/api/node/v1/vacation/get/' + year)
            .then(response => response.json())
            .then(response => {
                this.createDataSet(response.data.vacations);
                runInAction(() => {
                    this.weekendsQty = response.data.weekends;
                })
                let startWeekNum = moment(new Date(year, 0, 1)).isoWeek();
                let endWeekNum = moment(new Date(year, 11, 31)).isoWeek();
                let endDay = new Date(year, 11, 31).getDay();

                if (startWeekNum !== 1 && endDay === 0) {
                    runInAction(() => {
                        this.week = endWeekNum + 1;
                    });
                } else {
                    if ((startWeekNum !== 1 && endWeekNum !== 1) || (startWeekNum === 1 && endDay === 0)) {
                        runInAction(() => {
                            this.week = endWeekNum;
                        });
                    } else {
                        if (startWeekNum === 1 && endDay !== 0 && endWeekNum !== 1) {
                            runInAction(() => {
                                this.week = endWeekNum - 1;
                            });
                        }
                        else {
                            if (startWeekNum === 1 && endWeekNum === 1) {
                                runInAction(() => {
                                    this.week = 52;
                                });
                            }
                        }
                    }
                }
            })
            .catch(err => console.error(err))
    };

    createDataSet = async (vacations) => {
        let dataSet = vacations.map((element) => {
            let color = element.type === "holiday" ? "#c6e0b4" : "white";
            return {
                id: element.id,
                name: element.description,
                startDate: new Date(element.start),
                endDate: new Date(element.end),
                startDateTable: element.start.slice(5),
                endDateTable: element.end.slice(5),
                color: color,
                type: element.type,
            }
        })
        runInAction(async () => {
            this.vacations = await dataSet;
        });
    };

    get vacationsForTable() {
        let vacations = [];
        for (const day of this.vacations) {
            if (!day.id)
                break;

            if (new Date(day.startDate).getFullYear() === this.year || new Date(day.endDate).getFullYear() === this.year) {
                day.key = day.id;
                vacations.push(day);
            }
        }
        return vacations;
    };

    deleteVacation = (e) => {
        let params = new URLSearchParams({
            year: new Date(e.startDate).getFullYear(),
        });
        if (!e.id && (new Date(e.startDate).getDay() % 6 === 0)) {
            let message = "Weekends cannot be deleted!";
            let type = false;
            this.openNotificationWithIcon(type, message);
        } else {
            if (window.confirm("Delete vacation?")) {
                fetch('/api/node/v1/vacation/delete/' + e.id + "?" + params)
                    .then(response => response.json())
                    .then(response => {
                        this.openNotificationWithIcon(response.success, response.message);
                        this.getVacations(moment(this.startVacation).year())
                    })
                    .catch(err => console.error(err));
            }
        }
    };

    openNotificationWithIcon = (type, message) => {
        notification[type ? "success" : "error"]({
            message: message,
            placement: 'bottomRight'
        });
    };

    openWithUpdate = (e) => {
        if (!e.id && (new Date(e.startDate).getDay() % 6 === 0)) {
            let message = "Weekends cannot be updated!";
            let type = false;
            this.openNotificationWithIcon(type, message);
        } else {
            runInAction(() => {
                this.updateVacation = true;
                this.startVacation = e.startDate;
                this.endVacation = e.endDate;
                this.descriptionVacation = e.name;
                this.idVacation = e.id;
                this.typeVacation = e.type;
            });
            this.openModalVacation();
        }
    };

    saveVacation = () => {
        let req = this.updateVacation ? "/api/node/v1/vacation/update" : "/api/node/v1/vacation/save_new";
        let data = {
            start: this.startVacation,
            end: this.endVacation,
            desc: this.descriptionVacation,
            type: this.typeVacation,
            id: this.idVacation
        };

        runInAction(() => {
            this.loadingSaveButton = true;
        });

        axios.post(req, data)
            .then(response => {
                this.openNotificationWithIcon(response.data.success, response.data.message);
                this.getVacations(moment(this.startVacation).year())
                runInAction(() => {
                    this.loadingSaveButton = false;
                });
                if (response.data.success)
                    this.closeModalVacation();
            })
            .catch(error => { console.error(error) });
    };

    createOnClick = async (e) => {
        if (e.events.length === 0) {
            let date = await moment(e.date).format('YYYY-MM-DD');
            runInAction(() => {
                this.startVacation = date;
                this.endVacation = date;
                this.typeVacation = 'holiday';
                this.isModalVacationOpen = true
            });
        }
    }
}

export default CalendarStore;
