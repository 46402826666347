import { makeAutoObservable } from 'mobx'
import dayjs from 'dayjs'
import api from '../utils/axiosAuthInterceptor'
const axios = api

class PartsAnalyticsStore {
  //General analytics
  generalAnalyticsResponseData = {}

  generalAnalytics = {
    parameter: [],
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
    period: 'day',
    USD: '1',
    CNY: '1',
    currency: 'CNY',
    chartOption: '',
  }

  generalSelectValue = ''

  generalAnalyticsParameters = [
    {
      key: '1',
      value: 'quantity',
      label: 'w_quantity',
    },
    {
      key: '2',
      value: 'applications',
      label: 'w_number _of_application',
    },
    {
      key: '3',
      value: 'shipments',
      label: 'w_number_shipment',
    },
    {
      key: '4',
      value: 'delay',
      label: 'w_average_delay_for_the_selected_period',
    },
    {
      key: '5',
      value: 'revenue',
      label: 'w_the_total_amount_of_orders_for_the_selected_period',
    },
  ]

  //Managers Analytics
  managersAnalyticsResponseData = {}
  managersAnalyticsRequestData = {}
  parameterFromManagersTable
  dataFromApplicationsChart
  dataFromDelayChart
  managersChartsData = []
  managersSelectValue = 'all'
  dataFromManagersChart
  allManagers
  managersChartOptions = [
    {
      key: '1',
      value: 'all',
      label: 'w_all_managers',
    },
  ]

  managersAnalytics = {
    parameter: '',
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
    period: 'day',
    chartOption: '',
  }

  managersParameters = [
    {
      key: '1',
      value: 'applications',
      label: 'w_by_the_number_of_processed_applications',
    },
    {
      key: '2',
      value: 'delay',
      label: 'w_in_average_delay_time',
    },
  ]

  //Paretto analysis
  parettoAnalysisResponseData = {}
  parettoAnalysisRequestData = {}

  parettoAnalysis = {
    parameter: '',
    startDate: dayjs().subtract(1, 'month'),
    endDate: dayjs(),
    period: 'day',
    USD: '1',
    CNY: '1',
    currency: 'CNY',
    chartOption: '',
  }

  parettoAnalysisParameters = [
    {
      key: '1',
      value: 'contracts',
      label: 'w_top_20%_of_orders,_with_the_largest_order_amount',
    },
    {
      key: '2',
      value: 'sku',
      label: 'w_top_20%_sku,_with_the_highest_amount_for_a_specific_sku',
    },
  ]

  //Suppliers statistics
  suppliersStatisticsResponseData = {}
  suppliersStatisticsRequestData = {}

  suppliersStatistics = {
    parameter: [],
    factories: '',
    startDate: dayjs().subtract(1, 'year'),
    endDate: dayjs(),
    period: 'day',
    USD: '1',
    CNY: '1',
    currency: 'CNY',
    chartOption: '',
  }

  suppliersSelectValue = ''

  suppliersStatisticsParameters = [
    {
      key: '1',
      value: 'total orders',
      label: 'w_total_number_of_orders',
    },
    {
      key: '2',
      value: 'total amount of spare parts orders',
      label: 'w_total_amount_of_spare_parts_orders,_both_currencies_to_cny',
    },
    {
      key: '3',
      value: 'sum of orders for the main product',
      label: 'w_amount_of_orders_for_the_main_product,_both_currencies_cny',
    },
    {
      key: '4',
      value: 'percent of spare parts orders to goods orders',
      label: 'w_%_of_spare_parts_orders_to_goods_orders',
    },
    {
      key: '5',
      value: 'average delay',
      label: 'w_average_delay_per_month_for_spare_parts',
    },
  ]

  //Comparative analysis

  comparativeAnalysisResponseData = {}
  comparativeAnalysisRequestData = {}

  comparativeAnalysis = {
    parameter: [],
    firstFactories: '',
    secondFactories: '',
    startDate: dayjs().subtract(1, 'year'),
    endDate: dayjs(),
    period: 'day',
    USD: '1',
    CNY: '1',
    currency: 'CNY',
    chartOption: '',
  }

  comparativeSelectValue = ''

  comparativeAnalysisParameters = [
    {
      key: '1',
      value: 'total orders',
      label: 'w_total_number_of_orders',
    },
    {
      key: '2',
      value: 'total amount of spare parts orders',
      label: 'w_total_amount_of_spare_parts_orders',
    },
    {
      key: '3',
      value: 'sum of orders for the main product',
      label: 'w_amount_of_orders_for_the_main_produc',
    },
    {
      key: '4',
      value: 'percent of spare parts orders to goods orders',
      label: 'w_%_of_spare_parts_orders_to_goods_orders',
    },
    {
      key: '5',
      value: 'average delay',
      label: 'w_average_delay_per_month_for_spare_parts',
    },
  ]

  //Common
  isLoading = false

  factoriesOptions = []

  currentTabKey = '1'

  periodOptions = [
    { key: 1, value: 'day', label: 'w_day' },
    { key: 2, value: 'week', label: 'w_week' },
    { key: 3, value: 'month', label: 'w_month' },
    { key: 4, value: 'trimester', label: 'w_trimester' },
    { key: 5, value: 'year', label: 'w_year' },
  ]

  constructor() {
    makeAutoObservable(this)
  }

  //Common methods

  fetchSuppliers = async () => {
    try {
      this.isLoading = true

      if (this.factoriesOptions.length === 0) {
        const response = await fetch('/api/node/v1/factories/all')
        const data = await response.json()
        if (!data.data) {
          this.factoriesOptions = []
        } else {
          this.factoriesOptions = data.data.map((el) => {
            return {
              key: el.id,
              value: el.abbreviation,
              label: el.abbreviation,
            }
          })
        }
      }
    } catch (error) {
      this.handleError(error)
    } finally {
      this.isLoading = false
    }
  }

  setCurrentTabKey = (key) => {
    this.currentTabKey = key
  }

  clearFilters = () => {
    switch (this.currentTabKey) {
      case '1':
        this.generalAnalytics.parameter = []
        this.generalAnalytics.period = 'day'
        this.generalAnalytics.USD = '1'
        this.generalAnalytics.CNY = '1'
        this.generalAnalytics.currency = 'CNY'
        this.generalSelectValue = ''
        this.generalAnalytics.chartOption = ''

        break
      case '2':
        this.managersAnalytics.parameter = []
        this.managersAnalytics.period = 'day'
        this.managersSelectValue = ''
        this.managersAnalytics.chartOption = ''
        break
      case '3':
        this.parettoAnalysis.parameter = []
        this.parettoAnalysis.period = 'day'
        this.parettoAnalysis.USD = '1'
        this.parettoAnalysis.CNY = '1'
        this.parettoAnalysis.currency = 'CNY'
        this.parettoAnalysis.chartOption = ''
        break
      case '4':
        this.suppliersStatistics.parameter = []
        this.suppliersStatistics.factories = ''
        this.suppliersStatistics.period = 'day'
        this.suppliersStatistics.USD = '1'
        this.suppliersStatistics.CNY = '1'
        this.suppliersStatistics.currency = 'CNY'
        this.suppliersStatistics.chartOption = ''
        break
      case '5':
        this.comparativeAnalysis.parameter = []
        this.comparativeAnalysis.firstFactories = ''
        this.comparativeAnalysis.secondFactories = ''
        this.comparativeAnalysis.period = 'day'
        this.comparativeAnalysis.USD = '1'
        this.comparativeAnalysis.CNY = '1'
        this.comparativeAnalysis.currency = 'CNY'
        this.comparativeAnalysis.chartOption = ''
        break
    }
  }

  setCurrencyUSD = (type, value) => {
    switch (type) {
      case 'general':
        this.generalAnalytics.USD = value
        break
      case 'paretto':
        this.parettoAnalysis.USD = value
        break
      case 'suppliers':
        this.suppliersStatistics.USD = value
        break
      case 'comparative':
        this.comparativeAnalysis.USD = value
        break
    }
  }
  setCurrency = (type, value) => {
    switch (type) {
      case 'general':
        this.generalAnalytics.currency = value
        break
      case 'paretto':
        this.parettoAnalysis.currency = value
        break
      case 'suppliers':
        this.suppliersStatistics.currency = value
        break
      case 'comparative':
        this.comparativeAnalysis.currency = value
        break
    }
  }

  setCurrencyFromRender(type, value) {
    switch (type) {
      case 'general':
        this.generalAnalyticsRequestData.currency = value
        break
      case 'paretto':
        this.parettoAnalysisRequestData.currency = value
        break
      case 'suppliers':
        this.suppliersStatisticsRequestData.currency = value
        break
      case 'comparative':
        this.comparativeAnalysisRequestData.currency = value
        break
    }
  }

  setFilterRange = (type, values) => {
    type === 'general'
      ? (type = this.generalAnalytics)
      : type === 'managers'
      ? (type = this.managersAnalytics)
      : type === 'paretto'
      ? (type = this.parettoAnalysis)
      : type === 'suppliers'
      ? (type = this.suppliersStatistics)
      : (type = this.comparativeAnalysis)

    if (values === null) {
      type.startDate = dayjs().subtract(1, 'year')
      type.endDate = dayjs()
    } else {
      type.startDate = values[0]
      type.endDate = values[1]
    }
  }

  handleError = (error) => {
    if (error.response) {
      const errorMessage = error.response.data.message
      console.log(errorMessage)
    } else {
      console.log(error.message)
    }
  }

  //General analytics methods
  clearGeneralAnalyticsResponseData() {
    this.generalAnalyticsResponseData = {}
  }

  setGeneralAnalyticsValue(type, values) {
    this.generalAnalytics[type] = values
  }

  setGeneralAnalyticsChartOption(value) {
    this.generalAnalytics.chartOption = value
  }

  setGeneralAnalyticsChartOptionFromRender(value) {
    this.generalAnalyticsRequestData.chartOption = value
    this.generalSelectValue = value
  }

  fetchGeneralAnalyticsData = async () => {
    try {
      this.generalAnalyticsRequestData = {
        parameter: this.generalAnalytics.parameter,
        period: this.generalAnalytics.period,
        USD: this.generalAnalytics.USD,
        CNY: this.generalAnalytics.CNY,
        chartOption: this.generalAnalytics.chartOption,
        currency: this.generalAnalytics.currency,
      }

      this.generalSelectValue = this.generalAnalytics.chartOption

      this.isLoading = true
      const response = await axios.get(
        `/api/node/v1/analytics/common?startDate=${this.generalAnalytics.startDate.format(
          'YYYY-MM-DD'
        )}&endDate=${this.generalAnalytics.endDate
          .add(1, 'day')
          .format('YYYY-MM-DD')}&USD=${this.generalAnalytics.USD}&CNY=${
          this.generalAnalytics.CNY
        }&period=${this.generalAnalytics.period}&w_currency=${
          this.generalAnalytics.currency
        }`
      )

      if (response.data.success) {
        this.generalAnalyticsResponseData.total = response.data.result.total
        this.generalAnalyticsResponseData.rows = response.data.result.rows
      }
    } catch (error) {
      this.handleError(error)
    } finally {
      this.isLoading = false
    }
  }

  //Managers analytics methods
  clearManagersAnalyticsResponseData() {
    this.managersAnalyticsResponseData = {}
  }

  setManagerslAnalyticsValue(type, values) {
    if (type === 'period' || type === 'chartOption') {
      this.managersAnalytics[type] = values
      return
    }

    switch (values) {
      case 'applications':
        this.managersAnalytics[type] =
          'w_by_the_number_of_processed_applications'
        break
      case 'delay':
        this.managersAnalytics[type] = 'w_in_average_delay_time'
        break
    }
  }

  setDataFromManagersApplicationsChart(data) {
    this.dataFromApplicationsChart = data
    this.dataFromManagersChart = [...this.dataFromApplicationsChart]
    this.allManagers = [...this.dataFromManagersChart]
  }

  setFilteredDataFromManagersChart(value) {
    if (value === 'all') {
      this.managersChartsData = [...this.managersAnalyticsResponseData.rows]
      this.dataFromManagersChart = [...this.allManagers]
    } else {
      this.managersChartsData = [...this.managersAnalyticsResponseData.rows]
      this.managersChartsData = this.managersChartsData.filter(
        (el) => el.user.name === value
      )

      this.dataFromManagersChart = [...this.dataFromApplicationsChart].filter(
        (el) => {
          if (el.key !== 'total row') {
            return el.parameter === value
          }
          return el
        }
      )
    }
  }

  setManagersSelectValue(value) {
    this.managersSelectValue = value
  }

  filterManagersChartData(value) {
    this.managersChartsData = [...this.managersAnalyticsResponseData.rows]
    this.dataFromManagersChart = [...this.dataFromApplicationsChart]
    this.allManagers = [...this.dataFromManagersChart]

    if (value === null) {
      this.managersChartOptions = [
        {
          key: '1',
          value: 'all',
          label: 'w_all_managers',
        },
      ]

      this.dataFromManagersChart.forEach((el, index) => {
        if (el.key === 'total row') {
          return
        }
        this.managersChartOptions.push({
          key: index + 2,
          label: el.parameter,
          value: el.parameter,
        })
      })
      return
    }

    this.managersChartsData = this.managersChartsData.filter((el) => {
      return value.includes(el.user.name)
    })

    this.dataFromManagersChart = this.dataFromManagersChart.filter((el) => {
      if (el.key === 'total row') {
        return el
      }
      return value.includes(el.parameter)
    })

    this.allManagers = [...this.dataFromManagersChart]

    this.managersChartOptions = [
      {
        key: '1',
        value: 'all',
        label: 'w_all_managers',
      },
    ]

    this.dataFromManagersChart.forEach((el, index) => {
      if (el.key === 'total row') {
        return
      }
      this.managersChartOptions.push({
        key: index + 2,
        label: el.parameter,
        value: el.parameter,
      })
    })
  }

  fetchManagersAnalyticsData = async () => {
    try {
      this.isLoading = true
      this.managersAnalyticsResponseData = {}

      this.managersAnalyticsRequestData = {
        parameter: this.managersAnalytics.parameter,
        period: this.managersAnalytics.period,
        tableHeader: this.managersAnalytics.parameter,
        chartOption: this.managersAnalytics.chartOption,
      }

      if (
        this.managersAnalytics.parameter ===
        'w_by_the_number_of_processed_applications'
      ) {
        this.parameterFromManagersTable = 'applications'
      } else {
        this.parameterFromManagersTable = 'delay'
      }

      const response = await axios.get(
        `/api/node/v1/analytics/managers?startDate=${this.managersAnalytics.startDate.format(
          'YYYY-MM-DD'
        )}&endDate=${this.managersAnalytics.endDate.format(
          'YYYY-MM-DD'
        )}&period=${this.managersAnalyticsRequestData.period}&w_analytics_by=${
          this.managersAnalyticsRequestData.parameter
        }`
      )

      if (response.data.success) {
        this.managersAnalyticsResponseData.total = response.data.result.total
        this.managersAnalyticsResponseData.rows = response.data.result.rows

        this.managersChartsData = [...this.managersAnalyticsResponseData.rows]

        this.managersChartOptions = [
          {
            key: '1',
            value: 'all',
            label: 'w_all_managers',
          },
        ]
        Object.values(this.managersAnalyticsResponseData.total).forEach(
          (el, index) => {
            this.managersChartOptions.push({
              key: index + 2,
              label: el.user.name,
              value: el.user.name,
            })
          }
        )
      }
    } catch (error) {
      this.handleError(error)
    } finally {
      this.isLoading = false
    }
  }

  // Paretto analysis methods
  clearParettoAnalysisResponseData() {
    this.parettoAnalysisResponseData = {}
  }

  setParettoAnalysisValue(type, values) {
    this.parettoAnalysis[type] = values
  }

  setParettoAnalysisChartOption(value) {
    this.parettoAnalysis.chartOption = value
  }

  setParettoAnalysisChartOptionFromRender(value) {
    this.parettoAnalysisRequestData.chartOption = value
  }

  fetchParettoAnalysisData = async () => {
    try {
      this.parettoAnalysisResponseData = {}

      this.parettoAnalysisRequestData = {
        parameter: this.parettoAnalysis.parameter,
        period: this.parettoAnalysis.period,
        USD: this.parettoAnalysis.USD,
        CNY: this.parettoAnalysis.CNY,
        chartOption: this.parettoAnalysis.chartOption,
        currency: this.parettoAnalysis.currency,
      }

      this.isLoading = true

      const response = await axios.get(
        `/api/node/v1/analytics/pareto?startDate=${this.parettoAnalysis.startDate.format(
          'YYYY-MM-DD'
        )}&endDate=${this.parettoAnalysis.endDate.format('YYYY-MM-DD')}&USD=${
          this.parettoAnalysis.USD
        }&CNY=${this.parettoAnalysis.CNY}&w_analytics_by=${
          this.parettoAnalysis.parameter
        }&w_currency=${this.parettoAnalysis.currency}`
      )

      if (response.data.success) {
        this.parettoAnalysisResponseData.total = response.data.result.total
        this.parettoAnalysisResponseData.rows = response.data.result.rows
      }
    } catch (error) {
      this.handleError(error)
    } finally {
      this.isLoading = false
    }
  }

  //Suppliers statistics methods
  setSuppliersStatisticsValue(type, values) {
    this.suppliersStatistics[type] = values
  }

  setSuppliersStatisticsChartOption(value) {
    this.suppliersStatistics.chartOption = value
  }

  setSuppliersStatisticsChartOptionFromRender(value) {
    this.suppliersStatisticsRequestData.chartOption = value
    this.suppliersSelectValue = value
  }

  //Comparative analysis methods
  setComparativeAnalysisValue(type, values) {
    this.comparativeAnalysis[type] = values
  }

  setComparativeAnalysisChartOption(value) {
    this.comparativeAnalysis.chartOption = value
  }

  setComparativeAnalysisChartOptionFromRender(value) {
    this.comparativeAnalysisRequestData.chartOption = value
    this.comparativeSelectValue = value
  }
}

export default PartsAnalyticsStore
