import { makeAutoObservable } from 'mobx'
import download from 'downloadjs'
import { notification } from 'antd'
import i18next from 'i18next'
import api from '../utils/axiosAuthInterceptor'

const axios = api
const wsAddress = process.env.REACT_APP_WS_SERVER_ADDRESS
const wsType = process.env.REACT_APP_WS_TYPE
const dayjs = require('dayjs')

class IssuesStore {
  ws = null
  wsChat = null
  isAdmin = false
  issuesList = []
  issueFiles = []
  systemAdminId
  headOfSystemAdminsId
  typeOfAdmin
  systemAdminsArray = []
  reportAdminArray = []
  whichKindOfAdmin
  engineerPostId
  engineerHeadPostId
  webAdminId
  typeOfEngineer
  userOnline = {}
  dateOfInProgressStatus = ''
  disableButton = false
  dataIndex = {}
  departments = []
  sections = []
  priorities = []
  webAdminMode = false
  lengthCounter
  soundNotification
  wsIssuesData = null
  barData = []
  newIssuesStatCount = 0
  doneIssuesStatCount = 0
  checkConnection = false
  toChangeNewTaskCount = 0
  newIssuesForWeek = 0
  doneIssuesForWeek = 0
  newTaskArr = []
  toPlay = false
  editSelfComment = false
  toEditDeadline = false
  testers = []
  issueListFiltered = []
  downloadFileProgres = []

  currentRequest = {
    history: false,
    currentTab: null,
  }

  filtersOptions = []
  columnFilters = {
    id: {
      state: false,
      value: null,
    },
    created_at: {
      state: false,
      value: null,
    },
    executor: {
      state: false,
      value: null,
    },
    creator: {
      state: false,
      value: null,
    },
    priority: {
      state: false,
      value: null,
    },
    smart_section: {
      state: false,
      value: null,
    },
    status: {
      state: false,
      value: null,
    },
  }
  webAdminColumnFilters = {
    id: {
      state: false,
      value: null,
    },
    created_at: {
      state: false,
      value: null,
    },
    executor: {
      state: false,
      value: null,
    },
    creator: {
      state: false,
      value: null,
    },
    priority: {
      state: false,
      value: null,
    },
    smart_section: {
      state: false,
      value: null,
    },
    status: {
      state: false,
      value: null,
    },
    tester: {
      state: false,
      value: null,
    },
  }
  currentTab = null
  chatOpened = false
  chat = []
  statusMapping = {
    create: 'w_issue_created',
    work: 'w_issue_work',
    waiting: 'w_issue_wait',
    end: 'w_issue_end',
    paused: 'w_pause',
    withdrawn: 'w_issue_withdrawn',
    testing: 'w_being_tested',
  }

  // newRequests
  paginationInfo = {
    page: 1,
    pageSize: 20,
  }

  serverPagination = {}

  filters = []

  role = 'user'

  constructor() {
    makeAutoObservable(this)
  }

  updateFilters = (key, value) => {
    this.columnFilters[key].value = value
    this.columnFilters[key].state = !!value
  }

  updateWebAdminFilters = (key, value) => {
    this.webAdminColumnFilters[key].value = value
    this.webAdminColumnFilters[key].state = !!value
  }

  calcProportion = (a, b) => {
    let percent = Math.ceil((b / a) * 100)
    return percent
  }
  correctTime = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD HH:mm')
  }
  timeWithoutHours = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD')
  }
  checkUserRole = (issue) => {
    const roles = {
      [issue.tester_id]: 'tester',
      [issue.executor_id]: 'executor',
      [issue.user_id]: 'creator',
    }
    return roles[this.userOnline.userId]
  }
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  connectToWebSockets = async () => {
    let tvToken = 'tvToken'
    if (!this.ws) {
      return new Promise((resolve, reject) => {
        let server = new WebSocket(
          `${wsType}://${wsAddress}/sockjs-node/${tvToken}`
        )
        server.onopen = function () {
          console.log('Conntected')

          resolve(server)
        }
        server.onclose = function (err) {
          console.log('Disconeted')
          reject(err)
          console.log(err)
          this.checkConnection = false
        }

        server.onmessage = (event) => {
          const toClient = JSON.parse(event.data)
          if (toClient.type === 'issuesLength') {
            this.wsIssuesData = toClient.data
          }
          if (toClient.type === 'issuesStat') {
            this.wsIssuesData = toClient.data.adminData
            if (this.wsIssuesData) {
              this.barData = Object.keys(this?.wsIssuesData).map((key) => {
                return {
                  id: this?.wsIssuesData[key]?.id,
                  type: `${this?.wsIssuesData[key]?.name} ${this?.wsIssuesData[key]?.surname}`,
                  sales: this?.wsIssuesData[key]?.values?.length || 0,
                }
              })
              this.newIssuesStatCount = toClient.data.limits.newIssues.length
              this.doneIssuesStatCount = toClient.data.limits.doneIssues.length
              this.newIssuesForWeek =
                toClient.data.speedometer.newIssuesForWeek.length
              this.doneIssuesForWeek =
                toClient.data.speedometer.doneIssuesForWeek.length
              this.newTaskArr = toClient.data.lastTasks.sort(
                (a, b) => b.id - a.id
              )
            }
          }
          if (toClient.type === 'changeStat') {
            this.newIssuesStatCount =
              toClient.data.toPlus + this.newIssuesStatCount
            this.newTaskArr.push(toClient.data.lastTask)
            this.newTaskArr.sort((a, b) => b.id - a.id)
            if (this.newTaskArr.length > 5) {
              this.newTaskArr.pop()
            }
            this.toPlay = true
          }
          if (toClient.type === 'plusIssueEnd') {
            this.doneIssuesStatCount =
              toClient.data.toPlus + this.doneIssuesStatCount
            this.doneIssuesForWeek =
              toClient.data.toPlus + this.doneIssuesForWeek
            this.barData = this.barData.map((el) =>
              el.id === toClient.data.adminId
                ? { ...el, sales: el.sales + toClient.data.toPlus }
                : el
            )
          }
          if (toClient.type === 'executorNominated') {
            this.newTaskArr = toClient.data.lastTasks.sort(
              (a, b) => b.id - a.id
            )
            this.toPlay = false
          }
          if (toClient.type === 'taskRecalled') {
            this.newTaskArr = toClient.data.lastTasks.sort(
              (a, b) => b.id - a.id
            )
          }
        }
      })
    }
  }

  getForNewIssuePage = async () => {
    const data = await axios.get('/api/node/v1/issues/newIssueData')
    if (data) {
      this.departments = data.data.data.deps
      this.userOnline = data.data.data.userOnline
      this.sections = data.data.data.sections
      this.priorities = data.data.data.priorities
    }
    if (this.userOnline.userDepartment !== 7) {
      this.departments = data.data.data.deps.filter((el) => el.id !== 3)
    }
  }
  saveIssue = async (issueBody, issueFiles) => {
    const { data } = await axios.post('/api/node/v1/issues/newIssue', issueBody)
    if (data) {
      const { data: isSuccesed } = await axios.post(
        `/api/node/v1/issues/upload/issues/${data}`,
        issueFiles,
        {
          params: {
            id: data,
          },
        }
      )
      if (isSuccesed) {
        return true
      }
    }
  }

  getFilesForModal = async (id) => {
    const { data } = await axios.get(`/api/node/v1/issues/issueModal/${id}`, {
      params: { id },
    })

    if (data.issueFilesForModal || data.sysAdmins) {
      this.issueFiles = data?.issueFilesForModal
      this.systemAdminsArray = data?.sysAdmins
      this.sections = data?.getSections
      this.testers = data.testers || []
    } else {
      this.issueFiles = data.issueFiles
      this.testers = data.testers || []
    }
  }

  updateExecutor = async (exId, id) => {
    const temp = [...this.issuesList]
    const data = await axios.put(
      `/api/node/v1/issues/issueModal/executor/${id}`,
      exId,
      {
        params: { id },
      }
    )
    if (data) {
      const el = temp.find((el) => el.id === id)
      el.executor_id = exId
      this.issuesList = temp
      await this.getData()
      return true
    }
  }

  updateStatusFromCreate = async (id, decide) => {
    const data = decide
      ? await axios.post(
          `/api/node/v1/issues/issueModal/executor/${id}`,
          decide,
          {
            params: { id },
          }
        )
      : await axios.post(
          `/api/node/v1/issues/issueModal/executor/${id}`,
          { decide },
          {
            params: { id },
          }
        )
    if (data) {
      await this.getData()
      this.disableButton = false
      return true
    }
  }

  updateStatustFromWork = async (id, comment, issueFiles) => {
    const { data } = comment
      ? await axios.patch(
          `/api/node/v1/issues/issueModal/toWaitOrEnd/${id}`,
          comment,
          {
            params: { id },
          }
        )
      : await axios.patch(
          `/api/node/v1/issues/issueModal/toWaitOrEnd/${id}`,
          {},
          {
            params: { id },
          }
        )
    if (data) {
      await this.getData()
      this.disableButton = false
    }
  }

  getListOfSysAdmins = async () => {
    const { data } = await axios.get('/api/node/v1/issues/issueReportModal')

    if (data) {
      this.reportAdminArray = data
    }
  }

  exportDataToExcel = async (values) => {
    const data = await axios({
      url: '/api/node/v1/issues/issueReportModal',
      responseType: 'blob',
      method: 'post',
      data: values,
    })

    if (data) {
      const filename = `Issues report ${dayjs().format('DD.MM.YYYY')}.xlsx`
      download(data.data, filename)
    }
  }

  downloadFile = async (file, type) => {
    const data = await axios.get('/api/node/v1/issues/issueFile', {
      responseType: 'blob',
      params: { id: file.id, type: type },
    })

    download(data.data, file.original_name)
    if (data) {
      return data
    }
  }

  getFile = async (file, link) => {
    try {
      const fileObject = {
        fileId: file.id,
        type: file.type,
        filename: file.original_name,
        progress: 0,
        status: false,
        data: [],
      }
      this.downloadFileProgres.push(Object.assign({}, fileObject))

      const data = await axios.get(`${link}`, {
        responseType: 'blob',
        params: { id: file.id },
        onDownloadProgress: (progressEvent) => {
          const loaded = progressEvent.loaded
          const total = progressEvent.total
          const progressPercentage = Math.round((loaded / total) * 100)
          this.load = progressPercentage

          // Находим объект файла в массиве и обновляем его прогресс
          const fileIndex = this.downloadFileProgres.findIndex(
            (el) => el.fileId === file.id
          )

          if (fileIndex !== -1) {
            this.downloadFileProgres[fileIndex].progress = progressPercentage
            this.downloadFileProgres[fileIndex].status = true
          }
          this.downloadFileProgres = [...this.downloadFileProgres]
        },
      })
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].data = data
        this.downloadFileProgres[fileIndex].status = false
      }
      return data
    } catch (error) {
      console.error(`Error downloading file`)
      // Устанавливаем статус файла в "exception"
      const fileIndex = this.downloadFileProgres.findIndex(
        (el) => el.fileId === file.id
      )
      if (fileIndex !== -1) {
        this.downloadFileProgres[fileIndex].status = 'exception'
      }
      this.downloadFileProgres = [...this.downloadFileProgres]
    }
  }

  downloadAllFiles = async (issueId) => {
    const data = await axios.get(
      `/api/node/v1/issues/issueAllFiles/${issueId}`,
      {
        responseType: 'blob',
      }
    )

    download(data.data, `№${issueId}`)
  }

  updateToWithdrawnStatus = async (id) => {
    await axios.post(`/api/node/v1/issues/issueWithdrawn/${id}`)
  }

  toPause = async (id, comment) => {
    const data = comment
      ? await axios.post(`/api/node/v1/issues/toPause/${id}`, { comment })
      : await axios.post(`/api/node/v1/issues/toPause/${id}`)
    if (data) {
      await this.getData()
      this.disableButton = false
    }
  }

  toTesting = async (data) => {
    const res = await axios.patch(
      `/api/node/v1/issues/toTesting/${data.id}`,
      data
    )
    if (res) {
      await this.getData()
      this.disableButton = false
    }
  }

  fromTesting = async (data) => {
    const res = await axios.patch(
      `/api/node/v1/issues/fromTesting/${data.id}`,
      data
    )
    if (res) {
      await this.getData()
      this.disableButton = false
    }
  }

  saveSelfComment = async (id, comment) => {
    const data = await axios.post(`/api/node/v1/issues/selfComment/${id}`, {
      comment,
    })
    if (data) {
      await this.getData()
      this.disableButton = false
    }
  }

  backToWork = async (id) => {
    const data = await axios.patch(`/api/node/v1/issues/toWork/${id}`)
    if (data) await this.getData()
  }

  changeDepToWebAdmins = async (id) => {
    const data = await axios.patch(`/api/node/v1/issues/toWebAdmins/${id}`)
    if (data) await this.getData()
  }
  chacngeDepToIt = async (id) => {
    const data = await axios.patch(`/api/node/v1/issues/toItDep/${id}`)
    if (data) await this.getData()
  }
  moduleUpdate = async (id, moduleId) => {
    const data = await axios.patch(`/api/node/v1/issues/module/${id}`, {
      moduleId,
    })
    if (data) await this.getData()
  }

  //IssuesStat

  getInfo = async () => {
    this.checkConnection = true
    let server = await this.connectToWebSockets()
    this.ws = server
    await axios.get('/api/node/v1/issues/stat')
  }

  updateDeadline = async (id, deadline) => {
    const data = await axios.patch(
      `/api/node/v1/issues/issueModal/deadline/${id}`,
      deadline
    )
    if (data) await this.getData()
  }

  chatMessages = async (id) => {
    try {
      const result = await axios.get(
        `/api/node/v1/issues/issueModal/chat/${id}`
      )
      this.chat = result.data.data.messages
    } catch (e) {
      console.log(e)
    }
  }
  sendChatMessage = async (message, issue_id) => {
    try {
      await axios.post(
        `/api/node/v1/issues/issueModal/chat/message/issues/${issue_id}`,
        message
      )
    } catch (e) {
      console.log(e)
      this.openNotificationWithIcon('error', 'Error sending message')
    }
  }

  wsChatClose = async () => {
    if (this.wsChat) {
      this.wsChat.close()
      this.checkConnection = false
    }
  }

  connectChatToWS = async (issue_id) => {
    let id = `IssueChat-${issue_id}-${this.userOnline.userId}`

    if (!this.wsChat || this.wsChat.readyState == 3) {
      return new Promise((resolve, reject) => {
        let server = new WebSocket(`${wsType}://${wsAddress}/sockjs-node/${id}`)

        server.onopen = () => {
          console.log('Connected')
          resolve(server)
        }

        server.onclose = (err) => {
          console.log('Disconnected')
          reject(err)
          console.log(err)
        }

        server.onmessage = (event) => {
          const toClient = JSON.parse(event.data)
          if (toClient.type === 'newMessage') {
            this.chat = this.chat.concat(toClient.data)
          }
        }
      })
    } else {
      console.log(
        'Previous WS has not been disconnected',
        this.wsChat,
        this.wsChat.readyState
      )
    }
  }

  chatConnect = async (issue_id) => {
    if (this.wsChat) {
      await this.wsChatClose()
    }
    console.log('connect')

    let server = await this.connectChatToWS(issue_id)
    this.wsChat = server
  }

  //New requests

  setBaseOptions = () => {
    this.paginationInfo.page = 1
    this.paginationInfo.pageSize = 20
  }

  setRequestOptions = (key, value) => {
    this.currentRequest[key] = value
  }

  changeTab = (tabId) => {
    this.setBaseOptions()
    this.setRequestOptions('currentTab', tabId)
    this.getData()
  }

  tablePropsChange = async (props) => {
    this.paginationInfo.page = props.current
    this.paginationInfo.pageSize = props.pageSize
    this.getData()
  }

  setWebAdminMode = async (active) => {
    this.webAdminMode = active

    if (active) {
      this.setRequestOptions('currentTab', 'w_new_issues')
      this.setRequestOptions('history', null)
    } else {
      this.setRequestOptions('currentTab', null)
      this.setRequestOptions('history', false)
    }
    this.setFilters(null, null)
    this.getData()
  }

  setFilters = async (name, value) => {
    if (name == null) {
      this.filters = []
    } else {
      if (value == null) {
        delete this.filters[name]
      } else {
        this.filters[name] = value
        if (name == 'created_at') {
          this.getData()
        }
      }
    }
  }

  getData = async () => {
    let url = `/api/node/v1/issues/issues?admin=${this.webAdminMode}&page=${this.paginationInfo.page}&pageSize=${this.paginationInfo.pageSize}`

    for (let i in this.currentRequest) {
      if (this.currentRequest[i] != null) {
        url += '&' + i + '=' + this.currentRequest[i]
      }
    }

    let queryFilters = {}

    for (let i in this.filters) {
      queryFilters[i] = this.filters[i]
    }

    url += '&filters=' + encodeURIComponent(JSON.stringify(queryFilters))

    const { data } = await axios.get(url)

    this.issuesList = data.tableData.sort((a, b) => b.id - a.id)
    this.issuesListFiltered = this.issuesList

    this.serverPagination = data.pagination
    this.userOnline = data.user
    this.filtersOptions = data.options

    if (data.typeOfAdmin == 1) {
      this.role = 'adminMain'
    } else if (data.typeOfAdmin == 25) {
      this.role = 'adminIT'
    } else if (data.typeOfAdmin == 26) {
      this.role = 'adminHeadIT'
    } else {
      this.role = 'user'
    }
  }
}

export default IssuesStore
