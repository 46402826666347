// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UnarchiveIcon_svg__1vY9N {\n  vertical-align: middle;\n}\n.UnarchiveIcon_svg__1vY9N:hover {\n  fill: #4096ff;\n}", ""]);
// Exports
exports.locals = {
	"svg": "UnarchiveIcon_svg__1vY9N"
};
module.exports = exports;
