import {makeAutoObservable} from "mobx";
import api from '../utils/axiosAuthInterceptor';
import {notification} from "antd";
import i18next from "i18next";
import download from 'downloadjs'
const axios = api
const moment = require('moment')

class AgentsStore {
  isMainLoading = false
  isEditting = false
  rowBeforeEdit = null;
  paginations = {
    page : 1,
    pageSize: 10,
    totalAgents: null
  }
  filtersParams = {
    code: {
      filtered: [],
      options: [],
    },
    name: {
      filtered: [],
      options: [],
    },
    sort: {
      sortColumn: null,
      sortOrder: null,
    },
  }
  agentsData = [];
  user = {}
 

  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  checkCode = (error) => {
    let errorMessage = error.response.data.errors?.message
    if (errorMessage?.startsWith('w_')) {
      let fullCompanyName =
        error.response.data.errors.fullCompanyName.length > 0
          ? error.response.data.errors.fullCompanyName
          : 'Agent without full company name'
      errorMessage = i18next.t(errorMessage)
      errorMessage = errorMessage.split('#').join(fullCompanyName)
      this.openNotificationWithIcon('error', `Error: ${errorMessage}`)
    } else {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    }
    this.isMainLoading = false
  }

  //Main model CRUD
  getAgents = async () => {
    try{
      this.isMainLoading = true
      const params = {
        code: this.filtersParams.code.filtered,
        name: this.filtersParams.name.filtered,
        page: this.paginations.page,
        pageSize: this.paginations.pageSize,
        sortColumn: this.filtersParams.sort.sortColumn,
        sortOrder: this.filtersParams.sort.sortOrder,
      }
      let response = await axios.get(`/api/node/v1/agents`, {params})
      this.agentsData = response.data.data.rows
      this.paginations.totalAgents = response.data.data.count
      this.filtersParams.code.options = response.data.data.agentsCodesOpt
      this.filtersParams.name.options = response.data.data.agentsNamesOpt
      this.user = response.data.data.user
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
        this.clearMainState()
    }
  }

  createAgent = async (body) => {
    try{
      this.isMainLoading = true
      await axios.post(`/api/node/v1/agents`, body)
      await this.getAgents()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.checkCode(error)
    } 
  }

  updateAgent = async (body) => {
    try{
      this.isMainLoading = true
      await axios.put(`/api/node/v1/agents`, body)
      await this.getAgents()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.checkCode(error)
    } 
  }

  deleteAgent = async (id) => {
    try{
      this.isMainLoading = true
      await axios.delete(`/api/node/v1/agents/${id}`)
      await this.getAgents()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } 
  }

  //Row operations
  

  editRow = async (index) => {
    this.rowBeforeEdit = {...this.agentsData[index]}
    this.agentsData[index].isEdit = true;
    this.isEditting = true
  }

  onFinishMainForm = async (values) => {
    if (this.rowBeforeEdit) {
      values.id = this.rowBeforeEdit.id
      await this.updateAgent(values)
    } else {
      await this.createAgent(values)
    }
  }

  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }

  addRow = () => {
    let temp = [...this.agentsData];
    this.isEditting = true
    temp.unshift({
      id: 'New',
      code: '',
      name: '',
      full_company_name: '',
      address: '',
      isEdit: true
    })
    this.agentsData = temp
  }

  cancelEdit = (index) => {
    let temp = [...this.agentsData]
    if(temp[index].id === 'New'){
      temp.splice(index, 1)
    } else {
      temp[index] = this.rowBeforeEdit
    }
    temp[index].isEdit = false
    this.agentsData = temp
    this.clearMainState()
  }

  //Other
  changePage = (e) => {
    this.paginations.page = e.current
    this.getAgents()
  }

  changeSize = (current, size) => {
    this.paginations.pageSize = size
  } 

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  clearMainState = () => {
    this.isEditting = false
    this.isMainLoading = false
    this.rowBeforeEdit = null
    this.paginations.page = 1
  }

//Filtration
  setSortOrder = (type) => {
    this.filtersParams.sort.sortOrder = type
  }

  setSortColumn = (type) => {
    this.filtersParams.sort.sortColumn = type
  }

  acceptFilter = (type, data) => {
    this.filtersParams[type].filtered = data
    this.page = 1
    this.getAgents()
  }

  onClearFilter = (type) => {
    this.filtersParams[type].filtered = []
    this.getAgents()
  }

  clearFilters = (isGetAgentsNeeded = true) => {
    for (let filter in this.filtersParams) {
      if (this.filtersParams[filter].filtered) {
        this.filtersParams[filter].filtered = []
      } else {
        this.filtersParams[filter].sortColumn = null
        this.filtersParams[filter].sortOrder = null
      }
    }
    if (isGetAgentsNeeded) {
      this.getAgents()
    }
  }

  getAgentsExcel = async () => {
    try{
      const params = {
        code: this.filtersParams.code.filtered,
        name: this.filtersParams.name.filtered,
        sortColumn: this.filtersParams.sort.sortColumn,
        sortOrder: this.filtersParams.sort.sortOrder,
      }
  
      const response = await axios({
        url: '/api/node/v1/agents/excel',
        responseType: 'blob',
        method: 'get',
        params
      })
      const blob = await response.data
      download(blob, `Agents ${moment().format('DD.MM.YYYY HH:mm:ss')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }

}

export default AgentsStore
