// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ArchiveIcon_svg__1jtWh {\n  vertical-align: middle;\n}\n.ArchiveIcon_svg__1jtWh:hover {\n  fill: #4096ff;\n}", ""]);
// Exports
exports.locals = {
	"svg": "ArchiveIcon_svg__1jtWh"
};
module.exports = exports;
