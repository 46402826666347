import { makeAutoObservable } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
import i18next from 'i18next';
import dayjs from 'dayjs';
const download = require('downloadjs')
const axios = api;

class TranslationsStore {
    isMainLoading = false
    isEditting = false
    btnLoading = {
      idx: null,
      type: null,
    }
    paginations = {
        page : 1,
        pageSize: 10,
        totalTranslations: null
    }
    filtersParams = {
      search: '',
      comment: {
        options:[],
        filtered: []
      }
    }
    translationsData = [];
    user = {}
    rowBeforeEdit = null;

    constructor() {
        makeAutoObservable(this, {}, { deep: true });
      }

      //Main model CRUD
      getTranslations= async () => {
        try{
          this.isMainLoading = true
          const params = {
            pageSize: this.paginations.pageSize,
            page: this.paginations.page,
            search: this.filtersParams.search,
            comment: this.filtersParams.comment.filtered
          }
          let response = await axios.get(`/api/node/v1/translations`, { params })
          this.translationsData = response.data.data.rows
          this.user = response.data.data.user
          this.paginations.totalTranslations = response.data.data.count
          this.filtersParams.comment.options = response.data.data.commentOpt
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      createTranslation = async (body, index) => {
        try{
          this.isMainLoading = true
          this.btnLoading = {
            idx: index,
            type: 'save'
          }
          const {id, ...restBody} = body
          await axios.post(`/api/node/v1/translations`, restBody)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      updateTranslation= async (body, index) => {
        try{
          this.isMainLoading = true
          this.btnLoading = {
            idx: index,
            type: 'save'
          }
          await axios.put(`/api/node/v1/translations`, body)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      deleteTranslation = async (id, index) => {
        try{
          this.isMainLoading = true
          this.btnLoading = {
            idx: index,
            type: 'delete'
          }
          await axios.delete(`/api/node/v1/translations/${id}`)
          await this.getTranslations()
          this.openNotificationWithIcon('success', i18next.t('w_success'))
        } catch (error) {
          this.openNotificationWithIcon('error', i18next.t('w_error'))
        } finally {
          this.clearMainState()
        }
      }

      //Row operations

      editRow = async (index) => {
        this.rowBeforeEdit = {...this.translationsData[index]}
        this.translationsData[index].isEdit = true;
        this.isEditting = true
      }

      addRow = () => {
        let temp = [...this.translationsData];
        this.isEditting = true
        temp.unshift({
          id: 'New',
          key: 'w_',
          ru: '',
          en: '',
          cn: '',
          type: null,
          comment: '',
          isEdit: true
        })
        this.translationsData = temp
      }

      onFinishMainForm = async (values) => {
        if (this.rowBeforeEdit) {
          values.id = this.rowBeforeEdit.id
          values.type = values[`type_${this.rowBeforeEdit.id}`] ? 'category' : null
          await this.updateTranslation(values)
        } else {
          values.type = values.type_New ? 'category' : null
          await this.createTranslation(values)
        }
      }

      onFinisFailedMainForm = () => {
        this.openNotificationWithIcon(
          'error',
          i18next.t('w_form_finish_failed')
        )
      }

      cancelEdit = (index) => {
        let temp = [...this.translationsData]
        if(temp[index].id === 'New'){
          temp.splice(index, 1)
        } else {
          temp[index] = this.rowBeforeEdit
        }
        temp[index].isEdit = false
        this.translationsData = temp
        this.clearMainState()
      }



      //Other

    changePage = async (e) => {
        this.paginations.page = e.current
        await this.getTranslations()
    }

    changeSize = (current, size) => {
      this.paginations.pageSize = size
    } 

      openNotificationWithIcon = (type, message) => {
        notification[type]({
          message: message,
          placement: 'bottomRight'
        });
      };

      clearMainState = () => {
        this.isEditting = false
        this.isMainLoading = false
        this.btnLoading = {
          idx: null,
          type: null
        }
        this.rowBeforeEdit = null
      }


    setFilter = (e) => {
      this.filtersParams.search = e.target.value
    }

    onSearch = async (value) => {
      this.filtersParams.search = this.filtersParams.search .trim()
      if (value == '') {
        this.filtersParams.search  = ''
      }
      await this.getTranslations()
    }

    acceptFilter = async (type, data) => {
      this.filtersParams[type].filtered = data
      this.page = 1
      await this.getTranslations()
    }

    onClearFilter = async (type) => {
      this.filtersParams[type].filtered = []
      await this.getTranslations()
    }

    getTranslationsExcel = async () => {
      try{
        this.isExcelBtnLoading = true
        const params = {
          page: null,
          pageSize: null
        }

        const response = await axios({
          url: '/api/node/v1/translations/excel',
          responseType: 'blob',
          method: 'get',
          params
        })
        const blob = await response.data
        download(blob, `Translations ${dayjs().format('DD.MM.YYYY')}.xlsx`)
      } catch (error) {
        console.error(error)
        this.openNotificationWithIcon('error', i18next.t('w_error'))
      } finally {
        this.isExcelBtnLoading = false
      }
    }
  }

export default TranslationsStore;
