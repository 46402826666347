import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Gauge } from '@ant-design/plots'
import styles from './speedometer.module.scss'
import { useStores } from '../../../../../useStores'
const Speedometer = observer(() => {
  const store = useStores().issuesStore
  const [percent, setPercent] = useState()
  useEffect(() => {
    if (store.doneIssuesForWeek !== 0 && store.newIssuesForWeek !== 0) {
      setPercent(
        Math.round((store.doneIssuesForWeek / store.newIssuesForWeek) * 100) /
          100
      )
    } else {
      setPercent(0)
    }
  }, [store.newIssuesForWeek, store.doneIssuesForWeek])

  const config = {
    percent: percent,
   
    range: {
      ticks: [0, 1 / 2, 2 / 3, 1],
      color: ['#1890FF', '#F7B52C', '#FF2B2B'],
    }, 
    style: {
      height: '29vh'
    },

    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100
        },
        style: {
          fontSize: 25,
        },
        offset: -50
      },
    },
    statistic: {
      content: {
        style: {
          fontSize: '12px',
          lineHeight: '12px',
        },
      },
    },
  }
  return (
	<div className={styles.speedometr__wrapper}>
		<span className={styles.header}>
			Эффективность отдела за текущую неделю
		</span>
		<div>
			<Gauge {...config}/>
		</div>
		<div className={styles.footer}>
	    	<span className={styles.text}>Низкая</span>
        	<span className={styles.text}>Эффективность</span>
        	<span className={styles.text}>Высокая</span>
		</div>
	</div>
  )
})

export default Speedometer
