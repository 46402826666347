// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".datePicker_filterSelectWrapper__14Oio {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  width: 280px;\n}\n.datePicker_filterSelectWrapper__buttonsWrapper__1ZiOd {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  width: 260px;\n}\n.datePicker_filterSelectWrapper__buttonsWrapper_submit__245VB {\n  width: 125px;\n}\n.datePicker_filterSelectWrapper__buttonsWrapper_reset__2G1Y- {\n  width: 125px;\n}", ""]);
// Exports
exports.locals = {
	"filterSelectWrapper": "datePicker_filterSelectWrapper__14Oio",
	"filterSelectWrapper__buttonsWrapper": "datePicker_filterSelectWrapper__buttonsWrapper__1ZiOd",
	"filterSelectWrapper__buttonsWrapper_submit": "datePicker_filterSelectWrapper__buttonsWrapper_submit__245VB",
	"filterSelectWrapper__buttonsWrapper_reset": "datePicker_filterSelectWrapper__buttonsWrapper_reset__2G1Y-"
};
module.exports = exports;
