import { observer } from 'mobx-react-lite';
import React from 'react';
import { Column } from '@ant-design/plots';
import { useStores } from '../../../../../useStores';
import styles from './byPersonBar.module.scss';
const ByPersonBar = observer(() => {
  const store = useStores().issuesStore;
  const data = store.barData;

  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 1,
        fontSize: 30,
      },  
    },
    legend: false,
    seriesField: 'type',
    color: ['#FF0000', '#F7B52C', '#50BC74', '#1890FF',],
    style :{
      opacity: 0.75,
      fontSize: 30,
    },
    yAxis: {
      label: {
        style: {
          fontSize: 25,
        }
      }
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          fill: 'black',
          opacity: 1,
          fontSize: 20,
        },
      },
    },
  };
  return (
	<div className={styles.bar_wrapper}>
    	<span className={styles.header}>Статистика по выполнению заявок за последний месяц</span>
		<Column {...config} width={1093} height={505} className={styles.bar} />
    </div>
  );
});

export default ByPersonBar;
