// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IssuesCounter_taskBlock__2wA6E {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  height: 80%;\n  margin-left: 1%;\n}\n\n.IssuesCounter_countBlock__2R-Fz {\n  box-sizing: border-box;\n  margin-top: 5px;\n  width: 48%;\n  height: 45%;\n  background: #ffffff;\n  border: 5px solid #f2f3f3;\n}\n\n.IssuesCounter_text__2SNDF {\n  width: 5%;\n  margin-left: 1.5%;\n  font-style: normal;\n  font-weight: 430;\n  font-size: 1.7vw;\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.IssuesCounter_newTaskCount__1p4ua {\n  width: 20%;\n  margin-left: 3%;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 250%;\n  color: #1890ff;\n}\n\n.IssuesCounter_minCount__x_dqo {\n  width: 20%;\n  margin-left: 3%;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 250%;\n  color: #1890ff;\n}\n\n.IssuesCounter_middleCount__2FytQ {\n  width: 20%;\n  margin-left: 3%;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 250%;\n  color: #F7B52C;\n}\n\n.IssuesCounter_hightCount__1cUe9 {\n  width: 20%;\n  margin-left: 3%;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 250%;\n  color: #FF0000;\n}", ""]);
// Exports
exports.locals = {
	"taskBlock": "IssuesCounter_taskBlock__2wA6E",
	"countBlock": "IssuesCounter_countBlock__2R-Fz",
	"text": "IssuesCounter_text__2SNDF",
	"newTaskCount": "IssuesCounter_newTaskCount__1p4ua",
	"minCount": "IssuesCounter_minCount__x_dqo",
	"middleCount": "IssuesCounter_middleCount__2FytQ",
	"hightCount": "IssuesCounter_hightCount__1cUe9"
};
module.exports = exports;
