import { makeAutoObservable } from 'mobx';
import i18next from 'i18next';
import { notification } from 'antd';
import api from '../utils/axiosAuthInterceptor';

const axios = api;


class DefaultHeaderStore {
  addAccountModalIsOpen = false;
  comments = [];
  requestComments = [];
  lastCommentsRequest = undefined;
  tasksTotal = undefined;
  requestsTotal = undefined;
  currentTasksPage = 1;
  currentRequestsPage = 1;
  currentPartsPage = 1;
  currentIssuesPage = 1;

  currentTasksPageSize = 10;
  currentPoPageSize = 10;
  currentPartsPageSize = 10;
  currentIssuesPageSize = 10;

  activeTab = 'po';
  emailNotification = undefined;
  siteNotification = undefined;
  saveSettingsLoading = false;
  visiblePanel = false;
  userAvatar = '';

  partsNotices = {
    notices: [],
    total: undefined
  }

  issuesNotices = {
    notices: [],
    total: undefined
  }
  requestsAccessList = [];
  tasksAccessList = [];
  partsAccessList = [];
  issuesAccessList = [];
  checkedAll = true;

  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getUserAvatar = async () => {
    try{
      let response = await axios.get('/api/node/v1/users/getAvatar')
      this.userAvatar = response.data.data.avatar
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
      console.error(error)
    }
  };

  openAccountModal = () => {
    this.addAccountModalIsOpen = true;
  }

  closeAccountModal = () => {
    this.addAccountModalIsOpen = false;
  }

  handleChangePanelStatus = _ => {
    this.visiblePanel = !this.visiblePanel;
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  changeRequestsPage = (page) => {
    this.requestsAccessList = [];
    this.currentRequestsPage = page;
    this.getRequestComments(page);
  }

  changePartsPage = (page) => {
    this.partsAccessList = [];
    this.currentPartsPage = page;
    this.getPartsNotices(page);
  }

  changeTasksPage = (page) => {
    this.tasksAccessList = [];
    this.currentTasksPage = page;
    this.getTaskComments(page);
  }

  changeIssuesPage = (page) => {
    this.issuesAccessList = [];
    this.currentIssuesPage = page;
    this.getIssuesNotices(page);
  }

  changeIssuesPageSize = (pageSize) => {
	this.currentIssuesPageSize = pageSize;
  }

  changePartsPageSize = (pageSize) => {
	this.currentPartsPageSize = pageSize;
  }

  changeTasksPageSize = (pageSize) => {
	this.currentTasksPageSize = pageSize;
  }

  changePoPageSize = (pageSize) => {
	this.currentPoPageSize = pageSize;
  }

  getTaskComments = async (page = this.currentTasksPage, pageSize = this.currentTasksPageSize) => {
    this.lastCommentsRequest = new Date();
    try {
      let response = await fetch(`/api/node/v1/task/getTaskNotices/${page}/${pageSize}`)
      response = await response.json();
      this.comments = response.data.rows;
      this.tasksTotal = response.data.count;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  getRequestComments = async (page = this.currentRequestsPage, pageSize = this.currentPoPageSize) => {
    this.lastCommentsRequest = new Date();
    try {
      let response = await fetch(`/api/node/v1/statuses/getRequestNotices/${page}/${pageSize}`)
      response = await response.json();
      this.requestComments = response.data.rows;
      this.requestsTotal = response.data.count;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  getPartsNotices = async (page = this.currentPartsPage, pageSize = this.currentPartsPageSize) => {
    this.lastCommentsRequest = new Date();
    try {
      let response = await fetch(`/api/node/v1/statuses/getPartsNotices/${page}/${pageSize}`)
      response = await response.json();
      this.partsNotices.notices = response.data.rows;
      this.partsNotices.total = response.data.count;
    } catch (error) {
      console.log(error);
    }
    return;
  }

  switchUser = async (data) => {
    try {
      let res = await axios.post(`/api/users/switchUser`, data);
      return res;
    } catch (e) {
      this.openNotificationWithIcon('error', 'Authorization error. Sign in again')
    }
  }


	getIssuesNotices = async (page = this.currentIssuesPage, pageSize = this.currentIssuesPageSize) => {
		this.lastCommentsRequest = new Date();
		try {
			let response = await fetch(`/api/node/v1/issues/getIssuesNotices/${page}/${pageSize}`)
			response = await response.json();
			this.issuesNotices.notices = response.data.rows;
      		this.issuesNotices.total = response.data.count;
		}
		catch (error)
		{
			console.log(error)
		}
		return;
	}


  updateTaskAccess = async (item) => {
    return window.location.href = `/react/tasks/all?&id=${item.object_id}`
  }

  updateRequestAccess = async (item) => {
    return window.location.href = `/react/po?&id=${item.object_id}`
  }

  updatePartsAccess = async (item) => {
    if (item.notice_type_id === 11)
      return window.location.href = `/react/parts/InspectionReportTemplate?&id=${item.object_id}`
    else
      return window.location.href = `/react/parts/POFinal?&id=${item.object_id}`
  }

	updateIssuesAccess = async (item) => {
		return window.location.href = `/react/issues/notices?&id=${item.object_id}`
		// if (item.notice_type_id === 11)
    	// 	return window.location.href = `/react/parts/InspectionReportTemplate?&id=${item.object_id}`
  	}

  changeLanguage = async (value) => {
    if (i18next.language !== value) {
      i18next.changeLanguage(value);
      await fetch(`/api/node/v1/translates/save/${value}`);
    }
  }

  selectAll = () => {
    switch (this.activeTab) {
      case 'po':
        this.requestsAccessList = [];
        let tempRequests = [...this.requestComments];
        for (let request of this.requestComments) {
          request.checked = true;
          this.requestsAccessList.push(request)
        }
        this.requestComments = [...tempRequests];
        break;

      case 'tasks':
        this.tasksAccessList = [];
        let tempTasks = [...this.comments];
        for (let task of this.comments) {
          task.checked = true;
          this.tasksAccessList.push(task)
        }
        this.comments = [...tempTasks];
        break

      case 'parts':
        this.partsAccessList = [];
        let tempParts = [...this.partsNotices.notices];
        for (let [part] of tempParts) {
          part.checked = true;
          tempParts.push(part)
        }
        this.partsNotices.notices = [...tempParts];
        break;
      default:
        return 0;
    }
  }

  changeRequestsList = (e, item, index, type) => {
    let temp = [...this.requestComments];
    temp[index].checked = e.target.checked;

    if (e.target.checked)
      this.requestsAccessList.push(item);
    else {
      const idx = this.requestsAccessList.findIndex(el => el.id === item.id);
      if (idx > -1) {
        this.requestsAccessList.splice(idx, 1);
      }
    }
    this.requestComments = [...temp];
  }

  changeTasksList = (e, item, index) => {
    let temp = [...this.comments];
    temp[index].checked = e.target.checked;

    if (e.target.checked)
      this.tasksAccessList.push(item);
    else {
      const idx = this.tasksAccessList.findIndex(el => el.id === item.id);
      if (idx > -1) {
        this.tasksAccessList.splice(idx, 1);
      }
    }
    this.comments = [...temp];
  }

  changePartsList = (e, item, index) => {
    let temp = [...this.partsNotices.notices];
    temp[index].checked = e.target.checked;

    if (e.target.checked)
      this.partsAccessList.push(item);
    else {
      const idx = this.partsAccessList.findIndex(el => el.id === item.id);
      if (idx > -1) {
        this.partsAccessList.splice(idx, 1);
      }
    }
    this.partsNotices.notices = [...temp];
  }

  changeIssuesList = (e, item, index) => {
    let temp = [...this.issuesNotices.notices];
    temp[index].checked = e.target.checked;

    if (e.target.checked)
      this.issuesAccessList.push(item);
    else {
      const idx = this.issuesAccessList.findIndex(el => el.id === item.id);
      if (idx > -1) {
        this.issuesAccessList.splice(idx, 1);
      }
    }
    this.issuesNotices.notices = [...temp];
  }



  markAsRead = async () => {
    this.loading = true;
    let accessList = [...this.requestsAccessList, ...this.tasksAccessList, ...this.partsAccessList, ...this.issuesAccessList];

    await axios.post(`/api/node/v1/notices/updateAccess`, {
      notices: accessList
    });

    await this.getNotices();
    this.loading = false;
  }

  changeActiveTab = (e) => {
    this.activeTab = e;
  }

  toggleEmailNotify = (e) => {
    this.emailNotification = e;
  }

  toggleSiteNotify = (e) => {
    this.siteNotification = e;
  }

  getUserSettings = async () => {
    let response = await fetch(`/api/node/v1/users/getSettings`);
    let data = await response.json();
    this.emailNotification = data.data.email_notifications ? true : false;
    this.siteNotification = data.data.site_notifications ? true : false;
  }

  saveUserSettings = async () => {
    this.saveSettingsLoading = true;
    let response = await axios.post(`/api/node/v1/users/saveSettings`, {
      emailNotification: this.emailNotification,
      siteNotification: this.siteNotification
    });
    if (response.data.success)
      this.openNotificationWithIcon('success', 'Saved successfully!');
    else
      this.openNotificationWithIcon('error', ' Error!');
    this.saveSettingsLoading = false;
  }

  getNotices = async () => {
    await this.getTaskComments();
    await this.getRequestComments();
    await this.getPartsNotices();
	await this.getIssuesNotices();
    return;
  }

  logoutUser = async () => {
    let response = await axios.get('/api/node/v1/users/logoutUser')
    if (response.status === 200) {
      return window.location.href = `/login`
    }
  }


}

export default DefaultHeaderStore;
