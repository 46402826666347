import React from 'react'
import { Image } from 'antd'
import { useTranslation } from 'react-i18next'
import CustomPopConfirm from './customPopConfirm/CustomPopConfirm'

const CustomImage = (props) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        marginLeft: '10px',
      }}
    >
      <Image src={props.src} width={100} preview={props.preview} />
      {props.canDelete && (
        <CustomPopConfirm
          cancelText={t('w_cancel')}
          title={t('w_are_you_sure')}
          onConfirm={props.onDelete}
          htmlBody={
            <i
              style={{ paddingLeft: '5px', cursor: 'pointer' }}
              className="fa fa-times"
              aria-hidden="true"
              onClick={(e) => e.stopPropagation()}
            ></i>
          }
        />
      )}
    </div>
  )
}

export default CustomImage
