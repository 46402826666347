import React, { useState, useEffect, useRef } from 'react'
import {observer} from "mobx-react-lite";
import { Select, Button, Checkbox, Spin } from 'antd';
import styles from'./css/selectFilter.module.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const SelectFilter = observer(
  ({ onFilter, onReset, options, selectedData, single, forceCleanup, allowClear, checkAllOption, loadingOptionsState }) => {

    const [selectedValue, setSelectedValue] = useState(selectedData);
    const [checkAll, setCheckAll] = useState(false);
    const [open, setOpen] = useState(true);
    const selectRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
      setSelectedValue(selectedData);
      if (!loadingOptionsState) {
        setOpen(true);
      }
    }, [forceCleanup, selectedData]);

    useEffect(() => {
      if (open && selectRef.current) {
        selectRef.current.focus();
      }
    }, [open]);

    const isCheckedAll = () => selectedValue?.length && (selectedValue?.length === options.length);

    const handleSelectChange = (value) => {
      if (checkAllOption && value.includes("checkAll")) {
        if ( value.length === options.length + 1 && checkAll) {
          setSelectedValue([]);
        } else {
          setSelectedValue(options.map((option) => option.value));
        }
        setCheckAll(!checkAll);
      } else {
        setSelectedValue(value);
        const allChecked = checkAllOption && value.length && (value.length === options.length);
        setCheckAll(allChecked);
      }
    };

    const handleFilter = () => {
      (single && selectedValue) || selectedValue.length ? onFilter(selectedValue) : handleReset();
    };

    const handleReset = () => {
      setSelectedValue([]);
      onReset();
    };

    return (
      <div className={styles.filterSelectWrapper}>
        <div className={styles.filterSelectWrapper__buttonsWrapper}>
          <Button type="primary" onClick={handleFilter} className={styles.filterSelectWrapper__buttonsWrapper_submit} disabled={loadingOptionsState}>{t('w_ok')}</Button>
          <Button onClick={handleReset} className={styles.filterSelectWrapper__buttonsWrapper_reset}  disabled={loadingOptionsState}>{t('w_clear')}</Button>
        </div>
        {
          loadingOptionsState ? <Spin className={styles.filterSelectDropdown}/>
            :
            <Select
              ref={selectRef}
              mode={single ? "default" : "multiple"}
              style={{ width: 260 }}
              placeholder={t("w_select_value")}
              value={selectedValue}
              onChange={handleSelectChange}
              className={styles.filterSelectWrapper__select}
              popupClassName={styles.filterSelectDropdown}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
              }
              allowClear={allowClear}
              open={open}
              autoFocus={open}
              onDropdownVisibleChange={(visible) => setOpen(visible)}
            >
              {
                checkAllOption && !single && <Option key="checkAll" value="checkAll">
                  <Checkbox checked={isCheckedAll()} className={styles.filterSelectWrapper__checkAllOption}/>
                  {t("w_select_all")}
                </Option>
              }
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {t(option?.text || option?.label || option?.value)}
                </Option>
              ))}
            </Select>
        }
      </div>
    );
  });

export default SelectFilter;
