import React from 'react'
import { observable } from 'mobx';
import {observer} from "mobx-react-lite";
import { Button, Input } from 'antd';
import styles from'./css/selectFilter.module.scss';
import { useTranslation } from 'react-i18next';

const InputFilter = observer(
  ({ onFilter, onReset, selectedData, placeholder, textArea, rows }) => {

    const { t } = useTranslation();
    const selectedValue = observable.box(selectedData);

    const handleFilter = () => {
      selectedValue.get() ? onFilter(selectedValue.get()) : handleReset();
    };

    const handleInputChange = (e) => {
      selectedValue.set(e.target.value);
    };

    const handleReset = () => {
      selectedValue.set('');
      onReset();
    };

    const sharedProps = {
      allowClear: true,
      onPressEnter: handleFilter,
      placeholder: placeholder || t("w_select_value"),
      onChange: handleInputChange,
    }

    return (
      <div className={styles.filterSelectWrapper}>
        <div className={styles.filterSelectWrapper__buttonsWrapper}>
          <Button type="primary" onClick={handleFilter} className={styles.filterSelectWrapper__buttonsWrapper_submit}>{t('w_ok')}</Button>
          <Button onClick={handleReset} className={styles.filterSelectWrapper__buttonsWrapper_reset}>{t('w_clear')}</Button>
        </div>
        { !textArea ?
          <Input {...{
              ...sharedProps,
              value: selectedValue
            }}/>
          :
          <Input.TextArea {...{
            ...sharedProps,
            value: selectedValue,
            rows: rows || 1
          }}/>
        }
      </div>
    );
  });

export default InputFilter;
