// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".updatedTime_block__1qbVQ {\n  margin-top: 1%;\n  color: grey;\n}", ""]);
// Exports
exports.locals = {
	"block": "updatedTime_block__1qbVQ"
};
module.exports = exports;
