import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../../useStores';
import styles from './IssuesCounter.module.scss';
import useSound from 'use-sound';
import beep from './no-problem-notification-sound.mp3';
const IssuesCounter = observer(() => {
  const store = useStores().issuesStore;
  const [percent, setPercent] = useState();
  const [play, { stop }] = useSound(beep, { interrupt: true });
  const [isPlayed, setIsPlayed] = useState(false);
  const [interval, setSoundInterval] = useState(undefined)

  useEffect(() => {
    const result = store.calcProportion(
      store.newIssuesStatCount,
      store.doneIssuesStatCount
    );
    setPercent(result);
  }, [
    store.newIssuesStatCount,
    store.doneIssuesStatCount,
    store.toChangeNewTaskCount,
  ]);

  useEffect(() => {

    if (store.toPlay) {
      play();
      if (!isPlayed) {
      const intervalId = setInterval(() => {
          play();
        }, 30000);
        setIsPlayed(true);
        setSoundInterval(intervalId)
      }
    }

    if (!store.toPlay) {
      stop();
      clearInterval(interval);
      setIsPlayed(false);
    }
  
  }, [store.toPlay]);
  return (
    <div className={styles.taskBlock}>
      <div className={styles.countBlock}>
        <span className={styles.text}>Получено:</span>
        <div className={styles.newTaskCount}>{store.newIssuesStatCount}</div>
      </div>
      <div className={styles.countBlock}>
        <span className={styles.text}>Выполнено:</span>
        <div
          className={
            percent <= 45
              ? styles.minCount
              : percent > 45 && percent < 75
              ? styles.middleCount
              : percent >= 75
              ? styles.hightCount
              : styles.minCount
          }
        >
          {store.doneIssuesStatCount}
        </div>
      </div>
    </div>
  );
});

export default IssuesCounter;
