import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PagesError = ({status, subTitle, link}) => {
  const { t } = useTranslation()
  return (
    <Result
    status={status}
    subTitle={subTitle}
    extra={
      <Link to={link}>
         <Button type="primary">{t('w_back')}</Button>
      </Link>}
  />
  );
}

export default PagesError;
