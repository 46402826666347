// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".legend_legend_wrapper__3ZWT8 {\n  display: flex;\n  position: absolute;\n  right: 0;\n  margin-top: 5px;\n}\n.legend_legend_wrapper__3ZWT8 .legend_urgentPriority__xkDBZ {\n  width: 35px;\n  height: 35px;\n  background: #ff0000;\n  border-radius: 10px;\n}\n.legend_legend_wrapper__3ZWT8 .legend_standartPriority__1oESP {\n  width: 35px;\n  height: 35px;\n  background: #1890ff;\n  border-radius: 10px;\n}\n.legend_legend_wrapper__3ZWT8 .legend_legendText__1CZLu {\n  font-size: 20px;\n}", ""]);
// Exports
exports.locals = {
	"legend_wrapper": "legend_legend_wrapper__3ZWT8",
	"urgentPriority": "legend_urgentPriority__xkDBZ",
	"standartPriority": "legend_standartPriority__1oESP",
	"legendText": "legend_legendText__1CZLu"
};
module.exports = exports;
