import React from 'react'
import styles from './UnarchiveIcon.module.scss'

const UnarchiveIcon = () => {
  return (
    <svg
      fill="#000000"
      width="20"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path fill-rule="nonzero" d="M20 3l2 4v13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.004L4 3h16zm0 6H4v10h16V9zm-8 1l4 4h-3v4h-2v-4H8l4-4zm6.764-5H5.236l-.999 2h15.527l-1-2z" />
      </g>
    </svg>
  )
}

export default UnarchiveIcon
