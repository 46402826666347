import { makeAutoObservable } from 'mobx'
import api from '../utils/axiosAuthInterceptor'

const axios = api

class NotificationStore {

	constructor() {
		makeAutoObservable(this)
	}

	allRecepient = []
	sectionType = {
		PROPOSALS: 14,
	}

	// Variables

	isAccess = false;
	buttonGet = false;

	currentModuleId = null;

	modules = [];
	moduleAdmins = [];
	adminOptions = [];

	notificationsData = [];

	postOptions = [];
	
	
	// Get functions

	getAccess = () => {
		return this.isAccess || false;
	}

	getModules = () => {
		return this.modules || [];
	}

	getAdmins = () => {
		return this.moduleAdmins || [];
	}

	getAdminOptions = () => {
		return this.adminOptions || [];
	}

	getNotificationData = () => {
		return this.notificationsData || [];
	}

	getPostOptions = () => {
		return this.postOptions || [];
	}


	// Get userInfo from localStorage;
	userId = Number(localStorage.userId);
	userDepartment = Number(localStorage.userDepartment);
	userPost = Number(localStorage.userPostId);

	userIsAdmin = false;
	isGlobalAdmin = 0;
	adminBlock = 0;
	

	// State changers

	changeUserMode = () => {
		if(!this.adminBlock)
		{
			this.userIsAdmin = !this.userIsAdmin;
			this.getNotificationByModule('userModeChanged')
		}
	}

	tabChanged = (e) => {
		this.currentModuleId = e;
		const newModule = this.modules.find(item => item.id === e);

		if(this.isGlobalAdmin === 0)
		{
			if(newModule.is_admin === 1)
			{
				this.userIsAdmin = true;
			}
			else
			{
				this.userIsAdmin = false;
			}
			this.adminBlock = !this.userIsAdmin;
		}
		

		if(this.userIsAdmin)
		{
			this.getModuleAdminList(e)
		}

		this.getNotificationByModule('tabChanged')
	}

	updateAdminRow = () => {
		this.getModuleAdminList(this.currentModuleId)
	}


	// Request functions 

	tryAccess = async () => {
		const { data } = await axios.get('/api/node/v1/notifications/access')

		if(data)
		{
			this.isAccess = data.data.isAccess;
			return data.data.isAccess;
		}

		return false;
	}


	getNotificationModules = async () => {
		const { data } = await axios.get('/api/node/v1/notifications/modules')
		
		if(data)
		{
			this.buttonGet = true;
			this.modules = data.data.modules;
			this.currentModuleId = data.data.modules[0].id;

			if(data.data.isGlobalAdmin == 0)
			{
				this.userIsAdmin = data.data.modules[0].is_admin;
				this.adminBlock = !this.userIsAdmin;
			}
			else
			{
				this.isGlobalAdmin = 1;
				this.userIsAdmin = true;
				this.adminBlock = false;
			}
		}
		this.getNotificationByModule('AfterAccess')
	}

	getModuleAdminList = async (moduleId) => {
		const {data} = await axios.get(`/api/node/v1/notifications/adminsByModule/${moduleId}`)

		if(data)
		{
			this.moduleAdmins = data.data.admins;
		}
	}

	getFullAdminList = async () => {
		const {data} = await axios.get(`/api/node/v1/notifications/fullAdminList`)

		if(data)
		{
			this.adminOptions = data.data.admins;
		}
	}

	addAdminToModule = async (adminId) => {
		const {data} = await axios.post(`/api/node/v1/notifications/module/${this.currentModuleId}/admin/${adminId}`)
		this.updateAdminRow();
	}

	removeAdminFromModule = async (adminId) => {
		const {data} = await axios.delete(`/api/node/v1/notifications/module/${this.currentModuleId}/admin/${adminId}`)
		this.updateAdminRow();
	}

	getNotificationByModule = async (stack) =>  {
		
		console.log(stack)
		let url = this.userIsAdmin ? `/api/node/v1/notifications/byModule/${this.currentModuleId}` : `/api/node/v1/notifications/userByModule/${this.currentModuleId}`;
		
		const {data} = await axios.get(url)
		{
			if(data)
			{
				if(!this.userIsAdmin)
				{
					await this.getAdditionalNotifications(data.data.notifications)
				}
				else
				{
					this.notificationsData = data.data.notifications;
				}
			}
		}
		
		
	}

	handleChangeAdminSwitch = async (id, state) => {
		const { data } = await axios.post(`/api/node/v1/notifications/state/${id}/${state}`)
	}

	handleChangeUserSwitch = async (id, state, type, row) => {
		const { data } = await axios.post(`/api/node/v1/notifications/own/state/${id}/${type}/${state}/${row.id}/${this.userId}`)
		this.getNotificationByModule()
	}

	getPosts = async () => {
		const { data } = await axios.get('/api/node/v1/posts/getAll')
		
		if(data)
		{
			let newPosts = [];
			for(let i in data.data)
			{
				newPosts.push({name: data.data[i].name, id:data.data[i].id})
			}
			this.postOptions = newPosts;
		}
	}

	addPostToNotification = async (notificationId, postId) => {
		const { data } = await axios.post(`/api/node/v1/notifications/post/${notificationId}/${postId}`);	
	}

	removePostFromNotification = async (notificationId, postId) => {
		const { data } = await axios.delete(`/api/node/v1/notifications/post/${notificationId}/${postId}`);
	}

	getAdditionalNotifications = async (previousData) => {
		let newDataArray = previousData;
		const {data} = await axios.get(`/api/node/v1/notifications/other/${this.currentModuleId}`)
		{
			if(data)
			{
				let notifications = data.data.notifications
				for(let i in notifications)
				{
					newDataArray.push({'active': true, 'id': notifications[i].id, 'name': notifications[i].name, 'notification_module_id': this.currentModuleId, users: [{'email': false, 'push': false}]})
				}
			}

			this.notificationsData = newDataArray;
		}
	}


  	// getNotificationData = async () => {
	// 	const { data } = await axios.get('/api/node/v1/notifications')
	// 	if (data) {
	// 		this.data = data.data.sections
	// 		this.isAccess = data.data.isAccess
	// 	}
  	// }

//   toManageNotification = async (checked, notificationId, section_id) => {
//     const data = await axios.patch(
//       `/api/node/v1/notifications/toManage/${section_id}`,
//       { checked, notificationId }
//     )
//     if (data) {
//       this.getNotificationData()
//     }
//   }
//   updateRecepient = async (valueSelect, idNotification, postIdRecepient) => {
//     try {
//       const data = await axios.patch(
//         `/api/node/v1/notifications/updateRecepient`,
//         { valueSelect, idNotification, postIdRecepient }
//       )
//     } catch (error) {}
//   }
//   getRecepient = async () => {
//     try {
//       const data = await axios.get(`/api/node/v1/posts/getAll`)
//       if (data) {
//         this.allRecepient = data.data.data
//         this.getNotificationData()
//       }
//     } catch (error) {}
//   }
}

export default NotificationStore;
