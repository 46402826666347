// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".speedometer_speedometr__wrapper__1aohq {\n  margin: 5px;\n}\n.speedometer_speedometr__wrapper__1aohq .speedometer_header__246xZ {\n  width: 100%;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  color: rgba(0, 0, 0, 0.85);\n}\n.speedometer_speedometr__wrapper__1aohq .speedometer_footer__1qdtB {\n  display: flex;\n  width: 45%;\n  justify-content: center;\n  margin-left: auto;\n  margin-right: auto;\n}\n.speedometer_speedometr__wrapper__1aohq .speedometer_footer__1qdtB .speedometer_text__3VxMC {\n  font-style: normal;\n  font-weight: 600;\n  font-size: 22px;\n  color: #000000;\n  margin-right: 30px;\n}\n.speedometer_speedometr__wrapper__1aohq .speedometer_footer__1qdtB :last-child {\n  margin-right: 0px;\n}", ""]);
// Exports
exports.locals = {
	"speedometr__wrapper": "speedometer_speedometr__wrapper__1aohq",
	"header": "speedometer_header__246xZ",
	"footer": "speedometer_footer__1qdtB",
	"text": "speedometer_text__3VxMC"
};
module.exports = exports;
