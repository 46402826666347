import React from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Popconfirm } from 'antd'
import styles from './CustomPopConfirm.module.scss'

const CustomPopConfirm = observer(
  ({
    cancelText,
    title,
    onConfirm,
    onCancel,
    htmlBody,
    overlayCssName,
    okText,
    description,
  }) => {
    const { t } = useTranslation()

    return (
      <Popconfirm
        zIndex="2000"
        cancelText={t(cancelText || 'w_cancel')}
        okText={t(okText || 'w_ok')}
        title={t(title || 'w_are_you_sure')}
        description={description || ''}
        onConfirm={(d) => onConfirm(d)}
        onCancel={(d) => (onCancel ? onCancel(d) : null)}
        overlayClassName={`${styles.CustomPopConfirm} ${overlayCssName}`}
      >
        {htmlBody}
      </Popconfirm>
    )
  }
)

export default CustomPopConfirm
