import {makeAutoObservable} from "mobx";
import {notification} from "antd";
import api from "../utils/axiosAuthInterceptor";

const axios = api;

class PostsStore {
  posts = []
  departments = []
  loading = true
  isModalOpen = false
  isUpdate = false
  currentRow = undefined

  updateAccessRow = undefined
  updateAccessData = []

  userId = undefined
  userTypeId = undefined


  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  getUser = async () => {
    let resp = await fetch('/api/node/v1/users/get/current');
    let data = await resp.json();
    this.userId = data.userId
    this.userTypeId = data.userTypeId
  }

  getData = async() => {
    this.loading = true

    let responsePosts = await fetch(`/api/node/v1/posts/getData`)
    let dataPosts = await responsePosts.json();
    this.posts = dataPosts.data

    let responseDepartments = await fetch(`/api/node/v1/posts/getDepartments`)
    let dataDepartments = await responseDepartments.json();
    this.departments = dataDepartments.data
    await this.getUser()

    this.loading = false
  }

  openModal = () => {
    this.isUpdate = false
    this.currentRow = {}
    this.isModalOpen = true
  }

  closeModal = () => {
    this.isModalOpen = false
    this.isUpdate = false
  }

  updatePost = (row) => {
    this.currentRow = row
    const currentDepartment = this.departments.filter((el) => el.id === this.currentRow.department)
    this.currentRow.departmentName = currentDepartment.name_en
    this.isUpdate = true
    this.isModalOpen = true
  }

  handleSavePost = async (values) => {
    values.id = this.currentRow.id
    if(typeof values.department !== 'string') {
      values.department = this.currentRow.department
    } else {
      values.department = +values.department
    }

    let temp = [...this.posts]

    let response = await axios.post('/api/node/v1/posts/savePost', values)
    if(response.data.success){

      if(values.id){
        let el = temp.find(el => el.id === values.id)
        el.department = values.department;
        el.name = values.name;
        this.posts = temp;
        this.openNotificationWithIcon('success', 'Saved!');
      } else {
        await this.getData()
        this.openNotificationWithIcon('success', 'Saved!');
      }

    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }

    this.isModalOpen = false
    this.isUpdate = false
  }

  updateAccess = async (row) => {
    this.updateAccessRow = row
    let body = {
      id: row.id
    }
     let response = await axios.post('/api/node/v1/posts/updateAccess', body)
    this.updateAccessData = response.data.data
  }

  toggleToNull = async (row, type) => {
    const body = {
      id: row.id,
      type: type,
    }

    let response = await axios.post('/api/node/v1/posts/toggleToNull', body)
    if(response.data.success){
      let temp = [...this.updateAccessData]
      let curElem = temp.find(el => el.id === row.id)
      curElem[type] = null
      this.updateAccessData = temp
      this.openNotificationWithIcon('success', 'Saved!');
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

  toggleToTrue = async (row, type) => {
    const body = {
      id: row.id,
      type: type,
    }

    let response = await axios.post('/api/node/v1/posts/toggleToTrue', body)
    if(response.data.success){
      let temp = [...this.updateAccessData]
      let curElem = temp.find(el => el.id === row.id)
      curElem[type] = 'true'
      this.updateAccessData = temp
      this.openNotificationWithIcon('success', 'Saved!');
    } else {
      this.openNotificationWithIcon('error', 'Error!')
    }
  }

}

export default PostsStore
