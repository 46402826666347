import { makeAutoObservable, configure } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
const axios = api;


configure({
    enforceActions: "never",
})

class GoodsStore {

    dataLoading = true;
    dataSet = [];

    treeData = [
        {
            title: 'Expand to load1',
            key: '6',
        },
        {
            title: 'Expand to load2',
            key: '8',
        },
    ];

    constructor() {
        makeAutoObservable(this);
    }

    getTreeData = async () => {
        let response = await fetch('/api/node/v1/goods/getParents');
        let data = await response.json();
        this.treeData = data.data;
        this.dataLoading = false;
    }

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    onLoadData = async (e) => {
        let key = e.key
        let response = await fetch(`/api/node/v1/goods/getChildren/${key}`);
        let data = await response.json();

        if (data.data.isLeaf) {
            for (let child of data.data.children) {
                if (!this.dataSet.find(el => el.id === child.id)) {
                    this.dataSet.push(child);
                }
            }
        }

        let temp = [...this.treeData];
        let newData = this.updateTreeData(temp, key, data.data.children);

        this.treeData = newData;
        return;
    }

    updateTreeData = (list, key, children) => {
        return list.map((node) => {
            if (node.key === key) {
                return { ...node, children }
            }

            if (node.children) {
                return { ...node, children: this.updateTreeData(node.children, key, children) };
            }

            return node;
        });
    }

    onChange = (id, e) => {
        this.dataSet.find(el => el.id === id)['good.name'] = e.target.value;
    }

    save = async (id) => {
        let product = this.dataSet.find(el => el.id === id);
        axios.post('/api/node/v1/goods/save', { product: product })
            .then(response => {
                if (response.data.success) {
                    if (!product['good.id']) {
                        product['good.id'] = response.data.data.id
                    }
                    this.openNotificationWithIcon('success', 'Saved success!');
                } else {
                    this.openNotificationWithIcon('error', 'Error!');
                }
            }
            )
    }

}

export default GoodsStore;
