import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStores } from '../../../../useStores'
import ByPersonBar from './ByPersonBar/ByPersonBar'
import CurrentTime from './CurrentTime/CurrentTime'
import IssuesCounter from './IssuesCounter/IssuesCounter'
import styles from './issuesStat.module.scss'
import NewIssuesBlock from './NewIssuesBlock/NewIssuesBlock'
import Speedometer from './Speedometr/Speedometer'

const IssuesStat = observer(() => {
  const store = useStores().issuesStore
  useEffect(() => {
    store.getInfo()
  }, [])

  return (
	<div className={styles.main}>
		<div className={styles.top_block}>
			<div className={styles.left_block}>
				<Speedometer/>
			</div>
			<div className={styles.right_block}>
				<CurrentTime/>
				<IssuesCounter/>
			</div>
		</div>
		<div className={styles.middle_block}>
			<ByPersonBar/>
		</div>
		<div className={styles.bottom_block}>
			<NewIssuesBlock/>
		</div>
	</div>
  )
})

export default IssuesStat
