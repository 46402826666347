import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStores } from '../../../../../../useStores'
import styles from './updatedTime.module.scss'
const UpdatedTime = observer(() => {
  const store = useStores().issuesStore
  return (
    <div className={styles.block}>
    Updated at {store.correctTime(store.newTaskArr[0]?.created_at)}
    </div>
  )
})

export default UpdatedTime
