import { makeAutoObservable } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);
const axios = api;

class StaffStore {
  pageLoading = true;
  departments = [];
  users = [];
  managersPosts = [];
  mobile = false;
  userAvatar = undefined;
  userAvatarBlob = undefined;
  isEditModalOpen = false;
  avatarChanged = false;
  crop = { height: 0, unit: 'px', width: 0, x: 0, y: 0, aspect: 3.5 / 4 };
  inputKey = undefined;
  image = undefined;
  cropImage = undefined;
  userId = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  test = (key) => {
    this.pageLoading = false;
    this.getStaff(key);
  };

  getStaff = (department = [0]) => {
    this.pageLoading = false;

    axios
      .post('/api/node/v1/staff/get', {
        department: department,
      })
      .then((response) => {
        this.departments = response.data.data.departments;
        this.users = response.data.data.users;
        let posts = [];
        for (let element of response.data.data.departments) {
          if (
            element.post_head &&
            element.post_head !== 1 &&
            element.post_head !== 12
          ) {
            posts.push(element.post_head);
          }
        }
        this.managersPosts = posts;
        this.pageLoading = true;
      })
      .catch((err) => console.error(err));
  };

  checkMobile = () => {
    this.mobile = md.mobile();
  };

  openEditModal = (user) => {
    this.isEditModalOpen = true;
    this.userAvatar = user.avatar;
    this.userId = user.id;
  };

  closeEditModal = (_) => {
    this.isEditModalOpen = false;
    this.userAvatar = undefined;
    this.avatarChanged = false;
    this.crop = {
      height: 0,
      unit: 'px',
      width: 0,
      x: 0,
      y: 0,
      aspect: 3.5 / 4,
    };
    this.inputKey = Date.now();
    this.cropImage = undefined;
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.userAvatarBlob = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.userAvatar = reader.result;
        this.avatarChanged = true;
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  setCrop = (crop) => {
    this.crop = crop;
  };

  onImageLoaded = (image) => {
    this.image = image;
  };

  makeClientCrop = async (crop) => {
    if (this.image && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.image, crop);
      this.cropImage = croppedImageUrl;
    }
  };

  getCroppedImg = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        var formData = new FormData();
        formData.append('image', blob, 'avatar.jpg');
        resolve(formData);
      }, 'image/jpeg');
    });
  };

  openNotificationWithIcon = (type, message) => {
    notification[type ? 'success' : 'error']({
      message: message,
      placement: 'bottomRight',
    });
  };

  saveImage = async (_) => {
    let image = this.cropImage;
    if (!this.cropImage) {
      var formData = new FormData();
      formData.append('image', this.userAvatarBlob, 'avatar.jpg');
      image = formData;
    }
    let response = await fetch(
      '/api/node/v1/users/setAvatar/user/' + this.userId,
      {
        method: 'POST',
        body: image,
      }
    );
    let result = await response.json();
    this.openNotificationWithIcon(result.success, result.message);
    this.closeEditModal();
    this.getStaff();
  };
}

export default StaffStore;
