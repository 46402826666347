import { makeAutoObservable } from 'mobx'
import api from '../utils/axiosAuthInterceptor'

const axios = api;
const wsAddress = process.env.REACT_APP_WS_SERVER_ADDRESS;
const wsType = process.env.REACT_APP_WS_TYPE;

class HeaderNotificationStore {

	constructor() {
		makeAutoObservable(this)
	}


	/// Variables
	websocket = null;

	modules = [];
	activeModules = [];
	currentList = [];
	currentModuleId = 0;
	pageSize = 10;
	currentPage = 1;
	notificationListTotal = 0;

	sortDirection = false;
	sortBy = 'created_at';
  // TODO: была ошибка при отсутствии в localstorage
	filters = localStorage.getItem('notificationFilters')?.split(',') || [];

	userId = Number(localStorage.userId);

	clickedTile = [];

	// Get functions
	getModules = () => {
		return this.modules || [];
	}

	getCurrentList = () => {
		return this.currentList || [];
	}


	// State changers

	setFilters = (obj) => {
		this.filters = obj;
		localStorage.setItem('notificationFilters', obj);
		this.getCurrentModuleNotifications();
	}


	setClickedTile = (arr) => {
		this.clickedTile = arr;
	}


	tabChanged = async (tabId) => {
		this.currentModuleId = await tabId;
		this.currentPage = 1;
		this.setClickedTile([]);
		await this.getCurrentModuleNotifications();
	}

	changeRequestsPage = async (page) => {
		this.currentPage = page;
		await this.getCurrentModuleNotifications();
	}

	changePageSize = async (pageSize) => {
		this.pageSize = pageSize
		await this.getCurrentModuleNotifications();
	}

	readNotification = async (notificationId, notificationIndex) => {
		if(this.updateNotification(notificationId))
		{
			let newList = this.currentList;
			newList[notificationIndex].read = 1;
			this.currentList = [];
			this.currentList = newList;

			this.reduceModuleNoticeCount();
		}
	}

	reduceModuleNoticeCount = async () => {
		this.modules[this.currentModuleId].notices_count -= 1;
	}

	changeSortDirection = async () => {
		this.sortDirection = !this.sortDirection;
		this.getCurrentModuleNotifications()
	}

	setSelectedSort = async (event) => {
		this.sortBy = event;
		this.getCurrentModuleNotifications()
	}

	// Backend connectors

	setFlag = async (id, mode) => {
		const {data} = await axios.patch(`/api/node/v1/notifications/setFlag/${id}/${mode}`)
		this.getCurrentModuleNotifications();
	}

	setRead = async (id) => {
		const {data} = await axios.patch(`/api/node/v1/notifications/setRead/${id}`);
		this.getCurrentModuleNotifications();
	}

	deleteNotice = async (id) => {
		const {data} = await axios.delete(`/api/node/v1/notifications/notice/${id}`);
		this.getCurrentModuleNotifications();
	}

	getNotificationModules = async () => {
		const { data } = await axios.get('/api/node/v1/notifications/modulesWithAmount')

		if(data)
		{
			this.modules = data.data.modules;
			this.activeModules = data.data.isActive;
			this.currentModuleId = data.data.modules[0].id;
			this.getCurrentModuleNotifications();
		}
	}

	getCurrentModuleNotifications = async () => {

		let filterRow = '';
		if(this.filters.indexOf('flag')!=-1){filterRow+='?flag=true'}else{filterRow+='?flag=false'}
		if(this.filters.indexOf('unread')!=-1){filterRow+='&unread=true'}else{filterRow+='&unread=false'}
		if(this.filters.indexOf('all')!=-1){filterRow+='&all=true'}else{filterRow+='&all=false'}


		const { data } = await axios.get(`/api/node/v1/notifications/noticesByModule/${this.currentModuleId}/${this.currentPage}/${this.pageSize}${filterRow}`)

		if(data)
		{
			this.currentList = data.data.modules;
			this.notificationListTotal = data.data.count;
		}
	}

	updateNotification = async (notificationId) => {
		const { data } = await axios.patch(`/api/node/v1/notifications/notice/${notificationId}`)
		//To do - проверка на результат работы функции
		return true;
	}

	deleteNotification = async (notificationId, read) => {
		const { data } = await axios.delete(`/api/node/v1/notifications/notice/${notificationId}`)

		//To do - проверка на результат работы функции
		if(!read)
		{
			this.reduceModuleNoticeCount()
		}

		this.getCurrentModuleNotifications()
	}

	// Websocket connect

	getWsConnect = async () => {
		let server = await this.connectToWebSockets();
    	this.websocket = server;
	}


	connectToWebSockets = async () => {
		  return new Promise((resolve, reject) => {
			let server = new WebSocket(
				`${wsType}://${wsAddress}/sockjs-node/Notifications-${this.userId}`
			);
			server.onopen = function () {
				resolve(server);
			};
			server.onclose = function (err) {
				reject(err);
				this.checkConnection = false;
			};

			server.onmessage = (event) => {
				const toClient = JSON.parse(event.data);
				if(toClient.type == 'newNotification')
				{
					for(let i in this.modules)
					{
						if(this.modules[i].id == toClient.module.notification_module_id)
						{
							this.modules[i].notices_count += 1;
							break;
						}
					}

					if(this.currentModuleId == toClient.module.notification_module_id)
					{
						this.getCurrentModuleNotifications()
					}
				}
			};
		  });
	  };
}

export default HeaderNotificationStore;
