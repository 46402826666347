import { makeAutoObservable } from 'mobx'
import api from '../utils/axiosAuthInterceptor'
import { notification } from 'antd'
import i18next from 'i18next'
import download from 'downloadjs'
import dayjs from 'dayjs'
const axios = api

class DepartmentsStore {
  isMainLoading = false
  isEditting = false
  isExcelBtnLoading = false
  btnLoading = {
    id: null,
    type: null,
  }
  paginations = {
    page : 1,
    pageSize: 10,
    totalDepartments: null
  }
  postHeadsOpt = []
  departmentsData = []
  user = {}
  rowBeforeEdit = null

  constructor() {
    makeAutoObservable(this, {}, { deep: true })
  }

  //Main model CRUD
  getDepartments = async () => {
    try {
      this.isMainLoading = true
      const params = {
        page: this.paginations.page,
        pageSize: this.paginations.pageSize
      }
      let response = await axios.get(`/api/node/v1/departments`, {params})
      this.departmentsData = response.data.data.rows
      this.user = response.data.data.user
      this.paginations.totalDepartments = response.data.data.count
      this.postHeadsOpt = response.data.data.post_heads
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  createDepartment = async (body) => {
    try {
      this.isMainLoading = true
      this.btnLoading = {
        id: 'New',
        type: 'save',
      }
      await axios.post(`/api/node/v1/departments`, body)
      await this.getDepartments()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  updateDepartment = async (body) => {
    try {
      this.isMainLoading = true
      this.btnLoading = {
        id: body.id,
        type: 'save',
      }
      await axios.put(`/api/node/v1/departments`, body)
      await this.getDepartments()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  deleteDepartment = async (id) => {
    try {
      this.isMainLoading = true
      this.btnLoading = {
        id: id,
        type: 'delete',
      }
      await axios.delete(`/api/node/v1/departments/${id}`)
      await this.getDepartments()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  //Row operations

  editRow = async (index) => {
    this.rowBeforeEdit = JSON.parse(JSON.stringify(this.departmentsData[index]))
    this.departmentsData[index].isEdit = true
    this.isEditting = true
  }

  addRow = () => {
    let temp = [...this.departmentsData]
    this.isEditting = true
    temp.unshift({
      id: 'New',
      name_rus: '',
      name_en: '',
      name_cn: '',
      post_head: null,
      user: {
        name: '',
        surname: '',
        id: null,
      },
      isEdit: true,
    })
    this.departmentsData = temp
  }

  onFinishMainForm = async (values) => {
    if (this.rowBeforeEdit) {
      values.id = this.rowBeforeEdit.id
      await this.updateDepartment(values)
    } else {
      await this.createDepartment(values)
    }
  }

  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }

  cancelEdit = (index) => {
    let temp = [...this.departmentsData]
    if (temp[index].id === 'New') {
      temp.splice(index, 1)
    } else {
      temp[index] = this.rowBeforeEdit
    }
    temp[index].isEdit = false
    this.departmentsData = temp
    this.clearMainState()
  }

  //Other

  changePage = (e) => {
    this.paginations.page = e.current
    this.getDepartments()
  }

  changeSize = (current, size) => {
    this.paginations.pageSize = size
  } 

  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight',
    })
  }

  clearMainState = () => {
    this.isEditting = false
    this.isMainLoading = false
    this.btnLoading = {
      id: null,
      type: null,
    }
    this.rowBeforeEdit = null

  }

  getDepartmentsExcel = async () => {
    try{
      this.isExcelBtnLoading = true
      const params = {
        page: null,
        pageSize: null
      }
  
      const response = await axios({
        url: '/api/node/v1/departments/excel',
        responseType: 'blob',
        method: 'get',
        params
      })
      const blob = await response.data
      download(blob, `Departments ${dayjs().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }
}

export default DepartmentsStore
