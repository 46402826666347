import { makeAutoObservable, configure } from 'mobx';
import { notification } from 'antd';
import api from "../utils/axiosAuthInterceptor";
const axios = api;

configure({
    enforceActions: "never",
})

class NoticeStore {

    modules = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    getModules = async () => {
        let modules = await fetch('/api/node/v1/notices/getModules');
        this.modules = await modules.json();
    }

    onLoadData = async (e) => {
        let key = e.key;
        let response = await fetch(`/api/node/v1/notices/getTypes/${key}`);
        let data = await response.json();

        let temp = [...this.modules];
        temp.find(el => el.key === key).children = [{
            types: data.data,
            isLeaf: true
        }];
        this.modules = temp;
        return;
    }

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    toggleAccess = async (checked, typeId, postId, moduleId) => {
        let temp = [...this.modules];
        let check = () => {
            temp.find(el => el.key === moduleId).children[0].types.find(el => el.id === typeId).notice_type_accesses.push(
                {
                    post_id: postId
                }
            )
            this.modules = [...temp];
        }
        let uncheck = () => {
            let access = temp.find(el => el.key === moduleId).children[0].types.find(el => el.id === typeId).notice_type_accesses;
            let idx = access.findIndex(el => el.post_id === postId && el.notice_type_id === typeId);
            access.splice(idx, 1);
            this.modules = [...temp];
        }
        if (checked) {
            check();
        } else {
            uncheck();
        }

        let response = await axios.post('/api/node/v1/notices/setAccess',
            {
                checked: checked,
                typeId: typeId,
                postId: postId
            });

        if (!response.data.success) {
            if (checked) {
                uncheck();
            } else {
                check();
            }
            this.openNotificationWithIcon('error', 'Error!');
        }
    }

}

export default NoticeStore;
