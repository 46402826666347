import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './legend.module.scss';

const Legend = observer(() => {
  return (
    <div className={styles.legend_wrapper}>
    	<div className={styles.urgentPriority}> </div>{' '}
    	<span className={styles.legendText}> &nbsp;- срочная заявка</span> 
    	&nbsp;&nbsp;&nbsp;   
    	<div className={styles.standartPriority}> </div>{' '}
    	<span className={styles.legendText}> &nbsp;- стандартная заявка</span>
    </div>
  );
});

export default Legend;
