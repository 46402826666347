import React from 'react';
import { makeAutoObservable, configure } from 'mobx';
const moment = require('moment');

configure({
    enforceActions: "never",
})

class DutyStore {

    data = [
        {
            date: '22.10.2021',
            second: true
        },
        {
            date: '25.10.2021',
            second: true
        },
        {
            date: '26.10.2021',
            second: true
        },

        {
            date: '27.10.2021',
            first: true
        },
        {
            date: '28.10.2021',
            first: true
        },
        {
            date: '29.10.2021',
            first: true
        },

        {
            date: '01.11.2021',
            third: true
        },
        {
            date: '02.11.2021',
            third: true
        },
        {
            date: '03.11.2021',
            third: true
        },

        {
            date: '04.11.2021',
            second: true
        },
        {
            date: '05.11.2021',
            second: true
        },
        {
            date: '08.11.2021',
            second: true
        },

        {
            date: '09.11.2021',
            third: true
        },
        {
            date: '10.11.2021',
            third: true
        },
        {
            date: '11.11.2021',
            third: true
        },

        {
            date: '12.11.2021',
            first: true
        },
        {
            date: '15.11.2021',
            first: true
        },
        {
            date: '16.11.2021',
            first: true
        },

        {
            date: '17.11.2021',
            second: true
        },
        {
            date: '18.11.2021',
            second: true
        },
        {
            date: '19.11.2021',
            second: true
        },

        {
            date: '22.11.2021',
            third: true
        },
        {
            date: '23.11.2021',
            third: true
        },
        {
            date: '24.11.2021',
            third: true
        },

        {
            date: '25.11.2021',
            first: true
        },
        {
            date: '26.11.2021',
            first: true
        },
        {
            date: '29.11.2021',
            first: true
        },

        {
            date: '30.11.2021',
            second: true
        },
        {
            date: '01.12.2021',
            second: true
        },
        {
            date: '02.12.2021',
            second: true
        },

        {
            date: '03.11.2021',
            third: true
        },
        {
            date: '06.12.2021',
            third: true
        },
        {
            date: '07.12.2021',
            third: true
        },

        {
            date: '08.11.2021',
            first: true
        },
        {
            date: '09.12.2021',
            first: true
        },
        {
            date: '10.12.2021',
            first: true
        },

        {
            date: '13.11.2021',
            second: true
        },
        {
            date: '14.12.2021',
            second: true
        },
        {
            date: '15.12.2021',
            second: true
        },

        {
            date: '16.11.2021',
            third: true
        },
        {
            date: '17.12.2021',
            third: true
        },
        {
            date: '20.12.2021',
            third: true
        },

        {
            date: '21.11.2021',
            first: true
        },
        {
            date: '22.12.2021',
            first: true
        },
        {
            date: '23.12.2021',
            first: true
        },

        {
            date: '24.11.2021',
            second: true
        },
        {
            date: '27.12.2021',
            second: true
        },
        {
            date: '28.12.2021',
            second: true
        },

        {
            date: '29.11.2021',
            third: true
        },
        {
            date: '30.12.2021',
            third: true
        },
        {
            date: '31.12.2021',
            third: true
        },
    ]
    constructor() {
        makeAutoObservable(this);
    }

    customizeCell = (args) => {
        if (moment(new Date()).format('DD.MM.YYYY') === args.date)
            return ('confirmColor');
    }

    renderDuty = () => {
        return (<div style={{ width: "100%", textAlign: 'center' }}>
            +
        </div>)
    }

}

export default DutyStore;
