import { makeAutoObservable } from 'mobx';
import React from 'react';
import QueryString from 'querystring';
import {
    notification,
    Select
} from 'antd';
import form_field from '../configs/task';
import api from "../utils/axiosAuthInterceptor";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)
const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

const axios = api;
const { Option } = Select;


class TasksStore {
    calendar = 'calendar';

    isModalOpen = false;

    calendarWeekends = true;
    isModalVacationOpen = false;
    errors = [];
    isLoaded = false;
    mobile = null;

    show = {
        province: true,
        city: true,
        factory: true,
        productGroup: true,
        model: true,
        testType: true,
        contract: true,
        request: true,
        videoType: true,
        placeType: true,
        workType: true,
    };

    selectedStart = "";
    selectedEnd = "";

    workTypes = [];

    tasksTypes = [];
    selectedTasksTypes = [];

    tasksTestTypes = [];
    selectedTasksTestTypes = [];

    videoTypes = [];
    contracts = [];
    contractsInModal = [];
    requests = [];
    placeTypes = [];

    cities = [];
    filteredCities = [];
    selectedCities = [];
    modalFilteredCities = [];

    provinces = [];
    selectedProvinces = [];

    departments = [];
    selectedDepartment = [];

    allModels = [];
    selectedModel = null;

    selectedCategory = [];

    clients = [];
    filteredClients = [];
    selectedClient = null;

    selectedUsers = [];
    filteredUsers = [];

    factories = [];
    filteredFactories = [];
    selectedFactories = [];
    modalFilteredFactories = [];

    selectedContracts = [];
    selectedTheme = undefined;

    performers = [];
    filteredPerformers = [];
    allTasks = [];
    tasks = [];
    filteredTasks = [];
    comments = [];
    taskComments = [];

    taskStartDatetime = '';
    taskEndDatetime = '';
    taskWorkType = null;
    taskTestTypes = [];
    taskVideoType = null;
    taskType = 16;
    taskPriority = 2;
    taskPerformer = null;
    taskFactory = undefined;
    taskProvince = undefined;
    taskCity = undefined;
    taskContracts = [
        {
            contract: undefined,
            batches: []
        }
    ];
    taskProductGroups = [
        {
            category: undefined,
            models: [],
            filteredModels: []
        }
    ];
    taskRequest = null;
    taskContractConsignment = [];

    taskEditClient = null;
    taskEditPerformer = null;

    userForReactSelect = null;

    saveButtonDisabled = true;
    performersFieldDisabled = true;

    saveCommentButtonDisabled = true;

    isModalTaskOpen = false;
    activeTask = {
        id: null,
        client_id: null,
        task_user_id: null,
        performer_id: null,
        category_id: null,
        category_article: null,
        category_name: null,
        model_id: null,
        model_name: null,
        factory_id: null,
        factory_name: null,
        filteredCities: [],
        city_id: null,
        city_name: null,
        province_id: null,
        province_name: null,
        contract_id: null,
        contract_name: null,
        contract_consignment: [],
        taskModel: [],
        taskType: null,
        taskProductGroup: null,
        taskFactory: null,
        taskCity: null,
        taskProvince: null,
        taskTestType: [],
        contracts: [],
        taskRequest: null,
        taskVideoType: null,
        taskWorkType: null,
        taskPlaceType: null,
        taskWorkTypeText: null,
        performers: [],
        models: [],
        theme: null,
        date: null,
        start: '',
        end: '',
        state_id: null,
        state_name: null,
        status: null,
        priority: null,
        comments: [],
        files: [],
        filesComments: [],
        taskAvailableStatuses: [],
        taskProductGroups: [],
        needNotice: false
    };

    isModalEditOpen = false;
    editTask = {
        id: null,
        client_id: null,
        task_user_id: null,
        performer_id: null,
        category_id: null,
        category_article: null,
        category_name: null,
        model_id: null,
        model_name: null,
        factory_id: null,
        factory_name: null,
        city_id: null,
        city_name: null,
        contract_consignment: [],
        filteredCities: [],
        productGroups: [],
        province_id: null,
        province_name: null,
        contract_id: null,
        contract_name: null,
        request_id: null,
        place_type_id: null,
        video_type_id: null,
        work_type_id: null,
        taskModel: [],
        taskProductGroup: null,
        taskFactory: undefined,
        taskCity: undefined,
        taskProvince: undefined,
        taskType: null,
        taskTestType: [],
        taskContract: null,
        contracts: [],
        taskRequest: null,
        taskVideoType: null,
        taskPlaceType: null,
        taskWorkType: null,
        taskWorkTypeText: null,
        performers: [],
        models: [],
        theme: null,
        description: "",
        date: null,
        start: '',
        end: '',
        state_id: null,
        state_name: null,
        status: null,
        priority: null,
        comments: [],
        files: [],
        filesComments: [],
        taskAvailableStatuses: [],
        needNotice: false
    };
    isSavingLoading = false;

    showFinishedTasks = false;

    taskStatuses = [];
    taskStatus = null;
    taskCommentButtonDisabled = true;
    availableStatuses = [];
    taskCategory = null;

    disableInputPerformer = true;
    productGroups = [];
    models = [];
    user = { id: null, dep_id: null };
    users = [];
    needNotice = true;
    priority = ['', '', ''];
    taskTypes = ['All tasks', 'My tasks', 'Assigned tasks', 'Other tasks'];

    consignment = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    selectedTaskType = 'All tasks';

    hideFilters = window.screen.width > '380' ? false : true;
    isModalView = false;

    isModalUserVacationOpen = false;
    vacationUserName = undefined;
    usersForVacation = [];
    usersForVacationFiltered = [];
    userForVacationHand = [];
    taskTheme = '';
    workTypeInput = '';
    descriptionInput = '';
    editWorkTypeInput = '';
    allProductGroups = [];
    canUpdateStructure = false;
    modelsDisabled = false;
    cursorPosition = {};


    constructor() {
        makeAutoObservable(this, {}, { deep: true });
    }

    checkMobile = () => {
        this.mobile = md.mobile();
        if (this.mobile)
            this.hideFilters = true;
    }

    checkPresenceRusLetter(str = '') {
        let regexp = /[А-яЁё]/gm;
        return regexp.test(str);
    }

    changeShowField = (e) => {
        let show = this.show;

        for (const item of form_field) {
            if (+item.task_type_id === +e) {
                for (let key in show) {
                    let flag = false;
                    for (const field of item.field) {
                        if (field.name === key) flag = true;
                    }
                    show[key] = flag;
                }
                break;
            }
        }

        this.show = show;
    }

    changeWorkTypeInput = (e) => {
        this.workTypeInput = e.target.value;
    }

    changeDescriptionInput = (e) => {
        this.descriptionInput = e.target.value;
    }

    changeEditWorkTypeInput = (e) => {
        this.editWorkTypeInput = e.target.value;
    }

    openModal = (date = undefined) => {
        //Время по умолчанию для RangePicker
        let defaultTime = dayjs().utc();
        let defaultTimeEnd = dayjs().utc();
        if (date) {
            defaultTime = dayjs.utc(date.dateStr);
            defaultTimeEnd = dayjs.utc(date.dateStr);
        }

        defaultTime = defaultTime.set('hour', 9).set('minute', 0).set('second', 0);
        defaultTimeEnd = defaultTimeEnd.set('hour', 18).set('minute', 0).set('second', 0);


        this.changeShowField(16);

        this.saveButtonDisabled = true;
        this.isModalOpen = true;
        this.performersFieldDisabled = true;
        this.modalFilteredFactories = this.factories;
        this.modalFilteredCities = this.cities;
        this.modalFilteredContracts = this.contracts;
        this.taskProvince = undefined;
        this.taskCity = undefined;
        this.taskFactory = undefined;
        this.taskType = undefined;
        this.taskPerformer = [];
        this.taskStartDatetime = defaultTime;
        this.taskEndDatetime = defaultTimeEnd;
        this.taskContracts = [
            {
                contract_id: undefined,
                batches: []
            }
        ];
    }

    closeModal = () => {
        this.isModalOpen = false;
        this.taskTheme = '';
        this.taskProductGroup = undefined;
        this.taskModel = [];
        this.enableAllContracts();
    };

    closeModalUserVacation = () => { this.isModalUserVacationOpen = false; }

    toggleShowFilters = _ => {
        this.hideFilters = !this.hideFilters
    };

    enableAllContracts = () => {
        for (let contractModal of this.contractsInModal)
            contractModal.disabled = false;
    }

    closeEditModal = () => {
        this.isModalTaskOpen = true;
        this.isModalEditOpen = false
        this.enableAllContracts();
    }

    toggleModalTask = () => {
        this.isModalTaskOpen = !this.isModalTaskOpen;
    }


    getData = (search) => {
        fetch(`/api/node/v1/tasks/get/data`)
            .then(response => response.json())
            .then(response => {

                this.setStateResponseInfo(response);
                this.getTasks(null, null, null, search);

            })
            .catch(err => console.error(err))
    };

    getTasks = (all = false, start, end, search) => {
        if (all === true) {
            start = '';
            end = '';
        }
        const parsed = QueryString.parse(search);
        axios.post(`/api/node/v1/tasks/get/tasks`, { start: start, end: end })
            .then(response => response.data)
            .then(response => {

                this.setStateResponseTask(response);
                this.commonFilterTasks();

                if (parsed.hasOwnProperty('id')) {
                    if (Number.isInteger(+parsed.id)) {
                        this.openTask(+parsed.id)
                    }
                }

            })
            .catch(err => console.error(err))
    };

    setStateResponseInfo = (response) => {
        this.canUpdateStructure = response.canUpdateStructure;
        this.cities = response.cities;
        this.provinces = response.provinces;
        this.departments = response.departments;
        this.factories = response.factories;
        this.filteredFactories = response.factories;
        this.modalFilteredFactories = response.factories;
        this.filteredCities = response.cities;
        this.modalFilteredCities = response.cities;
        this.tasksTypes = response.tasksTypes;
        this.tasksTestTypes = response.tasksTestTypes;
        this.contracts = response.contracts;
        this.contractsInModal = response.contracts;
        this.requests = response.requests;
        this.videoTypes = response.videoTypes;
        this.placeTypes = response.placeTypes;
        this.workTypes = response.workTypes;
        this.taskProductGroups[0].filteredModels = response.models;
        this.allModels = response.models;
        this.clients = response.clients;
        this.filteredClients = response.clients;
        this.performers = response.users;
        this.filteredPerformers = response.users;
        this.userForReactSelect = response.user;
        this.taskStatuses = response.taskStatuses;
        this.productGroups = response.productGroups;
        this.models = response.models;
        this.users = response.allUsers;
        this.allProductGroups = response.allProductGroups;

        this.isLoaded = true;
    };

    setStateResponseTask = (response) => {
        this.allTasks = response.tasks;
        this.userForReactSelect = response.user;
        this.tasksNegotiations = response.tasksNegotiations;
        this.user = response.user;
        this.isLoaded = true;
    };

    setStateResponseData = (response) => {
        this.allTasks = response.tasks;
        this.cities = response.cities;
        this.provinces = response.provinces;
        this.departments = response.departments;
        this.factories = response.factories;
        this.filteredFactories = response.factories;
        this.modalFilteredFactories = response.factories;
        this.filteredCities = response.cities;
        this.modalFilteredCities = response.cities;
        this.tasksTypes = response.tasksTypes;
        this.tasksTestTypes = response.tasksTestTypes;
        this.contracts = response.contracts;
        this.contractsInModal = response.contracts;
        this.requests = response.requests;
        this.videoTypes = response.videoTypes;
        this.placeTypes = response.placeTypes;
        this.workTypes = response.workTypes;
        this.filteredModels = this.allModels;
        this.tasksNegotiations = response.tasksNegotiations;
        this.clients = response.clients;
        this.filteredClients = response.clients;
        this.performers = response.users;
        this.filteredPerformers = response.users;

        this.isLoaded = true;

        this.taskStatuses = response.taskStatuses;
        //Smart
        this.productGroups = response.productGroups;
        this.models = response.models;
        this.user = response.user;
        this.users = response.allUsers;
    };

    commonFilterTasks = _ => {
        //Фильтр по типу задач (мои, назначенные, остальные)
        let tasksFromType = this.getTasksFromType();

        let typesTasks = this.getTypesTasks(tasksFromType);

        //Фильтрация по провинции
        let provincesTask = this.getProvincesTasks(typesTasks);

        //Фильтрация по городу
        let citiesTask = this.getCitiesTasks(provincesTask);

        //Фильтрация по фабрике
        let factoriesTask = this.getFactoriesTasks(citiesTask);

        //Отображать законченные задачи
        let finished = this.getFinishedTasks(factoriesTask);

        //Фильтрация по отделу
        let departmentTasks = this.getDepartmentsTasks(finished);

        //Фильтрация по группе товара
        let productGroupsTasks = this.getProductGroupsTasks(departmentTasks);

        //Фильтрация по контракту
        let contractsTasks = this.getContractsTasks(productGroupsTasks);

        //Фильтрация по теме
        let themeTasks = this.getThemeTasks(contractsTasks);

        //Фильтрация по дате
        let dateTasks = this.getDateTasks(themeTasks);

        //Фильтрация по пользователю
        let usersTasks = this.getUsersTasks(dateTasks);

        //Фильтрация пользователей по департаменту
        let filteredUsers = [];
        let departments = this.selectedDepartment;

        if (departments.length) {
            let dep_ids = [];
            for (let i = 0; i < departments.length; i++) {
                dep_ids.push(+departments[i].split('_')[0])
            }

            for (let j = 0; j < this.users.length; j++) {
                if (dep_ids.includes(this.users[j].dep_id)) {
                    filteredUsers.push(this.users[j])
                }
            }

        } else {
            filteredUsers = this.users.map(user => user);
        }

        let usersForCalendar = [];
        let selectedUser = this.selectedUsers;
        if (selectedUser.length) {
            let userIds = [];
            for (let i = 0; i < selectedUser.length; i++) {
                userIds.push(+selectedUser[i].split('_')[0])
            }
            for (let j = 0; j < filteredUsers.length; j++) {
                if (userIds.includes(filteredUsers[j].id)) {
                    usersForCalendar.push(filteredUsers[j])
                }
            }
        } else {
            usersForCalendar = filteredUsers
        }


        //Фильтрация фабрик и городов по провинции
        let filteredCities = [];
        let filteredFactories = [];
        let provinces = this.selectedProvinces;
        if (provinces.length) {
            for (let j = 0; j < this.cities.length; j++) {
                if (provinces.includes(this.cities[j].province_id)) {
                    filteredCities.push(this.cities[j])
                }
            }
            for (let j = 0; j < this.factories.length; j++) {
                if (provinces.includes(this.factories[j].province_id)) {
                    filteredFactories.push(this.factories[j])
                }
            }

        } else {
            filteredCities = this.cities.map(city => city);
            filteredFactories = this.factories.map(factory => factory);
        }


        //Фильтрация фабрик по городу
        let cities = this.selectedCities;
        let tmp = [];
        if (cities.length) {
            for (let j = 0; j < this.filteredFactories.length; j++) {
                if (cities.includes(this.filteredFactories[j].city_id)) {
                    tmp.push(this.filteredFactories[j])
                }
            }
            filteredFactories = tmp;
        }

        //Добавление в задачи пользователей (Пока не найду способ передать свои props в Fullcalendar)
        let TasksWithUsersProps = this.addFilteredUsersToTasks(usersTasks, usersForCalendar);

        let usersForVacationFiltered = [];
        if (this.calendar === 'vacation') {
            let tmp = this.filteredUsersVacationOnDepartment();
            usersForVacationFiltered = this.filteredUsersVacationOnUser(tmp);
        }

        this.filteredTasks = TasksWithUsersProps;
        this.filteredUsers = filteredUsers;
        this.filteredCities = filteredCities;
        this.filteredFactories = filteredFactories;
        this.usersForVacationFiltered = usersForVacationFiltered;

    };

    //фильтрация пользователей во вкладке vacation по департаменту
    filteredUsersVacationOnDepartment = _ => {
        let usersForVacation = this.usersForVacation;
        let departments = this.selectedDepartment;
        let dep_ids = [];
        let filtered = [];
        if (departments.length < 1) {
            filtered = usersForVacation;
        } else {
            for (let i = 0; i < departments.length; i++) {
                dep_ids.push(+departments[i].split('_')[0])
            }

            for (let i = 0; i < usersForVacation.length; i++) {
                if (dep_ids.includes(usersForVacation[i].dep_id)) {
                    filtered.push(usersForVacation[i])
                }
            }
        }

        return filtered;
    };

    //фильтрация пользователей во вкладке vacation по пользователю
    filteredUsersVacationOnUser = (usersForVacation) => {
        let users = this.selectedUsers;
        let userIds = [];
        let filtered = [];

        if (users.length < 1) {
            filtered = usersForVacation;
        } else {
            for (let i = 0; i < users.length; i++) {
                userIds.push(+users[i].split('_')[0])
            }
            for (let i = 0; i < usersForVacation.length; i++) {
                if (userIds.includes(usersForVacation[i].id)) {
                    filtered.push(usersForVacation[i])
                }
            }
        }

        return filtered;
    };

    //Фильтр по типу задач (мои, назначенные, остальные)
    getTasksFromType = _ => {
        let tasks = JSON.parse(JSON.stringify(this.allTasks));
        let user = this.user;
        let filteredTasks = [];

        for (let i = 0; i < tasks.length; i++) {
            let performersIds = [];
            for (let j = 0; j < tasks[i].performers.length; j++) {
                performersIds.push(tasks[i].performers[j].user_id)
            }

            switch (this.selectedTaskType) {
                case 'All tasks':
                    filteredTasks.push(tasks[i]);
                    break;
                case 'My tasks':
                    if (performersIds.includes(user.id)) {
                        filteredTasks.push(tasks[i])
                    }
                    break;
                case 'Assigned tasks':
                    if (user.id === tasks[i].task_user_id) {
                        filteredTasks.push(tasks[i])
                    }
                    break;
                case 'Other tasks':
                    if (!performersIds.includes(user.id) && user.id !== tasks[i].task_user_id) {
                        filteredTasks.push(tasks[i])
                    }
                    break;
                default: break;
            }
        }
        return filteredTasks;
    };

    //Фильтрация по типу
    getTypesTasks = (tasks) => {
        let type = this.selectedTasksTypes;
        let filtered = [];
        if (type.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                if (type.includes(tasks[i].type_id)) {
                    exist = true;
                }

                if (exist) filtered.push(tasks[i])
            }
        }
        return filtered;
    };

    //Фильтрация законченых задач
    getFinishedTasks = (tasks) => {
        let filtered = [];

        if (this.showFinishedTasks) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                if (tasks[i].status !== 'done') {
                    filtered.push(tasks[i])
                }
            }
        }
        return filtered;
    };

    //Фильтрация по отделу
    getDepartmentsTasks = (tasks) => {
        let departments = this.selectedDepartment;
        let dep_ids = [];
        let filtered = [];

        if (departments.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < departments.length; i++) {
                dep_ids.push(+departments[i].split('_')[0])
            }

            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                for (let j = 0; j < tasks[i].performers.length; j++) {
                    if (dep_ids.includes(tasks[i].performers[j].dep_id)) {
                        exist = true;
                    }
                }

                if (exist) filtered.push(tasks[i])
            }
        }

        return filtered;
    };

    //Фильтрация по группе товара
    getProductGroupsTasks = (tasks) => {
        let category = this.selectedCategory;
        let filtered = [];

        if (category.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                for (let taskCategory of tasks[i].taskProductGroups)
                    if (category.includes(taskCategory.value)) {
                        exist = true;
                        break;
                    }

                if (exist) filtered.push(tasks[i])
            }
        }

        return filtered;
    };

    //Фильтрация по контракту
    getContractsTasks = (tasks) => {
        let contracts = this.selectedContracts;
        let filtered = [];

        if (contracts.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                for (let contract of tasks[i].contracts)
                    if (contracts.includes(contract.contract_id)) {
                        exist = true;
                        break;
                    }

                if (exist) filtered.push(tasks[i])
            }

        }

        return filtered;
    };

    //Фильтрация по теме
    getThemeTasks = (tasks) => {
        let theme = this.selectedTheme;
        let filtered = [];

        if (theme === "" || theme === undefined) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                if (tasks[i].theme.toLowerCase().indexOf(theme.toLowerCase()) >= 0)
                    filtered.push(tasks[i])
            }
        }

        return filtered;
    };

    //Фильтрация по дате
    getDateTasks = (tasks) => {
        let start = new Date(this.selectedStart).getTime();
        let end = new Date(this.selectedEnd).getTime();

        let filtered = [];

        if (isNaN(start) || isNaN(end)) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;
                let tmpStart = new Date(tasks[i].start).getTime();
                let tmpEnd = new Date(tasks[i].end).getTime();
                if (start <= tmpStart && end >= tmpEnd) {
                    exist = true;
                }

                if (exist) filtered.push(tasks[i])
            }

        }

        return filtered;
    };

    //Фильтрация по провинции
    getProvincesTasks = (tasks) => {
        let provinces = this.selectedProvinces;
        let filtered = [];
        if (provinces.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                if (provinces.includes(tasks[i].province_id)) {
                    exist = true;
                }

                if (exist) filtered.push(tasks[i])
            }

        }

        return filtered;
    };

    //Фильтрация по городу
    getCitiesTasks = (tasks) => {
        let cities = this.selectedCities;
        let filtered = [];

        if (cities.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                if (cities.includes(tasks[i].city_id)) {
                    exist = true;
                }

                if (exist) filtered.push(tasks[i])
            }

        }

        return filtered;
    };

    //Фильтрация по фабрике
    getFactoriesTasks = (tasks) => {
        let factories = this.selectedFactories;
        let filtered = [];

        if (factories.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                if (factories.includes(tasks[i].factory_id)) {
                    exist = true;
                }

                if (exist) filtered.push(tasks[i])
            }

        }

        return filtered;
    };

    //Фильтрация по пользователю
    getUsersTasks = (tasks) => {
        let users = this.selectedUsers;
        let userIds = [];
        let filtered = [];

        if (users.length < 1) {
            filtered = tasks;
        } else {
            for (let i = 0; i < users.length; i++) {
                userIds.push(+users[i].split('_')[0])
            }
            for (let i = 0; i < tasks.length; i++) {
                let exist = false;

                for (let j = 0; j < tasks[i].performers.length; j++) {
                    if (userIds.includes(tasks[i].performers[j].user_id)) {
                        exist = true;
                    }
                }

                if (exist) filtered.push(tasks[i])
            }
        }

        return filtered;
    };

    addFilteredUsersToTasks = (tasks, filteredUsers) => {
        for (let i = 0; i < tasks.length; i++) {
            tasks[i].filteredUsers = filteredUsers;
        }

        return tasks;
    };

    deleteTask = () => {
        if (window.confirm("Удалить задачу?")) {
            let taskId = this.activeTask.id;
            let checking = Number.isInteger(taskId) < 200 && taskId < 99999999999;
            this.isModalTaskOpen = false;
            if (checking) {
                fetch('/api/node/v1/task/delete/' + taskId)
                    .then(response => response.json())
                    .then(response => {

                        if (response.hasOwnProperty('tasks')) {
                            this.setStateResponseData(response);
                            this.allTasks = response.tasks;
                            this.commonFilterTasks();
                        } else { console.log("Ошибка при сохранении") }
                        this.closeModal();
                    })
                    .catch(err => console.error(err));
            }
        }
    };

    openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            placement: 'bottomRight'
        });
    };

    saveNewTask = () => {
        let theme = this.taskTheme;
        let model = null;
        let productGroups = this.taskProductGroups;
        let performers = [];
        if (this.taskPerformer.length > 0) performers = this.taskPerformer;
        const contracts = this.taskContracts;
        const request = this.taskRequest;
        const videoType = this.taskVideoType;
        const factory = this.taskFactory;
        const city = this.taskCity;
        const province = this.taskProvince;
        const type = this.taskType;
        const priority = this.taskPriority;
        const testType = this.taskTestTypes;
        const placeType = this.taskPlaceType;
        const workType = this.taskWorkType;
        let workTypeText = +workType === 4 ? this.workTypeInput : '';
        let startDate = this.taskStartDatetime;
        let endDate = this.taskEndDatetime;
        let description = this.descriptionInput;
        let files = document.getElementById('addFiles');
        let needNotice = this.needNotice;

        if (this.checkPresenceRusLetter(theme) || this.checkPresenceRusLetter(description) || this.checkPresenceRusLetter(workTypeText)) {
            this.openNotificationWithIcon('error', 'The text must not contain Russian characters!');
        } else {
            //добавление id исполнителей
            let performersIds = [];
            for (let i = 0; i < performers.length; i++) {
                performersIds.push(performers[i].value)
            }

            //Проверка значений
            let checking = theme.length < 200

                && performers.length > 0
                && startDate !== '' && endDate !== ''
                && Number.isInteger(priority) && priority < 9
                && Number.isInteger(type) && type > 0
                && description.length < 9999;

            if (checking) {
                this.saveButtonDisabled = true;

                let data = {
                    theme: theme,
                    model: model,
                    productGroups: productGroups,
                    factory: factory,
                    city: city,
                    province: province,
                    type: type,
                    videoType: videoType,
                    placeType: placeType,
                    workType: workType,
                    testType: testType,
                    workTypeText: workTypeText,
                    contracts: contracts,
                    request: request,
                    performers: performersIds,
                    startDate: startDate,
                    endDate: endDate,
                    priority: priority,
                    description: description,
                    needNotice: needNotice
                };


                axios.post('/api/node/v1/task/new/savetask', data)
                    .then(response => {

                        //Загрузка файлов
                        if (response.hasOwnProperty('data')) {
                            let typeId = response.data.data.taskId;

                            for (let i = 0; i < files.files.length; i++) {

                                let data = new FormData();
                                data.append('file', files.files[i]);
                                axios.post('/api/node/v1/file/upload/task/' + typeId, data)
                            }
                        }
                        //Конец загрузки файлов

                        //Получить id сохраненного таска
                        if (response.data.hasOwnProperty('tasks')) {
                            let tempResponse = response.data.data;
                            this.setStateResponseData(response.data.data);

                            this.allTasks = tempResponse.tasks;
                            this.isLoaded = true;
                            this.commonFilterTasks();
                        }

                        this.closeModal();
                        this.openNotificationWithIcon('success', 'Task saved successfully!')
                    })
                    .then(() => { this.getTasks() })
                    .catch(error => { console.log(error) });

            } else {
                this.saveButtonDisabled = false;
            }
        }
    };

    saveEditedTask = () => {
        this.isSavingLoading = true;
        let taskId = this.editTask.id;
        let theme = this.editTask.theme;
        let productGroups = this.editTask.productGroups;
        let factory = null;
        if (this.editTask.factory_id !== null) factory = this.editTask.factory_id;
        let city = null;
        if (this.editTask.city_id !== null) city = this.editTask.city_id;
        let province = null;
        if (this.editTask.province_id !== null) province = this.editTask.province_id;
        let testType = this.editTask.taskTestType;
        let performers = this.editTask.performers;
        let start = dayjs.utc(this.editTask.start);
        let end = dayjs.utc(this.editTask.end);
        let priority = +this.editTask.priority;
        let type = +this.editTask.type_id;
        let contracts = this.editTask.contracts;
        let request = +this.editTask.request_id;
        let placeType = +this.editTask.place_type_id;
        let videoType = +this.editTask.video_type_id;
        let description = this.editTask.description;
        let needNotice = this.editTask.needNotice;
        let files = document.getElementById('editFiles');
        let workType = this.editTask.work_type_id;
        let workTypeText = '';
        if (workType === 4) workTypeText = this.editWorkTypeInput;

        if (this.checkPresenceRusLetter(theme) || this.checkPresenceRusLetter(description) || this.checkPresenceRusLetter(workTypeText)) {
            this.isSavingLoading = false;
            this.openNotificationWithIcon('error', 'The text must not contain Russian characters!');
        } else {
            //добавление id исполнителей
            let performersIds = [];
            for (let i = 0; i < performers.length; i++) {
                performersIds.push(performers[i].value)
            }

            let checking =
                theme.length < 200
                && performersIds.length > 0
                && start !== '' && end !== ''
                && Number.isInteger(priority) && priority < 9
                && Number.isInteger(type) && type > 0
                && description.length < 9999;

            if (checking) {
                let data = {
                    productGroups: productGroups,
                    taskId: taskId,
                    theme: theme,
                    province: province,
                    city: city,
                    type: type,
                    request: request,
                    placeType: placeType,
                    videoType: videoType,
                    testType: testType,
                    workType: workType,
                    workTypeText: workTypeText,
                    factory: factory,
                    contracts: contracts,
                    performers: performersIds,
                    startDate: start,
                    endDate: end,
                    priority: priority,
                    description: description,
                    needNotice: needNotice
                };

                let requestAddress = taskId ? '/api/node/v1/task/edit/savetask' : '/api/node/v1/task/new/savetask';

                axios.post(requestAddress, data)
                    .then(response => {
                        if (response.data.success) {
                            if (!taskId) {
                                taskId = response.data.data.taskId;
                            } else {
                                this.setStateResponseData(response.data.data);
                            }

                            for (let i = 0; i < files.files.length; i++) {
                                let data = new FormData();
                                data.append('file', files.files[i]);
                                axios.post('/api/node/v1/file/upload/task/' + taskId, data)
                                    .then((response) => {
                                        console.log('response: ', response.data, response.status);
                                    })
                            }

                            this.openNotificationWithIcon('success', 'Task saved successfully!');
                            this.getTasks();

                        } else {
                            this.openNotificationWithIcon('error', 'Error!');
                        }
                        this.isSavingLoading = false;
                        this.enableAllContracts();
                    })
                    .catch(error => {
                        console.log(error);
                    });
                this.isModalEditOpen = false;
            }
        }
    };

    editTaskFunc = async (isNewTask = false) => {
        this.openTask(this.activeTask.id);
        this.performersFieldDisabled = false;
        this.editTask = JSON.parse(JSON.stringify(this.activeTask));
        this.editTask.productGroups = [];

        for (let group of this.activeTask.taskProductGroups) {
            let response = await fetch('/api/node/v1/filter/model/' + group.value);
            let models = await response.json();

            this.editTask.productGroups.push({
                category: group.value,
                models: this.activeTask.models.filter(model => +model.category_id === +group.value).map(model => model.id),
                filteredModels: models
            })
        }
        if (this.activeTask.taskProductGroups.length === 0) {
            this.editTask.productGroups.push({
                category: undefined,
                models: [],
                filteredModels: this.allModels
            })
        }

        for (let editContract of this.editTask.contracts) {
            let index = this.contractsInModal.findIndex(e => e.id === editContract.contract_id);
            this.contractsInModal[index].disabled = true;
        }
        if (isNewTask === true)
            this.editTask.id = null;
        this.isModalTaskOpen = false;
        this.isModalOpen = false;
        this.isModalEditOpen = true;
        this.saveButtonDisabled = false;
        this.modalFilteredFactories = this.factories;
        this.modalFilteredCities = this.cities;

        let taskType = this.activeTask.taskType.value;
        this.changeShowField(taskType)
    };

    saveNewComment = async () => {
        let task = this.activeTask;
        let taskId = this.activeTask.id;
        let comment = document.getElementById('comment').value;
        let files = document.getElementById('addCommentFiles');
        let saveData = {
            taskId: taskId,
            commentId: null,
            comment: comment,
        };

        //Проверка значений
        let checking = taskId < 99999999999 && Number.isInteger(taskId)
            && comment.length < 9999;

        if (checking) {
            this.saveCommentButtonDisabled = true;
            let response = await axios.post('/api/node/v1/task/new/savecomment', saveData)
            if (response.data.hasOwnProperty('comments')) {
                task.comments = response.data.comments;
                let insertId = response.data.insertId;
                let type = 'comment';

                for (let file of files.files) {
                    let data = new FormData();
                    data.append('file', file);
                    await axios.post('/api/node/v1/file/upload/' + type + '/' + insertId, data);
                }

                this.getTaskCommentsWithFiles(taskId, task)

                //Отчистка поля комментария и input file
                document.getElementById('comment').value = '';
                document.getElementById('addCommentFiles').value = '';
                this.task = task;
                this.openNotificationWithIcon('success', 'Comment add!');
            } else {
                console.error('comment not saved!');
            }
            this.saveCommentButtonDisabled = false;
        }
    };

    getTaskCommentsWithFiles = (taskId, task) => {
        fetch('/api/node/v1/task/comments/getOne/' + taskId)
            .then(response => response.json())
            .then(response => {
                task.comments = response;

                this.activeTask = task;
                this.taskCommentButtonDisabled = true;

            })
            .catch(err => console.error(err));
    };

    saveNewStatus = (e) => {
        let task = this.activeTask;
        let taskId = this.activeTask.id;
        let oldStatus = this.activeTask.status;
        let newStatus = e.target.value;
        let availableStatuses = ['backlog', 'progress', 'need_checking', 'done', 'rejected'];
        let index = e.target.selectedIndex;

        let conf = window.confirm("Change status on " + e.target[index].text + "?");

        if (conf) {
            let saveData = {
                taskId: taskId,
                newStatus: newStatus
            };

            //Проверка значений
            let checking = taskId < 99999999999 && Number.isInteger(taskId)
                && availableStatuses.includes(newStatus) && oldStatus !== newStatus;

            if (checking) {
                axios.post('/api/node/v1/task/status/update/' + taskId, saveData)
                    .then(response => {
                        if (availableStatuses.includes(response.data)) {
                            task.status = newStatus;
                            this.activeTask = task;
                        }
                        this.getTasks();
                    })
                    .catch(error => { console.log(error) });
            }
        }
    };

    sortFilters = (objectsArray) => {
        return objectsArray.sort((a, b) => {
            if (b.label > a.label) return -1;
            if (b.label < a.label) return 1;

            return 0;
        });
    };

    changeEditVacationDate = (value, dateString) => {

        this.startEditVacation = dateString[0];
        this.endEditVacation = dateString[1];

        this.checkEditVacationSaveButton();

    };

    changeEditVacationDesc = () => {
        let val = document.getElementById('edit-vacation-description').value;

        this.descriptionEditVacation = val;

        this.checkEditVacationSaveButton();

    };

    changeEditVacationType = (e) => {
        this.typeEditVacation = e;
        this.checkEditVacationSaveButton();
    };

    changePriority = (e) => {
        this.editTask.priority = e;
    };

    changeNewTaskDate = (value, dateString) => {
        this.taskStartDatetime = dateString[0] ? dayjs.utc(dateString[0]) : null;
        this.taskEndDatetime = dateString[1] ? dayjs.utc(dateString[1]) : null;
        this.checkTaskToActivateSaveButton();
    };

    filterModels = async (e, index) => {
        this.taskProductGroups[index].models = e;
        if (!this.taskProductGroups[index].category && e.length === 1) {
            this.modelsDisabled = true;
            let categoryId = this.allModels.find(el => el.id === e[0]).categories;
            if (categoryId) {
                categoryId = await this.findProductGroup(categoryId);
                this.taskProductGroups[index].category = categoryId;
                this.changeTaskModelOption(categoryId, index);
            }
            this.modelsDisabled = false;
        }
    };

    findProductGroup = async (categoryId) => {
        let category = this.allProductGroups.find(el => el.id === categoryId);
        if (category.type === 'group') {
            return category.id
        } else {
            return await this.findProductGroup(category.parent_id);
        }
    }

    filterProvinces = (e) => {
        this.taskProvince = e
        this.taskCity = undefined;
        this.taskFactory = undefined;

        this.filterSelectInModal();
    };

    filterCities = (e) => {
        this.taskFactory = undefined;
        this.taskCity = e;
        this.filterSelectInModal();
    };

    filterFactories = (e) => {
        this.taskFactory = e
        this.filterSelectInModal();
    };

    filterTypes = (e) => {

        this.taskType = e;
        this.checkTaskToActivateSaveButton();
        this.changeShowField(e);
    };


    filterPriorities = (e) => {
        this.taskPriority = +e;
        console.log(this.taskPriority)
    };

    filterContracts = (e, index) => {
        let contractsIndex = undefined;
        if (e) {
            contractsIndex = this.contractsInModal.findIndex(el => el.id === e);
            this.contractsInModal[contractsIndex].disabled = true;
        }

        let prevValue = this.taskContracts[index].contract_id;
        if (prevValue) {
            contractsIndex = this.contractsInModal.findIndex(el => el.id === prevValue);
            this.contractsInModal[contractsIndex].disabled = false;
        }

        this.taskContracts[index].contract_id = e;
        this.taskContracts[index].batches = [];
    };

    filterContractConsignment = (e, index) => {
        this.taskContracts[index].batches = e;
    };

    filterRequests = (e) => {
        e !== null ? this.taskRequest = e : this.taskRequest = null;
    };

    filterPlaceType = (e) => {
        e !== null ? this.taskPlaceType = e : this.taskPlaceType = null;
    };

    filterVideoType = (e) => {
        e !== null ? this.taskVideoType = e : this.taskVideoType = null;
    };

    filterWorkType = (e) => {
        e !== null
            ? this.taskWorkType = e
            : this.taskWorkType = null;
    };

    filterTestType = (e) => {
        e !== null
            ? this.taskTestTypes = e
            : this.taskTestTypes = [];
    };

    filterSelectInModal = (type = '') => {
        let province_id, city_id, factory_id;
        let modalFilteredCities = this.cities;
        let newModalFilteredCities = [];
        let modalFilteredFactories = this.factories;
        let newModalFilteredFactories = [];
        let modalFilteredContracts = this.contracts;
        let newModalFilteredContracts = [];
        if (type === 'edit') {
            province_id = this.editTask.province_id;
            city_id = this.editTask.city_id;
            factory_id = this.editTask.factory_id;
        } else {
            province_id = this.taskProvince;
            city_id = this.taskCity;
            factory_id = this.taskFactory;
        }

        if ((typeof province_id === 'undefined') && !city_id) {
            newModalFilteredCities = this.cities;
            newModalFilteredFactories = this.factories;
        } else {
            if (city_id > 0) {
                let city = this.cities.find(el => el.id === city_id);
                if (city && !province_id) {
                    province_id = city.province_id;
                    if (type === 'edit') {
                        this.editTask.province_id = city.province_id;
                    } else {
                        this.taskProvince = city.province_id;
                    }
                }
            }

            for (let i = 0; i < modalFilteredCities.length; i++) {
                if (modalFilteredCities[i]['province_id'] === province_id)
                    newModalFilteredCities.push(modalFilteredCities[i]);
            }

            for (let i = 0; i < modalFilteredFactories.length; i++)
                if (modalFilteredFactories[i]['province_id'] === province_id)
                    newModalFilteredFactories.push(modalFilteredFactories[i]);
        }

        if (city_id > 0) {
            let tmp = newModalFilteredFactories;
            newModalFilteredFactories = [];
            for (let i = 0; i < tmp.length; i++)
                if (tmp[i]['city_id'] === city_id)
                    newModalFilteredFactories.push(tmp[i]);
        }

        if (factory_id > 0) {
            if (type === 'edit') {
                this.editTask.contract_id = undefined;
            } else {
                this.editTask.contract_id = undefined;
            }
            for (let i = 0; i < modalFilteredContracts.length; i++) {
                if (modalFilteredContracts[i]['factory_id'] === factory_id) {
                    newModalFilteredContracts.push(modalFilteredContracts[i]);
                }
            }

            this.contractsInModal = newModalFilteredContracts;

            for (let i = 0; i < modalFilteredFactories.length; i++) {
                if (modalFilteredFactories[i]['id'] === factory_id) {
                    province_id = modalFilteredFactories[i]['province_id'] > 0 ? modalFilteredFactories[i]['province_id'] : undefined;
                    if (type === 'edit') {
                        this.editTask.province_id = modalFilteredFactories[i]['province_id'] > 0 ? modalFilteredFactories[i]['province_id'] : undefined;
                        this.editTask.city_id = modalFilteredFactories[i]['city_id'] > 0 ? modalFilteredFactories[i]['city_id'] : undefined;
                    } else {
                        this.taskProvince = modalFilteredFactories[i]['province_id'] > 0 ? modalFilteredFactories[i]['province_id'] : undefined;
                        this.taskCity = modalFilteredFactories[i]['city_id'] > 0 ? modalFilteredFactories[i]['city_id'] : undefined;
                    }
                    break;
                }
            }
            newModalFilteredCities = [];
            for (let i = 0; i < modalFilteredCities.length; i++) {
                if (modalFilteredCities[i]['province_id'] === province_id)
                    newModalFilteredCities.push(modalFilteredCities[i]);
            }
            newModalFilteredFactories = [];
            for (let i = 0; i < modalFilteredFactories.length; i++)
                if (modalFilteredFactories[i]['province_id'] === province_id)
                    newModalFilteredFactories.push(modalFilteredFactories[i]);
        }
        else {
            this.contractsInModal = modalFilteredContracts;
        }

        this.modalFilteredCities = newModalFilteredCities;
        this.modalFilteredFactories = newModalFilteredFactories;
    }

    filterEditWorkType = (e) => {
        e !== null
            ? this.editTask.work_type_id = e
            : this.editTask.work_type_id = null;

        this.editTask.taskWorkTypeText = null;
    };

    filterEditTestType = (e) => {

        e !== null
            ? this.editTask.taskTestType = e
            : this.editTask.taskTestType = [];

    };

    filterEditContracts = (e, index) => {
        let contractsIndex = undefined;
        if (e) {
            contractsIndex = this.contractsInModal.findIndex(el => el.id === e);
            this.contractsInModal[contractsIndex].disabled = true;
        }

        let prevValue = this.editTask.contracts[index].contract_id;
        if (prevValue) {
            contractsIndex = this.contractsInModal.findIndex(el => el.id === prevValue);
            this.contractsInModal[contractsIndex].disabled = false;
        }

        this.editTask.contracts[index].contract_id = e;
        this.editTask.contracts[index].batches = [];
    };

    filterEditRequest = (e) => {
        e !== null
            ? this.editTask.request_id = e
            : this.editTask.request_id = null;
    };

    filterEditPlaceType = (e) => {
        e !== null
            ? this.editTask.place_type_id = e
            : this.editTask.place_type_id = null;
    };

    filterEditVideoType = (e) => {
        e !== null
            ? this.editTask.video_type_id = e
            : this.editTask.video_type_id = null;
    };

    filterEditCities = (e) => {
        this.editTask.factory_id = undefined;
        this.editTask.city_id = e;

        this.filterSelectInModal('edit');
    };

    filterEditFactories = (e) => {
        this.editTask.factory_id = e;
        this.filterSelectInModal('edit');
    };

    changeEditType = (e) => {
        e !== null
            ? this.editTask.type_id = e
            : this.editTask.type_id = null;
        this.changeShowField(e);
    };

    filterEditProvinces = (e) => {
        let editTask = this.editTask;
        editTask.city_id = undefined;
        editTask.factory_id = undefined;
        editTask.province_id = e;

        this.editTask = editTask;
        this.filterSelectInModal('edit');
    };

    selectCategory = (e, index) => {
        this.taskProductGroups[index].category = e;
        this.taskProductGroups[index].models = [];

        this.changeTaskModelOption(e, index);
    };

    filterEditProductGroups = (e, index) => {
        this.editTask.productGroups[index].category = e;
        this.editTask.productGroups[index].models = [];
        this.changeTaskModelOption(e, index, true);
    };

    changeTaskModelOption = (productGroup, index, edit = false) => {
        if (productGroup > 0)
            fetch('/api/node/v1/filter/model/' + productGroup)
                .then(response => response.json())
                .then(response => {
                    if (response.length >= 0 && Array.isArray(response)) {
                        if (edit)
                            this.editTask.productGroups[index].filteredModels = response;
                        else
                            this.taskProductGroups[index].filteredModels = response;
                    } else { console.log("Ошибка при сохранении") }
                })
                .catch(err => console.error(err));
        else
            if (edit)
                this.editTask.productGroups[index].filteredModels = this.allModels;
            else
                this.taskProductGroups[index].filteredModels = this.allModels;
    };

    filterEditModels = async (e, index) => {
        this.editTask.productGroups[index].models = e;

        if (!this.editTask.productGroups[index].category && e.length === 1) {
            this.modelsDisabled = true;
            let categoryId = this.allModels.find(el => el.id === e[0]).categories;
            if (categoryId) {
                categoryId = await this.findProductGroup(categoryId);
                this.editTask.productGroups[index].category = categoryId;
                this.changeTaskModelOption(categoryId, index, true);
            }
            this.modelsDisabled = false;;
        }
    };

    changePerformer = (e, labels) => {
        let performers = [];
        for (let [idx, value] of e.entries()) {
            performers.push({
                value: value,
                label: labels[idx] ? labels[idx] : this.userForReactSelect.label
            })
        }
        this.taskPerformer = performers;
        this.checkTaskToActivateSaveButton();
    };

    changeEditPerformer = (e, labels) => {
        let performers = [];
        for (let [idx, value] of e.entries()) {
            performers.push({
                value: value,
                label: labels[idx] ? labels[idx] : this.userForReactSelect.label
            })
        }
        this.editTask.performers = performers;
        this.checkEditTaskToActivateSaveButton();
    };

    changeEditTheme = (e) => {
        this.editTask.theme = e.target.value;
        this.checkEditTaskToActivateSaveButton();
    };

    changeEditContractConsignment = (e, index) => {
        this.editTask.contracts[index].batches = e;
    };

    changeEditDate = (value, dateString) => {
        this.editTask.start = dateString[0];
        this.editTask.end = dateString[1];
        this.checkEditTaskToActivateSaveButton();
    };

    changeNeedNotice = (e) => {
        this.editTask.needNotice = e.target.checked;
    };

    changeEditDescription = (e) => {
        this.editTask.description = e.target.value;
    };

    setMeToTask = _ => {
        this.taskPerformer = [this.userForReactSelect];
        this.checkTaskToActivateSaveButton();
    };

    setMeToEditTask = _ => {
        this.editTask.performers = [this.userForReactSelect];
        this.checkEditTaskToActivateSaveButton();
    };

    changeTaskTheme = (e) => {
        this.taskTheme = e.target.value;
        this.checkTaskToActivateSaveButton();
    }

    checkTaskToActivateSaveButton = _ => {
        let theme = this.taskTheme;
        let performers = this.taskPerformer !== null
            ? this.taskPerformer
            : [];
        let taskType = this.taskType;

        let checking = theme !== '' && taskType > 0 && performers.length > 0 &&
            this.taskEndDatetime && this.taskEndDatetime;

        this.saveButtonDisabled = !checking;
    };

    checkVacationSaveButton = _ => {
        let type = this.typeVacation;
        let desc = this.descriptionVacation;
        let startDate = this.startVacation;
        let endDate = this.endVacation;
        let checking = type === undefined || desc === undefined || startDate === undefined || endDate === undefined;

        this.saveVacationButtonDisabled = checking;
    };

    checkEditVacationSaveButton = _ => {
        let type = this.typeEditVacation;
        let desc = this.descriptionEditVacation;
        let startDate = this.startEditVacation;
        let endDate = this.endEditVacation;
        let checking = type === undefined || desc === undefined || startDate === undefined || endDate === undefined;

        this.saveEditVacationButtonDisabled = checking;
    };

    checkEditTaskToActivateSaveButton = _ => {
        let theme = document.getElementById('taskEditTheme').value;
        let performers = this.editTask.performers !== null
            ? this.editTask.performers
            : [];
        let startDate = this.editTask.start;
        let endDate = this.editTask.end;

        let checking = theme !== '' && performers.length > 0 && startDate !== '' && endDate !== '';

        this.saveButtonDisabled = !checking;
    };

    renderPerformers = ({ id, userSurname, userName }) => {
        return (
            <span key={"performersShow" + id}>{" " + userName + " " + userSurname}</span>
        )
    };

    renderModels = ({ value, label }) => {
        return (
            <span key={"modelsShow" + value}>{label}; </span>
        )
    };

    renderTaskStatuses = ({ value, label }) => (
        <option key={"taskStatuses" + value} value={value}>{label}</option>
    );

    openModalTask = ({ event }) => {
        let taskId = event;
        if (event.hasOwnProperty('_def')) {
            taskId = +event._def.publicId;
        }
        if (Number.isInteger(+event)) {
            taskId = +event;
        }
        this.openTask(taskId);
    };

    openModalTaskView = ({ event, jsEvent }) => {
      const { clientX, clientY } = jsEvent || {};
      this.cursorPosition = jsEvent ? {clientX, clientY} : null;
        let taskId = 0;
        if (event.hasOwnProperty('_def')) {
            taskId = +event._def.publicId;
        }
        if (Number.isInteger(+event)) {
            taskId = +event;
        }
        this.openTaskView(taskId);
    };

    handleEventDrop = (info) => {
        if (window.confirm("Are you sure you want to change the event date and time?")) {
            this.updateAppointment(info.event.extendedProps, info.event.id, info.event.start, info.event.end)
        } else {
            info.revert();
        }
    };

    updateAppointment = (extend, taskId, start, end) => {
        //Проверка значений
        let startDate = new Date(start).toString();
        let formatStart = dayjs(startDate).format("YYYY-MM-DD HH:mm:ss");
        let endDate = new Date(end).toString();
        let formatEnd = dayjs(endDate).format("YYYY-MM-DD HH:mm:ss");


        let checking = start instanceof Date;

        if (checking) {
            axios.post('/api/node/v1/task/edit/updatedate', {
                taskId: taskId,
                start: formatStart,
                end: formatEnd
            })
                .then(response => {
                    console.log('response from server', response);
                    //Загрузка файлов

                    if (response.hasOwnProperty('data')) {

                    }
                    //Добавить всплывающее окно при сохранении таска
                    setTimeout(this.closeModal, 2000);
                })
                .catch(error => {
                    console.log(error)
                });
        }
    };

    openTask = (taskId) => {
        for (let i = 0; i < this.allTasks.length; i++) {
            if (+this.allTasks[i].id === +taskId) {
                this.updateNotices(this.allTasks[i].id);
                this.isModalTaskOpen = true;
                this.activeTask = this.allTasks[i];
            }
        }
    };

    getUserVacationsHand = (id) => {
        for (let i = 0; i < this.filteredUsers.length; i++) {
            if (+this.filteredUsers[i].id === +id) {
                this.isModalUserVacationOpen = true;
                this.vacationUserName = this.filteredUsers[i].label;
            }
        }

        fetch('/api/node/v1/get_user_vacations_hand/' + id)
            .then(response => response.json())
            .then(response => {
                if (response.length >= 0 && Array.isArray(response)) {
                    this.userForVacationHand = response;
                } else { console.log("Ошибка при получении") }

            })
            .catch(err => console.error(err));
    };

    openTaskView = (taskId) => {
        for (let i = 0; i < this.allTasks.length; i++) {
            if (+this.allTasks[i].id === +taskId) {
                this.isModalView = true;
                this.activeTask = this.allTasks[i];
            }
        }
    };

    closeTaskView = _ => {
        this.isModalView = false;
        this.cursorPosition = {};
    };

    changeCommentInput = _ => {
        let comment = document.getElementById('comment').value;
        let disabled = false;
        if (comment === '') disabled = true;

        if (this.taskCommentButtonDisabled !== disabled) {
            this.taskCommentButtonDisabled = disabled
        }
    };

    closeModalTask = () => {
        this.isModalTaskOpen = false;
    };

    openCalendarType = (type) => {
        if (type === 'users') {
            this.selectedTaskType = 'All tasks';
            this.commonFilterTasks();
        }
        this.calendar = type;
    };

    getStatistics = (type) => {
        this.calendar = type;

        this.getTasks(true);
    };

    getUsersVacations = (type, year = new Date().getFullYear()) => {
        this.calendar = type;

        fetch('/api/node/v1/get_users_vacations/' + year)
            .then(response => response.json())
            .then(response => {
                if (response.length >= 0 && Array.isArray(response)) {
                    this.usersForVacation = response;
                    this.usersForVacationFiltered = response;
                    this.commonFilterTasks();
                } else { console.log("Ошибка при получении") }

            })
            .catch(err => console.error(err));
    };

    downloadFile = (fileId, filename) => {
        axios.get('/api/node/v1/task/file/get/' + fileId,
            {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                console.log('response: ', response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click()
            })
    };

    deleteFile = (fileId, type, name) => {
        if (window.confirm("Удалить файл " + name + "?")) {
            fetch('/api/node/v1/task/file/delete/' + fileId)
                .then(response => response.json())
                .then(response => {
                    if (response.hasOwnProperty('files')) {
                        if (response.status !== 'file was deleted') return;

                        let task = this.activeTask;

                        if (type === 'task') {
                            task = this.editTask;
                            task.files = response.files;
                        }

                        if (type === 'comment') {
                            for (let i = 0; i < task.comments.length; i++) {
                                for (let j = 0; j < task.comments[i].files.length; j++) {
                                    if (+task.comments[i].files[j].id === +fileId) {
                                        task.comments[i].files = response.files;
                                    }
                                }
                            }
                        }

                        this.activeTask = task;
                        this.editTask = task;
                    }
                })
                .catch(err => console.error(err))
        }
    };

    toggleWeekends = (e) => {
        this.calendarWeekends = e.target.checked;
    };

    toggleFinished = (e) => {
        this.showFinishedTasks = e.target.checked;
        this.commonFilterTasks();
    };

    handleChangeNotice = (e) => {
        if (e.target.checked !== this.needNotice) {
            this.needNotice = e.target.checked;
        }
    };

    renderDepartments = ({ id, name_en, name_cn }) => {
        let label = name_en + " (" + name_cn + ")";
        return (
            id !== 5 &&
            <Option key={"selectDepartment" + id}
                value={id + '_' + label}>{label}
            </Option>
        )
    };


    renderConsignment = (item) => {
        return (
            <Option key={"selectDepartment" + item}
                value={item}>{item}
            </Option>
        )
    };

    renderUserForVacations = ({ id, count_day, date, description, type }) => {
        return (
            <tr key={'user_vacation_' + id}>
                <td>{date}</td>
                <td>{count_day}</td>
                <td>{type}</td>
                <td>{description}</td>
            </tr>
        )
    };

    renderFactories = ({ id, abbreviation }) => {
        return (
            <Option key={"selectFactory" + id}
                value={id}>{abbreviation}
            </Option>
        )
    };

    renderUsers = ({ id, label }) => {
        return (
            <Option key={"selectUsers" + id}
                value={id + '_' + label}>{label}
            </Option>
        )
    };

    renderTestTypeText = (key) => {
        let tasksTestTypes = this.tasksTestTypes;
        for (let i = 0; i < tasksTestTypes.length; i++) {
            if (tasksTestTypes[i].id === key)
                return (
                    <span key={'key' + key}>
                        &nbsp; {tasksTestTypes[i].name}
                    </span>
                )
        }
    };

    renderTaskType = (key) => {
        return (
            <Option key={"selectTaskTypeFilter" + key}
                value={key}>{key}
            </Option>
        )
    };

    renderOption = ({ id, name, disabled }, key) => {
        return (
            <Option key={key + '_' + id} disabled={disabled}
                value={id}>{name}
            </Option>
        )
    };

    handleChangeFilterType = (e, field, dateString) => {
        if (field === 'selectedTaskDate') {
            this.selectedStart = dateString[0];
            this.selectedEnd = dateString[1];
            this.commonFilterTasks();
        } else if (field === 'selectedTheme') {
            this.selectedTheme = e.target.value;
            this.commonFilterTasks();
        } else {
            this[field] = e;
            this.commonFilterTasks();
        }
    };

    updateNotices = async (taskId) => {
        await fetch(`/api/node/v1/notices/updateAccessByObject/${taskId}`);
        return;
    }

    addContract = () => {
        this.taskContracts.push({
            contract_id: undefined,
            batches: []
        })
    }

    addEditContract = () => {
        this.editTask.contracts.push({
            contract_id: undefined,
            batches: []
        })
    }

    addProductGroup = () => {
        this.taskProductGroups.push({
            category: undefined,
            models: [],
            filteredModels: this.allModels
        })
    };

    addEditProductGroup = () => {
        this.editTask.productGroups.push({
            category: undefined,
            models: [],
            filteredModels: this.allModels
        })
    };

    updateStructure = async () => {
        await fetch('/api/node/v1/task/updateDataStructure');
    }

}

export default TasksStore;
