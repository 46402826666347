const tabConf = {
  tabConf: {
    3: 'specification',
    4: 'testReport',
    5: 'sample_test_test_results',
  },
  tabComment: {
    3: 'techSpecificationComment',
    4: 'testReportComment',
    5: 'testResultComment',
  },
}
export default tabConf
