module.exports.PasteList = [
    'supplierModel',
    'inspectionTime',
    'qtyInTransportBox',
    'width',
    'height',
    'depth',
    'GW',
    'qtyInIntermediateBox',
    'intermediateWidth',
    'intermediateHeight',
    'intermediateDepth',
    'intermediateGW',
    'individualWidth',
    'individualHeight',
    'individualDepth',
    'individualGW',
    'assembledWidth',
    'assembledHeight',
    'assembledDepth',
    'NW'
];

module.exports.numberValues = [
    'inspectionTime',
    'qtyInTransportBox',
    'width',
    'height',
    'depth',
    'GW',
    'qtyInIntermediateBox',
    'intermediateWidth',
    'intermediateHeight',
    'intermediateDepth',
    'intermediateGW',
    'individualWidth',
    'individualHeight',
    'individualDepth',
    'individualGW',
    'assembledWidth',
    'assembledHeight',
    'assembledDepth',
    'NW'
];

module.exports.FiltersParams = {
    id: null,
    intCode1: {
        opened: false,
        filtered: [],
        input: [],
        options: []
    },
    intCode2: {
        opened: false,
        filtered: [],
        input: [],
        options: []
    },
    subIntCode1: {
        opened: false,
        filtered: [],
        input: [],
        options: []
    },
    subIntCode2: {
        opened: false,
        filtered: [],
        input: [],
        options: []
    },
    productGroupId: {
        opened: false,
        options: [],
        filtered: [],
        input: []
    },
    category1: {
        opened: false,
        options: [],
        filtered: [],
        input: []
    },
    category2: {
        opened: false,
        filtered: [],
        options: [],
        input: []
    },
    node: {
        opened: false,
        filtered: '',
        input: ''
    },
    article: {
        opened: false,
        filtered: [],
        options: [],
        input: []
    },
    customerModel: {
        opened: false,
        options: [],
        filtered: [],
        input: []
    },
    supplierModel: {
        opened: false,
        filtered: '',
        input: ''
    },
    supplierCode: {
        opened: false,
        filtered: [],
        input: []
    },
    date_of_confirmation: {
        opened: false,
        filtered: [],
        input: []
    },
    modification_date: {
        opened: false,
        filtered: '',
        input: ''
    },
    sample_test_sku: {
        opened: false,
        filtered: [],
        options: [
            {
                value: 1,
                label: 'w_recommended'
            },
            {
                value: 3,
                label: 'w_rejected'
            },
            {
                value: 2,
                label: 'w_no_status'
            }
        ],
        input: []
    },
    goodsNameForBL: {
        opened: false,
        filtered: '',
        input: ''
    },
    HS_code: {
        opened: false,
        filtered: '',
        input: ''
    },
    brand: {
        opened: false,
        options: [],
        filtered: [],
        input: []
    },
    accessories: {
        opened: false,
        options: [],
        filtered: [],
        input: []
    },
    containsBattery: {
        opened: false,
        filtered: '',
        input: ''
    },
    shipmentCondition: {
        opened: false,
        filtered: '',
        input: ''
    },
};
