import {makeAutoObservable} from "mobx";
import api from '../utils/axiosAuthInterceptor';
import {notification} from "antd";
import i18next from "i18next";
import download from 'downloadjs'
import dayjs from "dayjs";
const axios = api

class PostfixesStore {
  isMainLoading = false
  isEditting = false
  isExcelBtnLoading = false
  btnLoading = {
    id: null,
    type: null,
  } 
  postfixesData = [];
  user = {}
  rowBeforeEdit = null;
  paginations = {
    page : 1,
    pageSize: 10,
    totalPostfixes: null
}
  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

 //Main model CRUD
 getPostfixes = async () => {
  try{
    this.isMainLoading = true
    const params = {
      page: this.paginations.page,
      pageSize: this.paginations.pageSize
    }
    let response = await axios.get(`/api/node/v1/postfixes`, {params})
    this.postfixesData = response.data.data.rows
    this.paginations.totalPostfixes = response.data.data.count
    this.user = response.data.data.user
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

createPostfix= async (body) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: 'New',
      type: 'save'
    }
    await axios.post(`/api/node/v1/postfixes`, body)
    await this.getPostfixes()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

updatePostfix = async (body) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: body.id,
      type: 'save'
    }
    await axios.put(`/api/node/v1/postfixes`, body)
    await this.getPostfixes()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

deletePostfix = async (id) => {
  try{
    this.isMainLoading = true
    this.btnLoading = {
      id: id,
      type: 'delete'
    }
    await axios.delete(`/api/node/v1/postfixes/${id}`)
    await this.getPostfixes()
    this.openNotificationWithIcon('success', i18next.t('w_success'))
  } catch (error) {
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.clearMainState()
  }
}

//Row operations

changePage = (e) => {
  this.paginations.page = e.current
  this.getPostfixes()
}

changeSize = (current, size) => {
  this.paginations.pageSize = size
} 

editRow = async (index) => {
  this.rowBeforeEdit = {...this.postfixesData[index]}
  this.postfixesData[index].isEdit = true;
  this.isEditting = true
}

onFinishMainForm = async (values) => {
  if (this.rowBeforeEdit) {
    values.id = this.rowBeforeEdit.id
    await this.updatePostfix(values)
  } else {
    await this.createPostfix(values)
  }
}

onFinisFailedMainForm = () => {
  this.openNotificationWithIcon(
    'error',
    i18next.t('w_form_finish_failed')
  )
}

addRow = () => {
  let temp = [...this.postfixesData];
  this.isEditting = true
  temp.unshift({
    id: 'New',
    name: '',
    isEdit: true
  })
  this.postfixesData = temp
}

cancelEdit = (index) => {
  let temp = [...this.postfixesData]
  if(temp[index].id === 'New'){
    temp.splice(index, 1)
  } else {
    temp[index] = this.rowBeforeEdit
  }
  temp[index].isEdit = false
  this.postfixesData = temp
  this.clearMainState()
}



//Other

openNotificationWithIcon = (type, message) => {
  notification[type]({
    message: message,
    placement: 'bottomRight'
  });
};

clearMainState = () => {
  this.isEditting = false
  this.isMainLoading = false
  this.btnLoading = {
    id: null,
    type: null
  }
  this.rowBeforeEdit = null
}

getPostfixesExcel = async () => {
  try{
    this.isExcelBtnLoading = true
    const params = {
      page: null,
      pageSize: null
    }

    const response = await axios({
      url: '/api/node/v1/postfixes/excel',
      responseType: 'blob',
      method: 'get',
      params
    })
    const blob = await response.data
    download(blob, `Postfixes ${dayjs().format('DD.MM.YYYY')}.xlsx`)
  } catch (error) {
    console.error(error)
    this.openNotificationWithIcon('error', i18next.t('w_error'))
  } finally {
    this.isExcelBtnLoading = false
  }
}

}

export default PostfixesStore;
