import {makeAutoObservable} from "mobx";
import {notification} from "antd";
import api from "../utils/axiosAuthInterceptor";
import i18next from "i18next";
import download from 'downloadjs'
const axios = api
const moment = require('moment')

class DestinationPorts {
  isMainLoading = false
  isEditting = false
  isExcelBtnLoading = false
  btnLoading = {
    id: null,
    type: null,
  }
  paginations = {
    page : 1,
    pageSize: 10,
    totalDestinationPorts: null
  }
  rowBeforeEdit = null;
  destinationPortsData = [];
  user = {};

  constructor() {
    makeAutoObservable(this, {}, { deep: true });
  }

  //Main model CRUD
  getDestinationPorts = async() => {
    try{
      this.isMainLoading = true
      const params = {
        page: this.paginations.page,
        pageSize: this.paginations.pageSize
      }
      const response = await axios.get(`/api/node/v1/destinationPorts`, {params})
      this.destinationPortsData = response.data.data.rows
      this.paginations.totalDestinationPorts = response.data.data.count
      this.user = response.data.data.user
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  createDestinationPort = async (body) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: 'New',
        type: 'save'
      }
      await axios.post(`/api/node/v1/destinationPorts`, body)
      await this.getDestinationPorts()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  updateDestinationPort = async (body) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: body.id,
        type: 'save'
      }
      await axios.put(`/api/node/v1/destinationPorts`, body)
      await this.getDestinationPorts()
      this.openNotificationWithIcon('success', i18next.t('w_success'))
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  deleteDestinationPort = async (id) => {
    try{
      this.isMainLoading = true
      this.btnLoading = {
        id: id,
        type: 'delete'
      }
      await axios.delete(`/api/node/v1/destinationPorts/${id}`)
      await this.getDestinationPorts()
      this.openNotificationWithIcon('success', i18next.t('w_success'));
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.clearMainState()
    }
  }

  //Row operations main model
  editRow = async (index) => {
    this.rowBeforeEdit = {...this.destinationPortsData[index]}
    this.destinationPortsData[index].isEdit = true;
    this.isEditting = true
  }

  onFinishMainForm = async (values) => {
    if (this.rowBeforeEdit) {
      values.id = this.rowBeforeEdit.id
      await this.updateDestinationPort(values)
    } else {
      await this.createDestinationPort(values)
    }
  }

  onFinisFailedMainForm = () => {
    this.openNotificationWithIcon(
      'error',
      i18next.t('w_form_finish_failed')
    )
  }
  addRow = () => {
    let temp = [...this.destinationPortsData];
    this.isEditting = true
    temp.unshift({
      id: 'New',
      name: '',
      isEdit: true,
    })
    this.destinationPortsData = temp
  }

  cancelEdit = (index) => {
    let temp = [...this.destinationPortsData]
    if(temp[index].id === 'New'){
      temp.splice(index, 1)
    } else {
      temp[index] = this.rowBeforeEdit
    }
    temp[index].isEdit = false
    this.destinationPortsData = temp
    console.log(index, this.rowBeforeEdit )
    this.clearMainState()
  }

  //Other
  changePage = (e) => {
    this.paginations.page = e.current
    this.getDestinationPorts()
  }

  changeSize = (current, size) => {
    this.paginations.pageSize = size
  } 

  clearMainState = () => {
    this.isEditting = false
    this.isMainLoading = false
    this.btnLoading = {
      id: null,
      type: null
    }
    this.rowBeforeEdit = null
  }
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: 'bottomRight'
    });
  };

  getDestinationPortsExcel = async () => {
    try{
      this.isExcelBtnLoading = true
      const params = {
        page: null,
        pageSize: null
      }
  
      const response = await axios({
        url: '/api/node/v1/destinationPorts/excel',
        responseType: 'blob',
        method: 'get',
        params
      })
      const blob = await response.data
      download(blob, `Destination ports ${moment().format('DD.MM.YYYY')}.xlsx`)
    } catch (error) {
      console.error(error)
      this.openNotificationWithIcon('error', i18next.t('w_error'))
    } finally {
      this.isExcelBtnLoading = false
    }
  }
}

export default DestinationPorts;
